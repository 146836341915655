import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspCategorySparesListService {

  constructor(
    private http: HttpClient,
  ) { }

  getCategorySparesList(rowKey, variantKey, selectedCategory, garageKey, universal, brandKeys){
    let paramData = {      
      rowKey: rowKey,
      variantKey: variantKey,
      category: selectedCategory,
      brandKeys: brandKeys,
      searchKey: "",
      sortingOption: "",
      universal:universal,
      garageKey: garageKey
    };

    // console.log("------------paramData");
    // console.log(paramData);

    console.log('-----Get All Category Spares List-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/getCategorySpares?page=0&size=50', paramData);
  
  }

  getItemStock(sku){
    console.log('-----Get Item Stock-----------');   
    return this.http.patch(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/inventory/item/sync/'+sku, {});
  }
  
}
