import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';


@Injectable({
  providedIn: 'root'
})
export class AspPaymentService {

  constructor(
    private http: HttpClient,
  ) { }

  getUserDetails(garageKey){    
    console.log('-----Get User Details-----------');    
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/garage/address/getAll/'+garageKey);
  }

  createOrder(shippingAddressId, billingAddressId, paymentMethod, razorPayPaymentId, orderKey){
    // let userKey = localStorage.getItem('spareUserKey');
    let userId = localStorage.getItem('spareUserId');
    let userType = localStorage.getItem('spareUserType');

    let rowKey = '';
    if(localStorage.getItem('rowKey'))
      rowKey = localStorage.getItem('rowKey');

    let variantKey = '';
    if(localStorage.getItem('variantKey'))
      variantKey = localStorage.getItem('variantKey');

    let garageKey = localStorage.getItem('garageKey');
     
    let cartList = []; 
    if(sessionStorage.getItem('addedCart')){         
      let cartData;  
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      // Object.values(cartData[0]).forEach(cart => { 
      //   cartList.push({"partKey": cart['key'], "quantity": cart['cart_qty']});
      // });
      // console.log(cartList);
  
      Object.values(cartData[0]).forEach(cart => { 
        var rowKey = cart['rowKey'];
        var existingItem = cartList.find(function(item) {
          return item.rowKey === rowKey;
        });
  
        if (existingItem) {
          existingItem.items.push({
            partKey: cart['key'],
            quantity: cart['cart_qty']
          });
        } else {
          cartList.push({
            rowKey: rowKey,
            variantKey: cart['variantKey'],
            items: [{
              partKey: cart['key'],
              quantity: cart['cart_qty']
            }]
          });
        }
      });
    }

    
    let paramData = {      
      id: userId,
      garageKey: garageKey,
      userType: userType,
      orderKey: orderKey,
      razorPayPaymentId: razorPayPaymentId,
      paymentMethod: paymentMethod,
      shippingAddressId: shippingAddressId,
      billingAddressId: billingAddressId,
      source: 'garageweb',
      data: cartList
    };

    // console.log(`${GlobalVariable.BASE_API_URL_SPARES}` + '/order/create');
    // console.log(paramData);
    console.log('-----Create Order-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/v2/order/create', paramData);
  
  }

  createRazorPayOrder(orderKey, agentKey, garageKey, grandTotal, orderType, userType){
    let paramData = {      
      orderKey: orderKey,
      agentKey: agentKey,
      garageKey: garageKey,
      userType: userType,
      amount: grandTotal,
      orderType: orderType,
    };

    // console.log(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/createRazorPayOrder');
    // console.log(paramData);
    console.log('-----Create RazorPay Order-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/createRazorPayOrder', paramData);
  
  }

  verifyPayment(razorPayPaymentId, razorPayOrderId, razorPaySignature, orderKey){
    let paramData = {      
      razorPayPaymentId: razorPayPaymentId,
      razorPayOrderId: razorPayOrderId,
      razorPaySignature: razorPaySignature,
      orderKey: orderKey
    };

    // console.log(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/verifyPayment');
    // console.log(paramData);
    console.log('-----Verify Payment-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/verifyPayment', paramData);
  
  }

}
