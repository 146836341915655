<div id="invoice" class="compact page-layout blank" fxLayout="row">
    <div class="invoice-container">
        <button *ngIf="!loading || !pdfLoading" [disabled]="pdfLoading" mat-raised-button class="mb-16" color="accent"
            (click)="generatePDF()">Download
            Invoice</button>
        <mat-progress-bar *ngIf="loading || pdfLoading" mode="indeterminate"></mat-progress-bar>
        <div class="card" *ngIf="!loading" id="contentToConvert">
            <div class="header">
                <div class="invoice-date">{{invoiceTitle}} <br><strong>INVOICE DATE: </strong> {{invoiceDate}}</div>
                <div class="mb-32" fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div class="client w-50-p">
                        <div class="invoice-number" fxLayout="row" fxLayoutAlign="start center">
                            <span class="title">ORDER ID:</span>
                            <span class="number ml-4">{{id}}</span>
                        </div>
                        <div class="invoice-number" fxLayout="row" fxLayoutAlign="start center">
                            <span class="title">SERVICE DATE:</span>
                            <span class="date ml-4">{{serviceDate}}</span>
                        </div>
                        <div class="invoice-number" fxLayout="row" fxLayoutAlign="start center">
                            <span class="title">DELIVERY DATE:</span>
                            <span class="date ml-4">{{deliveryDate}}</span>
                        </div>
                    </div>
                    <div class="client w-50-p">
                        <div class="invoice-number" fxLayout="row" fxLayoutAlign="start center">
                            <span class="title">REGISTRATION NUMBER:</span>
                            <span class="date ml-4">{{regNum}}</span>
                        </div>
                        <div class="invoice-number" fxLayout="row" fxLayoutAlign="start center">
                            <span class="title">MAKE / MODEL / FUEL TYPE:</span>
                            <span class="date ml-4 text-capitalize">{{makeModelFuel}}</span>
                        </div>
                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                            <span class="title">ODOMETER:</span>
                            <span class="date ml-4">{{odometer}}</span>
                        </div>
                    </div>
                </div>
                <div class="text-center mb-32">
                    <div class="mat-display-1 m-0 w-100-p invoice-title">TAX INVOICE</div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div class="text-left mat-headline m-0 mb-8 w-50-p">From</div>
                    <div class="text-left mat-headline pl-16 pr-0 m-0 mb-8 w-50-p">To</div>
                </div>
                <div class="mb-32" fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div class="issuer pl-16 w-50-p" fxLayout="row" fxLayoutAlign="start center">
                        <div class="logo">
                            <img src="assets/images/logos/logo.png">
                        </div>
                        <div class="info">
                            <div class="title text-capitalize">{{billFromName}}</div>
                            <div class="address">{{billFromAddress}}</div>
                            <div *ngIf="billFromGst !== ''" class="phone">GST: {{billFromGst}}</div>
                            <div *ngIf="billFromPan !== ''" class="email">PAN: {{billFromPan}}</div>
                        </div>
                    </div>
                    <div class="client p-16 pr-0 w-50-p">
                        <div class="info">
                            <div class="title text-capitalize">{{billToName}}</div>
                            <div class="address">{{billToAddress}}</div>
                            <div *ngIf="billToGst !== ''" class="phone">GST: {{billToGst}}</div>
                            <div *ngIf="billToPan !== ''" class="email">PAN: {{billToPan}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <table class="invoice-table" border="1">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>PRODUCT</th>
                            <th class="text-center">HSN</th>
                            <th class="text-center">SAC</th>
                            <th class="text-center">PRICE</th>
                            <th class="text-center">QTY</th>
                            <th class="text-center">SPARE</th>
                            <th class="text-center">LABOUR</th>
                            <th class="text-center" colspan="2">CGST</th>
                            <th class="text-center" colspan="2">SGST</th>
                            <th class="text-center" colspan="2">IGST</th>
                            <th class="text-center">TOTAL</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <td class="text-center">RATE</td>
                            <td class="text-center">AMOUNT</td>
                            <td class="text-center">RATE</td>
                            <td class="text-center">AMOUNT</td>
                            <td class="text-center">RATE</td>
                            <td class="text-center">AMOUNT</td>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of invoiceItems; let i=index">
                            <td>{{i+1}}</td>
                            <td>
                                {{item.itemName}}
                            </td>
                            <td class="text-center">
                                {{item.itemTaxType === 'hsn' ? item.itemTaxCode : '-'}}
                            </td>
                            <td class="text-center">
                                {{item.itemTaxType === 'sac' ? item.itemTaxCode : '-'}}
                            </td>
                            <td class="text-center" *ngIf="!isSingapore">
                                {{item.itemUnitPrice | currency:"&#8377; "}}
                            </td>
                            <td class="text-center" *ngIf="isSingapore">
                                {{item.itemUnitPrice | currency:"S&#36; "}}
                            </td>
                            <td class="text-center">
                                {{item.itemQuantity}}
                            </td>
                            <td class="text-center" *ngIf="!isSingapore">
                                {{item.partsTotal | currency:"&#8377; "}}
                            </td>
                            <td class="text-center" *ngIf="isSingapore">
                                {{item.partsTotal | currency:"S&#36; "}}
                            </td>
                            <td class="text-center" *ngIf="!isSingapore">
                                {{item.labourTotal | currency:"&#8377; "}}
                            </td>
                            <td class="text-center" *ngIf="isSingapore">
                                {{item.labourTotal | currency:"S&#36; "}}
                            </td>
                            <td class="text-center">
                                {{totalIgst > 0? '0' : item.cgstRate * 100}} %
                            </td>
                            <td class="text-center" *ngIf="!isSingapore">
                                {{totalIgst > 0? '0' : item.cgst | currency:"&#8377; "}}
                            </td>
                            <td class="text-center" *ngIf="isSingapore">
                                {{totalIgst > 0? '0' : item.cgst | currency:"S&#36; "}}
                            </td>
                            <td class="text-center">
                                {{totalIgst > 0? '0' : item.sgstRate * 100}} %
                            </td>
                            <td class="text-center" *ngIf="!isSingapore">
                                {{totalIgst > 0? '0' : item.sgst | currency:"&#8377; "}}
                            </td>
                            <td class="text-center" *ngIf="isSingapore">
                                {{totalIgst > 0? '0' : item.sgst | currency:"S&#36; "}}
                            </td>
                            <td class="text-center">
                                {{totalIgst === 0? '0' : item.igstRate * 100}} %
                            </td>
                            <td class="text-center" *ngIf="!isSingapore">
                                {{totalIgst === 0? '0' : item.igst | currency:"&#8377; "}}
                            </td>
                            <td class="text-center" *ngIf="isSingapore">
                                {{totalIgst === 0? '0' : item.igst | currency:"S&#36; "}}
                            </td>
                            <td class="text-center" *ngIf="!isSingapore">
                                {{item.itemTotal | currency:"&#8377; "}}
                            </td>
                            <td class="text-center" *ngIf="isSingapore">
                                {{item.itemTotal | currency:"S&#36; "}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="simple invoice-table-footer">
                    <tbody>
                        <tr class="tax" *ngIf="!isSingapore">
                            <td>TOTAL BEFORE TAX</td>
                            <td>{{subTotal | currency:"&#8377; "}}</td>
                        </tr>
                        <tr class="tax" *ngIf="isSingapore">
                            <td>TOTAL BEFORE TAX</td>
                            <td>{{subTotal | currency:"S&#36; "}}</td>
                        </tr>
                        <tr class="tax" *ngIf="!isSingapore">
                            <td>TOTAL SGST</td>
                            <td>{{totalSgst | currency:"&#8377; "}}</td>
                        </tr>
                        <tr class="tax" *ngIf="isSingapore">
                            <td>TOTAL SGST</td>
                            <td>{{totalSgst | currency:"S&#36; "}}</td>
                        </tr>
                        <tr class="tax" *ngIf="!isSingapore">
                            <td>TOTAL CGST</td>
                            <td>{{totalCgst | currency:"&#8377; "}}</td>
                        </tr>
                        <tr class="tax" *ngIf="isSingapore">
                            <td>TOTAL CGST</td>
                            <td>{{totalCgst | currency:"S&#36; "}}</td>
                        </tr>
                        <tr class="tax" *ngIf="!isSingapore">
                            <td>TOTAL IGST</td>
                            <td>{{totalIgst | currency:"&#8377; "}}</td>
                        </tr>
                        <tr class="tax" *ngIf="isSingapore">
                            <td>TOTAL IGST</td>
                            <td>{{totalIgst | currency:"S&#36; "}}</td>
                        </tr>
                        <tr class="tax" *ngIf="discount > '0'">
                            <td>TOTAL DISCOUNT</td>
                            <td *ngIf="!isSingapore">{{discount | currency:"&#8377; "}}</td>
                            <td *ngIf="isSingapore">{{discount | currency:"S&#36; "}}</td>
                        </tr>
                        <tr class="total">
                            <td>NET PAYMENT DUE</td>
                            <td *ngIf="!isSingapore">{{gTotal | currency:"&#8377; "}}</td>
                            <td *ngIf="isSingapore">{{gTotal | currency:"S&#36; "}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="footer">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <div class="logo">
                        <img src="assets/images/logos/logo.png">
                    </div>
                    <div class="note">
                        Thank you very much for availing car service from {{billFromName}}. <br>
                        We look forward to serve you again!
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>