import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { LoginService } from 'app/main/authentication/login/login.service';
import { GlobalVariable } from 'app/main/globals';
import { first } from 'rxjs/operators';
import { BookingDetailsService } from '../booking-details.service';

@Component({
    selector: 'add-notes',
    templateUrl: 'add-notes.component.html',
    styleUrls: ['./add-notes.component.scss'],
})

export class AddNotesComponent {
    status: string;
    leadId: string;
    public addNoteForm: FormGroup;
    error = false;
    form: any;
    notesLoading = false;
    notesDataReceived = false;
    loginUserData: any;
    accountAppKey: any;
    constructor(
        private bottomSheetRef: MatBottomSheetRef<AddNotesComponent>,
        private _fb: FormBuilder,
        private router: Router,
        private loginService: LoginService,
        private bdService: BookingDetailsService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        this.status = data.status;
        this.leadId = data.leadId;
        this.addNoteForm = this._fb.group({
            note: ['', Validators.required]
        });
        // this.bottomSheetRef.afterDismissed().subscribe(() => {
        //     console.log('Bottom sheet has been dismissed.');
        // });
    }

    addNote() {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        this.notesLoading = true;
        this.form = this.addNoteForm.value;
        const formData: FormData = new FormData();
        formData.append('appKey', this.accountAppKey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('language', `${GlobalVariable.LANGUAGE}`);
        formData.append('device', `${GlobalVariable.DEVICE}`);
        formData.append('id', this.leadId);
        formData.append('status', this.status);
        formData.append('type', 'notes');
        formData.append('notes', this.form.note);
        formData.append('subject', '');
        formData.append('reason', '');
        formData.append('zoneNo', '');
        formData.append('userType', 'gms');
        this.bdService.addNotes(formData).pipe(first()).subscribe(
            result => {
                if (result.response.status === 'SUCCESS') {
                    this.error = false;
                    this.notesLoading = false;
                    this.notesDataReceived = false;
                    this.bottomSheetRef.dismiss();
                    this.router.navigate(['bookings/details/' + this.leadId]);
                } else {
                    this.notesLoading = false;
                    this.error = true;
                }
            },
            error => {
                console.log(error);
                this.notesLoading = false;
                this.error = true;
            }
        );
    }
}
