<mat-progress-bar
    class="mt_progres"
    *ngIf="changeStatusLoading"
    mode="indeterminate"
></mat-progress-bar>
<div class="page-layout carded fullwidth p-16" fusePerfectScrollbar>
    <div
        class="page-title detail_title"
        fxLayout="row"
        fxLayoutAlign="space-between center"
    >
        <span
            class="mat-display-1 m-0 p-16 pl-0 font_size"
            fxLayout="column"
            fxLayoutAlign="center start"
        >
            Booking Details</span
        >
        <div class="step_frwd">
            <span class="caps_text">Manage Bookings</span>
            <span class="caps_text dtl_color">Booking details</span>
        </div>
    </div>
    <div class="messageStatus" *ngIf="showstatusMsg">
        {{ confirmSucMessage }}
    </div>
    <div class="call_initiate" *ngIf="showInitiateCall">
        <span>Call is initiated...</span>
    </div>
    <div class="auto-width mt-20" *ngIf="!noData">
        <div class="position-relative p-16">
            <div class="md-stepper-horizontal orange-progress">
                <div
                    class="md-step position-relative p-24 pb-0 pl-0"
                    [class.active]="enquiry"
                >
                    <div class="md-step-circle">
                        <mat-icon>playlist_add_check</mat-icon>
                    </div>
                    <div
                        class="md-step-title font-size-13 font-weight-700 mt-8"
                    >
                        Enquiry
                    </div>
                    <div class="md-step-bar-left mr-20"></div>
                    <div class="md-step-bar-right ml-20"></div>
                </div>
                <div
                    class="md-step position-relative p-24 pb-0"
                    [class.active]="confirmed"
                >
                    <div class="md-step-circle confirmed">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div
                        class="md-step-title font-size-13 font-weight-700 mt-8"
                    >
                        Confirmed
                    </div>
                    <div class="md-step-bar-left mr-20"></div>
                    <div class="md-step-bar-right ml-20"></div>
                </div>
                <div
                    class="md-step position-relative p-24 pb-0"
                    [class.active]="cancelled"
                >
                    <div class="md-step-circle cancelled">
                        <mat-icon>clear</mat-icon>
                    </div>
                    <div
                        class="md-step-title font-size-13 font-weight-700 mt-8"
                    >
                        Cancelled
                    </div>

                    <div class="md-step-bar-left mr-20"></div>
                    <div class="md-step-bar-right ml-20"></div>
                </div>
                <div
                    class="md-step position-relative p-24 pb-0 pr-8"
                    [class.active]="invalid"
                >
                    <div class="md-step-circle invalid">
                        <mat-icon>not_interested</mat-icon>
                    </div>
                    <div
                        class="md-step-title font-size-13 font-weight-700 mt-8"
                    >
                        Invalid
                    </div>
                    <div class="md-step-bar-left mr-20"></div>
                    <div class="md-step-bar-right ml-20"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="order_details">
        <!-- -------------left secction start-------------- -->
        <div class="summary_section">
            <div class="bg_color_border">
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="svg_section">
                            <svg
                                xmlns:dc="http://purl.org/dc/elements/1.1/"
                                xmlns:cc="http://creativecommons.org/ns#"
                                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                                xmlns:svg="http://www.w3.org/2000/svg"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                                xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                                viewBox="0 0 32 40.00000125"
                                version="1.1"
                                x="0px"
                                y="0px"
                            >
                                <g transform="translate(0,-1020.3622)">
                                    <path
                                        d="m 9.499996,1026.6122 c -0.831,0 -1.5,0.6691 -1.5,1.5 l 0,20 c 0,0.8309 0.669,1.5 1.5,1.5 l 13,0 c 0.831,0 1.5,-0.6691 1.5,-1.5 l 0,-20 c 0,-0.8309 -0.669,-1.5 -1.5,-1.5 l -4.125,0 0,1 c 0,1.0252 -0.84995,1.875 -1.875,1.875 l -1,0 c -1.02505,0 -1.875,-0.8498 -1.875,-1.875 l 0,-1 z m 6.488951,4.5027 c 1.09906,0 2,0.9029 2,2.002 0,1.099 -0.90094,2 -2,2 -1.09906,0 -2,-0.901 -2,-2 0,-1.0991 0.90094,-2.002 2,-2.002 z m -4,4.502 1,0 0,2.5 c 2e-5,0.282 0.21794,0.5 0.5,0.5 l 5,0 c 0.28206,0 0.49998,-0.218 0.5,-0.5 l 0,-2.5 1,0 0,2.5 c -6e-5,0.8224 -0.67754,1.5 -1.5,1.5 l -5,0 c -0.82246,0 -1.49994,-0.6776 -1.5,-1.5 z m -1.5,6 11,0 0,1 -11,0 z m 0,2.5 11,0 0,1 -11,0 z m 2,2.498 7,0 0,1 -7,0 z"
                                        style="
                                            opacity: 1;
                                            fill: #0000008a;
                                            fill-opacity: 1;
                                            stroke: none;
                                            stroke-width: 0.99999994;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 4;
                                            stroke-dasharray: none;
                                            stroke-opacity: 1;
                                        "
                                    />
                                    <path
                                        d="m 14.499996,1023.1122 0,4.5 c 0,0.5523 0.44771,1 1,1 l 1,0 c 0.55227,0 1,-0.4477 1,-1 l 0,-4.5 z m 1,1.002 1,0 0,1 -1,0 z"
                                        style=""
                                        fill="#0000008a"
                                        fill-opacity="1"
                                        fill-rule="evenodd"
                                        stroke="none"
                                    />
                                </g>
                            </svg>
                        </span>
                        <span class="caps_text txt_bold blk_dspl"
                            >Booking Id: {{ id }}</span
                        >
                    </div>
                    <div class="order_price">
                        <span class="currency" *ngIf="!isSingapore">
                            {{ quotationGrandTotal | currency: "&#8377; " }}
                        </span>
                        <span class="currency" *ngIf="isSingapore">
                            {{
                                quotationGrandTotal | currency: "S&#36; "
                            }}</span
                        >
                    </div>
                </div>
                <hr />
                <div class="souce_text">
                    <p>Owner: {{ agentName }}</p>
                    <p>Source: {{ leadGen.source }}</p>
                </div>
            </div>
            <div class="bg_color_border">
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="service_icons"
                            ><i class="material-icons"> account_circle</i></span
                        >
                        <span class="caps_text txt_bold">customer details</span>
                    </div>
                    <div class="edit_icon">
                        <span class="service_icons" (click)="openEditCustomer()"
                            ><i class="material-icons"> edit</i></span
                        >
                    </div>
                </div>
                <hr />
                <div class="souce_text">
                    <div class="line-height-32">
                        <div class="h3 text-bold" *ngIf="!noName">
                            {{ name }}
                        </div>
                        <div *ngIf="noName">[No Name Provided]</div>
                        <div class="h4 c_detl" *ngIf="!noEmail">
                            <span
                                ><i class="material-icons">
                                    email
                                </i></span
                            >
                            {{ userEmail }}
                        </div>
                        <div *ngIf="noEmail">[No Email Provided]</div>
                        <div class="h4 c_detl" *ngIf="!noMobile">
                            <span
                                ><i class="material-icons">
                                    phone
                                </i></span
                            >
                            {{ leadGen.mobile }}
                        </div>
                        <div *ngIf="noMobile">[No Mobile Number Provided]</div>
                        <div class="h4 c_detl" *ngIf="!noAddress">
                            <span
                                ><i class="material-icons">
                                    home
                                </i></span
                            >
                            {{ fullAddress }}
                        </div>
                        <div *ngIf="noAddress">[No Address Provided]</div>
                    </div>
                </div>
            </div>
            <div class="bg_color_border">
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="service_icons"
                            ><i class="material-icons"> directions_car</i></span
                        >
                        <span class="caps_text txt_bold">vehicle details</span>
                    </div>
                </div>
                <hr />
                <div class="souce_text">
                    <div class="vehicl">
                        <div class="h3 text-bold captal">
                            {{ leadGen.registrationNumber }}
                        </div>
                        <div class="h3 text-capitalize">
                            {{ leadGen.make }} {{ leadGen.model }}
                        </div>
                        <div class="h3">{{ leadGen.fuelType }}</div>
                    </div>
                </div>
            </div>
            <div class="bg_color_border">
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="service_icons"
                            ><i class="material-icons"> track_changes</i></span
                        >
                        <span class="caps_text txt_bold">service details</span>
                    </div>
                </div>
                <hr />
                <div class="souce_text">
                    <div class="vehicl">
                        <div class="h3">
                            {{
                                leadGen.serviceType === "general_service"
                                    ? "General Service"
                                    : leadGen.serviceType
                            }}
                        </div>
                    </div>
                </div>
                <div class="last_service">
                    <div class="souce_text">
                        <div class="vehicl">
                            <div class="h3">Last Service</div>
                            <div class="service_date">
                                {{
                                    detailList.lastServiceDate
                                        | date: "dd-MM-yyyy"
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="souce_text">
                        <div class="vehicl">
                            <div class="h3">Inspection Date:</div>
                            <div class="service_date">
                                {{
                                    detailList.inspectionDueDate
                                        | date: "dd-MM-yyyy"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-------------------------- left section end------------------------------------------ -->
        <div class="action_section">
            <div class="bg_color_border" *ngIf="statusShowHide">
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="svg_section">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="-247 370.9 100 125"
                                style="
                                    enable-background: new -247 370.9 100 100;
                                "
                                xml:space="preserve"
                            >
                                <path
                                    d="M-193.3,465.1c-1.3,0-2.7-0.4-3.9-1.2c-1.6-1-2.6-2.6-3-4.4c-0.4-1.8,0-3.7,1-5.3l7.3-10.9l-6.4-10.2l-9.3-14.6l7.1-10.7  l-6.5-1.5l-6.5,9.8c-2.1,3.2-6.5,4.1-9.7,2c-1.6-1-2.6-2.6-3-4.4c-0.4-1.8,0-3.7,1-5.3l9.2-13.9c1.6-2.3,4.5-3.5,7.3-2.9l16,3.6  c0.9,0.2,7.1,3.5,18.5,9.8l1.9,1l-13.6,20.5l8.3,13.1c1.5,2.4,1.4,5.5-0.3,7.9c-0.2,0.3-0.3,0.6-0.5,0.9l-9.1,13.6  C-188.8,464-191,465.1-193.3,465.1z"
                                />
                                <path
                                    d="M-175.7,399.7c-2.3,0-4.6-0.6-6.6-2c-2.7-1.8-4.5-4.5-5.1-7.6s0-6.3,1.8-9c3.7-5.5,11.1-7,16.6-3.4c2.7,1.8,4.5,4.5,5.1,7.6  c0.6,3.1,0,6.3-1.8,9C-168,397.8-171.8,399.7-175.7,399.7z M-175.7,379.7c-0.5,0-1.1,0.1-1.6,0.2c-2.1,0.4-3.9,1.6-5.1,3.4  c-1.2,1.8-1.6,3.9-1.2,6c0.4,2.1,1.6,3.9,3.4,5.1c1.8,1.2,3.9,1.6,6,1.2s3.9-1.6,5.1-3.4c1.2-1.8,1.6-3.9,1.2-6  c-0.4-2.1-1.6-3.9-3.4-5.1C-172.6,380.1-174.1,379.7-175.7,379.7z"
                                />
                                <path
                                    d="M-163.6,422.5c-0.5,0-1-0.1-1.5-0.2l-13.9-3.1l8.3-12.3l4.4,1l6.4-9.6c1-1.5,2.6-2.6,4.4-2.9c1.8-0.4,3.6,0,5.2,1l0,0  c1.5,1,2.6,2.6,2.9,4.4c0.4,1.8,0,3.6-1,5.2l-8.9,13.4c-0.9,1.3-2.3,2.3-3.8,2.8c-0.1,0-0.1,0-0.2,0.1h-0.1h-0.1  C-162.3,422.4-163,422.5-163.6,422.5z M-172.4,416.6l8.2,1.9c0.5,0.1,1,0.1,1.5-0.1h0.1l0,0l0.3-0.1c0.5-0.1,1.2-0.4,1.6-1.2  l8.9-13.4c0.4-0.6,0.6-1.4,0.4-2.2c-0.2-0.8-0.6-1.4-1.2-1.8l0,0c-0.6-0.4-1.4-0.6-2.2-0.4c-0.8,0.2-1.4,0.6-1.8,1.2l-7.9,11.9  l-4.4-1L-172.4,416.6z"
                                />
                                <path
                                    d="M-213.9,446.9c-0.9,0-1.9-0.2-2.8-0.6l-14.3-3.2c-3.7-0.8-6-4.5-5.2-8.3c0.4-1.8,1.5-3.3,3.1-4.3c1.6-1,3.4-1.3,5.2-0.9  l11,2.5l6.2-9.3c0.1-0.2,0.2-0.3,0.4-0.5l1.8-2.2l1.5,2.3l6.4,10.1l-7.6,11.4C-209.5,445.8-211.7,446.9-213.9,446.9z M-229.5,433.5  c-0.5,0-1.1,0.2-1.5,0.4c-0.7,0.4-1.1,1.1-1.3,1.8c-0.4,1.6,0.6,3.1,2.2,3.5l14.8,3.4l0.2,0.1c1.3,0.6,2.9,0.2,3.7-1l6-9.1l-3.4-5.4  l-6.3,9.4l-13.7-3.1C-229.1,433.5-229.3,433.5-229.5,433.5z"
                                />
                                <rect
                                    x="-245.8"
                                    y="378.6"
                                    width="55.7"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="388.6"
                                    width="27.1"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="398.6"
                                    width="19.5"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="408.6"
                                    width="15.7"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="418.6"
                                    width="9.8"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="428.6"
                                    width="5.8"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="438.6"
                                    width="5.8"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="448.6"
                                    width="23.8"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="458.6"
                                    width="38.8"
                                    height="4"
                                />
                            </svg>
                        </span>
                        <span class="caps_text txt_bold">Action zone</span>
                    </div>
                    <div class="order_price">
                        <button
                            mat-mini-fab
                            matTooltip="Exotel Call"
                            (click)="showExotelModal(id)"
                            class="edit_mat"
                        >
                            <mat-icon>phone</mat-icon>
                        </button>
                        <button
                            mat-mini-fab
                            matTooltip="Edit Lead"
                            (click)="showEditBooking(id)"
                            class="edit_mat"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button
                            mat-mini-fab
                            matTooltip="Update Status"
                            (click)="showChangeStatus()"
                            *ngIf="notConfirmed"
                        >
                            <mat-icon
                                ><svg
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 500 500"
                                >
                                    <defs>
                                        <style>
                                            .cls-1 {
                                                fill: #ffffff;
                                            }
                                        </style>
                                    </defs>
                                    <title>icon</title>
                                    <path
                                        class="cls-1"
                                        d="M140.87,450.33c1.11,2.88,2.08,5.2,2.9,7.56a11.24,11.24,0,0,1-3.51,12.57,10.79,10.79,0,0,1-12.77.91,13,13,0,0,1-4.67-5.24q-8.85-21.3-17.2-42.81c-3-7.74,2.4-15.46,10.7-15.49q22.76-.07,45.52.06a11.2,11.2,0,0,1,10.84,8.24,11.67,11.67,0,0,1-5.24,13,13.23,13.23,0,0,1-5.27,1.4c-2.89.23-5.81.06-8.71.06l-.18.58c5.14,2.4,10.21,5,15.43,7.18a201.84,201.84,0,0,0,91.7,16.29c54.5-3.15,101-24.2,139-63.6,28-29.1,45.73-63.74,52.43-103.58,9-53.3-2.34-102.34-32.85-146.93-2.23-3.25-3.92-6.55-3-10.57a11.33,11.33,0,0,1,20.37-4.28A212.35,212.35,0,0,1,455,156.94c16,32.65,23.79,67.17,22.4,103.47C475,322,451,374,406.26,416.21c-30.76,29-67.18,47.65-108.51,56.48a230.37,230.37,0,0,1-154.33-21.13C142.76,451.22,142.09,450.92,140.87,450.33Z"
                                    />
                                    <path
                                        class="cls-1"
                                        d="M355.25,47.54c-1.37-3.46-2.67-6.49-3.78-9.59a11.3,11.3,0,0,1,19.4-11A15.77,15.77,0,0,1,373,30.8q8.13,19.84,16.17,39.73c3.6,8.87-1.61,16.64-11.22,16.69q-22.29.12-44.59.12c-7,0-12.08-4.81-12.1-11.34s5-11.34,12-11.41a14.63,14.63,0,0,0,3.9-.38c-.68-.34-1.35-.71-2-1A200.38,200.38,0,0,0,270.23,46C215.49,41.14,166,55.06,122.65,88.89c-40.49,31.6-65.83,73-74.56,123.68C39,265.57,50.31,314.43,80.41,359A21.61,21.61,0,0,1,84,366.38a10.82,10.82,0,0,1-6.81,12.14,11.16,11.16,0,0,1-13.6-4c-3.91-5.66-7.73-11.4-11.2-17.34-16.18-27.63-26.18-57.41-28.94-89.29C17.65,201.1,37.84,143,83.41,93.94c27.11-29.18,60.1-49.35,98.09-61.25A230.46,230.46,0,0,1,353.59,46.83C354,47,354.39,47.17,355.25,47.54Z"
                                    />
                                    <path
                                        class="cls-1"
                                        d="M318.64,227.47v2.94q0,37.28,0,74.54c0,8.6-4.57,13.14-13.21,13.14q-55,0-110.07,0c-8.69,0-13.19-4.49-13.2-13.13,0-14.16,0-28.33,0-42.49,0-8.61,7.93-14.19,15.6-11.12a11.21,11.21,0,0,1,7.13,10.7c0,10.14,0,20.28,0,30.42v2.69h90.93V217.63c0-8.48,4.39-12.86,12.86-12.88l18.58,0c.13,0,.26-.12.75-.35L250,119.25l-78.35,85.57c1.11.07,1.82.14,2.53.14,6.89,0,13.78-.08,20.67,0,5,.09,8.77,2.47,10.66,7.19a10.78,10.78,0,0,1-2.34,12,10.89,10.89,0,0,1-8.31,3.5H146.08c-5,0-8.69-2.2-10.73-6.72s-1.14-8.81,2.17-12.47c6.91-7.64,13.88-15.21,20.83-22.8q41.15-45,82.29-89.95c5.76-6.3,12.87-6.37,18.64-.08q51.26,55.86,102.53,111.7c6.84,7.41,3.38,17.32-4.24,19.46a20.19,20.19,0,0,1-5.27.58c-10.3.05-20.59,0-30.89,0Z"
                                    />
                                    <path
                                        class="cls-1"
                                        d="M249.86,365.28q-27.87,0-55.72,0c-6.15,0-10.58-3.12-12-8.29a11.39,11.39,0,0,1,10.41-14.43c.47,0,.93,0,1.4,0h111.9c6.29,0,10.78,3.45,12,9.13a11.2,11.2,0,0,1-10.23,13.53c-4.94.22-9.9.09-14.85.1Z"
                                    />
                                    <path
                                        class="cls-1"
                                        d="M249.92,387c11.46,0,22.92-.06,34.38,0a10.63,10.63,0,0,1,9.95,6.45,11.36,11.36,0,0,1-10.07,16.23q-34.26.07-68.51,0a11.35,11.35,0,0,1,.11-22.7C227.16,386.92,238.54,387,249.92,387Z"
                                    /></svg
                            ></mat-icon>
                        </button>
                    </div>
                </div>
                <!-- ----------------exotel cal------------------- -->
                <div class="status_container" *ngIf="exotelModal">
                    <div class="change_status">
                        <h3 class="headi_ng">
                            Call Confirmation
                            <span class="cancel_icon" (click)="cancelExoCall()"
                                ><i class="material-icons">
                                    cancel
                                </i></span
                            >
                        </h3>
                        <div>
                            <p>
                                Are you sure you want to proceed with the call?
                            </p>
                            <mat-error *ngIf="errorCall">{{
                                this.callSuccess.msg
                            }}</mat-error>
                        </div>
                        <mat-error *ngIf="erroMSG">{{
                            errorMessage
                        }}</mat-error>
                        <mat-progress-bar
                            *ngIf="succesLoading"
                            mode="indeterminate"
                            class="prog_ress"
                        >
                        </mat-progress-bar>
                        <div class="action_call">
                            <button
                                mat-button
                                class="call_exo"
                                (click)="connectExoCall()"
                            >
                                Yes
                            </button>
                            <button
                                mat-button
                                class="cancel_exo"
                                (click)="cancelExoCall()"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
                <!-- -------------------/-call initiated---------------- -->
                <!-- --------------------/apply coupon -------------------confirmRemove -->
                <div class="status_container" *ngIf="applyCouponSec">
                    <div class="change_status">
                        <h3 class="headi_ng">
                            Apply Coupon
                            <span class="cancel_icon" (click)="closeCoupon()"
                                ><i class="material-icons">
                                    cancel
                                </i></span
                            >
                        </h3>
                        <div class="form_coupon">
                            <form [formGroup]="applyCouponForm">
                                <mat-form-field class="full_fields">
                                    <input
                                        matInput
                                        placeholder="Coupon Code"
                                        formControlName="couponCode"
                                    />
                                </mat-form-field>
                            </form>
                        </div>
                        <mat-error *ngIf="couponError">{{
                            errorCoupon
                        }}</mat-error>
                        <div class="messageStatus" *ngIf="!couponError">
                            {{ applyCouponMsg }}
                        </div>

                        <mat-progress-bar
                            *ngIf="couponLoading"
                            mode="indeterminate"
                            class="prog_ress"
                        >
                        </mat-progress-bar>
                        <div class="copupon_btn_sect">
                            <button
                                mat-button
                                class="coupon_btn"
                                [disabled]="applyCouponForm.invalid"
                                (click)="applyCouponSubmit()"
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
                <!-- --------------------confirm remove coupon ------------------- -->
                <div class="status_container" *ngIf="confirmRemove">
                    <div class="change_status">
                        <h3 class="headi_ng">
                            Remove Discount<span
                                class="cancel_icon"
                                (click)="closeRemoveCoupon()"
                                ><i class="material-icons">
                                    cancel
                                </i></span
                            >
                        </h3>
                        <!-- <div class="form_coupon">
                            <form [formGroup]="applyCouponForm">
                                <mat-form-field class="full_fields">
                                    <input matInput placeholder="Coupon Code" formControlName="couponCode">
                                </mat-form-field>

                            </form>
                            
                        </div> -->
                        <h4>Are you sure you want remove discount ?</h4>
                        <mat-error *ngIf="couponError">{{
                            errorCoupon
                        }}</mat-error>
                        <div class="messageStatus" *ngIf="!couponError">
                            {{ applyCouponMsg }}
                        </div>

                        <mat-progress-bar
                            *ngIf="couponLoading"
                            mode="indeterminate"
                            class="prog_ress"
                        >
                        </mat-progress-bar>
                        <div class="copupon_btn_sect">
                            <button
                                mat-button
                                class="coupon_cancel"
                                (click)="closeRemoveCoupon()"
                            >
                                Cancel
                            </button>
                            <button
                                mat-button
                                class="coupon_btn"
                                (click)="removeCouponSubmit()"
                            >
                                YES
                            </button>
                        </div>
                    </div>
                </div>
                <!-- --------------/-------change status------- -->
                <div class="status_container" *ngIf="changeSatatus">
                    <div class="change_status">
                        <h3 class="headi_ng">
                            Change Status
                            <span
                                class="cancel_icon"
                                (click)="closeChangeStatus()"
                                ><i class="material-icons">
                                    cancel
                                </i></span
                            >
                        </h3>
                        <div>
                            <form [formGroup]="bookingStatusForm">
                                <mat-form-field class="full_fields">
                                    <mat-label>Change Status</mat-label>
                                    <mat-select
                                        (selectionChange)="
                                            getStatusType(statusVal.value)
                                        "
                                        #statusVal
                                        formControlName="bookingStatus"
                                        required
                                    >
                                        <mat-option
                                            *ngFor="let status of bookingStatus"
                                            [value]="status.value"
                                        >
                                            {{ status.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field
                                    class="full_fields"
                                    *ngIf="cancelledReason"
                                >
                                    <input
                                        type="text"
                                        placeholder="Reason for Not Scheduled"
                                        aria-label="Number"
                                        matInput
                                        [formControl]="reasonControl"
                                        [matAutocomplete]="cancellAuto"
                                        formControlName="reason"
                                        #reasonToStatus
                                        required
                                    />
                                    <mat-autocomplete
                                        #cancellAuto="matAutocomplete"
                                    >
                                        <mat-option
                                            *ngFor="
                                                let option of filteredResions
                                                    | async
                                            "
                                            [value]="option"
                                        >
                                            {{ option }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field
                                    class="full_fields"
                                    *ngIf="invalReason"
                                >
                                    <input
                                        type="text"
                                        placeholder="Reason for Invalidation"
                                        aria-label="Number"
                                        matInput
                                        [formControl]="invalidControl"
                                        [matAutocomplete]="autoInvalid"
                                        formControlName="reason"
                                        #reasonToStatus
                                        required
                                    />
                                    <mat-autocomplete
                                        #autoInvalid="matAutocomplete"
                                    >
                                        <mat-option
                                            *ngFor="
                                                let option of filteredInvalid
                                                    | async
                                            "
                                            [value]="option"
                                        >
                                            {{ option }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </form>
                        </div>
                        <mat-error *ngIf="erroMSG">{{
                            errorMessage
                        }}</mat-error>
                        <mat-error *ngIf="addresError"
                            >Please give the address</mat-error
                        >

                        <mat-progress-bar
                            *ngIf="succesLoading"
                            mode="indeterminate"
                            class="prog_ress"
                        >
                        </mat-progress-bar>
                        <button
                            mat-button
                            class="status_update"
                            (click)="updateBookingStatus()"
                        >
                            Update Booking Status
                        </button>
                    </div>
                </div>
            </div>
            <!-- -------------------------- edit customer details--------------- -->
            <div class="status_container" *ngIf="editCustomer">
                <div class="change_status">
                    <h3 class="headi_ng">
                        Edit Customer
                        <span class="cancel_icon" (click)="closeEditCustomer()"
                            ><i class="material-icons">
                                cancel
                            </i></span
                        >
                    </h3>
                    <div class="edit_customer">
                        <form [formGroup]="customerEditForm">
                            <mat-form-field class="full_fields">
                                <input
                                    matInput
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    formControlName="customerName"
                                    [(ngModel)]="leadGen.name"
                                    required
                                />
                            </mat-form-field>
                            <mat-form-field class="full_fields">
                                <input
                                    matInput
                                    type="text"
                                    name="name"
                                    placeholder="Email"
                                    formControlName="customerMail"
                                    [(ngModel)]="leadGen.email"
                                    required
                                />
                            </mat-form-field>
                            <mat-form-field class="full_fields">
                                <input
                                    matInput
                                    type="text"
                                    name="name"
                                    placeholder="Flat / House No./ Building"
                                    formControlName="customerHouse"
                                    [(ngModel)]="leadGen.address1"
                                />
                            </mat-form-field>
                            <mat-form-field class="full_fields">
                                <mat-label>Colony/Street/Locality</mat-label>
                                <input
                                    matInput
                                    matGoogleMapsAutocomplete
                                    country="{{ country }}"
                                    #pickupLocation
                                    id="localityAddress"
                                    [(ngModel)]="leadGen.address2"
                                    formControlName="customerLocation"
                                    (focusout)="
                                        getCustomerAdd(pickupLocation.value)
                                    "
                                    (onLocationSelected)="
                                        onLocationSelected($event)
                                    "
                                    (keypress)="checkAddressSelection()"
                                />
                            </mat-form-field>
                            <span *ngIf="selectSuggession">
                                <mat-error
                                    >Please select location from the
                                    drop-down</mat-error
                                >
                            </span>
                        </form>
                    </div>
                    <mat-error *ngIf="failEditMsg">{{ failEditMsg }}</mat-error>
                    <div class="messageStatus" *ngIf="successEdit">
                        {{ successEdit }}
                    </div>
                    <mat-progress-bar
                        *ngIf="editCustomerLoad"
                        mode="indeterminate"
                        class="prog_ress"
                    >
                    </mat-progress-bar>
                    <button
                        mat-button
                        class="status_update"
                        [disabled]="customerEditForm.invalid || validAddress"
                        (click)="submitCustomerEdit()"
                    >
                        Save Details
                    </button>
                </div>
            </div>
            <!--  /------------- -->
            <div class="bg_color_border timeline_height">
                <div
                    class="pad_0"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                >
                    <div
                        fxLayout="column"
                        fxLayoutAlign="center start"
                        class="mat-title p-0 mb-0 caps_text txt_bold"
                    >
                        <div class="price_n_id">
                            <div class="order_id">
                                <span class="svg_section timlin">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 125"
                                        style="
                                            enable-background: new 0 0 100 100;
                                        "
                                        xml:space="preserve"
                                    >
                                        <polygon
                                            points="64.6,59.5 57.7,66.4 35.4,66.4 42.3,59.5 35.4,52.6 57.7,52.6 "
                                        />
                                        <polygon
                                            points="36,59.5 29,66.4 6.8,66.4 6.8,52.6 29,52.6 "
                                        />
                                        <polygon
                                            points="93.2,59.5 86.3,66.4 64,66.4 71,59.5 64,52.6 86.3,52.6 "
                                        />
                                        <path
                                            d="M21,33.6c-3.8,0-6.9,3.1-6.9,6.9c0,3.2,2.2,5.9,5.1,6.7l1.8,3.1l1.8-3.1c3-0.8,5.1-3.5,5.1-6.7C27.9,36.7,24.8,33.6,21,33.6  z M21,44.5c-2.2,0-3.9-1.8-3.9-3.9s1.8-3.9,3.9-3.9c2.2,0,3.9,1.8,3.9,3.9S23.1,44.5,21,44.5z"
                                        />
                                        <path
                                            d="M79,33.6c-3.8,0-6.9,3.1-6.9,6.9c0,3.2,2.2,5.9,5.1,6.7l1.8,3.1l1.8-3.1c3-0.8,5.1-3.5,5.1-6.7C86,36.7,82.9,33.6,79,33.6z   M79,44.5c-2.2,0-3.9-1.8-3.9-3.9s1.8-3.9,3.9-3.9s3.9,1.8,3.9,3.9S81.2,44.5,79,44.5z"
                                        />
                                    </svg>
                                </span>
                                <span class="caps_text txt_bold blk_dspl"
                                    >Booking Timeline
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <mat-menu
                            #filterMenu="matMenu"
                            [overlapTrigger]="false"
                        >
                            <mat-radio-group class="filter-group">
                                <div mat-menu-item>
                                    <mat-radio-button
                                        [value]="all"
                                        [checked]="!notes && !updates"
                                        (click)="toggleFilter('all')"
                                        >All</mat-radio-button
                                    >
                                </div>
                                <div mat-menu-item>
                                    <mat-radio-button
                                        [value]="notes"
                                        [checked]="notes && !updates"
                                        (click)="toggleFilter('notes')"
                                        >Notes</mat-radio-button
                                    >
                                </div>
                                <div mat-menu-item>
                                    <mat-radio-button
                                        [value]="updates"
                                        [checked]="updates && !notes"
                                        (click)="toggleFilter('updates')"
                                        >Updates</mat-radio-button
                                    >
                                </div>
                            </mat-radio-group>
                        </mat-menu>
                        <button
                            [matMenuTriggerFor]="filterMenu"
                            mat-mini-fab
                            class="mr-8"
                        >
                            <mat-icon aria-label="Filter">filter_list</mat-icon>
                        </button>
                        <button mat-mini-fab (click)="openBottomSheet()">
                            <mat-icon aria-label="Add">add</mat-icon>
                        </button>
                    </div>
                </div>
                <hr />
                <div class="souce_text min_height_sec">
                    <div class="p-8 timeline-div">
                        <ul class="timeline">
                            <li
                                *ngFor="
                                    let item of bookingTimeline;
                                    let i = index
                                "
                                class="timeline-event"
                            >
                                <label class="timeline-event-icon"></label>
                                <div class="timeline-event-copy">
                                    <p
                                        class="timeline-event-thumbnail text-uppercase"
                                    >
                                        {{ item.status }}
                                    </p>
                                    <h4>
                                        Changed by
                                        <strong>{{ item.agentName }}</strong>
                                        {{
                                            item.agentRole ==
                                            "operations_manager_b2c"
                                                ? "(Operations Manager b2c)"
                                                : item.agentRole ===
                                                  "admin_manager"
                                                ? "(Admin Manager)"
                                                : item.agentRole ===
                                                  "service_advisor"
                                                ? "(Service
                                        Advisor)"
                                                : item.agentRole === "owner"
                                                ? "(Owner)"
                                                : item.agentRole
                                        }}
                                    </h4>

                                    <h3 *ngIf="!(item.type === 'call')">
                                        {{ item.notes }}
                                    </h3>
                                    <div *ngIf="item.type === 'call'">
                                        <audio controls>
                                            <source
                                                src="https://s3-ap-southeast-1.amazonaws.com/exotelrecordings/getpitstop/{{
                                                    item.notes
                                                }}.mp3"
                                                type="audio/mpeg"
                                            />
                                        </audio>
                                    </div>
                                    <p>
                                        <strong>{{ item.created }}</strong>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- -------------------------right section end---------------------------------------------------- -->
    </div>
    <div class="bg_color_border">
        <div class="pad_0" fxLayout="row" fxLayoutAlign="space-between center">
            <div
                fxLayout="column"
                fxLayoutAlign="center start"
                class="mat-title p-0 mb-0 caps_text txt_bold"
            >
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="quote">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="0 0 100 125"
                                enable-background="new 0 0 100 100"
                                xml:space="preserve"
                            >
                                <path
                                    d="M63.252,18.342c-0.38-0.436-1.125-0.436-1.505,0l-10.5,12c-0.258,0.295-0.32,0.714-0.158,1.071  S51.607,32,52,32h4v5.37C54.179,36.501,52.149,36,50,36s-4.179,0.501-6,1.37V31c0-0.553-0.448-1-1-1H32c-0.552,0-1,0.447-1,1v37h-4  c-0.393,0-0.749,0.229-0.911,0.587s-0.1,0.776,0.158,1.071l10.5,12C36.938,81.876,37.211,82,37.5,82s0.563-0.124,0.752-0.342  l10.5-12c0.258-0.295,0.32-0.714,0.158-1.071S48.393,68,48,68h-4v-5.37c1.821,0.869,3.851,1.37,6,1.37s4.179-0.501,6-1.37V69  c0,0.553,0.448,1,1,1h11c0.552,0,1-0.447,1-1V32h4c0.393,0,0.749-0.229,0.911-0.587s0.1-0.776-0.158-1.071L63.252,18.342z M43,70  h2.796L37.5,79.481L29.204,70H32c0.552,0,1-0.447,1-1V32h9v6.527c-3.622,2.533-6,6.728-6,11.473s2.378,8.939,6,11.473V69  C42,69.553,42.448,70,43,70z M38,50c0-6.617,5.383-12,12-12s12,5.383,12,12s-5.383,12-12,12S38,56.617,38,50z M68,30  c-0.552,0-1,0.447-1,1v37h-9v-6.527c3.622-2.533,6-6.728,6-11.473s-2.378-8.939-6-11.473V31c0-0.553-0.448-1-1-1h-2.796l8.296-9.481  L70.796,30H68z"
                                />
                                <path
                                    d="M50,47c0.439,0,1,0.561,1,1c0,0.553,0.448,1,1,1s1-0.447,1-1c0-1.198-0.884-2.337-2-2.789V44  c0-0.553-0.448-1-1-1s-1,0.447-1,1v1.211c-1.116,0.452-2,1.591-2,2.789c0,1.504,1.276,2.46,2.402,3.304  C50.151,51.865,51,52.501,51,53c0,0.439-0.561,1-1,1c-0.449,0-1-0.552-1-1c0-0.553-0.448-1-1-1s-1,0.447-1,1  c0,1.198,0.884,2.337,2,2.789V57c0,0.553,0.448,1,1,1s1-0.447,1-1v-1.211c1.116-0.452,2-1.591,2-2.789  c0-1.5-1.274-2.454-2.398-3.297C49.851,49.141,49,48.503,49,48C49,47.552,49.551,47,50,47z"
                                />
                            </svg>
                        </span>
                        <span class="caps_text txt_bold blk_dspl"
                            >Estimate Quotation</span
                        >
                    </div>
                </div>
            </div>
            <div>
                <button
                    mat-mini-fab
                    matTooltip="Apply Coupon"
                    (click)="openApplyCoupon()"
                    *ngIf="!completed"
                    class="mr-8"
                >
                    <mat-icon aria-label="Add">card_giftcard</mat-icon>
                </button>
                <button
                    mat-mini-fab
                    (click)="addUnit()"
                    *ngIf="!completed"
                    class="mr-8"
                >
                    <mat-icon aria-label="Add">add</mat-icon>
                </button>
                <button
                    mat-mini-fab
                    (click)="quotationSave.ngSubmit.emit()"
                    *ngIf="!completed"
                >
                    <mat-icon aria-label="Save">save</mat-icon>
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    (click)="openInvoice()"
                    *ngIf="completed"
                >
                    View Invoice
                </button>
            </div>
        </div>
        <hr />
        <div class="souce_text">
            <div class="p-8 pt-20 quotation-section" *ngIf="!noQuotationData">
                <mat-progress-bar
                    *ngIf="quotationLoading"
                    mode="indeterminate"
                ></mat-progress-bar>
                <form
                    *ngIf="dataReceived && !quotationLoading"
                    [formGroup]="quotationForm"
                    novalidate
                    (ngSubmit)="onQuotationSubmit()"
                    #quotationSave="ngForm"
                >
                    <div formArrayName="rows">
                        <table>
                            <thead>
                                <tr>
                                    <th class="w-20-p font-size-13">Name</th>
                                    <th class="w-20-p font-size-13">
                                        Description
                                    </th>
                                    <th class="w-15-p font-size-13">
                                        Unit Price
                                    </th>
                                    <th class="w-10-p font-size-13">Qty</th>
                                    <th class="w-15-p font-size-13">
                                        Row Total
                                    </th>
                                    <th class="w-10-p font-size-13">Status</th>
                                    <!-- <th class="w-10-p font-size-13">Action</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let row of quotationForm.controls.rows
                                            .controls;
                                        let i = index
                                    "
                                    [formGroupName]="i"
                                >
                                    <td class="w-20-p disable">
                                        <input
                                            class="form-control"
                                            formControlName="itemName"
                                            [matAutocomplete]="name"
                                            #itemName
                                        />
                                        <mat-autocomplete
                                            #name="matAutocomplete"
                                        >
                                            <mat-option
                                                *ngFor="
                                                    let item of filteredOptions[
                                                        i
                                                    ] | async
                                                "
                                                [value]="item.itemName"
                                            >
                                                {{ item.itemName }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </td>
                                    <td class="w-20-p disable">
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="itemDescription"
                                            [matAutocomplete]="desc"
                                        />
                                        <mat-autocomplete
                                            #desc="matAutocomplete"
                                        >
                                            <mat-option
                                                *ngFor="
                                                    let item of filteredOptions[
                                                        i
                                                    ] | async
                                                "
                                                [value]="item.itemName"
                                            >
                                                {{ item.itemName }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </td>
                                    <td class="w-15-p">
                                        <input
                                            type="number"
                                            class="form-control"
                                            formControlName="itemUnitPrice"
                                        />
                                    </td>
                                    <td class="w-10-p">
                                        <input
                                            type="number"
                                            min="1"
                                            class="form-control"
                                            formControlName="itemQuantity"
                                        />
                                    </td>
                                    <td class="w-15-p">
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="itemTotal"
                                        />
                                    </td>
                                    <td>
                                        <label class="approve"
                                            >Approve
                                            <input [ngClass]="{'checked': row.get('status').value === 2}"
                                                type="radio"
                                                value="2"
                                                [checked]="
                                                    row.get('status').value ===
                                                    2
                                                "
                                                formControlName="status"
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="approve"
                                            >Reject
                                            <input [ngClass]="{'checked': row.get('status').value === 0}"
                                                type="radio"
                                                value="0"
                                                [checked]="
                                                    row.get('status').value ===
                                                    0
                                                "
                                                formControlName="status"
                                            />
                                            <span class="checkmark2"></span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="quotation-totals">
                            <tbody>
                                <tr>
                                    <td
                                        class="w-65-p text-right font-size-13 font-weight-700 pr-16"
                                    >
                                        Subtotal<span
                                            class="secondary-text font-size-12"
                                            >(Without Tax)</span
                                        >
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="!isSingapore"
                                    >
                                        {{
                                            quotationSubTotal
                                                | currency: "&#8377; "
                                        }}
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="isSingapore"
                                    >
                                        {{
                                            quotationSubTotal
                                                | currency: "S&#36; "
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        class="w-65-p text-right font-size-13 font-weight-700 pr-16"
                                    >
                                        Total Tax
                                        <span
                                            class="secondary-text font-size-12"
                                            >(GST)</span
                                        >
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="!isSingapore"
                                    >
                                        {{
                                            quotationTotalTax
                                                | currency: "&#8377; "
                                        }}
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="isSingapore"
                                    >
                                        {{
                                            quotationTotalTax
                                                | currency: "S&#36; "
                                        }}
                                    </td>
                                </tr>
                                <tr *ngIf="couponApplied">
                                    <td
                                        class="w-65-p text-right font-size-13 font-weight-700 pr-16"
                                    >
                                        Discount
                                        <span
                                            class="coupon_type"
                                            (click)="openRemoveCoupon()"
                                            >{{ discountType }}
                                            <i class="material-icons">
                                                cancel
                                            </i></span
                                        >
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="!isSingapore"
                                    >
                                        {{
                                            totalDiscount | currency: "&#8377; "
                                        }}
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="isSingapore"
                                    >
                                        {{
                                            totalDiscount | currency: "S&#36; "
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        class="w-65-p text-right font-size-13 font-weight-700 pr-16"
                                    >
                                        Grand Total
                                        <span
                                            class="secondary-text font-size-12"
                                            >(Invoice Value - All
                                            Inclusive)</span
                                        >
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="!isSingapore"
                                    >
                                        {{
                                            quotationGrandTotal
                                                | currency: "&#8377; "
                                        }}
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="isSingapore"
                                    >
                                        {{
                                            quotationGrandTotal
                                                | currency: "S&#36; "
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
            <div
                class="p-16 secondary-text text-center"
                *ngIf="noQuotationData"
            >
                <div>
                    <mat-icon class="h-36 w-36 font-size-36"
                        >layers_clear</mat-icon
                    >
                </div>
                <div class="h4 mt-4 text-bold">No data found</div>
            </div>
        </div>
    </div>
    <div class="bg_color_border">
        <div class="pad_0" fxLayout="row" fxLayoutAlign="space-between center">
            <div
                fxLayout="column"
                fxLayoutAlign="center start"
                class="mat-title p-0 mb-0 caps_text txt_bold"
            >
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="svg_section">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="0 0 100 125"
                                enable-background="new 0 0 100 100"
                                xml:space="preserve"
                            >
                                <path
                                    d="M87.895,14.474h-75.79C8.188,14.474,5,17.661,5,21.579v56.842c0,3.918,3.188,7.104,7.105,7.104h75.79  c3.918,0,7.104-3.188,7.104-7.104V21.579C95,17.661,91.813,14.474,87.895,14.474z M74.279,53.826  c-1.055-1.117-2.467-1.732-3.979-1.732c-1.512,0-2.925,0.615-3.979,1.732L55.584,65.215l-18.205-19.86  c-1.044-1.14-2.45-1.767-3.958-1.767c-1.508,0-2.914,0.627-3.958,1.767L9.737,66.875V21.579c0-1.307,1.062-2.369,2.368-2.369h75.79  c1.307,0,2.367,1.062,2.367,2.369v49.198L74.279,53.826z"
                                />
                                <circle cx="72.736" cy="36.737" r="8.526" />
                            </svg> </span
                        ><span class="caps_text txt_bold blk_dspl">Images</span>
                    </div>
                </div>
            </div>
            <div>
                <button mat-mini-fab (click)="openImageBottomSheet()">
                    <mat-icon aria-label="Add">add</mat-icon>
                </button>
            </div>
        </div>
        <hr />
        <div class="souce_text">
            <div class="p-16 image-list-container" *ngIf="!noImages">
                <mat-progress-bar
                    *ngIf="imageLoading"
                    mode="indeterminate"
                ></mat-progress-bar>
                <div
                    class="image-container"
                    *ngFor="let image of images; let i = index"
                >
                    <img
                        [src]="image.url"
                        (click)="open(i)"
                        class="h-100-p w-100-p"
                        [alt]="image.description"
                    />
                </div>
                <div
                    class="image-container"
                    *ngFor="let image of imagesTotal; let i = index"
                >
                    <img
                        [src]="image.url"
                        (click)="open(i)"
                        class="h-100-p w-100-p"
                        [alt]="image.description"
                    />
                </div>
            </div>
            <div class="p-16 secondary-text text-center" *ngIf="noImages">
                <div>
                    <mat-icon class="h-36 w-36 font-size-36"
                        >layers_clear</mat-icon
                    >
                </div>
                <div class="h4 mt-4 text-bold">No data found</div>
            </div>
        </div>
    </div>
    <!-- left -->
</div>
