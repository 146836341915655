<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="garage-selected">
        <div class="garage-icon"><mat-icon>build</mat-icon></div>              
        <div class="garage-name">{{spareSelectedGarageName}}</div>      
    </div>
    
    <div class="mt-8 p-8 spares-list"> 
        <div class="spare-list-header">
            <h2 class="spares-list-title">{{'ONDEMAND_INFO.TITLE' | translate}}</h2>
            <!-- <mat-slide-toggle 
                name="partToggle"
                [checked]="defaultToggle" 
                [labelPosition]="labelPosition" 
                (change)="partValueToggle($event.checked)">
                {{'ONDEMAND_INFO.TOGGLE_TXT' | translate}}
            </mat-slide-toggle> -->
        </div>  

        <div *ngIf="makeImage && model && year && variant" class="vehicle-details-sec">
            <div class="make-sec">
                <img class="img-fluid make-image" src="{{makeImage}}" />
            </div>
            <div class="model-sec">
                {{model}},
            </div>
            <div class="year-sec">
                {{year}},
            </div>
            <div class="variant-sec">
                {{variant}}
            </div>
            <div class="change-vehicle-sec">
                <button mat-stroked-button (click)="changeVehicle()">{{'ONDEMAND_INFO.CHANGE' | translate}}</button>                 
            </div>
                          
        </div>

        <div *ngIf="vehicleType=='universal' && !make" class="vehicle-details-sec">
            <div class="make-sec">
                <mat-icon style="font-size: 35px;"> directions_car</mat-icon>
            </div>
            <div class="model-sec universal-sec">Universal</div>
            <div class="change-vehicle-sec">
                <button mat-stroked-button (click)="changeVehicle()">{{'ONDEMAND_INFO.CHANGE' | translate}}</button>                 
            </div>
                          
        </div>

        <form class="searchSpares-form">        
            <input type="text" class="search-bar" placeholder="{{'ONDEMAND_INFO.SEARCH' | translate}}" [(ngModel)]='searchText' name="searchText" autocomplete="off" (keyup)="searchFilterType()">
            <mat-icon *ngIf="openSearch" style="float: right; margin-top: 10px; z-index: 9999999; position: absolute; margin-left: -35px;">search</mat-icon>
            <mat-icon *ngIf="clearSearch" style="float: right; margin-top: 10px; z-index: 9999999; position: absolute; margin-left: -35px; cursor: pointer;" (click)="clearSearchFilter()">close</mat-icon>
        </form>   
        
        <div class="brand-details" *ngIf="selectedBrand">
            <div *ngIf="selectedBrandImg" class="img-sec">
                <img src="{{selectedBrandImg}}" alt="{{selectedBrand}}" />
            </div>
            <div class="info-sec">
                <span>Welcome to </span>
                <span>{{selectedBrand}}'s store</span>
            </div>
        </div>
        <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>

        <!-- Spares Added List -->
        <div class="spares-added"> 
            <div class="col-xs-12 col-md-3">
                <div class="cat-list-body" >
                    <div class="tab-left">                        
                        <div class="tab-labels" *ngFor="let catList of categories; let i = index">
                            <mat-checkbox *ngIf="i==0" class="tab-label {{catList.categoryName|spaceToDash}} tab-active" [checked]="catList.checked" (change)="updateSelectedCategories(catList.categoryName, $event.checked)">
                                {{catList.categoryName}} 
                                <!-- ({{catList.count}}) -->
                            </mat-checkbox>

                            <mat-checkbox *ngIf="i!=0" class="tab-label {{catList.categoryName|spaceToDash}}" [checked]="catList.checked" (change)="updateSelectedCategories(catList.categoryName, $event.checked)">
                                {{catList.categoryName}} 
                                <!-- ({{catList.count}}) -->
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="tab-right" #tabRight (scroll)="onWindowScroll($event)">
                        <div class="cat-list-items" #catListItems id="{{spare[0]['category']|spaceToDash}}" *ngFor="let spare of sparesList">   
                            <div class="items" *ngFor="let list of spare|filter:searchText;  let i = index">
                                <h2 *ngIf="i==0">{{list.category}}</h2>
                                <!-- Before add to cart -->
                                <div class="mat-elevation-z8 mt-8 spares-list-instock" *ngIf="((!list.code && list.spareStocks.quantity>0 && cartList.length<=0) || isReplacePopup==true)"> 
                                    <div class="spares-part"> 
                                        <div *ngIf="list.spareStocks.discountAmount" class="discount-tag">{{((list.spareStocks.discountAmount/list.spareStocks.mrp)*100) | number:'1.0-0'}}% Off</div>
                                        <img *ngIf="list.imageUrl" class="img-fluid part-image" src="{{list.imageUrl}}" />
                                        <img *ngIf="!list.imageUrl" class="img-fluid part-image" src="../../../../assets/images/logos/logo.png" />
                                    </div>
                                    <div class="spare-details-sec">
                                        <div class="spare-part-name">{{list.name}}</div>
                                        <div class="spare-brand">{{list.brand.name}}</div>
                                        <div class="spare-brand">{{list.partNumber}}</div>
                                        <div class="spare-price" *ngIf="list.spareStocks.discountAmount>0; else onlyMRP">
                                            <span class="selling-price">&#8377;{{list.spareStocks.sellingPrice | number : '1.2-2'}}</span>
                                            <span class="mrp-price">&#8377;{{list.spareStocks.mrp | number : '1.2-2'}}</span>
                                            <span class="discount-price">(You save &#8377;{{list.spareStocks.discountAmount | number : '1.2-2'}})</span>
                                        </div>
                                        <ng-template #onlyMRP>
                                            <div class="spare-price-mrp">
                                                &#8377;{{list.spareStocks.mrp | number : '1.2-2'}}
                                            </div>
                                        </ng-template>
                                    </div>
            
                                    <div class="spare-add-cart">
                                        <button mat-raised-button (click)="addCart(list, 1)">
                                            <mat-icon>control_point</mat-icon>
                                            {{'ONDEMAND_INFO.ADD' | translate}}
                                        </button> 
                                    </div>
                                    <div class="stock-sec">
                                        <span class="available-qty">Available Qty ({{list.spareStocks.quantity}})</span>
                                        <button class="get-stock-btn" mat-raised-button (click)="getStock(list.partNumber)">
                                            <mat-icon>autorenew</mat-icon>
                                            {{'ONDEMAND_INFO.GET_STOCK' | translate}}
                                        </button> 
                                    </div>
                                </div>
                                
                                <!-- After add to cart -->
                                <div class="mat-elevation-z8 mt-8 spares-list-instock" *ngIf="!list.code && list.spareStocks.quantity>0 && cartList.length>0 && isReplacePopup!=true"> 
                                    <div class="spares-part"> 
                                        <div *ngIf="list.spareStocks.discountAmount" class="discount-tag">{{((list.spareStocks.discountAmount/list.spareStocks.mrp)*100) | number:'1.0-0'}}% Off</div>
                                        <img *ngIf="list.imageUrl" class="img-fluid part-image" src="{{list.imageUrl}}" />
                                        <img *ngIf="!list.imageUrl" class="img-fluid part-image" src="../../../../assets/images/logos/logo.png" />
                                    </div>
                                    <div class="spare-details-sec">
                                        <div class="spare-part-name">{{list.name}}</div>
                                        <div class="spare-brand">{{list.brand.name}}</div>
                                        <div class="spare-brand">{{list.partNumber}}</div>
                                        <div class="spare-price" *ngIf="list.spareStocks.discountAmount>0; else onlyMRP">
                                            <span class="selling-price">&#8377;{{list.spareStocks.sellingPrice | number : '1.2-2'}}</span>
                                            <span class="mrp-price">&#8377;{{list.spareStocks.mrp | number : '1.2-2'}}</span>
                                            <span class="discount-price">(You save &#8377;{{list.spareStocks.discountAmount | number : '1.2-2'}})</span>
                                        </div>
                                        <ng-template #onlyMRP>
                                            <div class="spare-price-mrp">
                                                &#8377;{{list.spareStocks.mrp | number : '1.2-2'}}
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="!cartList[0][list.key]">
                                        <div class="spare-add-cart">
                                            <button mat-raised-button (click)="addCart(list, 1)" >
                                                <mat-icon>control_point</mat-icon>
                                                {{'ONDEMAND_INFO.ADD' | translate}}
                                            </button> 
                                        </div>
                                        <div class="stock-sec">
                                            <span class="available-qty">Available Qty ({{list.spareStocks.quantity}})</span>
                                            <button class="get-stock-btn" mat-raised-button (click)="getStock(list.partNumber)">
                                                <mat-icon>autorenew</mat-icon>
                                                {{'ONDEMAND_INFO.GET_STOCK' | translate}}
                                            </button> 
                                        </div>
                                    </div>
                                    <div *ngIf="cartList[0][list.key]">
                                        <div class="spare-add-cart" >  
                                            <div class="btn-group" *ngIf="list.key==cartList[0][list.key].key && cartList[0][list.key].cart_qty>0">
                                                <button mat-raised-button (click)="removeItem(cartList[0][list.key].cart_qty, list.key)"><mat-icon>remove</mat-icon></button> 
                                                <input type="text" class="part-count" [(ngModel)]="cartList[0][list.key].cart_qty" readonly>
                                                <button mat-raised-button (click)="addItem(cartList[0][list.key].cart_qty, list.key)"><mat-icon>add</mat-icon></button> 
                                            </div>
                                        </div>
                                        <div class="stock-sec">
                                            <span class="available-qty">Available Qty ({{list.spareStocks.quantity}})</span>
                                            <button class="get-stock-btn" mat-raised-button (click)="getStock(list.partNumber)">
                                                <mat-icon>autorenew</mat-icon>
                                                {{'ONDEMAND_INFO.GET_STOCK' | translate}}
                                            </button> 
                                        </div>
                                    </div>
                                </div>
            
                                <!-- Quantity less than or equal to 0 (out of stock)-->
                                <div class="mat-elevation-z8 mt-8 spares-list-out-of-stock" *ngIf="!list.code && (list.spareStocks.quantity <= 0 || !list.spareStocks.quantity)"> 
                                    <div class="spares-part"> 
                                        <div *ngIf="list.spareStocks.discountAmount" class="discount-tag">{{((list.spareStocks.discountAmount/list.spareStocks.mrp)*100) | number:'1.0-0'}}% Off</div>
                                        <img *ngIf="list.imageUrl" class="img-fluid part-image" src="{{list.imageUrl}}" />
                                        <img *ngIf="!list.imageUrl" class="img-fluid part-image" src="../../../../assets/images/logos/logo.png" />
                                    </div>
                                    <div class="spare-details-sec">
                                        <div class="spare-part-name">{{list.name}}</div>
                                        <div class="spare-brand">{{list.brand.name}}</div>
                                        <div class="spare-brand">{{list.partNumber}}</div>   
                                        <div class="spare-price" *ngIf="list.spareStocks.discountAmount>0; else onlyMRP">
                                            <span class="selling-price">&#8377;{{list.spareStocks.sellingPrice | number : '1.2-2'}}</span>
                                            <span class="mrp-price">&#8377;{{list.spareStocks.mrp | number : '1.2-2'}}</span>
                                            <span class="discount-price">(You save &#8377;{{list.spareStocks.discountAmount | number : '1.2-2'}})</span>
                                        </div>
                                        <ng-template #onlyMRP>
                                            <div class="spare-price-mrp" *ngIf="list.spareStocks.mrp">
                                                &#8377;{{list.spareStocks.mrp | number : '1.2-2'}}
                                            </div>
                                        </ng-template>
                                        <div class="out-of-stock-label">{{'ONDEMAND_INFO.OUT_OF_STOCK' | translate}}</div>                                    
                                    </div>
                                    <div class="out-of-stock-getstock">
                                        <span class="available-qty" *ngIf="list.spareStocks.quantity">Available Qty ({{list.spareStocks.quantity}})</span>
                                        <span class="available-qty" *ngIf="!list.spareStocks.quantity">Available Qty (0)</span>
                                        <button class="get-stock-btn" mat-raised-button (click)="getStock(list.partNumber)">
                                            <mat-icon>autorenew</mat-icon>
                                            {{'ONDEMAND_INFO.GET_STOCK' | translate}}
                                        </button> 
                                    </div>
                                </div>
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Spares Added List -->

    </div>
    
    <div class="shipping-charges-sec" *ngIf="isCartCount && isShippingCharges" (click)="shippingChargesPopup()" >
        Add services worth Rs.2000 to get FREE delivery !
    </div>
    <div class="cart-sec" *ngIf="isCartCount">
        <div class="cart-count">{{cartCount}}</div>
        <div class="grand-total">&#8377;{{cartGrandTotal| number : '1.2-2'}}</div>
        <button class="view-cart-btn" (click)="goToCart()">{{'ONDEMAND_INFO.VIEW_CART' | translate}}</button>
    </div>
</div>