import { Component, OnInit } from '@angular/core';
import {AspOrderListService} from './asp-order-list.service';

@Component({
  selector: 'app-asp-order-list',
  templateUrl: './asp-order-list.component.html',
  styleUrls: ['./asp-order-list.component.scss']
})
export class AspOrderListComponent implements OnInit {
  loading:boolean;
  orderList = [];

  constructor(
    private aspOrderListService: AspOrderListService,
  ) { }

  ngOnInit(): void {
    this.getList();    
    sessionStorage.clear();
  }

  getList(){
    this.loading = true;
    let spareUserKey = localStorage.getItem('spareUserKey');
    let garageKey = localStorage.getItem('garageKey');
    this.aspOrderListService.orderList(spareUserKey, garageKey).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        // console.log(res);
        if(res.code==200){          
          this.loading = false;
          this.orderList = res.data;
        }else{
          this.loading = false;
        }
      }
    );
  }

}
