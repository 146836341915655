import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariable } from 'app/main/globals';
import { LoginService } from 'app/main/authentication/login/login.service';

@Injectable({ providedIn: 'root' })
export class BookingDetailsService implements Resolve<any>
{
    id: number;
    bookingDetails: any[];
    paramData: any;
    chassisParamData: any;
    loginUserData: any;
    accountAppKey: any;
    editBookingId: any;
    exotelToken: any;
    exotelSid: any;
    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.id = route.params.id;
            Promise.all([
                this.getOrderDetails()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
    getOrderDetails(): Promise<any> {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
     
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'leadgenId': this.id,
            'userType': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
       
        return new Promise((resolve, reject) => {
            this.http.post(`${GlobalVariable.BASE_API_URL}` + '/crmgetleadgen', formData)
                .subscribe((response: any) => {
                    this.bookingDetails = response;
                    resolve(response);
                }, reject);
        });
    }

    getQuotationData() {
     
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'leadId': this.id,
            'type': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/estimate', formData);
    }

    addQuotation(formData: any): Observable<any> {
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/estimate', formData).pipe(
            map(this.extractData));
    }

    addNotes(formData: any): Observable<any> {
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/crmupdateleadgenstatus', formData).pipe(
            map(this.extractData));
    }

    addImages(formData: any): Observable<any> {
        return this.http.post(`${GlobalVariable.BASE_FILE_UPLOAD_URL}` + '/process-upload.php', formData).pipe(
            map(this.extractData));
    }

    getJobCards(orderKey: any) {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
       
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'orderKey': orderKey,
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/getjobcard', formData);
    }

    assignTechnician(formData: any): Observable<any> {
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/gmsupdateorder', formData).pipe(
            map(this.extractData));
    }

    changeOrderStatus(formData: any) {
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/hupdateorderstatus', formData);
    }
    applyCoupon(formData :any){
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/b2bapplyleaddiscount', formData);
    }
    
    removeCoupon(formData :any){
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/removediscount', formData);
    }
    changeBookingStatus(formData: any) {
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/crmupdateleadgenstatus', formData);
    }
    getInvoiceData(id: number) {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
       
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'orderId': id,
            'type': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/gstinvoice', formData);
    }
    registrationLeadData(registration): Observable<any> {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'vehicledetails.registrationNumber': registration,
            'userType': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/b2bsearchvehicles', formData);
      
    }
    chassisLeadData(chassis): Observable<any> {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'vehicledetails.chassis_no': chassis,
            'userType': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/b2bsearchvehicles', formData);
       
    }
    mobileLeadData(mobile): Observable<any> {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        if (this.loginUserData) {
            this.accountAppKey = this.loginUserData.account.appKey;
        }
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'customer.mobile': mobile,
            'userType': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/b2bsearchvehicles', formData);
       
    }
    
    createBooking(formData) {
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/crmleadgen', formData);
    }
    updateBookingLead(formData): Observable<any> {
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/crmupdateleadgen', formData);
    }
    getBookingLeadIdData(id): Observable<any> {
        this.editBookingId = localStorage.getItem('currentBookingId');
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'leadgenId': id,
            'userType': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/crmgetleadgen', formData);
        
    }
    initiateExoCall(formData) {
        return this.http.post(`${GlobalVariable.BASE_FILE_UPLOAD_URL}` + '/gms-exotel-call.php', formData);
    }
}
