import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import * as moment from "moment-timezone";
import { AppComponent } from "app/app.component";
import { LoginService } from "app/main/authentication/login/login.service";
import { SearchOrdersService } from "./search-orders.service";
import { PickupStatus, DoorstepStatus } from "app/main/globals";

const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

export interface SearchOrdersData {
    orderId: string;
    registration: string;
    fuelType: string;
    vehicle: string;
    orderStartTime: any;
    orderEndTime: any;
    grandTotal: string;
    status: string;
}

@Component({
    selector: "search-orders",
    templateUrl: "./search-orders.component.html",
    styleUrls: ["./search-orders.component.scss"],
})
export class SearchOrdersComponent implements OnInit {
    token: any;
    searchForm: FormGroup;
    loading = false;
    submitted = false;
    searchOrders: any;
    searchOrdersList: any;
    searchOrdersData: any = [];
    searchOrdersSource: MatTableDataSource<SearchOrdersData>;
    searchOrdersColumns: string[] = [
        "orderId",
        "registration",
        "orderStartTime",
        "orderEndTime",
        "grandTotal",
        "status",
    ];
    renderedData: any;
    noData = false;
    dialogId: number;
    rDialogData: any;
    dataCSV: any;
    timezone: any;
    format: any;
    moment = moment();
    selectedValue = "";

    @ViewChild("TABLE", { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    /**
     * Constructor
     * @param {Router} router
     * @param {LoginService} loginService
     * @param {SearchOrdersService} rService
     * @param {Papa} papa
     */
    constructor(
        public router: Router,
        private loginService: LoginService,
        private soService: SearchOrdersService,
        public dialog: MatDialog,
        private appComponent: AppComponent,
        private _formBuilder: FormBuilder
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(["/auth/login"]);
        }
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                // trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
            }
        });
        this.searchForm = this._formBuilder.group({
            condition: ["", [Validators.required]],
            query: ["", [Validators.required]],
        });
    }

    ngOnInit() {}

    get f() {
        return this.searchForm.controls;
    }

    onSubmit() {
        if (this.searchForm.invalid) {
            return;
        }
        this.loading = true;
        this.getSearchOrdersData(this.f.condition.value, this.f.query.value);
        this.submitted = true;
    }

    getSearchOrdersData(condition: string, query: string) {
        this.soService.getOrders(condition, query).subscribe((data: {}) => {
            this.searchOrdersList = data;
            if (this.searchOrdersList.response.status === "SUCCESS") {
                this.searchOrders = this.searchOrdersList.response.body.orders;
                if (this.searchOrders.length === 0) {
                    this.noData = true;
                    this.loading = false;
                } else {
                    this.searchOrders.forEach((searchOrderList) => {
                        this.searchOrdersData.push(
                            this.createSearchOrdersData(searchOrderList)
                        );
                    });
                    this.searchOrdersSource = new MatTableDataSource(
                        this.searchOrdersData
                    );
                    this.searchOrdersSource
                        .connect()
                        .subscribe((d) => (this.renderedData = d));
                    this.dataCSV = this.renderedData;
                    this.loading = false;
                    setTimeout(() => {
                        this.searchOrdersSource.paginator = this.paginator;
                        this.searchOrdersSource.sort = this.sort;
                    }, 500);
                }
            } else {
                this.loading = false;
                this.appComponent.openSnackBar(
                    "Session Timeout",
                    "Redirecting..."
                );
                setTimeout(() => {
                    this.loginService.logout();
                }, 1000);
            }
        });
    }

    applyFilter(filterValue: string) {
        this.searchOrdersSource.filter = filterValue.trim().toLowerCase();
        if (this.searchOrdersSource.paginator) {
            this.searchOrdersSource.paginator.firstPage();
        }
    }
    createSearchOrdersData(data: any): SearchOrdersData {
        let reg = "";
        let delivery = "";
        let startTime = "";
        let status = "";
        if (data.orderType.toString() === "doorstep") {
            status = DoorstepStatus[data.status - 1];
        } else {
            status = PickupStatus[data.status - 1];
        }
        if (data.registration) {
            reg = data.registration.toString();
        }
        if (data.orderEndTime) {
            delivery = moment
                .unix(data.orderEndTime / 1000)
                .tz(this.timezone)
                .format(this.format);
        }

        if (data.orderStartTime) {
            startTime = moment
                .unix(data.orderStartTime / 1000)
                .tz(this.timezone)
                .format(this.format);
        }

        return {
            orderId: data.orderId.toString(),
            registration: reg,
            fuelType: data.fuelType.toString(),
            vehicle: data.model.toString(),
            orderStartTime: startTime,
            orderEndTime: delivery,
            grandTotal: parseFloat(data.grandTotal).toFixed(2),
            status: status.toString(),
        };
    }
    exportEXCEL() {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            this.dataCSV
        );
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        this.saveAsExcelFile(excelBuffer, "Searched_Orders_list");
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        saveAs.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }

    openDetails(id: number) {
        this.router.navigate(["orders/details/", id]);
    }
}
