import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import { GlobalVariable } from 'app/main/globals';
import * as FileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import { FileUploader } from 'ng2-file-upload';
import { Lightbox } from 'ngx-lightbox';
import { first } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { CreateBookingComponent } from '../booking-details/create-booking/create-booking.component';
import { NewBookingsService } from '../new-bookings/new-bookings.service';
import { RenewalBookingsService } from './renewal-bookings.service';
const EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
export interface RenewalLeadData {
    leadId: string;
    refId: string;
    serviceType: string;
    make: string;
    leadStatus: any;
}
@Component({
    selector: 'app-renewal-bookings',
    templateUrl: './renewal-bookings.component.html',
    styleUrls: ['./renewal-bookings.component.scss'],
})
export class RenewalBookingsComponent implements OnInit {
    status: string;
    bookingId: string;
    error = false;
    imagesLoading = false;
    imagesDataReceived = false;
    uploader: FileUploader;
    fileError = false;
    _albums = [];
    selectedFiles = [];
    urls = [];
    tag: string;
    loginUserData: any;
    accountAppKey: any;
    paramData: any;
    filePath: any;
    uloadMessage: any;
    uploadResponse: any;
    bulkSuccess = false;
    canuploadSheet: any;
    @ViewChild('filesToUpload', { static: false }) filesToUpload: ElementRef;
    @ViewChild('image', { static: false }) image: ElementRef;
    // upload excel---------
    token: any;
    liveOrders: any;
    liveOrdersList: any;
    liveOrdersData: any = [];
    searchOrders: any;
    searchOrdersList: any;
    callsdueData: any;
    callsMadeLead: any;
    renewalMissedLead: any;
    renewalLeadData: any = [];
    renewalDueData: any = [];
    totalData = [];
    callMadeData: any = [];
    renewalMissedData: any = [];
    renewalLeadSource: MatTableDataSource<RenewalLeadData>;
    tableLength: any;
    searchOrdersColumns: string[] = [
        'leadId',
        'refId',
        'serviceType',
        'make',
        'leadStatus',
    ];
    renderedData: any;
    dataAvialable = true;
    noData = false;
    dialogId: number;
    rDialogData: any;
    dataCSV: any;
    timezone: any;
    format: any;
    loading = false;
    totalCallDue: any;
    totalcallsMade: any;
    totalMissed: any;
    converRate: any;
    fileNotUploaded = false;
    renewalDataLoading = false;
    totalCallsCheck = false;
    activeCallDue = true;
    makeUrl: any;
    loginData: any;
    totalFailedUpload: any;
    totalSuccessUpload: any;
    uploadRenewalLead = false;
    showFailureMsg = false;
    uploadFailedData: any;
    fileName: any;
    wentWrong = false;
    moment = moment();
    showUploadExcel = false;
    callDueActive = true;
    callMadeActive = false;
    missedActive = false;
    conversionActive = false;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    /**
     * Constructor
     * @param {Router} router
     * @param {LoginService} loginService
     * @param {AssignedBookingsService} rService
     * @param {Papa} papa
     */
    constructor(
        public router: Router,
        private loginService: LoginService,
        private renbService: RenewalBookingsService,
        public dialog: MatDialog,
        private appComponent: AppComponent,
        // upload -excel-----------
        private newBService: NewBookingsService,
        private sanitizer: DomSanitizer,
        private _lightbox: Lightbox
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                this.router.navigated = false;
            }
        });
        // upload excel-----------------
        this.uploader = new FileUploader({ url: '' });
        this.urls = [];
        this._albums = [];
        this.uploader.onAfterAddingFile = (file) => {
            this.fileName = file._file.name;
            this.selectedFiles.push(file);
            this.urls.push(
                this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(file._file)
                )
            );
            const sFiles = {
                src: this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(file._file)
                ),
            };
            this._albums.push(sFiles);
        };
    }
    ngOnInit() {
        this.loginData = JSON.parse(this.loginService.getLoginData());
        const canUploadLead = this.loginData.merchant.canCreateBulkLead;
        if (canUploadLead.toString() === '1') {
            this.uploadRenewalLead = true;
        } else {
            this.uploadRenewalLead = false;
        }
        this.getCallsDueData();
    }
    createNewBooking() {
        this.dialog.open(CreateBookingComponent);
    }
    getRenewalMissedData() {
        this.renewalDataLoading = true;
        this.callDueActive = false;
        this.callMadeActive = false;
        this.missedActive = true;
        this.conversionActive = false;
        this.renbService.getRenewalBookings().subscribe((data) => {
            if (data.response.status === 'SUCCESS') {
                this.renewalDataLoading = false;
                this.renewalMissedLead = data.response.body.missedRenewLeads;
                this.totalMissed = this.renewalMissedLead;
                if (this.totalMissed.toString() === '') {
                    this.totalMissed = 0;
                }
                if (this.renewalMissedLead.length === 0) {
                    this.dataAvialable = false;
                    this.noData = true;
                    this.loading = false;
                } else {
                    this.dataAvialable = true;
                    this.noData = false;
                    this.renewalMissedData = [];
                    this.renewalMissedLead.forEach((missedList) => {
                        this.renewalMissedData.push(missedList);
                    });
                    this.totalMissed = this.renewalMissedData.length;
                }
                this.makeUrl = `${GlobalVariable.BASE_MAKE_IMAGE_URL}`;
                this.renewalLeadSource = new MatTableDataSource(
                    this.renewalMissedData
                );
                this.renewalLeadSource
                    .connect()
                    .subscribe((d) => (this.renderedData = d));
                this.dataCSV = this.renderedData;
                this.totalData = this.renewalMissedData;
                this.loading = false;
                setTimeout(() => {
                    this.renewalLeadSource.paginator = this.paginator;
                    this.renewalLeadSource.sort = this.sort;
                }, 500);
            } else {
                this.loading = false;
                this.appComponent.openSnackBar(
                    'Session Timeout',
                    'Redirecting...'
                );
                setTimeout(() => {
                    this.loginService.logout();
                }, 1000);
            }
        });
    }
    getCallsMadeData() {
        this.callMadeData = [];
        this.renewalDataLoading = true;
        this.callDueActive = false;
        this.callMadeActive = true;
        this.missedActive = false;
        this.conversionActive = false;
        this.renbService.getRenewalBookings().subscribe((data) => {
            if (data.response.status === 'SUCCESS') {
                this.renewalDataLoading = false;
                this.callsMadeLead = data.response.body.leads;
                this.callsMadeLead.forEach((madeList) => {
                    if (madeList.callCount.toString() !== '0') {
                        this.callMadeData.push(madeList);
                    }
                });
                this.totalcallsMade = this.callMadeData.length;
                if (this.callMadeData.length === 0) {
                    this.dataAvialable = false;
                    this.noData = true;
                    this.loading = false;
                } else {
                    this.dataAvialable = true;
                    this.noData = false;
                }
                this.makeUrl = `${GlobalVariable.BASE_MAKE_IMAGE_URL}`;
                this.renewalLeadSource = new MatTableDataSource(
                    this.callMadeData
                );
                this.renewalLeadSource
                    .connect()
                    .subscribe((d) => (this.renderedData = d));
                this.dataCSV = this.renderedData;
                this.totalData = this.callMadeData;
                this.loading = false;
                setTimeout(() => {
                    this.renewalLeadSource.paginator = this.paginator;
                    this.renewalLeadSource.sort = this.sort;
                }, 500);
            } else {
                this.loading = false;
                this.appComponent.openSnackBar(
                    'Session Timeout',
                    'Redirecting...'
                );
                setTimeout(() => {
                    this.loginService.logout();
                }, 1000);
            }
        });
    }
    getCallsDueData() {
        this.renewalDataLoading = true;
        this.callDueActive = true;
        this.callMadeActive = false;
        this.missedActive = false;
        this.conversionActive = false;
        this.renbService.getRenewalBookings().subscribe((data) => {
            if (data.response.status === 'SUCCESS') {
                this.renewalDataLoading = false;
                this.callsdueData = data.response.body.leads;
                this.renewalDueData = [];
                this.callMadeData = [];
                this.callsdueData.forEach((searchOrderList) => {
                    if (searchOrderList.callCount.toString() === '0') {
                        this.renewalDueData.push(searchOrderList);
                    }
                    if (searchOrderList.callCount.toString() !== '0') {
                        this.callMadeData.push(searchOrderList);
                    }
                });
                this.makeUrl = `${GlobalVariable.BASE_MAKE_IMAGE_URL}`;
                this.renewalMissedLead = data.response.body.missedRenewLeads;
                this.totalMissed = this.renewalMissedLead;
                this.renewalMissedData = [];
                this.renewalMissedLead.forEach((missedList) => {
                    this.renewalMissedData.push(missedList);
                });
                this.converRate = data.response.body.rate;
                this.totalMissed = this.renewalMissedData.length;
                this.totalcallsMade = this.callMadeData.length;
                this.totalCallDue = this.renewalDueData.length;
                if (this.renewalDueData.length === 0) {
                    this.dataAvialable = false;
                    this.noData = true;
                    this.loading = false;
                } else {
                    this.dataAvialable = true;
                    this.noData = false;
                }
                this.makeUrl = `${GlobalVariable.BASE_MAKE_IMAGE_URL}`;
                this.renewalLeadSource = new MatTableDataSource(
                    this.renewalDueData
                );
                this.renewalLeadSource
                    .connect()
                    .subscribe((d) => (this.renderedData = d));
                this.dataCSV = this.renderedData;
                this.totalData = this.renewalDueData;
                this.loading = false;
                setTimeout(() => {
                    this.renewalLeadSource.paginator = this.paginator;
                    this.renewalLeadSource.sort = this.sort;
                }, 500);
            } else {
                this.loading = false;
                this.appComponent.openSnackBar(
                    'Session Timeout',
                    'Redirecting...'
                );
                setTimeout(() => {
                    this.loginService.logout();
                }, 1000);
            }
        });
    }

    applyFilter(filterValue: string) {
        this.renewalLeadSource.filter = filterValue.trim().toLowerCase();
        if (this.renewalLeadSource.paginator) {
            this.renewalLeadSource.paginator.firstPage();
        }
    }
    exportEXCEL() {
        this.dataCSV = this.totalData;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            this.dataCSV
        );
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, 'renewalBooking_list');
        // setTimeout(() => {
        //   this.router.navigate(['bookings/renewal-booking']);
        // }, 100);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(
            data,
            fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
        );
    }
    openDetails(id: number) {
        this.router.navigate(['bookings/details/', id]);
    }
    cancelExcelUpload() {
        this.showUploadExcel = false;
        this._lightbox.close();
        this.uloadMessage = '';
        this.fileNotUploaded = false;
        this.wentWrong = false;
        this.fileNotUploaded = false;
    }
    upladLeadSheet() {
        this.showUploadExcel = true;
    }
    cancelMsgUpload() {
        this.showFailureMsg = false;
    }
    printUploadFailure() {
        window.print();
    }
    // upload excel-------------
    open(index: number): void {
        this._lightbox.open(this._albums, index);
    }
    close(): void {
        this._lightbox.close();
    }
    removeImgEntry(id: number) {
        this.urls.splice(id, 1);
        this._albums.splice(id, 1);
        this.selectedFiles.splice(id, 1);
    }
    uploadSheet() {
        this.showUploadExcel = true;
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        this.imagesLoading = true;
        const formData: FormData = new FormData();
        if (!this.fileError) {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                formData.append(
                    'fileToUpload',
                    this.selectedFiles[i]._file,
                    this.selectedFiles[i]._file.name
                );
            }
            this.newBService
                .uploadExcelSheet(formData)
                .pipe(first())
                .subscribe(
                    (result) => {
                        this.error = false;
                        this.imagesLoading = false;
                        this.imagesDataReceived = false;
                        // this.bottomSheetRef.dismiss();
                        if (result.status == 200) {
                            this.filePath = result.xl_file_path;
                            this.uloadMessage = result.message;
                            this.sendExcelSheet();
                        } else {
                            this.wentWrong = true;
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.imagesLoading = false;
                        this.error = true;
                    }
                );
        } else {
            this.imagesLoading = false;
            this.error = true;
        }
    }
    removeSelection() {
        this.wentWrong = false;
        this.fileNotUploaded = false;
    }
    sendExcelSheet() {
        this.newBService.uploadLeadData(this.filePath).subscribe(
            (result) => {
                if (result.response.status === 'SUCCESS') {
                    this.uploadResponse = result.response.body.failedEntrys;
                    this.totalFailedUpload = this.uploadResponse.length;
                    this.totalSuccessUpload =
                        result.response.body.succesEntries.length;
                    this.showUploadExcel = false;
                    this.showFailureMsg = true;
                    if (this.uploadResponse.length > 0) {
                        this.bulkSuccess = false;
                        this.uploader.clearQueue();
                        this._lightbox.close();
                    } else {
                        this.bulkSuccess = true;
                    }
                } else {
                    this.fileNotUploaded = true;
                }
            },
            (error) => {
                console.log(error);
                this.fileNotUploaded = true;
            }
        );
    }
}
