<div class="page-layout carded fullwidth p-12" fusePerfectScrollbar>
    <div class="page-title detail_title" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-display-1 m-0 p-16 pl-0 font_size" fxLayout="column" fxLayoutAlign="center start">
            Assigned Bookings</span>
        <span class="createBtn"><button mat-button (click)="createNewBooking()">
                <mat-icon>add</mat-icon> Create Booking
            </button></span>
    </div>
    <div class="bg_color_border margn_top">
        <div class="p-12 pb-0 pt-0 export-block" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <!-- <button [disabled]="noData" (click)="exportEXCEL()" mat-raised-button color="accent">
                    Export
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button> -->
            </div>
            <!-- <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field> -->
        </div>
        <table *ngIf="dataAvialable" class="clickable" mat-table [dataSource]="searchOrdersSource" matSort matSortActive="orderId" matSortDirection="desc" matSortDisableClear>
            <ng-container matColumnDef="leadId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>BOOKING ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">{{row.leadId}} </td>
            </ng-container>

            <ng-container matColumnDef="leadCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>CREATED ON</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.leadCreated|date: 'd MMMM yy' }}
                    <span id="time_show">{{row.leadCreated|date: 'h:mm a'}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="make">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>CAR DETAILS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row;">
                    <img [src]="makeUrl+''+(row.make | removewhitespaces)+'.png'" alt="car make" class="cars_img">
                    <span id="make_show">{{' ' + row.make +' '}}</span><span>{{' '+row.model}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="accountName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>SOURCE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.accountName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="leadStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>STATUS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <span class="status{{row.leadStatus}}">
                        <strong>
                            {{row.leadStatus == 1 ? 'Invalid' : row.leadStatus == 2 ? 'Cancelled' : row.leadStatus == 7 ? 'Confirmed' : row.leadStatus == 6 ? 'Enquiry' : ''}}
                        </strong></span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="searchOrdersColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: searchOrdersColumns;" (click)="openDetails(row.leadId)" id="created_on"></tr>
        </table>
        <div *ngIf="noData" class="txt_center">
            <mat-error> No Data to display</mat-error>
        </div>
        <mat-paginator class="order_paginator" [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>