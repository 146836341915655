import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarView
} from 'angular-calendar';
import { LoginService } from 'app/main/authentication/login/login.service';
import { ChartOptions, ChartType } from 'chart.js';
import {
    addDays,
    addHours, endOfDay,
    endOfMonth,
    isSameDay,
    isSameMonth, startOfDay,
    subDays
} from 'date-fns';
import { BaseChartDirective, Label } from 'ng2-charts';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { DashboardService } from './dashboard.service';

// import * as pluginDataLabels from 'chartjs-plugin-datalabels';
const backDate = (numOfDays) => {
    const today1 = new Date();
    return new Date(today1.setDate(today1.getDate() - numOfDays));
};
export let single = [
    {
        name: 'Pending Quotation',
        value: 0,
    },
    {
        name: 'Pending Approval',
        value: 0,
    },
    {
        name: 'Delayed',
        value: 0,
    },
];
const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
};
@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class DashboardComponent implements OnInit {
    // ---------/calendar---------
    /**
     * Constructor
     *
     */
    constructor(
        private router: Router,
        private loginService: LoginService,
        private dService: DashboardService,
        private modal: NgbModal
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        const today = new Date();
        const lastMonth = backDate(30);
        this.setupPresets();
        this.options = {
            presets: this.presets,
            format: 'mediumDate',
            range: { fromDate: lastMonth, toDate: today },
            applyLabel: 'Submit',
            calendarOverlayConfig: {
                shouldCloseOnBackdropClick: true,
            },
        };
        this.dataPieChart = this.dService.chartPieData;
        if (this.dataPieChart.response.status === 'SUCCESS') {
        } else {
            setTimeout(() => {
                this.loginService.logout();
            }, 1000);
        }
        this.dashData = this.dService.dashboardData;
        this.widgets = this.dashData.response.body;
        this.revenueActive = true;
        if (this.dashData.response.status === 'SUCCESS') {
            this.updateChartVariables(this.widgets);
            this.emptyLineChart();
            // this.widgets.daily.forEach(data => {
            //     this.dData.push(data.revenue.toFixed(2).toString());
            //     this.lineChartLabels.push(data.dateWeekMonth);
            // });
            //  this.revenuArray.forEach(data => {
            //     this.dData.push(data.toFixed(2).toString());
            //     this.lineChartLabels.push(data.dateWeekMonth);
            // });
            this.revenuArray.forEach((data) => {
                if (this.revenueActive) {
                    this.dData.push(parseFloat(data).toFixed(0).toString());
                } else {
                    this.dData.push(data.orders.toString());
                }
            });
            this.lineChartLabelsDates.forEach((datesL) => {
                this.lineChartLabels.push(datesL);
            });
            this.lineChartData.push({ data: this.dData, label: this.daily });
            this.totalRankCount = this.widgets.totalNoOfRanks.rankCount;
            this.userRatings(this.widgets);
            this.avgRating = this.widgets.merchantRatingNratingCountNrank.avgRating;
            this.rank = this.widgets.merchantRatingNratingCountNrank.rank;
            this.rankingNum = this.rank.toString().slice(-1);
            this.totalRatingGiven = this.widgets.merchantRatingNratingCountNrank.ratingCount;
        } else {
            setTimeout(() => {
                this.loginService.logout();
            }, 1000);
        }
        Object.assign(this, { single });
    }
    token: any;
    loading = false;
    revenueClass = '';
    range: Range = { fromDate: new Date(), toDate: new Date() };
    options: NgxDrpOptions;
    presets: Array<PresetItem> = [];
    dashData: any;
    widgets: any;
    revenue: string;
    orders: string;
    npsRating: string;
    rDashData: any;
    rWidgets: any;
    revenueNum: number;
    revenueActive = false;
    chartTime = 'daily';
    daily = 'daily';
    weekly = 'weekly';
    monthly = 'monthly';
    firstDate: any;
    secondDate: any;
    totalDelivered: any;
    labourPriceInr: any;
    labourPrice: any;
    partsPrice: any;
    partsPriceInr: any;
    generalServiceTotalInr: any;
    generalServiceTotal: any;
    bodyRepairTotalInr: any;
    bodyRepairTotal: any;
    generalRepairTotalInr: any;
    generalRepairTotal: any;
    noPartsLabour = false;
    noServiceCate = false;
    monthData = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    public dData: Array<any> = [];
    public dLabels: Array<any> = [];
    public wData: Array<any> = [];
    public wLabels: Array<any> = [];
    public mData: Array<any> = [];
    public mLabels: Array<any> = [];
    public lineChartData: Array<any> = [];
    public lineChartLabels: Array<any> = [];
    isSingapore = false;
    revenueInr: any;
    discountInr: any;
    discount: any;
    credit: any;
    debit: any;
    creditInr: any;
    tds: any;
    tdsInr: any;
    tcs: any;
    tcsInr: any;
    commisionGst: any;
    commisionGstInr: any;
    debitInr: any;
    totalRankCount: any;
    ratingCount: any;
    ratings: any;
    ratingCountOne: any = [];
    ratingCountTwo: any = [];
    ratingCountThree: any = [];
    ratingCountFour: any = [];
    ratingCountFive: any = [];
    ratingOneTotal: number;
    ratingTwoTotal: number;
    ratingThreeTotal: number;
    ratingFourTotal: number;
    ratingFiveTotal: number;
    oneRatingBar: any;
    twoRatingBar: any;
    threeRatingBar: any;
    fourRatingBar: any;
    fiveRatingBar: any;
    totalUserRating: number;
    totalRatingGiven: number;
    avgRating: number;
    rank: number;
    rankingNum: any;
    dataPieChart: any;
    pieData: any;
    pieDashData: any;
    pendingQuote: any;
    completedOrder: any;
    pendingApprov: any;
    ongoingOrder: any;
    workProgress: any;
    barChartData = [];
    barGraphShow = false;
    hideOrderWidget = false;
    chartLength = true;
    hideDailyWeek = true;
    dayFiltered: any;
    totalOrders = 0;
    orderConfirmed: any;
    datesArray = [];
    revenuArray = [];
    lineChartLabelsDates = [];
    // calendar---------
    public lineChartOptions: any = {
        responsive: true,
        scales: {
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: 'Amount',
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: 'Time',
                    },
                },
            ],
        },
    };
    public lineChartLegend = false;
    public lineChartType = 'line';
    single: any[];
    addOne: any[];
    result: any[];
    // options
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = true;
    xAxisLabel = '';
    showYAxisLabel = true;
    yAxisLabel = '';

    colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
    };
    @ViewChild('dateRangePicker', { static: false }) dateRangePicker;
    @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
    // -------calendar----------------
    @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
    view: CalendarView = CalendarView.Month;
    View = CalendarView;
    viewDate: Date = new Date();
    // view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;
    modalData: {
        action: string;
        event: CalendarEvent;
    };

    actions: CalendarEventAction[] = [
        {
            label: '<i class="fa fa-fw fa-pencil"></i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            },
        },
        {
            label: '<i class="fa fa-fw fa-times"></i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events = this.events.filter((iEvent) => iEvent !== event);
                this.handleEvent('Deleted', event);
            },
        },
    ];
    refresh: Subject<any> = new Subject();

    events: CalendarEvent[] = [
        {
            start: subDays(startOfDay(new Date()), 1),
            end: addDays(new Date(), 1),
            title: 'A 3 day event',
            color: colors.red,
            actions: this.actions,
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true,
            },
            draggable: true,
        },
        {
            start: startOfDay(new Date()),
            title: 'An event with no end date',
            color: colors.yellow,
            actions: this.actions,
        },
        {
            start: subDays(endOfMonth(new Date()), 3),
            end: addDays(endOfMonth(new Date()), 3),
            title: 'A long event that spans 2 months',
            color: colors.blue,
            allDay: true,
        },
        {
            start: addHours(startOfDay(new Date()), 2),
            end: addHours(new Date(), 2),
            title: 'A draggable and resizable event',
            color: colors.yellow,
            actions: this.actions,
            resizable: {
                beforeStart: true,
                afterEnd: true,
            },
            draggable: true,
        },
    ];
    activeDayIsOpen = true;
    // -------------/calendar----------------
    // Pie
    public pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: 'left',
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                },
            },
        },
    };
    public pieChartLabels: Label[] = [
        'Confirmed',
        'Ongoing',
        'Service Completed',
        'Delivered',
    ];
    public pieChartData: any[] = [];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [];
    public pieChartColors = [
        {
            backgroundColor: [
                'rgba(203, 148, 46,1)',
                'rgba(151, 59, 204,1)',
                'rgba(196, 160, 92,1)',
                'rgba(109, 203, 153,1)',
            ],
        },
    ];
    // --------------parts v/s labour
    public pieChartPartsLabourLabels: Label[] = ['Parts', 'Labour'];
    public pieChartPartsLabourData: any[] = [];
    public pieChartPartLabourType: ChartType = 'pie';
    public pieChartPartLabourLegend = true;
    public pieChartPartsLabourPlugins = [];
    public pieChartPartsLabourColors = [
        {
            backgroundColor: ['rgba(255, 200, 99,1)', 'rgba(151, 59, 204,1)'],
        },
    ];
    // ---------service categories----------

    public pieChartServiceCateLabels: Label[] = [
        'General Service',
        'Body Repair',
        'General Repair',
    ];
    public pieChartServiceCatData: any[] = [];
    public pieChartServiceCateType: ChartType = 'pie';
    public pieChartServiceCateLegend = true;
    public pieChartServiceCatePlugins = [];
    public pieChartServiceCateColors = [
        {
            backgroundColor: [
                'rgba(204, 171, 110,1)',
                'rgba(109, 203, 153,1)',
                'rgba(255, 200, 99,1)',
            ],
        },
    ];
    // -----------calendar-----------------
    dayClicked({
        date,
        events,
    }: {
        date: Date;
        events: CalendarEvent[];
    }): void {
        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) &&
                    this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }
    eventTimesChanged({
        event,
        newStart,
        newEnd,
    }: CalendarEventTimesChangedEvent): void {
        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    }
    handleEvent(action: string, event: CalendarEvent): void {
        this.modalData = { event, action };
        this.modal.open(this.modalContent, { size: 'lg' });
    }
    addEvent(): void {
        this.events = [
            ...this.events,
            {
                title: 'New event',
                start: startOfDay(new Date()),
                end: endOfDay(new Date()),
                color: colors.red,
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true,
                },
            },
        ];
    }
    // onPieSliceSelect(event){
    //         alert("hi");
    // }
    deleteEvent(eventToDelete: CalendarEvent) {
        this.events = this.events.filter((event) => event !== eventToDelete);
    }

    setView(view: CalendarView) {
        this.view = view;
    }
    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }
    updateRange(range: Range) {
        this.loading = true;
        this.range = range;
        const from = new Date(this.range.fromDate);
        const to = new Date(this.range.toDate);
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        this.firstDate = new Date(this.range.fromDate);
        this.secondDate = new Date(this.range.toDate);
        const diffDays = Math.round(
            Math.abs((this.firstDate - this.secondDate) / oneDay)
        );
        this.dayFiltered = diffDays;
        if (diffDays > 31) {
            this.hideDailyWeek = false;
            this.monthlyData();
        } else {
            this.hideDailyWeek = true;
        }
        if (from.getTime() <= to.getTime()) {
            this.dService
                .getDashboardCustomData(
                    this.range.fromDate,
                    this.range.toDate,
                    diffDays
                )
                .pipe(first())
                .subscribe(
                    (data) => {
                        this.rDashData = data;
                        if (this.rDashData && this.rDashData.response) {
                            this.widgets = this.rDashData.response.body;
                            // this.initChart(this.widgets);
                            this.pieChartPartsLabourData = [];

                            this.partsPriceInr = this.widgets.labourPartsPercent.partsTotal.replace(
                                /(\d)(?=(\d{2})+\d\.)/g,
                                '$1,'
                            );
                            this.partsPrice = this.widgets.labourPartsPercent.partsTotal;
                            this.labourPriceInr = this.widgets.labourPartsPercent.labourTotal.replace(
                                /(\d)(?=(\d{2})+\d\.)/g,
                                '$1,'
                            );
                            this.labourPrice = this.widgets.labourPartsPercent.labourTotal;
                            const labourPercent = (
                                this.widgets.labourPartsPercent.labourPercent *
                                100
                            ).toFixed(1);
                            const partsPercent = (
                                this.widgets.labourPartsPercent.partsPercent *
                                100
                            ).toFixed(1);
                            this.pieChartPartsLabourData.push(partsPercent);
                            this.pieChartPartsLabourData.push(labourPercent);
                            this.bodyRepairTotalInr = this.widgets.labourPartsPercent.bodyRepairTotal.replace(
                                /(\d)(?=(\d{2})+\d\.)/g,
                                '$1,'
                            );
                            this.bodyRepairTotal = this.widgets.labourPartsPercent.bodyRepairTotal;
                            this.generalServiceTotalInr = this.widgets.labourPartsPercent.generalServiceTotal.replace(
                                /(\d)(?=(\d{2})+\d\.)/g,
                                '$1,'
                            );
                            this.generalServiceTotal = this.widgets.labourPartsPercent.generalServiceTotal;
                            this.generalRepairTotalInr = this.widgets.labourPartsPercent.generalRepairTotal.replace(
                                /(\d)(?=(\d{2})+\d\.)/g,
                                '$1,'
                            );
                            this.generalRepairTotal = this.widgets.labourPartsPercent.generalRepairTotal;
                            const generalServicePercent = (
                                this.widgets.labourPartsPercent
                                    .generalServicePercent * 100
                            ).toFixed(1);
                            const bodyRepairPercent = (
                                this.widgets.labourPartsPercent
                                    .bodyRepairPercent * 100
                            ).toFixed(1);
                            const generalRepairPercent = (
                                this.widgets.labourPartsPercent
                                    .generalRepairPercent * 100
                            ).toFixed(1);
                            this.pieChartServiceCatData.push(
                                generalServicePercent
                            );
                            this.pieChartServiceCatData.push(bodyRepairPercent);
                            this.pieChartServiceCatData.push(
                                generalRepairPercent
                            );
                            if (
                                this.bodyRepairTotal == 0 &&
                                this.generalServiceTotal == 0 &&
                                this.generalRepairTotal == 0
                            ) {
                                this.noServiceCate = false;
                            } else {
                                this.noServiceCate = true;
                            }
                            if (this.partsPrice == 0 && this.labourPrice == 0) {
                                this.noPartsLabour = false;
                            } else {
                                this.noPartsLabour = true;
                            }
                        }
                        this.getDates(this.range.fromDate, this.range.toDate);
                        this.initChart(this.widgets);
                    },
                    (error) => {
                        this.loading = false;
                    }
                );
            this.pieChartData = [];
            this.pieChartPartsLabourData = [];
            this.pieChartServiceCatData = [];
            this.dService
                .getPiechartCustomData(this.range.fromDate, this.range.toDate)
                .pipe(first())
                .subscribe(
                    (data) => {
                        this.pieDashData = data;
                        this.pieData = this.pieDashData.response.body;
                        if (this.pieDashData.response.status === 'SUCCESS') {
                            this.pieChartData = [];
                            this.completedOrder = this.pieData.ordersStats.completedOrders.length;
                            this.pendingQuote = this.pieData.ordersStats.noQuotaionUploadedOrders.length;
                            this.ongoingOrder = this.pieData.ordersStats.ongoingOrders.length;
                            this.pendingApprov = this.pieData.ordersStats.pendingApprovalOrders.length;

                            this.totalDelivered = this.pieData.deliveredOrdersCount;
                            this.orderConfirmed = this.pieData.confirmedOrderCount;
                            this.totalOrders =
                                this.pieData.confirmedOrderCount +
                                this.ongoingOrder +
                                this.completedOrder +
                                this.totalDelivered;
                            const percentConfirmOrder = (
                                (this.pieData.confirmedOrderCount * 100) /
                                this.totalOrders
                            ).toFixed(1);
                            const percentongoingOrder = (
                                (this.ongoingOrder * 100) /
                                this.totalOrders
                            ).toFixed(1);
                            const percentcompletedOrder = (
                                (this.completedOrder * 100) /
                                this.totalOrders
                            ).toFixed(1);
                            const percenttotalDelivered = (
                                (this.totalDelivered * 100) /
                                this.totalOrders
                            ).toFixed(1);
                            this.pieChartData.push(percentConfirmOrder);
                            this.pieChartData.push(percentongoingOrder);
                            this.pieChartData.push(percentcompletedOrder);
                            this.pieChartData.push(percenttotalDelivered);
                            if (
                                this.pieData.confirmedOrderCount > 0 ||
                                this.ongoingOrder > 0 ||
                                this.completedOrder > 0 ||
                                this.totalDelivered > 0
                            ) {
                                this.hideOrderWidget = true;
                                this.chartLength = false;
                            } else {
                                this.hideOrderWidget = false;
                                this.chartLength = true;
                            }
                            this.barChartData = [];
                            this.barChartData.push(this.pendingQuote);
                            this.barChartData.push(this.pendingApprov);
                            this.barChartData.push(this.ongoingOrder);
                            if (
                                this.pieData.ordersStats.length !== undefined ||
                                (this.pendingQuote !== 0 &&
                                    this.pendingApprov !== 0 &&
                                    this.ongoingOrder !== 0)
                            ) {
                                this.barGraphShow = true;
                            } else {
                                this.barGraphShow = false;
                            }
                            this.single = single.slice();
                            this.addOne = single.slice();
                            this.result = single.slice();
                            for (let i = 0; i < single.length; i++) {
                                for (
                                    let index = 0;
                                    index < this.barChartData.length;
                                    index++
                                ) {
                                    const element = this.barChartData[index];
                                    if (i === index) {
                                        this.single[
                                            i
                                        ].value = this.barChartData[index];
                                    }
                                }
                            }
                        }
                    },
                    (error) => {
                        this.loading = false;
                    }
                );
        } else {
            alert('"From Date" cannot be greater than "To Date"');
            this.loading = false;
        }
    }
    getDates(start, end) {
        this.lineChartLabels = [];
        this.lineChartLabelsDates = [];
        this.dData = [];
        this.revenuArray = [];
        const datesMap = new Map();
        const startDate = new Date(start);
        while (startDate <= end) {
            datesMap.set(
                new Date(
                    startDate.getTime() - startDate.getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .split('T')[0],
                0
            );
            this.lineChartLabels.push(
                new Date(
                    startDate.getTime() - startDate.getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .split('T')[0]
            );
            this.lineChartLabelsDates.push(
                new Date(
                    startDate.getTime() - startDate.getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .split('T')[0]
            );
            startDate.setDate(startDate.getDate() + 1);
        }
        for (let index = 0; index < this.widgets.daily.length; index++) {
            const element = this.widgets.daily[index];
            for (const key of datesMap.keys()) {
                if (this.widgets.daily[index].dateWeekMonth === key) {
                    datesMap.set(key, element.revenue);
                }
            }
        }
        for (const dateRevenue of datesMap.values()) {
            this.dData.push(dateRevenue);
            this.revenuArray.push(dateRevenue);
        }
        this.lineChartData.push({ data: this.dData, label: this.daily });
    }
    // DatePicker helper function to create initial presets
    setupPresets() {
        const today = new Date();
        const yesterday = backDate(1);
        const minus7 = backDate(7);
        const minus30 = backDate(30);
        const currMonthStart = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
        );
        const currMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
        );
        const lastMonthStart = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            1
        );
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        this.presets = [
            {
                presetLabel: 'Yesterday',
                range: { fromDate: yesterday, toDate: today },
            },
            {
                presetLabel: 'Last 7 Days',
                range: { fromDate: minus7, toDate: today },
            },
            {
                presetLabel: 'Last 30 Days',
                range: { fromDate: minus30, toDate: today },
            },
            {
                presetLabel: 'This Month',
                range: { fromDate: currMonthStart, toDate: currMonthEnd },
            },
            {
                presetLabel: 'Last Month',
                range: { fromDate: lastMonthStart, toDate: lastMonthEnd },
            },
        ];
    }

    ngOnInit() {
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        } else {
            this.isSingapore = false;
        }
        // const loginData = JSON.parse(this.loginService.getLoginData());
        // const canUploadLead = loginData.merchant.canCreateBulkLead;
        // if (canUploadLead.toString() === '1') {

        // }
        // else {
        //     this._fuseNavigationService.removeNavigationItem('renewal-bookings');
        // }
    }

    public randomize(): void {
        const _lineChartData: Array<any> = new Array(this.lineChartData.length);
        for (let i = 0; i < this.lineChartData.length; i++) {
            _lineChartData[i] = {
                data: new Array(this.lineChartData[i].data.length),
                label: this.lineChartData[i].label,
            };
            for (let j = 0; j < this.lineChartData[i].data.length; j++) {
                _lineChartData[i].data[j] = Math.floor(Math.random() * 100 + 1);
            }
        }
        this.lineChartData = _lineChartData;
    }

    // events
    public chartClicked(e: any): void {}
    public chartHovered(e: any): void {}
    initChart(chartData: any) {
        this.dData = [];
        this.lineChartLabels = [];
        this.emptyLineChart();
        this.updateChartVariables(chartData);
        // console.log(this.revenuArray);
        this.revenuArray.forEach((data) => {
            if (this.revenueActive) {
                this.lineChartType = 'line';
                this.dData.push(data.toFixed(0).toString());
            }
            // else {
            //     this.lineChartType = 'bar';
            //     this.dData.push(data.orders.toString());
            // }
            // this.lineChartLabels.push(data.dateWeekMonth);
        });
        this.lineChartLabelsDates.forEach((datesL) => {
            this.lineChartLabels.push(datesL);
        });
        // console.log(this.lineChartLabelsDates);

        if (this.dayFiltered > 31) {
            this.lineChartData.push({ data: this.dData, label: this.monthly });
            this.chartTime = this.monthly;
            this.monthlyData();
        } else {
            this.lineChartData.push({ data: this.dData, label: this.daily });
            this.chartTime = this.daily;
        }
        this.updateLineChart();
        this.loading = false;
    }
    dataToggle(type: string) {
        this.loading = true;
        if (type === 'revenue') {
            this.revenueActive = true;
        } else {
            this.revenueActive = false;
        }
        this.updateRange(this.range);
    }

    // Daily Chart
    dailyData() {
        this.dData = [];
        this.emptyLineChart();
        this.updateChartVariables(this.widgets);
        // this.widgets.daily.forEach(data => {
        //     if (this.revenueActive) {
        //         this.dData.push(
        //             parseFloat(data.revenue).toFixed(2) .toString()
        //         );
        //     } else {
        //         this.dData.push(data.orders.toString());
        //     }

        //     this.lineChartLabels.push(data.dateWeekMonth);
        // });

        this.revenuArray.forEach((data) => {
            if (this.revenueActive) {
                this.dData.push(parseFloat(data).toFixed(0).toString());
            } else {
                this.dData.push(data.orders.toString());
            }
        });
        this.lineChartLabelsDates.forEach((datesL) => {
            this.lineChartLabels.push(datesL);
        });
        this.lineChartData.push({ data: this.dData, label: this.daily });
        this.chartTime = this.daily;
        this.updateLineChart();
    }
    // Weekly Chart
    weeklyData() {
        this.wData = [];
        this.emptyLineChart();
        this.updateChartVariables(this.widgets);
        this.widgets.weekly.forEach((data) => {
            if (this.revenueActive) {
                this.wData.push(data.revenue.toFixed(2).toString());
            } else {
                this.wData.push(data.orders.toString());
            }
            this.lineChartLabels.push(data.dateWeekMonth + '-Week');
        });
        this.lineChartData.push({ data: this.wData, label: this.weekly });
        this.chartTime = this.weekly;
        this.updateLineChart();
    }
    // Monthly Chart
    monthlyData() {
        this.mData = [];
        this.emptyLineChart();
        this.updateChartVariables(this.widgets);
        this.widgets.monthly.forEach((data) => {
            if (this.revenueActive) {
                this.mData.push(data.revenue.toFixed(2).toString());
            } else {
                this.mData.push(data.orders.toString());
            }
            this.lineChartLabels.push(this.monthData[data.dateWeekMonth - 1]);
        });
        this.lineChartData.push({ data: this.mData, label: this.monthly });
        this.chartTime = this.monthly;
        this.updateLineChart();
    }
    emptyLineChart() {
        this.lineChartLabels.length = 0;
        this.lineChartData.length = 0;
    }
    updateLineChart() {
        this.chart.chart.config.data.labels = this.lineChartLabels;
        this.chart.chart.config.data.datasets = this.lineChartData;
        this.chart.chart.config.type = this.lineChartType;
        this.chart.chart.update();
        if (this.chart !== undefined) {
            this.chart.ngOnDestroy();
            this.chart.chart = this.chart.getChartBuilder(this.chart.ctx);
        }
    }
    updateChartVariables(dashData: any) {
        dashData.revenue
            ? (this.revenueNum = dashData.revenue.toString())
            : (this.revenueNum = 0);
        dashData.revenue
            ? (this.revenue = dashData.revenue.toFixed(2))
            : (this.revenue = '0');
        dashData.totalDiscount
            ? (this.discount = dashData.totalDiscount.toFixed(2))
            : (this.discount = '0');
        const parts = this.revenue.toString().split('.');
        this.revenueInr = dashData.revenue
            .toFixed(2)
            .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
        this.discountInr = dashData.totalDiscount
            .toFixed(2)
            .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');

        dashData.crditDebitDetails.credit
            ? (this.credit = dashData.crditDebitDetails.credit.toString())
            : (this.credit = 0);
        dashData.crditDebitDetails.credit
            ? (this.credit = dashData.crditDebitDetails.credit.toFixed(2))
            : (this.credit = 0);
        this.creditInr = dashData.crditDebitDetails.credit
            .toFixed(2)
            .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
        dashData.crditDebitDetails.tds
            ? (this.tds = dashData.crditDebitDetails.tds.toFixed(2))
            : (this.tds = '0');
        this.tdsInr = dashData.crditDebitDetails.tds
            .toFixed(2)
            .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
        dashData.crditDebitDetails.commisionGst
            ? (this.commisionGst = dashData.crditDebitDetails.commisionGst.toFixed(
                  2
              ))
            : (this.commisionGst = '0');
        this.commisionGstInr = dashData.crditDebitDetails.commisionGst
            .toFixed(2)
            .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
        dashData.crditDebitDetails.tcs
            ? (this.tcs = dashData.crditDebitDetails.tcs.toFixed(2))
            : (this.tcs = '0');
        this.tcsInr = dashData.crditDebitDetails.tcs
            .toFixed(2)
            .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
        dashData.crditDebitDetails.debit
            ? (this.debit = dashData.crditDebitDetails.debit.toString())
            : (this.debit = 0);
        dashData.crditDebitDetails.debit
            ? (this.debit = dashData.crditDebitDetails.debit.toFixed(2))
            : (this.debit = 0);
        this.debitInr = dashData.crditDebitDetails.debit
            .toFixed(2)
            .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
    }
    userRatings(ratingData: any) {
        ratingData.ratingList.forEach((element) => {
            this.ratingCount = element.countOfRating;
            this.ratings = element.rating;
            if (this.ratings >= 0 && this.ratings <= 1) {
                this.ratingCountOne.push(this.ratingCount);
            }
            if (this.ratings > 1 && this.ratings <= 2) {
                this.ratingCountTwo.push(this.ratingCount);
            }
            if (this.ratings > 2 && this.ratings <= 3) {
                this.ratingCountThree.push(this.ratingCount);
            }
            if (this.ratings > 3 && this.ratings <= 4) {
                this.ratingCountFour.push(this.ratingCount);
            }
            if (this.ratings > 4 && this.ratings <= 5) {
                this.ratingCountFive.push(this.ratingCount);
            }
        });
        this.totalUserRating = ratingData.ratingList.reduce(function (
            prev,
            cur
        ) {
            return Number(prev) + Number(cur.countOfRating);
        },
        0);
        const totalRatingList = ratingData.ratingList.length;
        this.ratingOneTotal = this.ratingCountOne.reduce(function (prev, cur) {
            return Number(prev) + Number(cur);
        }, 0);
        if (this.ratingOneTotal !== 0 && this.totalUserRating !== 0) {
            this.oneRatingBar =
                (this.ratingOneTotal / this.totalUserRating) * 100;
        } else {
            this.oneRatingBar = 0;
        }
        this.ratingTwoTotal = this.ratingCountTwo.reduce(function (prev, cur) {
            return Number(prev) + Number(cur);
        }, 0);
        if (this.ratingTwoTotal !== 0 && this.totalUserRating !== 0) {
            this.twoRatingBar =
                (this.ratingTwoTotal / this.totalUserRating) * 100;
        } else {
            this.twoRatingBar = 0;
        }
        this.ratingThreeTotal = this.ratingCountThree.reduce(function (
            prev,
            cur
        ) {
            return Number(prev) + Number(cur);
        },
        0);
        if (this.ratingThreeTotal !== 0 && this.totalUserRating !== 0) {
            this.threeRatingBar =
                (this.ratingThreeTotal / this.totalUserRating) * 100;
        } else {
            this.threeRatingBar = 0;
        }
        this.ratingFourTotal = this.ratingCountFour.reduce(function (
            prev,
            cur
        ) {
            return Number(prev) + Number(cur);
        },
        0);
        if (this.ratingFourTotal !== 0 && this.totalUserRating !== 0) {
            this.fourRatingBar =
                (this.ratingFourTotal / this.totalUserRating) * 100;
        } else {
            this.fourRatingBar = 0;
        }
        this.ratingFiveTotal = this.ratingCountFive.reduce(function (
            prev,
            cur
        ) {
            return Number(prev) + Number(cur);
        },
        0);
        if (this.ratingFiveTotal !== 0 && this.totalUserRating !== 0) {
            this.fiveRatingBar =
                (this.ratingFiveTotal / this.totalUserRating) * 100;
        } else {
            this.fiveRatingBar = 0;
        }
    }
    // -----------------calander ----------------------------------
}
