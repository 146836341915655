import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/main/globals';
import { LoginService } from 'app/main/authentication/login/login.service';

@Injectable({ providedIn: 'root' })
export class PastOrdersService implements Resolve<any>
{
    pastOrders: any[];
    paramData: any;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getPastOrders()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
    getPastOrders(): Promise<any> {
        const data = {
            'appKey': `${GlobalVariable.APP_KEY}`,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'statuses': '11'
        };
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            this.http.post(`${GlobalVariable.BASE_API_URL}` + '/gmssearchordersv2',
               formData)
                .subscribe((response: any) => {
                    this.pastOrders = JSON.parse(response);
                    resolve(JSON.parse(response));
                }, reject);
        });
    }
}
