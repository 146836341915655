import { NgModule } from '@angular/core';
import { NewBookingsModule } from 'app/main/bookings/new-bookings/new-bookings.module';
import { SearchBookingsModule } from './search-bookings/search-bookings.module';
import { AssignedBookingsModule } from './assigned-bookings/assigned-bookings.module';
import { RenewalBookingsModule } from './renewal-bookings/renewal-bookings.module';
import { BookingDetailsModule } from './booking-details/booking-details.module';
import { ApplicationPipesModule } from '../applicationPipesModule.module';
@NgModule({
  imports: [
    NewBookingsModule,
    SearchBookingsModule,
    AssignedBookingsModule,
    RenewalBookingsModule,
    BookingDetailsModule,
    ApplicationPipesModule,

  ],
})
export class BookingsModule {
}
