import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AspReplaceCartPopupComponent } from './asp-replace-cart-popup.component';
// import { MatButtonModule } from '@angular/material/button';
// import { MatIconModule } from '@angular/material/icon';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [AspReplaceCartPopupComponent],
  imports: [
    CommonModule,
    // MatButtonModule,
    // MatIconModule,

    HttpClientModule,
    TranslateModule.forRoot({
    loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient]
    }
    })
  ]
})
export class AspReplaceCartPopupModule { }
