import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import { DoorstepStatus, PickupStatus } from 'app/main/globals';
import { SearchOrdersService } from 'app/main/orders/search-orders/search-orders.service';
import { navigation } from 'app/navigation/navigation';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

export interface SearchOrdersData {
    orderId: string;
    registration: string;
    fuelType: string;
    vehicle: string;
    orderStartTime: any;
    orderEndTime: any;
    grandTotal: string;
    status: string;
}

@Component({
    host: {
        '(document:click)': 'onClick($event)'
      },
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    isfullscreen: any;
    searchForm: FormGroup;
    loading = false;
    noData = false;
    submitted = false;
    completed = false;
    selectedValue = '';
    searchOrders: any;
    searchOrdersList: any;
    displayOrders: [];
    searchOrdersData: any = [];
    searchSource: MatTableDataSource<SearchOrdersData>;
    searchOrdersColumns: string[] = ['orderId', 'registration', 'orderStartTime', 'orderEndTime', 'grandTotal', 'status'];
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    userData: any = { name: '', img: '' };
    loginData: any;
    timezone: any;
    format: any;
    moment = moment();
    showSearch = false;
    marketPlaceAppKey: any;
    marketPlaceAccountId: any;
    permissionType: any;
    showCreateOrder = false;
    offset = 1;
    notificationData: any;
    responseData: any;
    totalNotification: any;
    cartCount = 0;
    showNotifaction = false;
    notificationId: any;
    deactivateResult: any;
    showPaginations = false;
    showNotification = false;
    showPrevButton = true;
    noNotification = false;
    translateImg:any = './assets/icons/language-change-icon.png';
    langSwitchImg = [{"en":"./assets/icons/en.png", "hi": "https://cube.getpitstop.com/prodimages/pitstop_delhi_icon.png", "ka": "https://cube.getpitstop.com/prodimages/pitstop_bengaluru_icon.png"}];
    currLang:boolean = false;
    // Private
    private _unsubscribeAll: Subject<any>;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        public router: Router,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        public loginService: LoginService,
        private _formBuilder: FormBuilder,
        private soService: SearchOrdersService,
        private appComponent: AppComponent,
        private _fuseNavigationService: FuseNavigationService,
        private _eref: ElementRef,
        public translate: TranslateService
    ) {

        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.navigation = navigation;
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.searchForm = this._formBuilder.group({
            condition: ['', [Validators.required]],
            query: ['', [Validators.required]]
        });

        if (window.innerWidth > 960) {
            this.showSearch = true;
        } else {
            this.showSearch = false;
        }

        // Register translation languages
        translate.addLangs(['en', 'hi']);
        // Set default language
        translate.setDefaultLang('en');

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth > 960) {
            this.showSearch = true;
        } else {
            this.showSearch = false;
        }
    }

    get f() { return this.searchForm.controls; }

    onSubmit() {
        this.openSearch();
        if (this.searchForm.invalid) {
            return;
        }
        this.noData = false;
        this.completed = false;
        this.loading = true;
        this.submitted = true;
        this.getSearchOrdersData(this.f.condition.value, this.f.query.value);
    }
    toggleNotification(){
        this.showNotifaction = ! this.showNotifaction ;
         this.deActivateNotification();
    }
    closeNotification(){
        this.showNotifaction = false;
    }
    openCreateOrder(){
        this.router.navigate(['orders/create-order']);
    }
    deActivateNotification(){
        this.soService.postDeactivatedNOtify(this.notificationId).subscribe(result => {
            this.deactivateResult=result;
            if(this.deactivateResult.response.status === 'SUCCESS'){
            this.getNotificationData();
            }
        },
        error => {
            console.log(error);
        });
    }
    confirmedOrderDetails(orderId){
        this.router.navigate(['orders/details/' + orderId]);
        this.showNotifaction = false;
    }
    onClick(event) {
        if (!this._eref.nativeElement.contains(event.target)){
            this.showNotifaction = false;
       }
    }
    getNotificationData(){
       const noticationIdArray = [];
        this.soService.getNotifications(this.offset).subscribe(result => {
            this.responseData = result;
            if(this.responseData.response.status === 'SUCCESS'){
                this.notificationData = this.responseData.response.body.notifications;

                this.notificationData.forEach(element => {
                    noticationIdArray.push(element.id);
                });
                this.notificationId = noticationIdArray.join();
                this.totalNotification = this.responseData.response.body.notificationsCount;
                    if(this.notificationData.length > 9){
                        this.showPaginations = true;
                    }
                    else{
                        this.showPaginations = false;
                    }
                    if(this.totalNotification > 0){
                        this.showNotification = true;
                    }
                    else{
                        this.showNotification = false;
                    }
                    if(this.notificationData.length < 1 ){
                            this.noNotification=true;
                    }
                    else{
                        this.noNotification=false;
                    }

            }
            else{

            }
          },
          error => {
              console.log(error);

          });
    }
    prevNotify(){
        this.offset = this.offset - 1;
        if(this.offset < 2 ){
        this.showPrevButton = true;
        }
        else{
            this.showPrevButton = false;
        }
        this.soService.getNotifications(this.offset).subscribe(result => {
        this.responseData = result;
        this.notificationData = this.responseData.response.body.notifications;
        this.totalNotification = this.responseData.response.body.notificationsCount;
        this.noNotification=false;
      });
    }
    nextNotify(){
        this.showPrevButton=false;
        this.offset = this.offset + 1;
        this.soService.getNotifications(this.offset).subscribe(result => {
        this.responseData = result;
        if(this.responseData.response.status === 'SUCCESS'){
        this.notificationData = this.responseData.response.body.notifications;
        this.totalNotification = this.responseData.response.body.notificationsCount;
        if(this.notificationData.length < 1 ){
            this.noNotification=true;
        }
        else{
            this.noNotification=false;
        }
        }
      });
    }
    getSearchOrdersData(condition: string, query: string) {
        this.searchOrdersData = [];
        this.soService.getOrders(condition, query).subscribe((data: {}) => {
            this.searchOrdersList = data;
            if (this.searchOrdersList.response.status === 'SUCCESS') {
                this.searchOrders = this.searchOrdersList.response.body.orders;
                if (this.searchOrders.length === 0) {
                    this.searchSource = new MatTableDataSource(this.searchOrdersData);
                    this.searchSource.sort = this.sort;
                    this.searchSource.paginator = this.paginator;
                    this.noData = true;
                    this.completed = true;
                    this.loading = false;
                } else {
                    this.searchOrders.forEach(searchOrderList => {
                        this.searchOrdersData.push(this.createSearchOrdersData(searchOrderList));
                    });
                    this.searchSource = new MatTableDataSource(this.searchOrdersData);
                    this.searchSource.sort = this.sort;
                    this.searchSource.paginator = this.paginator;
                    this.loading = false;
                    this.completed = true;
                }
            } else {
                this.loading = false;
                this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                setTimeout(() => {
                    this.loginService.logout();
                }, 1000);
            }
        });
    }

    createSearchOrdersData(data: any): SearchOrdersData {
        let reg = '';
        let delivery = '';
        let startTime = '';
        let status = '';
        if (data.orderType.toString() === 'doorstep') {
            status = DoorstepStatus[data.status - 1];
        } else {
            status = PickupStatus[data.status - 1];
        }
        if (data.registration) {
            reg = data.registration.toString();
        }
        if (data.orderEndTime) {
            delivery = moment.unix(data.orderEndTime / 1000).tz(this.timezone).format(this.format);
        }

        if (data.orderStartTime) {
            startTime = moment.unix(data.orderStartTime / 1000).tz(this.timezone).format(this.format);
        }

        return {
            orderId: data.orderId.toString(),
            registration: reg,
            fuelType: data.fuelType.toString(),
            vehicle: data.model.toString(),
            orderStartTime: startTime,
            orderEndTime: delivery,
            grandTotal: parseFloat(data.grandTotal).toFixed(2),
            status: status.toString()
        };
    }

    openDetails(id: number) {
        this.router.navigate(['orders/details/', id]);
        this.closeSearchResults();
    }

    closeSearchResults() {
        this.searchOrdersData = [];
        this.searchSource = new MatTableDataSource(this.searchOrdersData);
        this.searchSource.sort = this.sort;
        this.searchSource.paginator = this.paginator;
        this.noData = false;
        this.completed = false;
        this.loading = false;
        this.submitted = false;
        this.searchForm.markAsPristine();
        this.searchForm.markAsUntouched();
        this.showSearch = true;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getNotificationData();

        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });
        this.getUserData();

        this.getCart();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    logout(): void {
        this.loginService.logout();
    }

    getUserData() {
        if (this.loginService.getToken()) {
            this.loginData = JSON.parse(this.loginService.getLoginData());
            this.userData = { name: this.loginData.agentName, img: this.loginData.agentPhoto };
            return this.userData;
        }
    }

    openFullscreen() {
        // Trigger fullscreen
        const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
            mozRequestFullScreen(): Promise<void>;
            webkitRequestFullscreen(): Promise<void>;
            msRequestFullscreen(): Promise<void>;
        };

        if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
            docElmWithBrowsersFullScreenFunctions.requestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
            docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
        } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
            docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
        }
        this.isfullscreen = true;
    }

    closeFullscreen() {
        const docWithBrowsersExitFunctions = document as Document & {
            mozCancelFullScreen(): Promise<void>;
            webkitExitFullscreen(): Promise<void>;
            msExitFullscreen(): Promise<void>;
        };
        if (docWithBrowsersExitFunctions.exitFullscreen) {
            docWithBrowsersExitFunctions.exitFullscreen();
        } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
            docWithBrowsersExitFunctions.mozCancelFullScreen();
        } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            docWithBrowsersExitFunctions.webkitExitFullscreen();
        } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
            docWithBrowsersExitFunctions.msExitFullscreen();
        }
        this.isfullscreen = false;
    }

    openProfile() {
        this.router.navigate(['profile']);
    }

    openSearch() {
        this.showSearch = !this.showSearch;
    }


    getCart(){
        if(sessionStorage.getItem('addedCart')){    
          let cartData; 
          this.cartCount = 0;          
          cartData = JSON.parse(sessionStorage.getItem('addedCart'));          
          Object.values(cartData[0]).forEach(cart => {             
            this.cartCount += cart['cart_qty'];            
          });       
        }
    }

    openCart(){
        let cartCountNew = (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML;
        if(Number(cartCountNew) > 0){
            this.router.navigate(['auto-spare-parts/cart']);
        }
    }

    //Switch language
    translateLanguageTo(lang: string) {
        this.translate.use(lang);

        if(lang=='en' || lang==''){
            document.querySelector(".hi").classList.remove('activeLang');
            document.querySelector(".hi").classList.add('inactiveLang');

            document.querySelector(".en").classList.remove('inactiveLang');
            document.querySelector(".en").classList.add('activeLang');
        }else if(lang=='hi' || lang==''){           
            document.querySelector(".en").classList.remove('activeLang');
            document.querySelector(".en").classList.add('inactiveLang');

            document.querySelector(".hi").classList.remove('inactiveLang');
            document.querySelector(".hi").classList.add('activeLang');
        }
    }
}
