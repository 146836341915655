<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div class="h-100-p" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg">
                </div>
            </div>
            <div [hidden]="!showSearch" class="p-0 form-container" *ngIf="router.url != '/orders/search-orders'">
                <form [class.loading]="loading" name="searchForm" [formGroup]="searchForm" (ngSubmit)="onSubmit()" novalidate>
                    <mat-form-field>
                        <mat-select placeholder="Select" [(value)]="selectedValue" formControlName="condition">
                            <mat-option value="id">Order ID</mat-option>
                            <mat-option value="mobile">Customer Mobile Number</mat-option>
                            <mat-option value="registrationNumber">Vehicle Reg. Number</mat-option>
                            <!-- <mat-option value="status">Status</mat-option> -->
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="selectedValue == 'id' || selectedValue == 'mobile'">
                        <mat-label>Enter here</mat-label>
                        <input matInput type="number" min="0" formControlName="query">
                    </mat-form-field>
                    <mat-form-field *ngIf="selectedValue == 'registrationNumber'">
                        <mat-label>Enter here</mat-label>
                        <input matInput formControlName="query">
                    </mat-form-field>

                    <button mat-raised-button color="accent" class="submit-button" aria-label="Search Orders" [disabled]="searchForm.invalid" style="background: #ddd !important;">
                        Search Orders
                    </button>
                    <button fxHide.gt-sm type="button" mat-icon-button class="fuse-search-bar-collapser" (click)="$event.preventDefault();openSearch()" aria-label="Collapse Search Bar">
                        Cancel
                    </button>
                </form>
            </div>
            <div class="px-8 px-md-16">
                <button mat-raised-button class="create_btn" aria-label="Search Orders">
                       
                        <a  (click)="openCreateOrder()"> Create Orders</a>
                    </button>
                <!-- <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts> -->
            </div>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <div fxHide.gt-sm class="toolbar-separator"></div>
            <button fxHide.gt-sm mat-icon-button (click)="openSearch()" class="fuse-search-bar-expander" aria-label="Expand Search Bar">
                <mat-icon class="s-24 secondary-text">search</mat-icon>
            </button>
            <div class="toolbar-separator"></div>
            <!-- <div class="notify_btn">
                <button class="open_btn" (click)="openCustomerData()"><i class="material-icons">
                    library_books
                    </i></button>
            </div> -->
            <div class="toolbar-separator"></div>
            <div class="notify_btn" #_eref2>
                <div class="count_notified" *ngIf="showNotification">
                    <p class="count_num">{{totalNotification}}</p>
                </div>
                <button class="open_btn" (click)="toggleNotification()"><i class="material-icons">
                    notification_important
                    </i></button>
            </div>
            <div class="toolbar-separator"></div>
            
            <!-- Language Switcher -->
            <!-- <img class="translate-img" [matMenuTriggerFor]="menu" src="{{translateImg}}" /> -->
            <!-- <mat-menu #menu="matMenu" class="lang-translation">
                <button mat-menu-item class="{{language}}" *ngFor="let language of translate.getLangs()" [ngClass]="currLang?'activeLang':'inactiveLang'" (click)="translateLanguageTo(language)">
                    <img *ngIf="language=='en'" src="{{langSwitchImg[0].en}}" />
                    <img *ngIf="language=='hi'" src="{{langSwitchImg[0].hi}}" />
                </button>
            </mat-menu> -->
            <!-- <mat-menu #menu="matMenu" class="lang-translation">
                <mat-radio-group aria-label="Select an option">
                    <mat-radio-button value="{{language}}" class="{{language}}" *ngFor="let language of translate.getLangs()" [ngClass]="currLang?'activeLang':'inactiveLang'" (click)="translateLanguageTo(language)">
                        <div class="langTranslationSec"> 
                            <span *ngIf="language=='en'">English</span>
                            <img *ngIf="language=='en'" src="{{langSwitchImg[0].en}}" />
                            <span *ngIf="language=='hi'">हिन्दी</span>
                            <img *ngIf="language=='hi'" src="{{langSwitchImg[0].hi}}" />
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </mat-menu>
            <div class="toolbar-separator"></div> -->
            <!-- End Language Switcher -->
            
            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" [src]="userData.img" 
                        onerror="this.onerror=null;this.src='https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{ userData.name }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="openProfile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>Profile</span>
                </button>
                <button mat-menu-item class="" (click)="loginService.logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="helpSection">
                    <mat-icon>help_outline</mat-icon>
                    <span>Help</span>
                </button>
            </mat-menu>
            <mat-menu #helpSection="matMenu" [overlapTrigger]="false">
                <div class="help_class">
                    <h4 class="helo_hedng">HELP</h4>
                    <hr>
                    <div class="email_help">
                        <p class="mail_sect">
                            <mat-icon>mail</mat-icon>
                        </p>
                        <p class="mail_sect"><a href="mailto:garage-help@getpitstop.com">garage-help@getpitstop.com</a></p>
                    </div>
                </div>
            </mat-menu>
            <div class="toolbar-separator" fxHide.lt-md></div>

            <!-- Cart  -->
            <div class="cart_btn">
                <div class="count_notified">
                    <p class="cart_count_num">{{cartCount}}</p>
                </div>
                <button class="open_btn" (click)="openCart()">
                    <mat-icon>shopping_cart</mat-icon>
                </button>
            </div>
            <div class="toolbar-separator"></div>
            <!-- Cart End -->
            
            <button mat-button fxHide.lt-md class="h-64 w-64" *ngIf="!isfullscreen" (click)="openFullscreen()">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="s-32">fullscreen</mat-icon>
                </div>
            </button>
            <button mat-button fxHide.lt-md class="h-64 w-64" *ngIf="isfullscreen" (click)="closeFullscreen()">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="s-32">fullscreen_exit</mat-icon>
                </div>
            </button>
            <!-- <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->

        </div>

    </div>
</mat-toolbar>
<div class="search-results-autocomplete" *ngIf="submitted">
    <div class="table-container mat-elevation-z8">
        <div class="page-title" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-headline text-left m-0 p-12" fxLayout="column" fxLayoutAlign="center start">Search
                Results</span>
            <div class="close-btn text-right p-12 pt-0 pr-8" fxLayout="column" fxLayoutAlign="center end" (click)="closeSearchResults()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <table class="clickable" mat-table [dataSource]="searchSource" matSort>
            <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.orderId}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="registration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>VEHICLE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <strong>{{row.registration | uppercase}}</strong>
                    <div><small>{{row.vehicle | uppercase}}</small> (<small>{{row.fuelType | lowercase}}</small>)</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="orderStartTime">
                <th fxHide.lt-md mat-header-cell *matHeaderCellDef mat-sort-header> <strong>APPOINTMENT</strong> </th>
                <td fxHide.lt-md mat-ripple mat-cell *matCellDef="let row">
                    {{row.orderStartTime == '' ? '-' : row.orderStartTime}}
                </td>
            </ng-container>

            <ng-container matColumnDef="orderEndTime">
                <th fxHide.lt-md mat-header-cell *matHeaderCellDef mat-sort-header> <strong>EXPECTED DELIVERY</strong>
                </th>
                <td fxHide.lt-md mat-ripple mat-cell *matCellDef="let row">
                    {{row.orderEndTime == '' ? '-' : row.orderEndTime}}
                </td>
            </ng-container>

            <ng-container matColumnDef="grandTotal">
                <th fxHide.lt-sm mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                <td fxHide.lt-sm mat-ripple mat-cell *matCellDef="let row">
                    {{row.grandTotal == 0 ? '-' : row.grandTotal | currency:"&#8377; "}}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>STATUS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <mat-chip-list>
                        <mat-chip class="{{row.status}} mat-chip mat-standard-chip mat-chip-selected" selected [innerHTML]="row.status"></mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="searchOrdersColumns;sticky: false"></tr>
            <tr mat-row *matRowDef="let row; columns: searchOrdersColumns;" (click)="openDetails(row.orderId)"></tr>
        </table>
        <div *ngIf="noData && !loading">
            <div class="message-box error mt-16 text-center font-weight-700">
                No Data to display
            </div>
        </div>
        <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
    </div>
</div>
<div class="notify_overlay" *ngIf="showNotifaction" #_eref>
    <button class="cls_btn" (click)="closeNotification()"><i class="material-icons">
        close
        </i></button>
    <div class="notify_cotainer">
        <ul *ngIf="!noNotification">
            <li *ngFor="let notifyMsg of notificationData" (click)="confirmedOrderDetails(notifyMsg.orderId)">{{notifyMsg.message}} </li>
        </ul>
        <p class="notification" *ngIf="noNotification"> No Data to display </p>
    </div>
    <div class="pagination_sec" *ngIf="showPaginations">
        <button (click)="prevNotify()" [disabled]="showPrevButton">Previous</button>
        <button (click)="nextNotify()">Next</button>
    </div>
</div>