<div id="switch-garage" class="inner-scroll" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center">
    <div id="switch-garage-form-wrapper" fusePerfectScrollbar>
        <mat-icon style="text-align: right; width: 100%; cursor: pointer;" 
        (click)="closePopup()" 
        *ngIf="garageKey">close</mat-icon>

        <div id="switch-garage-form">
            <div class="title">{{'LOGIN_INFO.GARAGE_POPUP_TITLE' | translate}}</div>
            <div *ngFor="let list of garageList">
                <div class="garage-name" (click)="selectGarage(list.garageKey, list.name)">{{list.name}}</div>
            </div>
        </div>
    </div>
</div>
