<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="content">
        <div class="left">
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="25">
                    <div class="fuse-card m-0">
                        <div class="p-0" fxLayout="row wrap" fxLayoutAlign="start center">
                            <div class="p-16 pb-0 w-100-p" fxLayout="row wrap" fxLayoutAlign="start center">
                                <ngx-mat-drp color="accent" (selectedDateRangeChanged)="updateRange($event)" [options]="options" #dateRangePicker></ngx-mat-drp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="fuse-card mt-8" *ngIf="dailReport">
        <div class="position-relative p-24" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="start start">
                <span class="h2">Daily Reports</span>
            </div>
        </div>
        <div class="position-relative p-16 pt-0">
            <!-- <canvas baseChart height="120vh" [datasets]="lineChartData" [labels]="lineChartLabels"
                [options]="lineChartOptions" [legend]="lineChartLegend" [chartType]="lineChartType"></canvas> -->
            <!-- <div style="height: 100%; width:100%">
                <div style="height:20vh; width: 20vh;" class="barChart_gst">
                    <ngx-charts-bar-vertical [view]="view" [scheme]="colorScheme" [results]="single" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                        [yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
                  </canvas>
                </div>
            </div> -->
            <div>
                <canvas baseChart height="80vh" [datasets]="barChartOrderData" [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
                </canvas>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8 mt-8" *ngIf="!loading">
        <div *ngIf="!noData" class="p-12 pb-0 pt-0 export-block" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <button [disabled]="noData" (click)="exportEXCEL()" class="common_btn" mat-button color="accent">
                    Export
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>
            </div>
            <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table *ngIf="!noData" mat-table [dataSource]="dailyOrdersDataSource" matSort matSortActive="order_id" matSortDirection="desc" matSortDisableClear>
            <ng-container matColumnDef="BR">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> <strong>BR</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'"> <strong>{{row.BR}}</strong>
                </td>
            </ng-container>
            <ng-container matColumnDef="GMS">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> <strong>GMS</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'"> {{row.GMS}}
                </td>
            </ng-container>
            <ng-container matColumnDef="GST" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>GST</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">&#8377; {{row.GST}} </td>
            </ng-container>
            <ng-container matColumnDef="GST" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>GST</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">&#36; {{row.GST}} </td>
            </ng-container>
            <ng-container matColumnDef="Labour" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Labour</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">&#8377; {{row.Labour}} </td>
            </ng-container>
            <ng-container matColumnDef="Labour" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Labour</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">&#36; {{row.Labour}} </td>
            </ng-container>
            <ng-container matColumnDef="PMS">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> <strong>PMS</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'"> {{row.PMS}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Parts" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Parts</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">&#8377; {{row.Parts}} </td>
            </ng-container>
            <ng-container matColumnDef="Parts" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Parts</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">S&#36; {{row.Parts}} </td>
            </ng-container>
            <ng-container matColumnDef="Total" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>TOTAL</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>&#8377;
                        {{row.Total == '' ? '-' : row.Total}}</strong>
                </td>
            </ng-container>
            <ng-container matColumnDef="Total" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>TOTAL</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>&#36;
                        {{row.Total == '' ? '-' : row.Total}}</strong>
                </td>
            </ng-container>
            <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ACCOUNT<br>NAME</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.account == '' ? '-' : row.account}}
                </td>
            </ng-container>
            <ng-container matColumnDef="accountId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'">
                    <strong>ACCOUNT<br>ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    {{row.accountId == '' ? '-' : row.accountId}}
                </td>
            </ng-container>
            <ng-container matColumnDef="delivery_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>DELIVERY<br>DATE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" innerHTML="{{row.delivery_date == '' ? '-' : row.delivery_date}}"></td>
            </ng-container>
            <ng-container matColumnDef="makeModel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>MAKE<br>MODEL</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" innerHTML="{{row.makeModel == '' ? '-' : row.makeModel}}">
                </td>
            </ng-container>
            <ng-container matColumnDef="merchant_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'">
                    <strong>MERCHANT<br>NAME</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    {{row.merchant_name == '' ? '-' : row.merchant_name}}</td>
            </ng-container>
            <ng-container matColumnDef="order_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER<br>ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.order_id}}</strong> </td>
            </ng-container>
            <ng-container matColumnDef="order_start_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>START<br>DATE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" innerHTML="{{row.order_start_date == '' ? '-' : row.order_start_date}}">
                </td>
            </ng-container>
            <ng-container matColumnDef="registration_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>REG.<br>NUMBER</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.registration_number == '' ? '-' : row.registration_number}}
                </td>
            </ng-container>
            <ng-container matColumnDef="serviceType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>SERVICE<br>TYPE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.serviceType == '' ? '-' : row.serviceType}}
                </td>
            </ng-container>
            <ng-container matColumnDef="vehicle_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'">
                    <strong>VEHICLE<br>STATUS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    {{row.vehicle_status == '' ? '-' : row.vehicle_status}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dailyOrdersDataColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: dailyOrdersDataColumns;"></tr>
        </table>

        <mat-paginator class="order_paginator" [pageSizeOptions]="[10, 20]" showFirstLastButtons *ngIf="!noData"></mat-paginator>
        <div *ngIf="noData" class="txt_center">
            <mat-error> No Data to display</mat-error>
        </div>
    </div>
</div>