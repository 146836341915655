import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import { GlobalVariable } from 'app/main/globals';
import * as FileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import * as XLSX from 'xlsx';
import { CreateBookingComponent } from '../booking-details/create-booking/create-booking.component';
import { NewBookingsService } from './new-bookings.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface SearchOrdersData {
  leadId: string;
  leadCreated: string;
  make: string;
  accountName: any;
  model: string;
  fuelType: string;
}

@Component({
  selector: 'app-new-bookings',
  templateUrl: './new-bookings.component.html',
  styleUrls: ['./new-bookings.component.scss']
})

export class NewBookingsComponent implements OnInit {
  token: any;
  liveOrders: any;
  liveOrdersList: any;
  liveOrdersData: any = [];
  searchOrders: any;
  searchOrdersList: any;
  searchOrdersData: any = [];
  searchOrdersSource: MatTableDataSource<SearchOrdersData>;
  searchOrdersColumns: string[] = ['leadId', 'leadCreated', 'make', 'accountName'];
  renderedData: any;
  dataAvialable = true;
  noData = false;
  dialogId: number;
  rDialogData: any;
  dataCSV: any;
  timezone: any;
  format: any;
  loading = false;
  moment = moment();
  makeImg: any = [];
  makeUrl: any;
  dataLoading = false;
  @ViewChild('TABLE', { static: false }) table: ElementRef;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   * @param {Router} router
   * @param {LoginService} loginService
   * @param {NewBookingsService} rService
   * @param {Papa} papa
   */
  constructor(
    public router: Router,
    private loginService: LoginService,
    private nbService: NewBookingsService,
    public dialog: MatDialog,
    private appComponent: AppComponent,
    // private bottomSheet: MatBottomSheet,
  ) {
    this.token = this.loginService.getToken();
    if (!this.token) {
      this.router.navigate(['/auth/login']);
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.timezone = this.loginService.getTimezone();
    this.format = this.loginService.getFormat();
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }
  ngOnInit() {
    this.getNewBookingData();
    this.dataLoading=true;
  }
  createNewBooking() {
    this.dialog.open(CreateBookingComponent);
  }
  getNewBookingData() {
    this.nbService.getNewLeads().subscribe((data: {}) => {
      this.searchOrdersList = data;
      if (this.searchOrdersList.response.status === 'SUCCESS') {
        this.dataLoading=false;
        this.searchOrders = this.searchOrdersList.response.body.leads;
        if (this.searchOrders.length === 0) {
          this.dataAvialable = false;
          this.noData = true;
          this.loading = false;
        } else {
          this.dataAvialable = true;
          this.noData = false;
          this.searchOrders.forEach(searchOrderList => {
            this.searchOrdersData.push(this.createNewbookingData(searchOrderList));
          });
          this.makeUrl = `${GlobalVariable.BASE_MAKE_IMAGE_URL}`;
          this.searchOrdersSource = new MatTableDataSource(this.searchOrdersData);
          this.searchOrdersSource.connect().subscribe(d => this.renderedData = d);
          this.dataCSV = this.renderedData;
          this.loading = false;
          setTimeout(() => {
            this.searchOrdersSource.paginator = this.paginator;
            this.searchOrdersSource.sort = this.sort;
          }, 500);
        }
      }
      else {
        this.loading = false;
        this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
        setTimeout(() => {
          this.loginService.logout();
        }, 1000);
      }
    });
  }

  // applyFilter(filterValue: string) {
  //   this.liveOrdersSource.filter = filterValue.trim().toLowerCase();
  //   if (this.liveOrdersSource.paginator) {
  //     this.liveOrdersSource.paginator.firstPage();
  //   }
  // }
  createNewbookingData(data: any): SearchOrdersData {
    return {
      leadId: data.leadId.toString(),
      leadCreated: moment.utc(data.leadCreated).tz(this.timezone).format(),
      make: data.make.toString(),
      accountName: data.accountName.toString(),
      fuelType: data.fuelType.toString(),
      model: data.model.toString()
    };
  }
  exportEXCEL() {
    for (let i = 0; i < this.renderedData.length; i++) {
      if (this.renderedData[i].orderStartTime.indexOf('<br>') > 0) {
        this.renderedData[i].orderStartTime = this.renderedData[i].orderStartTime.replace(/<br\s*\/?>/gi, ' ');
      }
      if (this.renderedData[i].orderEndTime.indexOf('<br>') > 0) {
        this.renderedData[i].orderEndTime = this.renderedData[i].orderEndTime.replace(/<br\s*\/?>/gi, ' ');
      }
    }
    this.dataCSV = this.renderedData;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'liveOrders_list');
    setTimeout(() => {
      this.router.navigate(['orders/live-orders']);
    }, 100);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  openDetails(id: number) {
    this.router.navigate(['bookings/details/', id]);
  }
}
