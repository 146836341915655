import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GlobalVariable } from 'app/main/globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from 'app/main/authentication/login/login.service';

const backDate = (numOfDays) => {
    const today1 = new Date();
    return new Date(today1.setDate(today1.getDate() - numOfDays));
};

@Injectable({ providedIn: 'root' })

export class DashboardService implements Resolve<any> {
    dashboardData: any[];
    paramData: any;
    loginData: any;
    accountAppKey: any;
    chartPieData: any[];
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {
    }

    private cData(res: Response) {
        const body = res;
        return body || {};
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getDashboardData(),
                this.dashboardPieData()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    dashboardPieData() {
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        const today = new Date();
        const lastMonth = backDate(30);
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'merchantId': this.loginData.merchant.id,
            'fromDate': lastMonth.toISOString().split('T')[0].toString(),
            'toDate': today.toISOString().split('T')[0].toString(),
            'type': 'gms'
        };
     
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            this.http.post(`${GlobalVariable.BASE_API_URL}` + '/b2bgetorderandjobstats', formData)
                .subscribe((response: any) => {
                    this.chartPieData = response;
                    resolve(response);
                }, reject);
        });
    }
    getDashboardData() {
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        const today = new Date();
        const lastMonth = backDate(30);
      
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'merchantId': this.loginData.merchant.id,
            'startdate': lastMonth.toISOString().split('T')[0].toString(),
            'enddate': today.toISOString().split('T')[0].toString()
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            this.http.post(`${GlobalVariable.BASE_API_URL}` + '/b2bmerchantorder', formData)
                .subscribe((response: any) => {
                    this.dashboardData = response;
                    resolve(response);
                }, reject);
        });
    }
    convertDate(t: any) {
        const date = new Date(t);
        const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join('-');
    }
    getDashboardCustomData(cToday: any, cLast: any, diffDays) {
        const st = this.convertDate(cToday);
        const et = this.convertDate(cLast);
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        if (diffDays < 2) {
            
            const data = {
                'appKey': this.accountAppKey,
                'sessionId': this.loginService.getToken(),
                'language': `${GlobalVariable.LANGUAGE}`,
                'device': `${GlobalVariable.DEVICE}`,
                'merchantId': this.loginData.merchant.id,
                'startdate': st,
                'enddate': et,
                'detailsFor': 'oneDay',
            }
            const formData = new FormData();
            formData.append('sessionPost', JSON.stringify(data));
            return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/b2bmerchantorder', formData);
        }
        else {
            
            const data = {
                'appKey': this.accountAppKey,
                'sessionId': this.loginService.getToken(),
                'language': `${GlobalVariable.LANGUAGE}`,
                'device': `${GlobalVariable.DEVICE}`,
                'merchantId': this.loginData.merchant.id,
                'startdate': st,
                'enddate': et,
            }
            const formData = new FormData();
            formData.append('sessionPost', JSON.stringify(data));
            return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/b2bmerchantorder', formData);
        }
        
        // return this.http.get(`${GlobalVariable.BASE_API_URL}` + '/b2bmerchantorder', { params: this.paramData }).pipe(
        //     map(this.cData));
    }
    getPiechartCustomData(cToday: any, cLast: any) {
        const st = this.convertDate(cToday);
        const et = this.convertDate(cLast);
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
     
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'merchantId': this.loginData.merchant.id,
            'fromDate': st,
            'toDate': et,
            'type': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/b2bgetorderandjobstats', formData);
    }
}
