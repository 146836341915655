import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ChartsModule } from 'ng2-charts';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { CalendarService } from './calendar.service';
import { DashboardOrdersComponent } from './dashboard-orders.component';
import { DashboardOrdersService } from './dashboard-orders.service';
import { CalendarEventFormDialogComponent } from './event-form/event-form.component';
const routes: Routes = [
    {
        path: 'dashboard-orders',
        component: DashboardOrdersComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
            data: DashboardOrdersService
        }
    }
];

@NgModule({
    declarations: [
        DashboardOrdersComponent,
        CalendarEventFormDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatListModule,
        MatDividerModule,
        MatCardModule,
        MatGridListModule,
        MatDialogModule,
        MatChipsModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatProgressBarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatStepperModule,
        NgxMatDrpModule,
        ChartsModule,
        NgxChartsModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        NgbModule,
        FlatpickrModule.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        })
    ],
    exports: [
        DashboardOrdersComponent,
        CalendarEventFormDialogComponent
    ],
    providers: [
        DashboardOrdersService,
        CalendarService
    ],
    entryComponents: [
        CalendarEventFormDialogComponent
    ]
})

export class DashboardOrdersModule {
}
