import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { MatDialog } from '@angular/material/dialog';
// import {AspLoginComponent} from '../asp-login/asp-login.component';
import {AspMakeService} from '../asp-make/asp-make.service';
import {AspLoginService} from '../asp-login/asp-login.service';


@Component({
  selector: 'app-asp-make',
  templateUrl: './asp-make.component.html',
  styleUrls: ['./asp-make.component.scss']
})
export class AspMakeComponent implements OnInit {  
  loading: boolean = false;
  selectedMake: any;
  searchText:string = '';
  makeList:any = [];
  openSearch:boolean = true;
  clearSearch:boolean = false;
  spareSelectedGarageName: any;

  constructor(
    private router: Router,
    // public dialog: MatDialog,  
    private aspMakeService: AspMakeService, 
    private aspLoginService: AspLoginService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.aspLoginService.checkLogin();
    // this.clearLocalStorage();    
    if(localStorage.getItem('selectedMake')){
      this.selectedMake = localStorage.getItem('selectedMake');
    }
    
    this.getMakeList();

    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName");

  }

  // Get Make List   
  getMakeList(){  
    let data = sessionStorage.getItem('spareVehicleDetails'); 
    let vehicleDetails;

    if(data){
      this.loading = false;
      vehicleDetails = data;
      let res = JSON.parse(vehicleDetails); 
      Object.values(res.data).forEach(make => {
        if(make[0]['title']=='Universal'){
          this.makeList.push(make[0]);
        }
      });

      Object.values(res.data).forEach(make => {
        if(make[0]['title']!='Universal'){
          this.makeList.push(make[0]);
        }
      });

    }else{
      this.aspMakeService.getMake().subscribe(
        result => {
          this.loading = false;
          // console.log(result);
          vehicleDetails = JSON.stringify(result);
          this.setVehicleDetails(vehicleDetails);
          let res = JSON.parse(vehicleDetails); 
          Object.values(res.data).forEach(make => {
            if(make[0]['title']=='Universal'){
              this.makeList.push(make[0]);
            }
          });

          Object.values(res.data).forEach(make => {
            if(make[0]['title']!='Universal'){
              this.makeList.push(make[0]);
            }
          });
        }
      );
    }
  }

  selectMake(make: any) {
    this.clearLocalStorage();    

    if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant'))
      localStorage.setItem('newMake', make); 
    else
      localStorage.setItem('selectedMake', make);  

    this.router.navigate(['/auto-spare-parts/model']);

  }

  selectUniversalVehicle() {
    this.clearLocalStorage();    
    localStorage.setItem('vehicleType', 'universal'); 

    if(localStorage.getItem('selectedBrand')){
      this.router.navigate(['auto-spare-parts/brand-spares-list']);
    }else if(localStorage.getItem('selectedCategory')){
      this.router.navigate(['auto-spare-parts/category-spares-list']);
    }else{
      this.router.navigate(['auto-spare-parts/spare-parts-list']);
    }
  }

  setVehicleDetails(data){
    sessionStorage.setItem('spareVehicleDetails', data); 
  }

  searchFilterType(){
    this.openSearch = false;
    this.clearSearch = true;
  }

  clearSearchFilter(){
    this.openSearch = true;
    this.clearSearch = false;
    this.searchText = '';
  }

  private clearLocalStorage(){
    sessionStorage.removeItem('sparePartsDetails');
    localStorage.removeItem('vehicleType');
    localStorage.removeItem('newMake');
    localStorage.removeItem('newModel');
    localStorage.removeItem('newYear');
    localStorage.removeItem('newVariant');
    localStorage.removeItem('newVariantKey');
    localStorage.removeItem('newRowKey');
    localStorage.removeItem('isShippingCharges');
    localStorage.removeItem('prepayUpiDiscount');

    localStorage.removeItem('selectedMake');
    localStorage.removeItem('selectedModel');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('variant');
    localStorage.removeItem('selectedFuel');
    localStorage.removeItem('variantKey');
    localStorage.removeItem('rowKey');
    localStorage.removeItem('searchPartNumber');
    // sessionStorage.removeItem('addedCart');
    // (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = '0';
    
  };

}
