import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
    MatBottomSheetRef,
    MAT_BOTTOM_SHEET_DATA
} from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { LoginService } from 'app/main/authentication/login/login.service';
import { GlobalVariable } from 'app/main/globals';
import { OrderDetailsService } from 'app/main/orders/order-details/order-details.service';
import { first } from 'rxjs/operators';
@Component({
    selector: 'assign-technician',
    templateUrl: 'assign-technician.component.html',
    styleUrls: ['./assign-technician.component.scss'],
})
export class AssignTechnicianComponent {
    status: string;
    orderId: string;
    technicianControl = new FormControl('', [Validators.required]);
    error = false;
    form: any;
    assignTechLoading = false;
    assignedDataReceived = false;
    employees: any;
    empList = [];
    employeeNames: any = [];
    employeeIds: any = [];
    owId: string;
    accountAppKey: any;
    loginUserData: any;
    orderType: any;
    technicianId: any;
    orderPickup = false;
    pickupTechnician = false;
    statusOrder: any;
    driverType: string;
    @ViewChild('technicianAssign', { static: false })
    technicianAssign: ElementRef;
    constructor(
        private bottomSheetRef: MatBottomSheetRef<AssignTechnicianComponent>,
        private router: Router,
        private loginService: LoginService,
        private odService: OrderDetailsService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        this.employees = data.data;
        this.orderId = data.orderId;
        this.orderType = data.orderList.orderType;
        this.technicianId = data.orderList.technicianId;
        this.statusOrder = data.orderList.status;
        this.driverType = data.driverType;
        if (this.orderType === 'pickup') {
            this.orderPickup = true;
            this.filterDriverType(data.data);
            this.employees = data.data.filter(this.filterDriverType);
        } else {
            this.orderPickup = false;
        }
        if (this.driverType === 'technician') {
            this.orderPickup = false;
            this.pickupTechnician = true;
            this.filterEmployeeType(data.data);
            this.employees = data.data.filter(this.filterEmployeeType);
        } else {
            this.pickupTechnician = false;
        }
    }
    filterEmployeeType(empData) {
        return empData.designation === 'service_advisor';
    }

    filterDriverType(empData) {
        return empData.designation === 'driver';
    }
    assignPickupTechnician() {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        this.assignTechLoading = true;
        this.form = this.technicianControl.value;
        this.owId = this.findId(this.form);
        const formData: FormData = new FormData();
        formData.append('appKey', this.accountAppKey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('language', `${GlobalVariable.LANGUAGE}`);
        formData.append('device', `${GlobalVariable.DEVICE}`);
        formData.append('id', this.orderId);
        formData.append('ownerId', this.owId);
        formData.append('ownerName', this.form);
        formData.append('type', 'technician');
        this.odService
            .assignTechnicianPickupOrder(formData)
            .pipe(first())
            .subscribe(
                (result) => {
                    if (result.response.status === 'SUCCESS') {
                        this.error = false;
                        this.assignTechLoading = false;
                        this.assignedDataReceived = false;
                        this.bottomSheetRef.dismiss();
                        this.router.navigate([
                            'orders/details/' + this.orderId,
                        ]);
                    } else {
                        this.assignTechLoading = false;
                        this.error = true;
                    }
                },
                (error) => {
                    console.log(error);
                    this.assignTechLoading = false;
                    this.error = true;
                }
            );
    }
    assignTechnician() {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        this.assignTechLoading = true;
        this.form = this.technicianControl.value;
        this.owId = this.findId(this.form);
        const formData: FormData = new FormData();
        formData.append('appKey', this.accountAppKey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('language', `${GlobalVariable.LANGUAGE}`);
        formData.append('device', `${GlobalVariable.DEVICE}`);
        formData.append('id', this.orderId);
        formData.append('ownerId', this.owId);
        formData.append('ownerName', this.form);
        formData.append('type', 'driver');
        this.odService
            .assignTechnician(formData)
            .pipe(first())
            .subscribe(
                (result) => {
                    if (result.response.status === 'SUCCESS') {
                        this.error = false;
                        this.assignTechLoading = false;
                        this.assignedDataReceived = false;
                        this.bottomSheetRef.dismiss();
                        this.router.navigate([
                            'orders/details/' + this.orderId,
                        ]);
                    } else {
                        this.assignTechLoading = false;
                        this.error = true;
                    }
                },
                (error) => {
                    console.log(error);
                    this.assignTechLoading = false;
                    this.error = true;
                }
            );
    }
    findId(obj: any) {
        for (let i = 0; i < this.employees.length; i++) {
            if (this.employees[i].driverName.indexOf(obj) !== -1) {
                return this.employees[i].employeeId;
            }
        }
    }
}
