<mat-nav-list class="form-container">
    <div mat-list-item class="mat-display-2 mb-8">
        <span *ngIf="!orderPickup"> Assign Technician</span>
        <span *ngIf="orderPickup">Assign Driver</span>
    </div>
    <div mat-list-item>
        <mat-form-field class="w-100-p">
            <mat-select
                placeholder="Select"
                [formControl]="technicianControl"
                required
            >
                <mat-option
                    *ngFor="let employee of employees"
                    [value]="employee.driverName"
                >
                    {{ employee.driverName }}
                </mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="technicianControl.hasError('required')">Please choose a technician</mat-error> -->
        </mat-form-field>
    </div>
    <div mat-list-item class="text-right">
        <button
            (click)="assignTechnician()"
            *ngIf="!pickupTechnician"
            mat-raised-button
            color="accent"
            class="submit-button"
            aria-label="Assign Technician"
            [disabled]="technicianControl.invalid"
        >
            Assign
        </button>
        <button
            (click)="assignPickupTechnician()"
            *ngIf="pickupTechnician"
            mat-raised-button
            color="accent"
            class="submit-button"
            aria-label="Assign "
            [disabled]="technicianControl.invalid"
        >
            Assign
        </button>
    </div>
    <div mat-list-item class="text-left" *ngIf="error">
        <mat-error>
            Something went wrong. Please try again!
        </mat-error>
    </div>
    <mat-progress-bar
        class="mt-8"
        *ngIf="assignTechLoading"
        mode="indeterminate"
    ></mat-progress-bar>
</mat-nav-list>
