let options: any;
if (window.location.host === "garage.getpitstop.com") {
    options = {
        BASE_API_URL: "https://iapi.getpitstop.com/v1.0/pitstop",
        BASE_API_URL_SPARES: "https://iapi.getpitstop.com/v1.0/pitstopspare",
        BASE_FILE_UPLOAD_URL: "https://fiapi.getpitstop.com/v1.0/processors",
        BASE_MAKE_IMAGE_URL: "https://cube.getpitstop.com/assets/img",
        APP_KEY: "T6XAPH97LWIROB31LFKFJICJ5EIP8ZRBM",
        LANGUAGE: "en",
        DEVICE: "web",
        GOOGLE_MAPS_API_KEY: "AIzaSyA-FwkEuPz6efVsc-NszVSD-Fgo4-gXcvA",
        TYPE: "dashboard",
        ORDER_OF: "garage",
        SEND_SMS: "0",
        VERSION: "2",
        MARKET_PLACE_APP_KEY: "1f280789c02775f62a06186718547250",
        COMPLETE_NUMBER: "+91",
        CUSTOMER_ADDRESS_ID: "0",
        EXOTEL_KEY: "0debb5c5a6c8a519a630ea645743e08d9551f89593948443",
        EXOTEL_TOKEN: "b35f12a0dff32201c88b8390973fd038e30e7d00331efad0",
        RAZORPAY_ID: 'rzp_live_jHMKRADDs9Hg3V',
        AUTH_KEY: '3e75cbdbdfc733a59348188f686341d4',
    };
} else if (window.location.host.indexOf("testpitstop") > -1) {
    options = {
        BASE_URL: "https://garage.testpitstop.com/",
        BASE_API_URL: "https://iapi.testpitstop.com/v1.0/pitstop",        
        BASE_API_URL_SPARES: "https://iapi.testpitstop.com/v1.0/pitstopspare",
        BASE_FILE_UPLOAD_URL: "https://fiapi.testpitstop.com/v1.0/processors",
        BASE_MAKE_IMAGE_URL: "https://cube.getpitstop.com/assets/img",
        APP_KEY: "T6XAPH97LWIROB31LFKFJICJ5EIP8ZRBM",
        LANGUAGE: "en",
        DEVICE: "web",
        GOOGLE_MAPS_API_KEY: "AIzaSyA-FwkEuPz6efVsc-NszVSD-Fgo4-gXcvA",
        TYPE: "dashboard",
        ORDER_OF: "garage",
        SEND_SMS: "0",
        VERSION: "2",
        MARKET_PLACE_APP_KEY: "1f280789c02775f62a06186718547250",
        COMPLETE_NUMBER: "+91",
        CUSTOMER_ADDRESS_ID: "0",
        EXOTEL_KEY: "0debb5c5a6c8a519a630ea645743e08d9551f89593948443",
        EXOTEL_TOKEN: "b35f12a0dff32201c88b8390973fd038e30e7d00331efad0",
        RAZORPAY_ID: 'rzp_test_oKVjXvcmpVR7SU',
        AUTH_KEY: '255c84bd3ff0110cfe6f5406d9e64f82',
    };
} else {
    options = {
        BASE_API_URL: "https://iapi.testpitstop.com/v1.0/pitstop",
        BASE_API_URL_SPARES: "https://iapi.testpitstop.com/v1.0/pitstopspare",
        BASE_FILE_UPLOAD_URL: "https://fiapi.testpitstop.com/v1.0/processors",
        BASE_MAKE_IMAGE_URL: "https://cube.getpitstop.com/assets/img",
        APP_KEY: "T6XAPH97LWIROB31LFKFJICJ5EIP8ZRBM",
        LANGUAGE: "en",
        DEVICE: "web",
        GOOGLE_MAPS_API_KEY: "AIzaSyA-FwkEuPz6efVsc-NszVSD-Fgo4-gXcvA",
        TYPE: "dashboard",
        ORDER_OF: "garage",
        SEND_SMS: "0",
        VERSION: "2",
        MARKET_PLACE_APP_KEY: "1f280789c02775f62a06186718547250",
        COMPLETE_NUMBER: "+91",
        CUSTOMER_ADDRESS_ID: "0",
        EXOTEL_KEY: "0debb5c5a6c8a519a630ea645743e08d9551f89593948443",
        EXOTEL_TOKEN: "b35f12a0dff32201c88b8390973fd038e30e7d00331efad0",        
        RAZORPAY_ID: 'rzp_test_oKVjXvcmpVR7SU',
        AUTH_KEY: '255c84bd3ff0110cfe6f5406d9e64f82',
    };
}
export const GlobalVariable = Object.freeze(options);

export const PickupStatus = [
    "Cancelled",
    "Request",
    "Confirmed",
    "Confirmed",
    "Confirmed",
    "Ongoing",
    "Completed",
    "Completed",
    "Completed",
    "PreClose",
    "Closed",
    "Exception",
];

export const DoorstepStatus = [
    "Cancelled",
    "Request",
    "Confirmed",
    "Assigned",
    "Reached",
    "Ongoing",
    "Completed",
    "Completed",
    "Completed",
    "PreClose",
    "Closed",
    "Exception",
];

export const OrderDetailPickupStatus = [
    "Cancelled",
    "***",
    "Confirmed",
    "Driver Assigned",
    "Vehicle Picked",
    "Ongoing",
    "Service Completed",
    "***",
    "***",
    "***",
    "Closed",
];

export const OrderDetailDoorstepStatus = [
    "Cancelled",
    "***",
    "Confirmed",
    "Technician Assigned",
    "Technician Reached",
    "Ongoing",
    "Service Completed",
    "***",
    "***",
    "***",
    "Closed",
];

export const JobStatus = [
    "Cancelled",
    "Created",
    "Start Job",
    "Reached Location",
    "Pickedup Vehicle",
    "Drop Location",
    "Complete",
];
export const BookingStatus = [
    "Invalid",
    "Cancelled",
    "***",
    "***",
    "***",
    "Enquiry",
    "Confirmed",
];
