import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { ApplicationPipesModule } from 'app/main/applicationPipesModule.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CreateOrderComponent } from './create-order.component';
import { CreateOrderService } from './create-order.service';

const routes: Routes = [
    {
        path: 'create-order',
        component: CreateOrderComponent,
    }
];

@NgModule({
    declarations: [
        CreateOrderComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatListModule,
        MatDividerModule,
        MatCardModule,
        MatGridListModule,
        MatDialogModule,
        MatChipsModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatProgressBarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        CdkStepperModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatAutocompleteModule,
        GooglePlaceModule,
        MatProgressSpinnerModule,
        ApplicationPipesModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyA-FwkEuPz6efVsc-NszVSD-Fgo4-gXcvA',
            libraries: ['places'],
            region: 'IN'
        }),
        MatGoogleMapsAutocompleteModule
    ],
    exports: [
        CreateOrderComponent
    ],
    providers: [CreateOrderService]
})

export class CreateOrderModule {
}
