import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/main/globals';
import { LoginService } from 'app/main/authentication/login/login.service';

@Injectable({ providedIn: 'root' })

export class StatementService implements Resolve<any>
{
    statement: any[];
    paramData: any;
    loginUserData: any;
    accountAppKey: any;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getStatement()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
    getStatement(): Promise<any> {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
     const paramData = {
            appKey: this.accountAppKey,
            sessionId: this.loginService.getToken(),
            language: `${GlobalVariable.LANGUAGE}`,
            device: `${GlobalVariable.DEVICE}`
        };
        const formData=new FormData;
        formData.append('sessionPost', JSON.stringify(paramData));
        return new Promise((resolve, reject) => {
            this.http.post(`${GlobalVariable.BASE_API_URL}` + '/garagestatement', formData)
                .subscribe((response: any) => {
                    this.statement = response;
                    resolve(response);
                }, reject);
        });
    }
}
