import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AspSwitchGarageService } from './asp-switch-garage.service';

@Component({
  selector: 'app-asp-switch-garage',
  templateUrl: './asp-switch-garage.component.html',
  styleUrls: ['./asp-switch-garage.component.scss']
})
export class AspSwitchGarageComponent implements OnInit {
  garageList:any = [];
  garageKey: any;

  constructor(
    public dialog:MatDialogRef<AspSwitchGarageComponent>,
    private aspSwitchGarageService: AspSwitchGarageService,
    private toastr: ToastrService,    
    private router: Router,
  ) { 
    dialog.disableClose = true;
  }

  ngOnInit(): void {         
    this.garageKey = localStorage.getItem("garageKey");
    this.getGaragesList();
  }

  getGaragesList(){    
    let spareUserKey = localStorage.getItem('spareUserKey');
    this.aspSwitchGarageService.getUserDetails(spareUserKey).subscribe(
      result => {
        // console.log(result);
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code && res.code==200){
          this.garageList = res.data.garage;
          
          if(res.data.id)
            localStorage.setItem('spareUserId', res.data.id);
          
        }else{
          this.toastr.error(res.message, 'Error');
        }
      }
    );
  }

  selectGarage(garageKey, garageName){
    localStorage.setItem("garageKey", garageKey);
    localStorage.setItem("spareSelectedGarageName", garageName);
    this.router.navigate(['auto-spare-parts/home']);
    this.toastr.success(garageName+' selected successfully', 'Garage selected');
    this.closePopup();
  }

  closePopup(){
    this.dialog.close();
  }

}
