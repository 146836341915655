import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormGroup,
    FormGroupDirective,
    Validators,
} from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import {
    GlobalVariable,
    OrderDetailDoorstepStatus,
    OrderDetailPickupStatus,
} from 'app/main/globals';
import { OrderDetailsService } from 'app/main/orders/order-details/order-details.service';
import * as moment from 'moment-timezone';
import { Lightbox } from 'ngx-lightbox';
import { Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { AddImagesComponent } from './add-images/add-images.component';
import { AddNotesComponent } from './add-notes/add-notes.component';
import { AssignTechnicianComponent } from './assign-technician/assign-technician.component';
import { JobsListComponent } from './jobs-list/jobs-list.component';
export interface ItemName {
    itemName: string;
    lineItemId: number;
    created: string;
    categoryId: number;
    type: string;
}
export interface ItemDescription {
    name: string;
    lineItemId: number;
    created: string;
    categoryId: number;
    type: string;
}

import PlaceResult = google.maps.places.PlaceResult;
@Component({
    selector: 'order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss'],
    animations: fuseAnimations
})
export class OrderDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
    navigationSubscription;
    timezone: any;
    format: any;
    moment = moment;
    token: any;
    noData = false;
    id: number;
    private sub: any;
    loading = false;
    details: any;
    detailList: any;
    orderDetail: any;
    accountImage: string;
    defaultAccountImage = 'https://www.getpitstop.com/img/pitstop-logo.png';
    assignedTechnician = false;
    assignedDriver = false;
    doorStep = false;
    pickUp = false;
    reachedTechnician = false;
    vehiclePicked = false;
    ongoing = false;
    completed = false;
    closed = false;
    preClosed = false;
    cashImg = false;
    serviceType: string;
    serviceSubType: string;
    orderType: string;
    technicianId: number;
    total: number;
    locality: string;
    orderStartTime: any;
    customerAddress: string;
    customer: any;
    customerName: string;
    customerEmail = '';
    customerMobile: string;
    vehicle: any;
    vehicleOdometer: string;
    vehicleRegNum: string;
    vehicleModel: string;
    vehicleFuelType: string;
    vehicleChasisNum: string;
    noPickupDriverData = false;
    ptData: any;
    pickName: string;
    pickMobile: number;
    returnName: string;
    returnMobile: number;
    noReturnData = true;
    noTechData = true;
    noPickupData = true;
    assignedPickUp = false;
    pickDriverDtl = false;
    techData: any;
    assignedTech = false;
    selfData: any;
    netBankingImg = false;
    orderTimeline: any = [];
    all = true;
    notes = false;
    updates = false;
    quotationLoading = false;
    noQuotationData = false;
    quotationData: any;
    quotationDetails: any;
    quotationItems: any;
    quotationArray = [];
    quotationSubTotal: number;
    quotationTotalTax: number;
    quotationGrandTotal: number;
    discount: number;
    prefilled = false;
    public quotationForm: FormGroup;
    applyCouponForm: FormGroup;
    numberPatern = '^[0-9.,]+$';
    myFormValueChanges$;
    totalSum: number;
    totalUnitPrice: number;
    grandTotal: number;
    paymentDone = false;
    showMode = false;
    newUnit = 0;
    lineItemsData: any;
    paymentData: any;
    lineItemNames: any = [];
    filteredOptions: Observable<ItemName[]>[] = [];
    filteredCategOptions: Observable<ItemDescription[]>[] = [];

    filterValue: string;
    items: any[] = [];
    updateData: Object = {};
    userId: number;
    dataReceived = false;
    // Job Details
    noJobs = false;
    jobsCount: number;
    jobsData: any;
    jobs: [];
    couponApplied = false;
    // Image Details
    images: any;
    paymentStatus = [];
    noImages = false;
    imageLoading = false;
    imgCount: number;
    fileError = false;
    _albums = [];
    selectedFiles = [];
    sourceName: any;
    self = false;
    confirmRemove = false;
    doostepPickup = true;
    changeSatatus = false;
    currentStatus: any;
    nextStatus: any;
    statusShowHide = true;
    jobImages: any;
    jobProcess = [];
    totalJobImages = [];
    statusOrderResult: any;
    error = false;
    changeStatusLoading = false;
    responseMessage: any;
    canCloseOrder: any;
    currentZoneLocation: any;
    isSingapore = false;
    engineCallInspected: any;
    diagnosed: any;
    qcChecked: any;
    hideCoupon = true;
    removeCouponResult: any;
    inspectionData = false;
    qcCheckData = false;
    noEngineScan = false;
    noInspection = false;
    inspectionResult: any;
    accountAppKey: any;
    loginUserData: any;
    ongoingOrder = true;
    pickDriverGoldbell = false;
    technicianName: any;
    technicianMobile: any;
    paymentStat: any;
    paymentMode: any;
    pickedDriver: any;
    noTechnicianData = false;
    addTechnician = false;
    assigedPickDriver = false;
    techDstSelf = true;
    dropDriver = false;
    pickupOrderTechnician: any;
    pickupTechnicianNumber: number;
    pickupTimline = false;
    addDropDriver = true;
    showDropDriver = false;
    driverDropSection = false;
    agentRole: any;
    objectKeys: any;
    roleList: any;
    roleId = [];
    objectValue: any;
    couponLoading = false;
    applyCouponSec = false;
    couponResponse: any;
    applyCouponMsg: any;
    removeCouponMsg: any;
    errorCoupon: any;
    couponError = false;
    discountType: any;
    techinicianAutoAssign: any;
    techinicianAssignNum: any;
    editAddress = false;
    addressEditForm: FormGroup;
    addressEdit = true;
    isLeasing: any;
    country: any;
    selectedCountry: any;
    selectedLocation: any;
    selectSuggession = false;
    validAddress = false;
    addressData: any;
    failEditMsg: string;
    successEdit: string;
    address: any;
    pincode: any;
    customerId: any;
    localitySelected: any;
    city: any;
    state: any;
    pickupJobId: any;
    dropJobId: any;
    public zoom: number;
    public latitude: any;
    public longitude: any;
    public selectedAddress: PlaceResult;
    puckupAddress: string;
    dropAddress: string;
    pickupLine1: any;
    pickupLine2: any;
    dropLine1: any;
    dropLine2: any;
    editLine1: any;
    editLine2: any;
    editAddressLoad = false;
    itemCategories: any;
    version: any;
    preServiceImages = [];
    duringServiceImages = [];
    postServiceImages = [];
    noDuringImage = true;
    noPreImage = true;
    noPostImage = true;
    serchText: any;
    invoiceImage: any;
    invoiceImageUrl: string;
    showCustomQuotation = false;
    @ViewChild('quotationSave', { static: false })
    quotationSave: FormGroupDirective;
    @ViewChild('technicianAssign', { static: false })
    technicianAssign: ElementRef;
    constructor(
        private odService: OrderDetailsService,
        private loginService: LoginService,
        public router: Router,
        private route: ActivatedRoute,
        private appComponent: AppComponent,
        private elem: ElementRef,
        private _fb: FormBuilder,
        public dialog: MatDialog,
        private _lightbox: Lightbox,
        private bottomSheet: MatBottomSheet,
        private sanitizer: DomSanitizer
    ) {
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.getOrderDetail();
            }
        });
    }
    ngOnInit() {
        this.paymentDetails();
        this.loginUserData = JSON.parse(this.loginService.getLoginData());

        this.isLeasing = this.loginUserData.account.isLeasing;
        if (this.isLeasing === 1) {
            this.showCustomQuotation = true;
            this.customeQuotation();
            this.country = 'sg';
        } else {
            this.country = 'in';
            this.showCustomQuotation = false;
        }
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        } else {
            this.isSingapore = false;
        }
        this.sub = this.route.params.subscribe((params) => {
            this.id = +params['id'];
        });
        this.quotationForm = this._fb.group({
            rows: this._fb.array([]),
        });
        this.getQuotationData();
        this.myFormValueChanges$ = this.quotationForm.controls[
            'rows'
        ].valueChanges;
        this.myFormValueChanges$.subscribe((rows) =>
            this.updateCalculations(rows)
        );
        this.applyCouponForm = this._fb.group({
            couponCode: ['', Validators.required],
        });
        this.addressEditForm = this._fb.group({
            customerHouse: ['', Validators.required],
            customerLocation: ['', Validators.required],
        });
    }

    ngAfterViewInit() {
        this.elem.nativeElement
            .querySelectorAll('.md-step.active')
            [
                this.elem.nativeElement.querySelectorAll('.md-step.active')
                    .length - 1
            ].classList.add('last');
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }
    getCustomerAddress(location) {
        this.selectedLocation = location;
    }
    checkAddressSelection() {
        this.selectSuggession = true;
        this.validAddress = true;
    }
    onLocationSelected(location: Location) {
        this.latitude = location.latitude;
        this.longitude = location.longitude;
        this.selectSuggession = false;
        this.validAddress = false;
    }
    onAutocompleteSelected(result: PlaceResult) {
        this.address = result.formatted_address;
        result.address_components.forEach((items) => {
            items.types.forEach((items2) => {
                if (items2 === 'postal_code') {
                    this.pincode = items.long_name;
                }
                if (items2 === 'country') {
                    this.selectedCountry = items.long_name;
                }
                if (items2 === 'administrative_area_level_1') {
                    this.state = items.long_name;
                }
                if (items2 === 'locality') {
                    this.city = items.long_name;
                }
                if (items2 === 'sublocality_level_1') {
                    this.localitySelected = items.long_name;
                }
            });
        });
    }
    customeQuotation() {
        this.odService.getItemCategoy().subscribe((res) => {
            this.itemCategories = res.response.body.categories;
        });
    }
    submitAddressEdit() {
        this.editAddressLoad = true;
        const loginsData = JSON.parse(this.loginService.getLoginData());
        const appkey = loginsData.account.appKey;
        const formData = new FormData();
        formData.append('appKey', appkey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('customerId', this.customerId);
        if (this.address) {
            formData.append('pincode', this.pincode);

            formData.append('line2', this.selectedLocation);
            formData.append('city', this.city);
            formData.append('state', this.state);
            formData.append('country', this.selectedCountry);

            formData.append(
                'address',
                this.addressEditForm.get('customerHouse').value +
                    ',' +
                    this.selectedLocation
            );

            formData.append('latitude', this.latitude);
            formData.append('longitude', this.longitude);

            formData.append('locality', this.localitySelected);
        } else {
            formData.append('pincode', this.pincode);

            formData.append('line2', this.editLine2);
            formData.append('city', this.city);
            formData.append('state', this.state);
            formData.append('country', this.country);

            formData.append(
                'address',
                this.addressEditForm.get('customerHouse').value +
                    ',' +
                    this.editLine2
            );

            formData.append('latitude', this.latitude);
            formData.append('longitude', this.longitude);

            formData.append('locality', this.locality);
        }
        formData.append('name', this.customerName);
        formData.append(
            'line1',
            this.addressEditForm.get('customerHouse').value
        );
        formData.append('orderId', this.id.toString());
        formData.append('jobId', this.pickupJobId);
        this.odService.updateAddress(formData).subscribe(
            (result) => {
                this.addressData = result;
                if (this.addressData.response.status === 'SUCCESS') {
                    this.successEdit = this.addressData.response.message;
                    this.editAddress = false;
                    this.editAddressLoad = false;
                    setTimeout(() => {
                        this.successEdit = '';
                        this.router.navigate(['orders/details/' + this.id]);
                    }, 2000);
                } else {
                    this.failEditMsg = this.addressData.response.message;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    getOrderDetail() {
        this.details = this.odService.liveOrderDetails;
        this.sourceName = this.details.response.body.accountName;
        if (this.details.response.status === 'SUCCESS') {
            this.detailList = this.details.response.body;
            this.userId = this.detailList.order.userId;
            this.orderType = this.detailList.order.orderType;
            this.techinicianAutoAssign = this.detailList.jobs[0].ownerName;
            this.techinicianAssignNum = this.detailList.jobs[0].driverMobile;
            this.detailList.jobs.forEach((items) => {
                if (items.pickupAddress || items.destinationAddress) {
                    if (items.orderStatus > 6) {
                        this.addressEdit = false;
                        this.pickupJobId = items.id;
                        this.puckupAddress = items.pickupAddress;
                        this.dropAddress = items.destinationAddress;
                        this.pickupLine1 =
                            items.addresses.pickupjobAddress.line1;
                        this.pickupLine2 =
                            items.addresses.pickupjobAddress.line2;
                        this.dropLine1 = items.addresses.dropjobAddress.line1;
                        this.dropLine2 = items.addresses.dropjobAddress.line2;
                        this.city = items.addresses.dropjobAddress.city;
                        this.state = items.addresses.dropjobAddress.state;
                        this.country = items.addresses.dropjobAddress.country;
                        this.latitude = items.addresses.dropjobAddress.latitude;
                        this.longitude =
                            items.addresses.dropjobAddress.longitude;
                        this.locality = items.addresses.dropjobAddress.locality;
                        this.pincode = items.addresses.dropjobAddress.pincode;
                    } else {
                        this.pickupJobId = items.id;
                        this.puckupAddress = items.pickupAddress;
                        this.dropAddress = items.destinationAddress;
                        this.pickupLine1 =
                            items.addresses.pickupjobAddress.line1;
                        this.pickupLine2 =
                            items.addresses.pickupjobAddress.line2;
                        this.dropLine1 = items.addresses.dropjobAddress.line1;
                        this.dropLine2 = items.addresses.dropjobAddress.line2;
                        this.city = items.addresses.pickupjobAddress.city;
                        this.state = items.addresses.pickupjobAddress.state;
                        this.country = items.addresses.pickupjobAddress.country;
                        this.latitude =
                            items.addresses.pickupjobAddress.latitude;
                        this.longitude =
                            items.addresses.pickupjobAddress.longitude;
                        this.locality =
                            items.addresses.pickupjobAddress.locality;
                        this.pincode = items.addresses.pickupjobAddress.pincode;
                    }
                }
            });
            if (this.techinicianAutoAssign) {
                this.addTechnician = true;
            }
            this.canCloseOrder = this.detailList.order.merchant.canCloseOrder;
            this.engineCallInspected = this.detailList.engineCalInspected;
            this.qcChecked = this.detailList.qcChecked;
            this.diagnosed = this.detailList.diagnosed;
            this.accountImage = this.detailList.order.orderAccount.serviceRequestEmail;

            for (let i = 0; i < this.detailList.employees.length; i++) {
                if (
                    this.detailList.order.technicianId.toString() ===
                    this.detailList.employees[i].employeeId.toString()
                ) {
                    this.pickupOrderTechnician = this.detailList.employees[
                        i
                    ].driverName;
                    this.pickupTechnicianNumber = this.detailList.employees[
                        i
                    ].mobile;
                    if (this.pickupOrderTechnician) {
                        this.addTechnician = true;
                    }
                }
            }
            if (this.engineCallInspected === true) {
                this.noEngineScan = false;
            } else {
                this.noEngineScan = true;
            }
            if (this.qcChecked === true || this.diagnosed === true) {
                this.noInspection = false;
            } else {
                this.noInspection = true;
            }

            if (this.diagnosed === true) {
                this.inspectionData = true;
            } else {
                this.inspectionData = false;
            }
            if (this.qcChecked === true) {
                this.qcCheckData = true;
            } else {
                this.qcCheckData = false;
            }
            this.setStatus();
            this.decideStatus();
            this.orderDetail = this.detailList.order;
            console.log(this.orderDetail);

            this.customerId = this.detailList.order.customer.id;
            this.getOrderDetails();
            if (this.orderDetail.customerAddress) {
                this.customerAddress = this.orderDetail.customerAddress;
            } else {
                this.customerAddress = '';
            }
            this.customer = this.orderDetail.customer;
            this.customerName = this.customer.name;
            if (this.customer.email) {
                this.customerEmail = this.customer.email.split(',')[0];
            }
            this.customerMobile = this.customer.mobile;
            this.vehicleOdometer = this.orderDetail.odometer;
            this.vehicle = this.orderDetail.vehicleDetails;
            this.vehicleRegNum = this.vehicle.registrationNumber;
            this.vehicleModel =
                this.vehicle.manufacturer + ' ' + this.vehicle.model;
            this.vehicleFuelType = this.vehicle.fuelType;
            this.vehicleChasisNum = this.vehicle.chassisNo;
            this.roleList = this.detailList.orderStatusAgentList;
            this.objectKeys = Object.keys(this.roleList);
            this.objectValue = Object.values(this.roleList);
            this.orderTimeline = [];
            this.orderDetail.orderStatusList.forEach((element) => {
                if (this.doorStep) {
                    element.status =
                        OrderDetailDoorstepStatus[element.status - 1];
                } else {
                    element.status =
                        OrderDetailPickupStatus[element.status - 1];
                }
                element.created = moment(element.created)
                    .tz(this.timezone)
                    .format(this.format);
                element.agentRole = this.getAgentName(element.agentId);
                this.orderTimeline.push(element);
            });
            this.getJobCards();
            if (this.pickUp) {
                this.ptData = this.detailList.jobs;
                this.pickupDetails();
                this.assignedPickUp = true;
                this.noTechData = false;
            }

            if (
                this.detailList.order.status < 6 ||
                (this.pickUp && this.detailList.order.status < 6)
            ) {
                this.noTechnicianData = true;
            } else {
                this.noTechnicianData = false;
            }
            if (this.doorStep) {
                this.selfData = this.detailList.jobs;
                if (this.selfData.length > 0) {
                    this.pickName = this.selfData[0].ownerName;
                    this.pickMobile = this.selfData[0].driverMobile;
                    this.noTechData = false;
                    this.assignedTech = true;
                    this.techDstSelf = true;
                }
            }
            if (this.self) {
                this.selfData = this.detailList.jobs;
                if (this.selfData.length > 0) {
                    this.pickName = this.selfData[0].ownerName;
                    this.pickMobile = this.selfData[0].driverMobile;
                    if (this.pickName) {
                        this.noTechData = false;
                        this.assignedTech = true;
                        this.techDstSelf = true;
                    }
                }
            }
            this.jobImages = this.detailList.jobs;
            for (let i = 0; i < this.jobImages.length; i++) {
                this.jobProcess = this.jobImages[i].jobprocessImages;
                for (let j = 0; j < this.jobProcess.length; j++) {
                    this.totalJobImages.push(this.jobProcess[j]);
                }
            }
            this.images = this.orderDetail.images;

            this.imgCount = this.images.length;
            if (this.imgCount === 0) {
                this.noImages = true;
            } else {
                this.noImages = false;
                this._albums = [];
                this.images.forEach((image) => {
                    if (image.tag === 'pre_replacement_others') {
                        this.preServiceImages.push(image);
                    } else if (image.tag === 'during_replacement') {
                        this.duringServiceImages.push(image);
                    } else {
                        this.postServiceImages.push(image);
                    }
                    const srcs = {
                        src: image.url,
                    };
                    this._albums.push(srcs);
                });

                if (this.duringServiceImages.length < 1) {
                    this.noDuringImage = true;
                } else {
                    this.noDuringImage = false;
                }
                if (this.preServiceImages.length < 1) {
                    this.noPreImage = true;
                } else {
                    this.noPreImage = false;
                }
                if (this.postServiceImages.length < 1) {
                    this.noPostImage = true;
                } else {
                    this.noPostImage = false;
                }
            }
        } else {
            this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
            setTimeout(() => {
                this.loginService.logout();
            }, 1000);
        }
    }
    getAgentName(agentId) {
        return agentId !== 0
            ? this.detailList.orderStatusAgentList[agentId]
            : '';
    }
    openPdf(pdfUrl) {
        window.open(pdfUrl, '_blank');
    }
    setStatus() {
        if (this.detailList.order.orderType === 'doorstep') {
            this.doorStep = true;
        }
        if (this.detailList.order.orderType === 'pickup') {
            this.pickUp = true;
        }
        if (this.detailList.order.orderType === 'self') {
            this.self = true;
        }
    }
    decideStatus() {
        this.currentStatus = this.detailList.order.status;
        if (this.currentStatus === 3 || this.currentStatus < 6) {
            this.nextStatus = 6;
        } else if (this.currentStatus === 6) {
            this.nextStatus = 7;
        } else if (
            this.currentStatus === 7 ||
            this.currentStatus === 8 ||
            this.currentStatus === 9
        ) {
            this.nextStatus = 10;
        } else if (this.currentStatus === 10) {
            this.nextStatus = 11;
        }
        if (this.doorStep && this.detailList.order.status.toString() === '4') {
            this.assignedTechnician = true;
            this.assignedTech = true;
        }
        if (this.pickUp) {
            this.pickupTimline = true;
        } else {
            this.pickupTimline = false;
        }
        if (this.pickUp && this.detailList.order.status.toString() === '4') {
            this.assignedDriver = true;
        }
        if (this.self && this.detailList.order.status.toString() === '4') {
            this.assignedTechnician = true;
        }
        if (this.doorStep && this.detailList.order.status.toString() === '5') {
            this.assignedTechnician = true;
            this.reachedTechnician = true;
        }
        if (this.pickUp && this.detailList.order.status.toString() === '5') {
            this.assignedDriver = true;
            this.vehiclePicked = true;
        }
        if (this.self && this.detailList.order.status.toString() === '5') {
            this.assignedTechnician = true;
            this.reachedTechnician = true;
        }
        if (this.doorStep && this.detailList.order.status.toString() === '6') {
            this.assignedTechnician = false;
            this.reachedTechnician = true;
            this.doostepPickup = false;
        }
        if (this.self && this.detailList.order.status.toString() === '6') {
            this.assignedTechnician = false;
            this.reachedTechnician = true;
            this.doostepPickup = false;
        }
        if (this.self && this.detailList.order.status.toString() >= '7') {
            this.ongoingOrder = false;
        }
        if (this.pickUp && this.detailList.order.status.toString() === '6') {
            this.assignedDriver = false;
            this.vehiclePicked = true;
            this.doostepPickup = false;
            this.ongoing = true;
            this.pickDriverGoldbell = false;
        }
        if (this.doorStep && this.detailList.order.status.toString() === '7') {
            this.assignedTechnician = false;
            this.reachedTechnician = true;
            this.doostepPickup = false;
        }
        if (this.pickUp && this.detailList.order.status < 8) {
            this.assigedPickDriver = true;
        } else {
            this.assigedPickDriver = false;
        }
        if (this.detailList.order.status.toString() > 6) {
            this.hideCoupon = false;
        }
        if (
            (this.pickUp && this.detailList.order.status.toString() < '6') ||
            (this.pickUp && this.detailList.order.status.toString() >= '8')
        ) {
            this.assignedTechnician = false;
            this.reachedTechnician = true;
            this.doostepPickup = false;
            this.ongoingOrder = false;
            this.pickDriverGoldbell = true;
        }
        if (this.pickUp && this.detailList.order.status.toString() >= '7') {
            this.pickDriverGoldbell = false;
            this.ongoingOrder = false;
        }
        if (
            (this.doorStep && this.detailList.order.status.toString() < '6') ||
            (this.doorStep &&
                this.detailList.order.status.toString() === '7') ||
            (this.doorStep && this.detailList.order.status.toString() > '7')
        ) {
            this.pickDriverGoldbell = true;
        }
        if (
            (this.self && this.detailList.order.status.toString() < '6') ||
            (this.self && this.detailList.order.status.toString() === '7')
        ) {
            this.pickDriverGoldbell = true;
        }
        if (this.detailList.order.status.toString() === '6') {
            if (this.pickUp) {
                this.assignedDriver = true;
                this.vehiclePicked = true;
            } else {
                this.assignedTechnician = true;
                this.reachedTechnician = true;
            }
            this.ongoing = true;
        }
        if (
            this.detailList.order.status.toString() === '7' ||
            this.detailList.order.status.toString() === '8' ||
            this.detailList.order.status.toString() === '9' ||
            this.detailList.order.status.toString() === '10'
        ) {
            if (this.pickUp) {
                this.assignedDriver = true;
                this.vehiclePicked = true;
            } else {
                this.assignedTechnician = true;
                this.reachedTechnician = true;
            }
            this.ongoing = true;
            this.completed = true;
            this.doostepPickup = false;
        }
        if (this.pickUp && this.detailList.order.status > 6) {
            this.showDropDriver = true;
            this.driverDropSection = true;
        }
        if (this.detailList.order.status.toString() === '10') {
            if (this.pickUp) {
                this.assignedDriver = true;
                this.vehiclePicked = true;
                this.addDropDriver = false;
            } else {
                this.assignedTechnician = true;
                this.reachedTechnician = true;
            }
            this.ongoing = true;
            this.completed = true;
            this.preClosed = true;
            this.pickDriverGoldbell = false;
        }
        if (this.detailList.order.status.toString() === '11') {
            if (this.pickUp) {
                this.assignedDriver = true;
                this.vehiclePicked = true;
            } else {
                this.assignedTechnician = true;
                this.reachedTechnician = true;
            }
            this.pickDriverGoldbell = false;
            this.ongoing = true;
            this.completed = true;
            this.preClosed = true;
            this.closed = true;
            this.doostepPickup = false;
            this.statusShowHide = false;
        }
    }
    getOrderDetails() {
        this.invoiceImageUrl = this.orderDetail.supplierInvoiceUrl;
        this.invoiceImage = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.invoiceImageUrl
        );
        if (this.orderDetail.serviceSubType !== '') {
            this.serviceType = this.orderDetail.serviceType;
        }
        if (this.orderDetail.serviceSubType !== '') {
            this.serviceSubType = this.orderDetail.serviceSubtype;
        }
        this.orderType = this.orderDetail.orderType;
        this.total = this.orderDetail.grandTotal;
        this.locality = this.orderDetail.merchant.locality;
        this.orderStartTime = moment
            .unix(this.orderDetail.startTime / 1000)
            .tz(this.timezone)
            .format(this.format);
    }
    getQuotationData() {
        this.quotationLoading = true;
        this.quotationData = [];
        this.lineItemsData = '';
        this.lineItemsData = JSON.parse(this.loginService.getLineItems());
        this.lineItemsData.forEach((item) => {
            this.lineItemNames.push(this.createLineItems(item));
        });
        this.odService.getQuotationData().subscribe((data: {}) => {
            this.quotationData = data;
            if (this.quotationData.response.status === 'SUCCESS') {
                this.quotationDetails = this.quotationData.response.body;
                this.quotationArray = this.quotationDetails.items;
                this.version = this.quotationDetails.quotation.version;
                this.quotationGrandTotal = 0;
                this.quotationSubTotal = 0;
                this.quotationTotalTax = 0;
                if (this.quotationArray.length > 0) {
                    this.totalSum = 0;
                    this.quotationGrandTotal = this.quotationDetails.quotation.grandTotal.toFixed(
                        2
                    );
                    this.quotationSubTotal = this.quotationDetails.order.subtotal.toFixed(
                        2
                    );
                    this.discount = this.quotationDetails.order.discount.toFixed(
                        2
                    );
                    if (this.discount > 0) {
                        this.couponApplied = true;
                    } else {
                        this.couponApplied = false;
                    }
                    this.discountType = this.quotationDetails.order.discountDescription;
                    this.quotationArray.forEach((quotation) => {
                        if (quotation.status === 1 || quotation.status === 2) {
                            this.quotationTotalTax += quotation.gst;
                        }
                        this.prefillUnit(quotation);
                        this.prefilled = true;
                        this.dataReceived = true;
                    });
                } else {
                    this.noQuotationData = true;
                }
                this.quotationLoading = false;
            } else {
                this.quotationLoading = false;
                this.noQuotationData = true;
            }
        });
    }
    downloadEngineScanSheet(id) {
        this.accountAppKey = this.loginUserData.account.appKey;
        window.open(
            `${GlobalVariable.BASE_FILE_UPLOAD_URL}` +
                '/downloadEngineCalDiagnosis.php' +
                '?appKey=' +
                this.accountAppKey +
                '&orderId=' +
                id
        );
    }
    downloadInspection(id) {
        this.accountAppKey = this.loginUserData.account.appKey;
        window.open(
            `${GlobalVariable.BASE_FILE_UPLOAD_URL}` +
                '/downloadInspectionDiagnosis.php' +
                '?appKey=' +
                this.accountAppKey +
                '&orderId=' +
                id
        );
    }
    downloadQcCheckSheet(id) {
        this.accountAppKey = this.loginUserData.account.appKey;
        window.open(
            `${GlobalVariable.BASE_FILE_UPLOAD_URL}` +
                '/downloadQualityCheckSheet.php' +
                '?appKey=' +
                this.accountAppKey +
                '&orderId=' +
                id
        );
    }
    updateCalculations(rows: any) {
        this.totalSum = 0;
        const control = <FormArray>this.quotationForm.controls['rows'];
        for (let i = 0; i < rows.length; i++) {
            const totalUnitPrice = rows[i].itemQuantity * rows[i].itemUnitPrice;
            if (
                control.at(+i).get('itemName').value &&
                ((control.at(+i).get('itemName').value.itemName &&
                    control
                        .at(+i)
                        .get('itemName')
                        .value.itemName.toUpperCase()
                        .indexOf('LABOUR') !== 0) ||
                    control
                        .at(+i)
                        .get('itemName')
                        .value.toUpperCase()
                        .indexOf('LABOUR') !== 0)
            ) {
                control
                    .at(+i)
                    .get('itemTotal')
                    .setValue(totalUnitPrice, {
                        onlySelf: true,
                        emitEvent: false,
                    });
            }
            if (rows[i].status === 2 || rows[i].status === 1) {
                this.totalSum += rows[i].itemTotal;
            }
        }
        this.totalSum = this.totalSum - this.quotationTotalTax;
    }
    createLineItems(item: any): ItemName {
        return {
            itemName: item.name,
            lineItemId: item.id,
            created: item.created,
            categoryId: item.quotecategoryId,
            type: item.type,
        };
    }
    private prefillUnit(quotation) {
        const rows = this.quotationForm.get('rows') as FormArray;
        rows.push(
            this._fb.group({
                approved: quotation.approved,
                barcode: quotation.barcode,
                created: quotation.created,
                id: quotation.id,
                imageUrls: quotation.imageUrls,
                isLocked: quotation.isLocked,
                itemCategoryId: quotation.itemCategoryId,
                itemDescription: quotation.itemDescription,
                itemName: quotation.itemName,
                itemQuantity: quotation.itemQuantity,
                itemTotal: parseFloat(quotation.itemTotal).toFixed(2),
                itemUnitPrice: parseFloat(quotation.itemUnitPrice).toFixed(2),
                lineitemId: quotation.lineitemId,
                orderId: quotation.orderId,
                status: quotation.status,
                userId: quotation.userId,
                version: quotation.version,
            })
        );
        this.manageNameControl(rows.length - 1);
        this.manageCategoryControl(rows.length - 1);
    }
    addUnit() {
        this.dataReceived = true;
        this.noQuotationData = false;
        this.newUnit += 1;
        const numberPatern = '^[0-9.,]+$';
        const rows = this.quotationForm.get('rows') as FormArray;
        rows.push(
            this._fb.group({
                approved: ['1'],
                barcode: [null],
                created: [null],
                id: [null],
                imageUrls: [null],
                isLocked: ['0'],
                itemCategoryId: [null],
                itemDescription: [null, Validators.required],
                itemName: [null, Validators.required],
                itemQuantity: [
                    1,
                    [
                        Validators.required,
                        Validators.pattern(numberPatern),
                        Validators.min(0),
                    ],
                ],
                itemTotal: [0],
                itemUnitPrice: [0],
                lineitemId: [null],
                orderId: [null],
                status: ['1'],
                userId: [null],
                version: this.version,
            })
        );
        this.manageNameControl(rows.length - 1);
        setTimeout(() => {
            this.elem.nativeElement
                .querySelectorAll('[formcontrolname="itemName"]')
                [
                    this.elem.nativeElement.querySelectorAll(
                        '[formcontrolname="itemName"]'
                    ).length - 1
                ].focus();
        }, 600);
        this.manageCategoryControl(rows.length - 1);
    }

    manageCategoryControl(index: number) {
        const arrayControl = this.quotationForm.get('rows') as FormArray;

        this.filteredCategOptions[index] = arrayControl
            .at(index)
            .get('itemDescription')
            .valueChanges.pipe(
                startWith<string | ItemDescription>(''),
                map((value) =>
                    typeof value === 'string' ? value : value.name
                ),
                map((name) =>
                    name ? this._filterCateg(name) : this.itemCategories.slice()
                )
            );
    }
    manageNameControl(index: number) {
        const arrayControl = this.quotationForm.get('rows') as FormArray;
        this.filteredOptions[index] = arrayControl
            .at(index)
            .get('itemName')
            .valueChanges.pipe(
                startWith<string | ItemName>(''),
                map((value) =>
                    typeof value === 'string' ? value : value.itemName
                ),
                map((name) =>
                    name ? this._filter(name) : this.lineItemNames.slice()
                )
            );
    }
    displayFn(item?: ItemName): string | undefined {
        return item ? item.itemName : undefined;
    }
    private _filter(itemName: string): ItemName[] {
        const filterValue = itemName.toLowerCase();
        return this.lineItemNames.filter(
            (option) => option.itemName.toLowerCase().indexOf(filterValue) === 0
        );
    }
    private _filterCateg(itemDescription: string): ItemDescription[] {
        const filterValue = itemDescription.toLowerCase();
        return this.itemCategories.filter(
            (option) => option.name.toLowerCase().indexOf(filterValue) === 0
        );
    }
    private removeUnit(i: number) {
        const rows = this.quotationForm.get('rows') as FormArray;
        rows.removeAt(i);
        this.newUnit -= 1;
    }
    private editUnit() {
        this.quotationForm.enable();
    }
    onQuotationSubmit() {
        this.items = [];
        this.quotationLoading = true;
        this.createQuotationData(this.quotationForm.value);
    }
    createQuotationData(quotation) {
        const loginUserData = JSON.parse(this.loginService.getLoginData());
        const accountAppKey = loginUserData.account.appKey;
        let created = '';
        let categoryId = '';
        let lineItemId = '';
        let type = '';
        const formData: FormData = new FormData();

        quotation.rows.forEach((element) => {
            let items;
            if (this.isLeasing === 1) {
                items = this.findItemCateg(element.itemDescription);
                categoryId = items.id;
                lineItemId = '0';
                type = 'normal';
            } else {
                items = this.findItem(element.itemName);
                categoryId = items.categoryId;
                created = items.created;
                lineItemId = items.lineItemId;
                type = items.type;
            }

            if (element.itemName != null) {
                this.items.push({
                    approved: element.approved,
                    barcode: '',
                    created: created,
                    imageUrls: '',
                    isLocked: '0',
                    itemCategoryId: categoryId,
                    itemDescription: element.itemDescription,
                    itemName: element.itemName,
                    itemQuantity: element.itemQuantity,
                    itemTotal: element.itemTotal,
                    itemUnitPrice: element.itemUnitPrice,
                    lineitemId: lineItemId,
                    orderId: this.id,
                    status: element.status,
                    type: type,
                    userId: this.userId,
                    version: this.version,
                });
            }
        });
        this.updateData['items'] = this.items;
        formData.append('appKey', accountAppKey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('language', `${GlobalVariable.LANGUAGE}`);
        formData.append('device', `${GlobalVariable.DEVICE}`);
        formData.append('type', 'gms');
        formData.append('data', JSON.stringify(this.updateData));
        formData.append('orderId', this.id.toString());
        formData.append('date', Math.round(new Date().getTime()).toString());
        this.odService
            .addQuotation(formData)
            .pipe(first())
            .subscribe(
                (result) => {
                    if (result.response.status === 'SUCCESS') {
                        this.quotationLoading = false;
                        this.dataReceived = false;
                        this.newUnit = 0;
                        this.ngOnInit();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    findItem(obj: any) {
        for (let i = 0; i < this.lineItemNames.length; i++) {
            if (this.lineItemNames[i].itemName.indexOf(obj) !== -1) {
                return this.lineItemNames[i];
            }
        }
    }
    findItemCateg(obj: any) {
        for (let i = 0; i < this.itemCategories.length; i++) {
            if (this.itemCategories[i].name.indexOf(obj) !== -1) {
                return this.itemCategories[i];
            }
        }
    }
    open(index: number): void {
        this._lightbox.open(this._albums, index);
    }
    close(): void {
        this._lightbox.close();
    }
    openBottomSheet(): void {
        this.bottomSheet.open(AddNotesComponent, {
            panelClass: 'bottom-sheet',
            data: {
                status: this.detailList.order.status,
                orderId: this.id,
            },
        });
    }
    toggleFilter(type: string) {
        this.orderTimeline = [];
        if (type === 'all') {
            this.orderDetail.orderStatusList.forEach((element) => {
                if (this.doorStep) {
                    element.status = element.status;
                } else {
                    element.status = element.status;
                }
                element.created = moment(element.created)
                    .tz(this.timezone)
                    .format(this.format);
                this.orderTimeline.push(element);
            });
        } else {
            if (type === 'notes') {
                this.orderDetail.orderStatusList.forEach((element) => {
                    if (element.type === 'notes') {
                        this.orderTimeline.push(element);
                    }
                });
                this.notes = true;
                this.updates = false;
            } else if (type === 'updates') {
                this.orderDetail.orderStatusList.forEach((element) => {
                    if (element.type === 'updates') {
                        this.orderTimeline.push(element);
                    }
                });
                this.updates = true;
                this.notes = false;
            } else {
                this.updates = false;
                this.notes = false;
            }
            this.orderDetail.orderStatusList.forEach((element) => {
                if (element.type === type) {
                    if (this.doorStep) {
                        element.status = element.status;
                    } else {
                        element.status = element.status;
                    }
                    element.created = moment(element.created)
                        .tz(this.timezone)
                        .format(this.format);
                    this.orderTimeline.push(element);
                }
            });
        }
    }
    pickupDetails() {
        if (this.ptData.length > 0) {
            this.ptData.forEach((ptElement) => {
                if (this.pickUp) {
                    this.techDstSelf = false;
                    if (ptElement.orderStatus < 6 && this.assignedDriver) {
                        this.pickedDriver = ptElement.ownerName;
                        this.pickMobile = ptElement.driverMobile;
                        this.noPickupData = false;
                        if (this.pickedDriver) {
                            this.assignedTech = true;
                        }
                    }
                    if (ptElement.orderStatus > 6) {
                        this.returnName = ptElement.ownerName;
                        this.returnMobile = ptElement.driverMobile;
                        if (this.returnName) {
                            this.dropDriver = true;
                        }
                    }
                    if (this.detailList.order.technicianId <= 0) {
                        this.addTechnician = false;
                    } else {
                        this.addTechnician = true;
                    }
                }
            });
        } else {
            this.noPickupDriverData = true;
        }
    }
    openImageBottomSheet(imageType) {
        this.bottomSheet.open(AddImagesComponent, {
            panelClass: 'bottom-sheet',
            data: {
                status: this.detailList.order.status,
                orderId: this.id,
                imageType: imageType,
            },
        });
    }
    getJobCards() {
        this.odService
            .getJobCards(this.detailList.order.orderKey)
            .subscribe((data: {}) => {
                this.jobsData = data;
                if (this.jobsData.response.status === 'SUCCESS') {
                    this.jobsCount = this.jobsData.response.body.items.length;
                    if (this.jobsCount > 0) {
                        this.jobs = this.jobsData.response.body.items;
                    } else {
                        this.noJobs = true;
                    }
                } else {
                    this.noJobs = true;
                }
            });
    }
    openJobsBottomSheet() {
        this.bottomSheet.open(JobsListComponent, {
            panelClass: 'bottom-sheet',
            data: {
                data: this.jobs,
            },
        });
    }
    openAssignTechnicianBottomSheet(event, driverType) {
        this.bottomSheet.open(AssignTechnicianComponent, {
            panelClass: 'bottom-sheet',
            data: {
                data: this.detailList.employees,
                orderId: this.id,
                orderList: this.detailList.order,
                driverType: driverType,
            },
        });
    }
    openInvoice() {
        this.router.navigate(['/orders/details/invoice/' + this.id]);
    }
    showChangeStatus() {
        this.changeSatatus = true;
    }
    closeChangeStatus() {
        this.changeSatatus = false;
        this.error = false;
    }
    openApplyCoupon() {
        this.applyCouponSec = true;
    }
    closeCoupon() {
        this.applyCouponSec = false;
        this.couponError = false;
    }
    applyCouponSubmit() {
        this.couponLoading = true;
        const loginsData = JSON.parse(this.loginService.getLoginData());
        const appkey = loginsData.account.appKey;
        const formData = new FormData();
        formData.append('appKey', appkey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('orderId', this.id.toString());
        formData.append(
            'couponCode',
            this.applyCouponForm.get('couponCode').value
        );
        formData.append('type', 'gms');
        this.odService.applyCoupon(formData).subscribe(
            (result) => {
                this.couponResponse = result;
                if (this.couponResponse.response.status === 'SUCCESS') {
                    this.couponLoading = false;
                    this.applyCouponMsg = this.couponResponse.response.message;
                    setTimeout(() => {
                        this.applyCouponSec = false;
                        this.router.navigate(['orders/details/' + this.id]);
                        this.getQuotationData();
                    }, 2000);
                } else {
                    this.couponLoading = false;
                    this.errorCoupon = this.couponResponse.response.message;
                    this.couponError = true;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    openRemoveCoupon() {
        this.confirmRemove = true;
    }
    closeRemoveCoupon() {
        this.confirmRemove = false;
    }
    removeCouponSubmit() {
        this.couponLoading = true;
        const loginsData = JSON.parse(this.loginService.getLoginData());
        const appkey = loginsData.account.appKey;
        const formData = new FormData();
        formData.append('appKey', appkey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('orderId', this.id.toString());
        formData.append('leadLevel', 'false');
        formData.append('type', 'gms');
        this.odService.removeCoupon(formData).subscribe(
            (result) => {
                this.removeCouponResult = result;
                if (this.removeCouponResult.response.status === 'SUCCESS') {
                    this.couponLoading = false;
                    this.removeCouponMsg = this.removeCouponResult.response.message;
                    setTimeout(() => {
                        this.applyCouponSec = false;
                        this.confirmRemove = false;
                        this.removeCouponMsg = '';
                        this.router.navigate(['orders/details/' + this.id]);
                        this.getQuotationData();
                    }, 2000);
                } else {
                    this.couponLoading = false;
                    this.couponError = true;
                    this.errorCoupon = this.couponResponse.response.errorMessage;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    ChangeOrderStatus() {
        this.changeStatusLoading = true;
        const formData = new FormData();
        formData.append('appKey', `${GlobalVariable.APP_KEY}`);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('language', `${GlobalVariable.LANGUAGE}`);
        formData.append('device', `${GlobalVariable.DEVICE}`);
        formData.append('orderId', this.id.toString());
        formData.append('status', this.nextStatus);
        formData.append('type', 'update');
        formData.append('plateform', 'gms');
        this.odService.changeOrderStatus(formData).subscribe(
            (result) => {
                this.statusOrderResult = result;
                if (this.statusOrderResult.response.status === 'SUCCESS') {
                    this.changeStatusLoading = false;
                    this.error = false;
                    this.changeSatatus = false;
                    this.router.navigate(['orders/details/' + this.id]);
                    location.reload();
                } else {
                    this.changeStatusLoading = false;
                    this.error = true;
                }
                if (
                    this.statusOrderResult.response.message ===
                    'QuoteItems with approved/reject pending'
                ) {
                    alert('Please add the Quotations!');
                }
                this.responseMessage = this.statusOrderResult.response.message;
            },
            (error) => {
                console.log(error);
            }
        );
    }
    paymentDetails() {
        this.odService.getPaymentLog().subscribe((result) => {
            this.paymentData = result;
            this.paymentStatus = this.paymentData.response.body.payments;
            if (this.paymentStatus.length > 0) {
                this.showMode = true;
                for (
                    let index = 0;
                    index < this.paymentStatus.length;
                    index++
                ) {
                    this.paymentStat = this.paymentStatus[index].status;
                    this.paymentMode = this.paymentStatus[index].paymentMode;
                    if (this.paymentMode === 'CASH') {
                        this.cashImg = true;
                    } else {
                        this.netBankingImg = true;
                    }
                }
                this.paymentDone = true;
            } else {
                this.paymentDone = false;
                this.showMode = false;
            }
        });
    }
    openEditAddress(line1, line2) {
        this.editAddress = true;
        this.editLine1 = line1;
        this.editLine2 = line2;
    }
    closeEditAddress() {
        this.editAddress = false;
    }
}
