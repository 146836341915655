import { Component, OnInit } from '@angular/core';
import { AspLoginService } from '../asp-login/asp-login.service';
import { AspBrandsService } from './asp-brands.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asp-brands',
  templateUrl: './asp-brands.component.html',
  styleUrls: ['./asp-brands.component.scss']
})
export class AspBrandsComponent implements OnInit {
  loading: boolean = false;
  spareSelectedGarageName: any;
  brands: any = [];
  searchText:string = '';
  filteredBrands: any = [];
  numericArray: any = [0,1,2,3,4,5,6,7,8,9];
  uppercaseArray: any = [];
  isActiveAll: boolean = true;
  isActiveNum: boolean = false;
  // isActiveAlpha: boolean = false;
  activeLetter: string | null = null;

  constructor(
    private aspLoginService: AspLoginService,
    private aspBrandsService: AspBrandsService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {    
    this.loading = true;
    this.aspLoginService.checkLogin();
    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName");

    this.generateAlpha();
    this.getAllBrands();
  }

  generateAlpha(): any {
    this.uppercaseArray = Array.from({ length: 26 }, (_, i) =>
      String.fromCharCode('A'.charCodeAt(0) + i)
    );
  }


  searchFilterType = () =>{    
      this.filteredBrands = this.brands.filter(brand =>
        brand.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
  }

  filterBrandsByChar(filterVal) {
    if(filterVal=='all'){
      this.isActiveAll = !this.isActiveAll;
      this.isActiveNum = false;
      this.activeLetter = null;

      this.filteredBrands = this.brands;      
    }else if(filterVal=='numbers'){
      this.isActiveNum = !this.isActiveNum;
      this.isActiveAll = false;
      this.activeLetter = null;

      this.filteredBrands = this.brands.filter(brand =>
        !isNaN(brand.name) &&         
        brand.name.toLowerCase().match(/[0-9]/) 
      );
    }else{
      this.isActiveNum = false;
      this.isActiveAll = false;
      this.activeLetter = filterVal;
      
      this.filteredBrands = this.brands.filter(brand =>
        brand.name.toLowerCase().match(/[a-zA-Z]/) && 
        brand.name.toLowerCase().startsWith(filterVal.toLowerCase()) 
      );
    }

    // console.log(this.filteredBrands);
  }

  getAllBrands = () => {
    this.aspBrandsService.allBrands().subscribe(
      res => {
        if(res && res['code']==200){
          this.loading = false;
          this.brands = [];

          if(res['data']){
            this.brands = res['data'];          
            this.filteredBrands = this.brands.sort((a, b) => a.name.localeCompare(b.name));
          }
        }else{    
          this.toastr.error(res['message'], 'Error');          
          this.loading = false;
        }
      }
    )

  }

  brandSparesList = (brand:any, brandImg:any) => {
    if(localStorage.getItem("selectedBrand")){
      localStorage.removeItem('selectedBrand');
    }

    if(localStorage.getItem("selectedBrandImg")){
      localStorage.removeItem('selectedBrandImg');
    }
      
    localStorage.setItem("selectedBrand",  brand);   
    localStorage.setItem("selectedBrandImg",  brandImg);   
    this.router.navigate(["auto-spare-parts/brand-spares-list"]);
  }

}
