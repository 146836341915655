<mat-dialog-content class="mat-typography">
    <mat-grid-list [cols]="4" rowHeight="35px">
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div mat-dialog-title class="mat-headline">
                Receivable Details
                <span *ngIf="receivableDetails.status==2" class="mat-caption deep-orange-300-fg">
                    <em>(This receivable will become eligible for payment only if it
                        is approved.)</em>
                </span>
            </div>
        </mat-grid-tile>
        <mat-grid-tile class="order-id" [colspan]="1" [rowspan]="1">
            <div class="mr-24 mat-headline" mat-dialog-title>Order ID # {{receivableDetails.orderId}}</div>
            <div class="close-btn" (click)="onNoClick()" mat-dialog-close>
                <mat-icon>close</mat-icon>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list [cols]="2" rowHeight="400px" *ngIf="!isSingapore">
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-list class="collection">
                <mat-list-item>
                    <span class="font-weight-900 font-size-20">Collection</span>
                    <span class="text-right font-weight-900 font-size-20">Amount</span>
                </mat-list-item>
                <mat-list-item>
                    <span>Parts Total</span>
                    <span class="text-right">{{receivableDetails.partsTotal | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span>Labour Total</span>
                    <span class="text-right">{{receivableDetails.labourTotal | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span>Sub Total</span>
                    <span class="text-right">{{receivableDetails.subtotal | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="grey-500-fg">GST</span>
                    <span class="text-right grey-500-fg">{{receivableDetails.gst | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="font-weight-700">Grand Total</span>
                    <span
                        class="text-right font-weight-700">{{receivableDetails.grandTotal | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="orange-500-fg">Discount</span>
                    <span class="text-right orange-500-fg">{{receivableDetails.discount | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="font-weight-700">Net Payment Collected</span>
                    <span class="text-right font-weight-700">{{receivableDetails.gst | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list class="collection-p grey-200">
                    <mat-list-item>
                        <span class="grey-700-fg text-bold">Payment Collection</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="font-weight-700 font-size-14">By {{receivableDetails.merchantName}}</span>
                        <span
                            class="text-right font-weight-700 font-size-14">{{receivableDetails.garageCollected | currency:"&#8377; "}}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="font-weight-700 font-size-14">By Pitstop</span>
                        <span
                            class="text-right font-weight-700 font-size-14">{{receivableDetails.pitstopCollected | currency:"&#8377; "}}</span>
                    </mat-list-item>
                </mat-list>
            </mat-list>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-list class="commission">
                <mat-list-item>
                    <span class="font-weight-900 font-size-20">Commissions</span>
                    <span class="text-right font-weight-900 font-size-20">Amount</span>
                </mat-list-item>
                <mat-list-item>
                    <span>Commision on Parts Total [@ {{receivableDetails.partCommissionRate}}%]</span>
                    <span class="text-right">{{receivableDetails.partsCommission | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span>Commission on Labour Total [@ {{receivableDetails.labourCommissionRate}}%]</span>
                    <span class="text-right">{{receivableDetails.labourCommission | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="font-weight-700">Total Commission</span>
                    <span
                        class="text-right font-weight-700">{{receivableDetails.commission | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="grey-500-fg">GST [@ {{receivableDetails.commissionGstRate}}%]</span>
                    <span class="text-right">{{receivableDetails.commissionGst | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="orange-500-fg">Net Commission</span>
                    <span class="text-right orange-500-fg">{{receivableDetails.commission | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="orange-500-fg">TDS Deduction [@ {{receivableDetails.tdsPercent}}%]</span>
                    <span class="text-right orange-500-fg">{{receivableDetails.tdsValue | currency:"&#8377; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="orange-500-fg">TCS Deduction [@ {{receivableDetails.tcsPercent}}%]</span>
                    <span class="text-right orange-500-fg">{{receivableDetails.tcsValue | currency:"&#8377; "}}</span>
                </mat-list-item>
            </mat-list>
        </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list [cols]="2" rowHeight="400px" *ngIf="isSingapore">
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-list class="collection">
                <mat-list-item>
                    <span class="font-weight-900 font-size-20">Collection</span>
                    <span class="text-right font-weight-900 font-size-20">Amount</span>
                </mat-list-item>
                <mat-list-item>
                    <span>Parts Total</span>
                    <span class="text-right">{{receivableDetails.partsTotal | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span>Labour Total</span>
                    <span class="text-right">{{receivableDetails.labourTotal | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span>Sub Total</span>
                    <span class="text-right">{{receivableDetails.subtotal | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="grey-500-fg">GST</span>
                    <span class="text-right grey-500-fg">{{receivableDetails.gst | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="font-weight-700">Grand Total</span>
                    <span
                        class="text-right font-weight-700">{{receivableDetails.grandTotal | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="orange-500-fg">Discount</span>
                    <span class="text-right orange-500-fg">{{receivableDetails.discount | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="font-weight-700">Net Payment Collected</span>
                    <span class="text-right font-weight-700">{{receivableDetails.gst | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list class="collection-p grey-200">
                    <mat-list-item>
                        <span class="grey-700-fg text-bold">Payment Collection</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="font-weight-700 font-size-14">By {{receivableDetails.merchantName}}</span>
                        <span
                            class="text-right font-weight-700 font-size-14">{{receivableDetails.garageCollected | currency:"S&#36; "}}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="font-weight-700 font-size-14">By Pitstop</span>
                        <span
                            class="text-right font-weight-700 font-size-14">{{receivableDetails.pitstopCollected | currency:"S&#36; "}}</span>
                    </mat-list-item>
                </mat-list>
            </mat-list>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-list class="commission">
                <mat-list-item>
                    <span class="font-weight-900 font-size-20">Commissions</span>
                    <span class="text-right font-weight-900 font-size-20">Amount</span>
                </mat-list-item>
                <mat-list-item>
                    <span>Commision on Parts Total [@ {{receivableDetails.partCommissionRate}}%]</span>
                    <span class="text-right">{{receivableDetails.partsCommission | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span>Commission on Labour Total [@ {{receivableDetails.labourCommissionRate}}%]</span>
                    <span class="text-right">{{receivableDetails.labourCommission | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="font-weight-700">Total Commission</span>
                    <span
                        class="text-right font-weight-700">{{receivableDetails.commission | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="grey-500-fg">GST [@ {{receivableDetails.commissionGstRate}}%]</span>
                    <span class="text-right">{{receivableDetails.commissionGst | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="orange-500-fg">Net Commission</span>
                    <span class="text-right orange-500-fg">{{receivableDetails.commission | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="orange-500-fg">TDS Deduction [@ {{receivableDetails.tdsPercent}}%]</span>
                    <span class="text-right orange-500-fg">{{receivableDetails.tdsValue | currency:"S&#36; "}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="orange-500-fg">TCS Deduction [@ {{receivableDetails.tcsPercent}}%]</span>
                    <span class="text-right orange-500-fg">{{receivableDetails.tcsValue | currency:"S&#36; "}}</span>
                </mat-list-item>
            </mat-list>
        </mat-grid-tile>
    </mat-grid-list>
    <!--  -->
    <mat-grid-list [cols]="1" rowHeight="80px">
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-list class="commission-n blue-100 mat-elevation-z8">
                <mat-list-item>
                    <span class="grey-700-fg text-bold">Net Receivable</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="font-weight-700">By {{receivableDetails.merchantName}}</span>
                    <span class="text-right font-weight-700"
                        *ngIf="!isSingapore">{{receivableDetails.payout | currency:"&#8377; "}}</span>
                    <span class="text-right font-weight-700"
                        *ngIf="isSingapore">{{receivableDetails.payout | currency:"S&#36; "}}</span>
                </mat-list-item>
            </mat-list>
        </mat-grid-tile>
    </mat-grid-list>
</mat-dialog-content>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<mat-dialog-actions align="start" *ngIf="receivableDetails.status==2">
    <div class="uploaded-images m-0">
        <div class="uploaded-images-title font-weight-900 font-size-20">Upload Invoices <span
                *ngIf="uploader?.queue?.length != 0" class="text-capitalized">(Added:
                {{ uploader?.queue?.length }})</span></div>
        <mat-list class="image-details p-0" *ngIf="uploader?.queue?.length != 0">
            <mat-list-item class="image-headings mt-8 mb-8">
                <span class="font-weight-900 font-size-16">Image</span>
                <span class="font-weight-900 font-size-16">Name</span>
                <span class="font-weight-900 font-size-16">Size</span>
                <span class="font-weight-900 font-size-16">Action</span>
            </mat-list-item>

            <mat-list-item class="image-data mt-8 mb-8" *ngFor="let item of uploader.queue;let i=index;">
                <span><img class="auto-width h-60 image" [src]="urls[i]" (click)="open(i)" /></span>
                <span class="font-weight-500 font-size-14">{{ item?.file?.name }}</span>
                <span class="font-weight-500 font-size-14"
                    *ngIf="uploader.options.isHTML5">{{ item?.file?.size/1024/1024 | number:'.2' }}
                    MB</span>
                <span class="font-weight-500 font-size-14">
                    <button mat-mini-fab class="mr-8" (click)="open(i)">
                        <mat-icon>zoom_in</mat-icon>
                    </button>
                    <button mat-mini-fab color="warn" (click)="item.remove();removeImgEntry(i)">
                        <mat-icon>close</mat-icon>
                    </button>
                </span>
            </mat-list-item>
        </mat-list>
        <input hidden type="file" ng2FileSelect [uploader]="uploader" #fileInput multiple />
        <button mat-raised-button color="accent" class="mt-8"
            (click)="fileInput.click()">{{ uploader?.queue?.length == 0 ? 'Upload' : 'Add More' }}</button>
    </div>
    <div class="approve-actions text-right" [hidden]="!uploader.getNotUploadedItems().length">
        <button mat-raised-button class="green-600"
            (click)="uploadInvoices(receivableDetails.id, receivableDetails.orderId, receivableDetails.orderType)">Upload
            Invoices</button>
        <button mat-raised-button class="grey-600" (click)="uploader.clearQueue()">Remove
            all</button>
    </div>
</mat-dialog-actions>