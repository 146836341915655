<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="content">
        <div class="left">
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="100">
                    <span fxLayout="row wrap" fxLayoutAlign="start center" class="mat-display-1 m-0 p-16 pl-0">Bulk
                        Invoice Download</span>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="25">
                    <div class="fuse-card m-0">
                        <div class="p-0" fxLayout="row wrap" fxLayoutAlign="start center">
                            <div class="p-16 pb-0 w-100-p" fxLayout="row wrap" fxLayoutAlign="start center">
                                <ngx-mat-drp color="accent" (selectedDateRangeChanged)="updateRange($event)" [options]="options" #dateRangePicker></ngx-mat-drp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="mat-elevation-z8 mt-8" *ngIf="!loading">
        <div class="p-12 pb-0 pt-0 export-block" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <button *ngIf="!checkedDownload" [disabled]="noDataSelect" (click)="downloadAll()" class="common_btn" mat-button color="accent">
                    Download All
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>
                <button *ngIf="checkedDownload" [disabled]="noDataSelect" (click)="downloadSelected()" class="common_btn" mat-button color="accent">
                    Download
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>

            </div>
            <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table mat-table [dataSource]="invoicesDataSource" matSort matSortActive="orderId" matSortDirection="desc" matSortDisableClear>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle($event) : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="getCheckedValue($event,row.orderId)" #checkRef [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.orderId}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>TITLE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.title == '' ? '-' : row.title}}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ACTIONS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <a class="mr-24 amber-500-fg" [href]="row.actions" target="_blank">
                        <mat-icon>zoom_in</mat-icon>
                    </a>
                    <a color="accent" [href]="row.actions" download="invoice_{{row.orderId}}.pdf">
                        <mat-icon>cloud_download</mat-icon>
                    </a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="invoicesDataColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: invoicesDataColumns;"></tr>
        </table>
        <div *ngIf="noData" class="txt_center">
            <mat-error> No Data to display</mat-error>
        </div>
        <mat-paginator class="order_paginator" [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>