<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="order-list mt-8 p-8">            
        <h2 class="order-list-title">{{'MY_ORDERS_INFO.TITLE' | translate}}</h2>
        
        <div class="spares-list" *ngIf="orderList.length>0">
            <div class="order-list-card" *ngFor="let list of orderList"> 
                <div class="spare-part-status">
                    <div class="status-icon"><mat-icon>local_shipping</mat-icon></div>
                    <div class="status-info">                        
                        <span class="order-id">#{{list.orderNumber}}</span>
                        <span class="order-status">{{list.orderStatus}}</span>
                        <span *ngIf="list.lastUpdateOn">On {{list.lastUpdateOn | date: 'MMM dd yyyy'}} at {{list.lastUpdateOn | date: 'hh:mm a'}}</span>
                        <span *ngIf="!list.lastUpdateOn">On {{list.orderDate | date: 'MMM dd yyyy'}} at {{list.orderDate | date: 'hh:mm a'}}</span>
                    </div>
                </div>
                <div class="mat-elevation-z8 mt-8" *ngFor="let item of list.orderItems">
                    <div class="spares-part" *ngIf="item.imageUrl!=''"> 
                        <img class="img-fluid part-image" src="{{item.imageUrl}}" />
                    </div>
                    <div class="spares-part" *ngIf="item.imageUrl==''"> 
                        <img class="img-fluid part-image" src="../../../../assets/images/logos/logo.png" />
                    </div>
                    <div class="spare-details-sec">
                        <div class="spare-brand">{{item.brandName}}</div>
                        <div class="spare-part-name">{{item.partName}}</div>
                        <div class="spare-price">&#8377;{{item.price*item.quantity | number : '1.2-2'}}</div>
                    </div>
                </div>
            </div>
        </div>   

    </div>
</div>