import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AppComponent } from 'app/app.component';

import { LoginService } from 'app/main/authentication/login/login.service';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { ToastrService } from 'ngx-toastr';
import { OrderDetailsService } from 'app/main/orders/order-details/order-details.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    token: any;
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    loginError = false;
    serverError = false;
    hide: any;
    jsonData: any;
    notOwner:any;
    parseData: any;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private loginService: LoginService,
        private appComponent: AppComponent,
        // private afMessaging: AngularFireMessaging,
        private toastr: ToastrService,
        private service: OrderDetailsService,
        private _fuseNavigationService: FuseNavigationService,
    ) {
        // redirect to home if already logged in

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.token = this.loginService.getToken();
        if (this.token) {
            this.router.navigate(['/dashboard']);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
    }

    get f() { return this.loginForm.controls; }
    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.loginService.getUser(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {

                    if (data && data.response.status === 'SUCCESS') {
                        // this.requestPermission();
                        // this.listen();
                        this.loginError = false;
                        this.loginService.savePassHash(window.btoa(this.f.password.value));
                        this.loginService.saveLoginData(JSON.stringify(data.response.body));
                        this.loginService.saveMapKey(JSON.stringify(data.response.body.mapKey));
                        this.loginService.saveSpareLoginNumber(JSON.stringify(data.response.body.agentMobile));
                        this.loginService.saveLineItems(JSON.stringify(data.response.body.lineitems));
                        this.loginService.saveMerchantData(JSON.stringify(data.response.body.merchant));
                        this.loginService.sendToken(data.response.body.sessionId);
                        this.loginService.saveTimeZone(data.response.body.account.timezone, data.response.body.account.dateformat);
                        const permissionData = JSON.parse(this.loginService.getLoginData());
                        this.appComponent.openSnackBar('Login Successful', 'Redirecting...');
                        setTimeout(() => {
                            this.router.navigate(['/dashboard']);
                        }, 1000);
                    } else {
                        this.notOwner=data.response.message;
                        this.loading = false;
                        this.loginError = true;
                        setTimeout(() => {
                            this.loginError = false;
                        }, 2000);
                    }
                },
                error => {
                    this.loading = false;
                    this.serverError = true;
                    setTimeout(() => {
                        this.serverError = false;
                    }, 2000);
                });
    }
  
    // requestPermission() {
    //     this.afMessaging.requestToken
    //         .subscribe(
    //             (token) => {
    //                 this.service.updateFCMToken(token).subscribe(
    //                     result => {
    //                     },
    //                     error => {
    //                         console.log(error);
    //                     }
    //                 );

    //             },
    //             (error) => { console.error(error); },
    //         );
    // }

    // listen() {
    //     let notify: any;
    //     this.afMessaging.messages
    //         .subscribe((message) => {
    //             notify = message;
    //             const msg = notify.data.body;
    //             const title = notify.data.title;
    //             this.toastr.success(msg, title);
    //         });
    // }
}
