import { Component, OnDestroy, OnInit, ViewEncapsulation, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { ProfileService } from 'app/main/profile/profile.service';
import { LoginService } from 'app/main/authentication/login/login.service';
import { first } from 'rxjs/operators';
import { AppComponent } from 'app/app.component';
import { MapsAPILoader } from '@agm/core';
@Component({
    selector: 'profile-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ProfileAboutComponent implements OnInit, OnDestroy {
    about: any;
    token: any;
    generalInfoForm: FormGroup;
    generalInfoFormEdit = false;
    generalInfoFormLoading = false;
    generalInfoFormSubmitted = false;
    generalInfoFormError = false;
    generalInfoResult: any;
    public latitude: number;
    public longitude: number;
    public zoom: number;
    public city: string;
    public state: string;
    public locality: string;
    public pincode: string;
    public fullAddress: string;
    contactInfoForm: FormGroup;
    contactInfoFormEdit = false;
    contactInfoFormLoading = false;
    contactInfoFormSubmitted = false;
    contactInfoFormError = false;
    contactInfoResult: any;
    financialInfoForm: FormGroup;
    financialInfoFormEdit = false;
    financialInfoFormLoading = false;
    financialInfoFormSubmitted = false;
    financialInfoFormError = false;
    financialInfoResult: any;
    bankInfoForm: FormGroup;
    bankInfoFormEdit = false;
    bankInfoFormLoading = false;
    bankInfoFormSubmitted = false;
    bankInfoFormError = false;
    bankInfoResult: any;
    doorstepLoading = false;
    doorstepSubmitted = false;
    doorstepError = false;
    doorstepResult: any;
    doorstepFlag = false;
    openDays = [
        { index: '1', day: 'Monday', checked: false },
        { index: '2', day: 'Tuesday', checked: false },
        { index: '3', day: 'Wednesday', checked: false },
        { index: '4', day: 'Thursday', checked: false },
        { index: '5', day: 'Friday', checked: false },
        { index: '6', day: 'Saturday', checked: false },
        { index: '7', day: 'Sunday', checked: false },
    ];
    openDaysData: any;
    openDaysLoading = false;
    openDaysSubmitted = false;
    openDaysError = false;
    openDaysResult: any;
    openDaysDataSubmit = [];
    private _unsubscribeAll: Subject<any>;
    @ViewChild('line2', { static: false })
    public addressElementRef: ElementRef;
    /**
     * Constructor
     *
     * @param {ProfileService} pService
     */
    constructor(
        public router: Router,
        private pService: ProfileService,
        private loginService: LoginService,
        private _formBuilder: FormBuilder,
        private appComponent: AppComponent,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this._unsubscribeAll = new Subject();
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                this.router.navigated = false;
            }
        });
    }
    ngOnInit(): void {
        this.about = this.pService.about;
        this.latitude = parseFloat(this.about.latitude);
        this.longitude = parseFloat(this.about.longitude);
        this.zoom = 12;
        this.city = this.about.city;
        this.state = this.about.state;
        this.locality = this.about.locality;
        this.pincode = this.about.pincode;
        this.fullAddress = this.about.address;
        this.openDaysData = this.about.openDays.split(',');
        for (let i = 0; i < this.openDaysData.length; i++) {
            for (let j = 0; j < this.openDays.length; j++) {
                if (this.openDays[j].index.indexOf(this.openDaysData[i]) !== -1) {
                    this.openDays[j].checked = true;
                }
            }
        }
        if (this.about.doorstep === 1) {
            this.doorstepFlag = true;
        }
        this.generalInfoForm = this._formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(3)]],
            description: [''],
            primaryContactName: ['']
        });
        this.contactInfoForm = this._formBuilder.group({
            line1: ['', [Validators.required, Validators.minLength(3)]],
            line2: ['', [Validators.required, Validators.minLength(3)]],
            address: [''],
            latitude: ['', Validators.required],
            longitude: ['', Validators.required],
            city: ['', Validators.required],
            locality: [''],
            state: ['', Validators.required],
            pincode: ['', Validators.required],
            phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            email: ['', [Validators.required, Validators.email, Validators.minLength(5)]]
        });
        this.financialInfoForm = this._formBuilder.group({
            gstNo: ['', [Validators.required, Validators.minLength(3)]],
            panNo: ['', [Validators.required, Validators.minLength(3)]]
        });
        this.bankInfoForm = this._formBuilder.group({
            bankAccountName: ['', [Validators.required, Validators.minLength(3)]],
            bankAccountNumber: ['', [Validators.required, Validators.minLength(3)]],
            bankAccountType: ['', [Validators.required, Validators.minLength(3)]],
            bankBranch: ['', [Validators.required, Validators.minLength(3)]],
            bankIfsc: ['', [Validators.required, Validators.minLength(3)]],
            bankName: ['', [Validators.required, Validators.minLength(3)]],
        });
    }
    generalInfoFormSubmit(generalInfoFormData: any, generalInfoFormDirective: FormGroupDirective): void {
        this.generalInfoFormSubmitted = true;
        this.generalInfoFormLoading = true;
        const data = generalInfoFormData.value;
        const general_info_form_data = new FormData();
        for (const i in data) {
            if (data.hasOwnProperty(i)) {
                general_info_form_data.append(i, data[i].toString());
            }
        }
        this.pService.updateMerchantInfo(general_info_form_data).pipe(first()).subscribe(
            result => {
                this.generalInfoFormError = false;
                this.generalInfoResult = result;
                if (this.generalInfoResult.response.status === 'SUCCESS') {
                    this.generalInfoFormLoading = false;
                    generalInfoFormDirective.resetForm();
                    this.generalInfoForm.reset();
                    this.loginService.saveMerchantData(JSON.stringify(this.generalInfoResult.response.body.merchant));
                    setTimeout(() => {
                        this.generalInfoFormSubmitted = false;
                        this.generalFormEditToggle();
                        this.router.navigate(['profile']);
                    }, 2000);
                } else {
                    this.generalInfoFormError = true;
                    this.generalInfoFormLoading = false;
                    this.generalInfoFormSubmitted = false;
                    if (this.generalInfoResult.response.message !== 'Cannot process request') {
                        this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                        setTimeout(() => {
                            this.loginService.logout();
                        }, 1000);
                    }
                }
            },
            error => {
                console.log(error);
                this.generalInfoFormError = true;
                this.generalInfoFormSubmitted = false;
                this.generalInfoFormLoading = false;
            }
        );
    }
    generalFormEditToggle() {
        this.generalInfoFormEdit = !this.generalInfoFormEdit;
        if (this.generalInfoFormEdit) {
            this.generalInfoForm.patchValue({
                name: this.about.name,
                description: this.about.description,
                primaryContactName: this.about.primaryContactName
            });
        }
    }
    contactInfoFormSubmit(contactInfoFormData: any, contactInfoFormDirective: FormGroupDirective): void {
        this.contactInfoFormSubmitted = true;
        this.contactInfoFormLoading = true;
        const data = contactInfoFormData.value;
        console.log(data);
        const contact_info_form_data = new FormData();
        for (const i in data) {
            if (data.hasOwnProperty(i)) {
                contact_info_form_data.append(i, data[i].toString());
            }
        }
        this.pService.updateMerchantInfo(contact_info_form_data).pipe(first()).subscribe(
            result => {
                this.contactInfoFormError = false;
                this.contactInfoResult = result;
                if (this.contactInfoResult.response.status === 'SUCCESS') {
                    this.contactInfoFormLoading = false;
                    contactInfoFormDirective.resetForm();
                    this.contactInfoForm.reset();
                    this.loginService.saveMerchantData(JSON.stringify(this.contactInfoResult.response.body.merchant));
                    setTimeout(() => {
                        this.contactInfoFormSubmitted = false;
                        this.contactFormEditToggle();
                        this.router.navigate(['profile']);
                    }, 2000);
                } else {
                    this.contactInfoFormError = true;
                    this.contactInfoFormLoading = false;
                    this.contactInfoFormSubmitted = false;
                    if (this.contactInfoResult.response.message !== 'Cannot process request') {
                        this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                        setTimeout(() => {
                            this.loginService.logout();
                        }, 1000);
                    }
                }
            },
            error => {
                console.log(error);
                this.contactInfoFormError = true;
                this.contactInfoFormSubmitted = false;
                this.contactInfoFormLoading = false;
            }
        );
    }
    contactFormEditToggle() {
        this.contactInfoFormEdit = !this.contactInfoFormEdit;
        if (this.contactInfoFormEdit) {
            this.contactInfoForm.patchValue({
                line1: this.about.line1,
                line2: this.about.line2,
                address: this.about.line1 + ', ' + this.about.line2,
                latitude: this.about.latitude,
                longitude: this.about.longitude,
                locality: this.about.locality,
                city: this.about.city,
                state: this.about.state,
                pincode: this.about.pincode,
                phone: this.about.phone,
                email: this.about.email
            });
        }
    }
    getGoogleAddress() {
        if (this.contactInfoFormEdit) {
            this.mapsAPILoader.load().then(() => {
                const autocomplete = new google.maps.places.Autocomplete(this.addressElementRef.nativeElement, {
                    types: [], componentRestrictions: { country: 'IN' }
                });
                autocomplete.addListener('place_changed', () => {
                    this.ngZone.run(() => {
                        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                        if (place.geometry === undefined || place.geometry === null) {
                            return;
                        }
                        this.contactInfoForm.get('line2').setValue(place.name + ', ' + place.formatted_address);
                        this.fullAddress = place.name + ', ' + place.formatted_address;
                        const addressComponents = place.address_components;
                        addressComponents.forEach(component => {
                            if (component.types[0] === 'locality') {
                                this.city = component.long_name;
                            }
                            if (component.types[0] === 'administrative_area_level_1') {
                                this.state = component.long_name;
                            }
                            if (component.types[0] === 'sublocality_level_1') {
                                this.locality = component.long_name;
                            }
                            if (component.types[0] === 'postal_code') {
                                this.pincode = component.long_name;
                            }
                        });
                        this.latitude = place.geometry.location.lat();
                        this.longitude = place.geometry.location.lng();
                        this.zoom = 17;
                    });
                });
            });
        }
    }
    financialInfoFormSubmit(financialInfoFormData: any, financialInfoFormDirective: FormGroupDirective): void {
        this.financialInfoFormSubmitted = true;
        this.financialInfoFormLoading = true;
        const data = financialInfoFormData.value;
        const financial_info_form_data = new FormData();
        for (const i in data) {
            if (data.hasOwnProperty(i)) {
                financial_info_form_data.append(i, data[i].toString());
            }
        }
        this.pService.updateMerchantInfo(financial_info_form_data).pipe(first()).subscribe(
            result => {
                this.financialInfoFormError = false;
                this.financialInfoResult = result;
                if (this.financialInfoResult.response.status === 'SUCCESS') {
                    this.financialInfoFormLoading = false;
                    financialInfoFormDirective.resetForm();
                    this.financialInfoForm.reset();
                    this.loginService.saveMerchantData(JSON.stringify(this.financialInfoResult.response.body.merchant));
                    setTimeout(() => {
                        this.financialInfoFormSubmitted = false;
                        this.financialFormEditToggle();
                        this.router.navigate(['profile']);
                    }, 2000);
                } else {
                    this.financialInfoFormError = true;
                    this.financialInfoFormLoading = false;
                    this.financialInfoFormSubmitted = false;
                    if (this.financialInfoResult.response.message !== 'Cannot process request') {
                        this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                        setTimeout(() => {
                            this.loginService.logout();
                        }, 1000);
                    }
                }
            },
            error => {
                console.log(error);
                this.financialInfoFormError = true;
                this.financialInfoFormSubmitted = false;
                this.financialInfoFormLoading = false;
            }
        );
    }
    financialFormEditToggle() {
        this.financialInfoFormEdit = !this.financialInfoFormEdit;
        if (this.financialInfoFormEdit) {
            this.financialInfoForm.patchValue({
                gstNo: this.about.gstNo,
                panNo: this.about.panNo
            });
        }
    }
    bankInfoFormSubmit(bankInfoFormData: any, bankInfoFormDirective: FormGroupDirective): void {
        this.bankInfoFormSubmitted = true;
        this.bankInfoFormLoading = true;
        const data = bankInfoFormData.value;
        const bank_info_form_data = new FormData();
        for (const i in data) {
            if (data.hasOwnProperty(i)) {
                bank_info_form_data.append(i, data[i].toString());
            }
        }
        this.pService.updateMerchantInfo(bank_info_form_data).pipe(first()).subscribe(
            result => {
                this.bankInfoFormError = false;
                this.bankInfoResult = result;
                if (this.bankInfoResult.response.status === 'SUCCESS') {
                    this.bankInfoFormLoading = false;
                    bankInfoFormDirective.resetForm();
                    this.bankInfoForm.reset();
                    this.loginService.saveMerchantData(JSON.stringify(this.bankInfoResult.response.body.merchant));
                    setTimeout(() => {
                        this.bankInfoFormSubmitted = false;
                        this.bankFormEditToggle();
                        this.router.navigate(['profile']);
                    }, 2000);
                } else {
                    this.bankInfoFormError = true;
                    this.bankInfoFormLoading = false;
                    this.bankInfoFormSubmitted = false;
                    if (this.bankInfoResult.response.message !== 'Cannot process request') {
                        this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                        setTimeout(() => {
                            this.loginService.logout();
                        }, 1000);
                    }
                }
            },
            error => {
                console.log(error);
                this.bankInfoFormError = true;
                this.bankInfoFormSubmitted = false;
                this.bankInfoFormLoading = false;
            }
        );
    }
    bankFormEditToggle() {
        this.bankInfoFormEdit = !this.bankInfoFormEdit;
        if (this.bankInfoFormEdit) {
            this.bankInfoForm.patchValue({
                bankAccountName: this.about.bankAccountName,
                bankAccountNumber: this.about.bankAccountNumber,
                bankAccountType: this.about.bankAccountType,
                bankBranch: this.about.bankBranch,
                bankIfsc: this.about.bankIfsc,
                bankName: this.about.bankName
            });
        }
    }
    doorstepChange(doorstep: any): void {
        let doorstepValue;
        this.doorstepSubmitted = true;
        this.doorstepLoading = true;
        doorstep === true ? doorstepValue = 1 : doorstepValue = 0;
        const doorstep_change_form_data = new FormData();
        doorstep_change_form_data.append('doorstep', doorstepValue);
        this.pService.updateMerchantInfo(doorstep_change_form_data).pipe(first()).subscribe(
            result => {
                this.doorstepError = false;
                this.doorstepResult = result;
                if (this.doorstepResult.response.status === 'SUCCESS') {
                    this.doorstepLoading = false;
                    this.loginService.saveMerchantData(JSON.stringify(this.doorstepResult.response.body.merchant));
                    setTimeout(() => {
                        this.doorstepSubmitted = false;
                        this.router.navigate(['profile']);
                    }, 2000);
                } else {
                    this.doorstepError = true;
                    this.doorstepLoading = false;
                    this.doorstepSubmitted = false;
                    if (this.doorstepResult.response.message !== 'Cannot process request') {
                        this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                        setTimeout(() => {
                            this.loginService.logout();
                        }, 1000);
                    }
                }
            },
            error => {
                console.log(error);
                this.doorstepError = true;
                this.doorstepSubmitted = false;
                this.doorstepLoading = false;
            }
        );
    }
    openDaysChange(openDays: any): void {
        this.openDaysSubmitted = true;
        this.openDaysLoading = true;
        this.openDaysDataSubmit = [];
        for (let i = 0; i < this.openDays.length; i++) {
            if (this.openDays[i].checked === true) {
                this.openDaysDataSubmit.push(this.openDays[i].index.toString());
            }
        }
        const openDays_change_form_data = new FormData();
        openDays_change_form_data.append('openDays', this.openDaysDataSubmit.toString());
        this.pService.updateMerchantInfo(openDays_change_form_data).pipe(first()).subscribe(
            result => {
                this.openDaysError = false;
                this.openDaysResult = result;
                if (this.openDaysResult.response.status === 'SUCCESS') {
                    this.openDaysLoading = false;
                    this.loginService.saveMerchantData(JSON.stringify(this.openDaysResult.response.body.merchant));
                    setTimeout(() => {
                        this.openDaysSubmitted = false;
                        this.router.navigate(['profile']);
                    }, 2000);
                } else {
                    this.openDaysError = true;
                    this.openDaysLoading = false;
                    this.openDaysSubmitted = false;
                    if (this.openDaysResult.response.message !== 'Cannot process request') {
                        this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                        setTimeout(() => {
                            this.loginService.logout();
                        }, 1000);
                    }
                }
            },
            error => {
                console.log(error);
                this.openDaysError = true;
                this.openDaysSubmitted = false;
                this.openDaysLoading = false;
            }
        );
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
