<div id="about" class="p-24" fxLayout="row wrap">
    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">
        <!-- General Info -->
        <div class="profile-box info-box general" fxLayout="column">
            <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="title">General Information</div>
                <div class="more secondary-text">
                    <!-- <button mat-mini-fab color="default" (click)="generalFormEditToggle()">
                        <mat-icon *ngIf="!generalInfoFormEdit" aria-label="Edit">edit</mat-icon>
                        <mat-icon *ngIf="generalInfoFormEdit" aria-label="Arrow Back">arrow_back</mat-icon>
                    </button> -->
                </div>
            </header>
            <div class="content" *ngIf="!generalInfoFormEdit">
                <div class="info-line">
                    <div class="title">Name</div>
                    <div class="info">{{about.name}}</div>
                </div>
                <div class="info-line">
                    <div class="title">Description</div>
                    <div class="info">{{about.description}}</div>
                </div>
                <div class="info-line">
                    <div class="title">Primary Contact Name</div>
                    <div class="info">{{about.primaryContactName}}</div>
                </div>
            </div>
            <form #generalInfoFormDirective="ngForm" *ngIf="generalInfoFormEdit" class="content" [class.loading]="generalInfoFormLoading" name="generalInfoForm" [formGroup]="generalInfoForm" (ngSubmit)="generalInfoFormSubmit(generalInfoForm, generalInfoFormDirective)"
                novalidate>
                <mat-form-field appearance="standard">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" minlength="3" formControlName="name">
                    <mat-error *ngIf="generalInfoForm.get('name').hasError('required')">
                        Name is required
                    </mat-error>
                    <mat-error *ngIf="generalInfoForm.get('name').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>Description</mat-label>
                    <input matInput type="text" formControlName="description">
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>Primary Contact Name</mat-label>
                    <input matInput type="text" formControlName="primaryContactName">
                </mat-form-field>
                <mat-error class="custom-error" *ngIf="generalInfoFormError" fxLayoutAlign="center">
                    Server Error. Please Try again.
                </mat-error>
                <strong class="custom-success green-800-fg" *ngIf="!generalInfoFormError && !generalInfoFormLoading && generalInfoFormSubmitted" fxLayoutAlign="center">
                    General Information Updated Successfully.
                </strong>
                <div class="text-right">
                    <button mat-raised-button color="accent" class="submit-button" aria-label="Update" [disabled]="generalInfoForm.invalid">
                        Update
                    </button>
                    <button mat-raised-button color="default" (click)="generalFormEditToggle()" class="ml-8">Cancel</button>
                </div>
            </form>
            <mat-progress-bar *ngIf="generalInfoFormLoading" mode="indeterminate"></mat-progress-bar>
        </div>
        <!-- Contact Info -->
        <div class="profile-box info-box work" fxLayout="column">
            <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="title">Contact Information</div>
                <div class="more secondary-text">
                    <!-- <button mat-mini-fab color="default" (click)="contactFormEditToggle()">
                        <mat-icon *ngIf="!contactInfoFormEdit" aria-label="Edit">edit</mat-icon>
                        <mat-icon *ngIf="contactInfoFormEdit" aria-label="Arrow Back">arrow_back</mat-icon>
                    </button> -->
                </div>
            </header>
            <div class="content" *ngIf="!contactInfoFormEdit">
                <div class="info-line text-center">
                    <agm-map disableDefaultUI="false" [scrollwheel]="false" [zoom]="zoom" [latitude]="latitude" [longitude]="longitude">
                        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                    </agm-map>
                    <small class="mr-12"><strong>Latitude: </strong>{{about.latitude}}</small>
                    <small><strong>Longitude: </strong>{{about.longitude}}</small>
                </div>
                <div class="info-line">
                    <div class="title">Address</div>
                    <div class="info">{{about.address}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">Locality</div>
                    <div class="info">{{about.locality}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">City</div>
                    <div class="info">{{about.city}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">State</div>
                    <div class="info">{{about.state}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">Pincode</div>
                    <div class="info">{{about.pincode}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">Mobile</div>
                    <div class="info">{{about.phone}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">Email</div>
                    <div class="info">{{about.email}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">Website</div>
                    <div class="info">{{about.website===''? 'No website':about.website}}</div>
                </div>
            </div>
            <form #contactInfoFormDirective="ngForm" *ngIf="contactInfoFormEdit" class="content text-center" [class.loading]="contactInfoFormLoading" name="contactInfoForm" [formGroup]="contactInfoForm" (ngSubmit)="contactInfoFormSubmit(contactInfoForm, contactInfoFormDirective)"
                novalidate>
                <div>
                    <agm-map disableDefaultUI="false" [scrollwheel]="false" [zoom]="zoom" [latitude]="latitude" [longitude]="longitude">
                        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                    </agm-map>
                    <small class="mr-12"><strong>Latitude: </strong>{{latitude}}</small>
                    <small><strong>Longitude: </strong>{{longitude}}</small>
                </div>
                <mat-form-field appearance="standard">
                    <mat-label>Plot/Floor Number</mat-label>
                    <input matInput type="text" minlength="3" formControlName="line1">
                    <mat-error *ngIf="contactInfoForm.get('line1').hasError('required')">
                        Plot/Floor Number is required
                    </mat-error>
                    <mat-error *ngIf="contactInfoForm.get('line1').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>Address</mat-label>
                    <input matInput (keyup)="getGoogleAddress()" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" #line2 formControlName="line2">
                    <mat-error *ngIf="contactInfoForm.get('line2').hasError('required')">
                        Address is required
                    </mat-error>
                    <mat-error *ngIf="contactInfoForm.get('line2').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" style="display: none">
                    <mat-label>Address</mat-label>
                    <input matInput type="text" name="address" [value]="fullAddress">
                </mat-form-field>
                <mat-form-field appearance="standard" style="display: none" class="w-50-p">
                    <mat-label>Latitude</mat-label>
                    <input matInput type="text" name="latitude" [value]="latitude">
                </mat-form-field>
                <mat-form-field appearance="standard" style="display: none" class="w-50-p">
                    <mat-label>Longitude</mat-label>
                    <input matInput type="text" name="longitude" [value]="longitude">
                </mat-form-field>
                <mat-form-field appearance="standard" class="w-50-p">
                    <mat-label>Locality</mat-label>
                    <input matInput type="text" name="locality" disabled [value]="locality">
                </mat-form-field>
                <mat-form-field appearance="standard" class="w-50-p">
                    <mat-label>City</mat-label>
                    <input matInput type="text" name="city" disabled [value]="city">
                </mat-form-field>
                <mat-form-field appearance="standard" class="w-50-p">
                    <mat-label>State</mat-label>
                    <input matInput type="text" name="state" disabled [value]="state">
                </mat-form-field>
                <mat-form-field appearance="standard" class="w-50-p">
                    <mat-label>Pincode</mat-label>
                    <input matInput type="text" name="pincode" disabled [value]="pincode">
                </mat-form-field>
                <mat-form-field appearance="standard" class="w-50-p">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput type="text" minlength="10" maxlength="10" formControlName="phone">
                    <mat-error *ngIf="contactInfoForm.get('phone').hasError('required')">
                        Mobile Number is required
                    </mat-error>
                    <mat-error *ngIf="contactInfoForm.get('phone').hasError('minlength')">
                        Minimum 10 digits
                    </mat-error>
                    <mat-error *ngIf="contactInfoForm.get('phone').hasError('maxlength')">
                        Maximum 10 digits
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="w-50-p">
                    <mat-label>Email</mat-label>
                    <input matInput type="text" minlength="5" formControlName="email">
                    <mat-error *ngIf="contactInfoForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="contactInfoForm.get('email').hasError('email')">
                        Invalid Email format
                    </mat-error>
                    <mat-error *ngIf="contactInfoForm.get('email').hasError('minlength')">
                        Minimum 5 character
                    </mat-error>
                </mat-form-field>
                <mat-error class="custom-error" *ngIf="contactInfoFormError" fxLayoutAlign="center">
                    Server Error. Please Try again.
                </mat-error>
                <strong class="custom-success green-800-fg" *ngIf="!contactInfoFormError && !contactInfoFormLoading && contactInfoFormSubmitted" fxLayoutAlign="center">
                    Contact Information Updated Successfully.
                </strong>
                <div class="text-right">
                    <button mat-raised-button color="accent" class="submit-button" aria-label="Update" [disabled]="contactInfoForm.invalid">
                        Update
                    </button>
                    <button mat-raised-button color="default" (click)="contactFormEditToggle()" class="ml-8">Cancel</button>
                </div>
            </form>
            <mat-progress-bar *ngIf="contactInfoFormLoading" mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
    <div class="about-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="35">
        <!-- Financial Information -->
        <div class="profile-box info-box" fxLayout="column">
            <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="title">Financial Information</div>
                <div class="more secondary-text">
                    <!-- <button mat-mini-fab color="default" (click)="financialFormEditToggle()">
                        <mat-icon *ngIf="!financialInfoFormEdit" aria-label="Edit">edit</mat-icon>
                        <mat-icon *ngIf="financialInfoFormEdit" aria-label="Arrow Back">arrow_back</mat-icon>
                    </button> -->
                </div>
            </header>
            <div class="content" *ngIf="!financialInfoFormEdit">
                <div class="info-line">
                    <div class="title">GST Number</div>
                    <div class="info">{{about.gstNo}}</div>
                </div>
                <div class="info-line">
                    <div class="title">PAN Number</div>
                    <div class="info">{{about.panNo}}</div>
                </div>
            </div>
            <form #financialInfoFormDirective="ngForm" *ngIf="financialInfoFormEdit" class="content text-center" [class.loading]="financialInfoFormLoading" name="financialInfoForm" [formGroup]="financialInfoForm" (ngSubmit)="financialInfoFormSubmit(financialInfoForm, financialInfoFormDirective)"
                novalidate>
                <mat-form-field appearance="standard">
                    <mat-label>GST Number</mat-label>
                    <input matInput type="text" minlength="3" formControlName="gstNo">
                    <mat-error *ngIf="financialInfoForm.get('gstNo').hasError('required')">
                        GST Number is required
                    </mat-error>
                    <mat-error *ngIf="financialInfoForm.get('gstNo').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>PAN Number</mat-label>
                    <input matInput type="text" minlength="3" formControlName="panNo">
                    <mat-error *ngIf="financialInfoForm.get('panNo').hasError('required')">
                        PAN Number is required
                    </mat-error>
                    <mat-error *ngIf="financialInfoForm.get('panNo').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-error class="custom-error" *ngIf="financialInfoFormError" fxLayoutAlign="center">
                    Server Error. Please Try again.
                </mat-error>
                <strong class="custom-success green-800-fg" *ngIf="!financialInfoFormError && !financialInfoFormLoading && financialInfoFormSubmitted" fxLayoutAlign="center">
                    Financial Information Updated Successfully.
                </strong>
                <div class="text-right">
                    <button mat-raised-button color="accent" class="submit-button" aria-label="Update" [disabled]="financialInfoForm.invalid">
                        Update
                    </button>
                    <button mat-raised-button color="default" (click)="financialFormEditToggle()" class="ml-8">Cancel</button>
                </div>
            </form>
            <mat-progress-bar *ngIf="financialInfoFormLoading" mode="indeterminate"></mat-progress-bar>
        </div>
        <!-- Bank Information -->
        <div class="profile-box info-box" fxLayout="column">
            <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="title">Bank Information</div>
            </header>
            <div class="content" *ngIf="!bankInfoFormEdit">
                <div class="info-line">
                    <div class="title">Bank Account Name</div>
                    <div class="info text-uppercase">{{about.bankAccountName}}</div>
                </div>
                <div class="info-line">
                    <div class="title">Bank Account Number</div>
                    <div class="info">{{about.bankAccountNumber}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">Bank Name</div>
                    <div class="info text-uppercase">{{about.bankName}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">Bank IFSC</div>
                    <div class="info text-uppercase">{{about.bankIfsc}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">Bank Branch</div>
                    <div class="info text-uppercase">{{about.bankBranch}}</div>
                </div>
                <div class="info-line w-50-p block-inline">
                    <div class="title">Bank Account Type</div>
                    <div class="info text-uppercase">{{about.bankAccountType}}</div>
                </div>
            </div>
            <form #bankInfoFormDirective="ngForm" *ngIf="bankInfoFormEdit" class="content text-center" [class.loading]="bankInfoFormLoading" name="bankInfoForm" [formGroup]="bankInfoForm" (ngSubmit)="bankInfoFormSubmit(bankInfoForm, bankInfoFormDirective)" novalidate>
                <mat-form-field appearance="standard">
                    <mat-label>Bank Account Name</mat-label>
                    <input matInput type="text" minlength="3" formControlName="bankAccountName">
                    <mat-error *ngIf="bankInfoForm.get('bankAccountName').hasError('required')">
                        Bank Account Name is required
                    </mat-error>
                    <mat-error *ngIf="bankInfoForm.get('bankAccountName').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>Bank Account Number</mat-label>
                    <input matInput type="text" minlength="3" formControlName="bankAccountNumber">
                    <mat-error *ngIf="bankInfoForm.get('bankAccountNumber').hasError('required')">
                        Bank Account Number is required
                    </mat-error>
                    <mat-error *ngIf="bankInfoForm.get('bankAccountNumber').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="w-50-p">
                    <mat-label>Bank Name</mat-label>
                    <input matInput type="text" minlength="3" formControlName="bankName">
                    <mat-error *ngIf="bankInfoForm.get('bankName').hasError('required')">
                        Bank Name is required
                    </mat-error>
                    <mat-error *ngIf="bankInfoForm.get('bankName').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="w-50-p">
                    <mat-label>Bank IFSC</mat-label>
                    <input matInput type="text" minlength="3" formControlName="bankIfsc">
                    <mat-error *ngIf="bankInfoForm.get('bankIfsc').hasError('required')">
                        Bank IFSC is required
                    </mat-error>
                    <mat-error *ngIf="bankInfoForm.get('bankIfsc').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="w-50-p">
                    <mat-label>Bank Branch</mat-label>
                    <input matInput type="text" minlength="3" formControlName="bankBranch">
                    <mat-error *ngIf="bankInfoForm.get('bankBranch').hasError('required')">
                        Bank Branch is required
                    </mat-error>
                    <mat-error *ngIf="bankInfoForm.get('bankBranch').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="w-50-p">
                    <mat-label>Bank Account Type</mat-label>
                    <input matInput type="text" minlength="3" formControlName="bankAccountType">
                    <mat-error *ngIf="bankInfoForm.get('bankAccountType').hasError('required')">
                        Bank Account Type is required
                    </mat-error>
                    <mat-error *ngIf="bankInfoForm.get('bankAccountType').hasError('minlength')">
                        Minimum 3 character
                    </mat-error>
                </mat-form-field>
                <mat-error class="custom-error" *ngIf="bankInfoFormError" fxLayoutAlign="center">
                    Server Error. Please Try again.
                </mat-error>
                <strong class="custom-success green-800-fg" *ngIf="!bankInfoFormError && !bankInfoFormLoading && bankInfoFormSubmitted" fxLayoutAlign="center">
                    Bank Information Updated Successfully.
                </strong>
                <div class="text-right">
                    <button mat-raised-button color="accent" class="submit-button" aria-label="Update" [disabled]="bankInfoForm.invalid">
                        Update
                    </button>
                    <button mat-raised-button color="default" (click)="bankFormEditToggle()" class="ml-8">Cancel</button>
                </div>
            </form>
            <mat-progress-bar *ngIf="bankInfoFormLoading" mode="indeterminate"></mat-progress-bar>
        </div>
        <!-- Preferences Information -->
        <div class="profile-box info-box" fxLayout="column">
            <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="title">Preferences</div>
            </header>
            <div class="content">
                <div class="info-line">
                    <div class="title">
                        Doorstep
                        <mat-slide-toggle class="ml-8" (change)="doorstepChange(doorstepFlag)" [(ngModel)]="doorstepFlag">
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="info-line">
                    <div class="title">
                        Open Days
                    </div>
                    <div class="info-line">
                        <ul class="open-days-list">
                            <li class="open-days-list-item" *ngFor="let data of openDays; let i = index">
                                <mat-checkbox name="{{data.index}}" [(ngModel)]="data.checked" labelPosition="after" (change)="openDaysChange(data.index)">
                                    {{data.day}}
                                </mat-checkbox>
                            </li>
                        </ul>
                    </div>
                </div>
                <mat-error class="custom-error" *ngIf="doorstepError || openDaysError" fxLayoutAlign="center">
                    Server Error. Please Try again.
                </mat-error>
                <strong class="custom-success green-800-fg" *ngIf="!doorstepError && !doorstepLoading && doorstepSubmitted" fxLayoutAlign="center">
                    Doorstep Preference Updated.
                </strong>
                <strong class="custom-success green-800-fg" *ngIf="!openDaysError && !openDaysLoading && openDaysSubmitted" fxLayoutAlign="center">
                    Open Days Preference Updated.
                </strong>
            </div>
            <mat-progress-bar *ngIf="doorstepLoading || openDaysLoading" mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
</div>