import { Component, OnInit } from '@angular/core';
// import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AspLoginService } from './asp-login.service';
import { MatDialog } from '@angular/material/dialog';
import {AspSwitchGarageComponent} from '../asp-switch-garage/asp-switch-garage.component';
import {LoginService} from '../../authentication/login/login.service';


@Component({
  selector: 'app-asp-login',
  templateUrl: './asp-login.component.html',
  styleUrls: ['./asp-login.component.scss']
})
export class AspLoginComponent implements OnInit {
  
  loginForm: FormGroup;  
  requestOTPForm: FormGroup;  
  loading = false;
  hide: any;
  enableOTP = false;
  isMobileDisabled: boolean = true;
  showLogin = false;
  spareLoginNumber = '';
  

  constructor(
    private router: Router,
    // public dialog:MatDialogRef<AspLoginComponent>,
    private _formBuilder: FormBuilder,
    private aspLoginService: AspLoginService, 
    private toastr: ToastrService,    
    public dialog: MatDialog,
    private garageLoginService:LoginService,
  ) { 
    // dialog.disableClose = true;
  }

  ngOnInit(): void {
    this.aspLoginService.thisCheckLogin();
    // this.requestOTPForm = this._formBuilder.group({
    //   mobile: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(10)]],
    // });
    
    // this.spareLoginNumber = '9898989898';
    this.spareLoginNumber = JSON.parse(this.garageLoginService.getSpareLoginNumber());
    this.requestOTPForm = this._formBuilder.group({
      mobile: [{
        value: this.spareLoginNumber,
        disabled: this.isMobileDisabled
      }],
    });

    this.loginForm = this._formBuilder.group({
      // mobile: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(10)]],
      mobile: [{value: this.spareLoginNumber, disabled: this.isMobileDisabled}],
      otp: ['', [Validators.required, Validators.minLength(6)]]
    });
    // this.dialog.open(AspSwitchGarageComponent);
  }

  // closePopup(){
  //   this.dialog.close();
  // }

  // Request OTP
  get rf() { return this.requestOTPForm.controls; }
  requestOTP() {
    // if (this.requestOTPForm.invalid) {
    //     return;
    // }
    this.loading = true;
    this.aspLoginService.getOTP(this.rf.mobile.value).subscribe(
      res => {
        console.log(res);
        if(res['code'] && res['code']==200){
          this.enableOTP = true;
          this.loading = false;
        }
      }
    );
  }
  // End Request OTP

  // Verify OTP and login
  get f() { return this.loginForm.controls; }
  verifyOTP(){
    if (this.loginForm.invalid) {
        return;
    }
    this.loading = true;
    this.aspLoginService.login(this.f.mobile.value, this.f.otp.value).subscribe(
      res => {
        console.log(res);
        if(res && res['code']==200){
          let userKey = res['data']['userKey'];
          localStorage.setItem('spareUserKey', userKey); 

          let userType = res['data']['userType'];
          localStorage.setItem('spareUserType', userType);  

          let token = res['data']['accessToken'];
          localStorage.setItem('spareLoginToken', token);  

          let refreshToken = res['data']['refreshToken'];
          localStorage.setItem('spareRefreshToken', refreshToken);  

          // this.closePopup();
          // this.router.navigate(['auto-spare-parts/make']);
          this.toastr.success('You are successfully logged in', 'Login');
          this.dialog.open(AspSwitchGarageComponent);
          
          this.loading = false;
        }else{    
          // this.closePopup();
          this.router.navigate(['auto-spare-parts/login']);  
          this.toastr.error(res['message'], 'Error');
          
          this.loading = false;
        }
      }
    )
  }
  // End Verify OTP and login

}
