import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'app/main/authentication/login/login.service';
import { GlobalVariable } from 'app/main/globals';
import { OrderDetailsService } from 'app/main/orders/order-details/order-details.service';
import { first } from 'rxjs/operators';
@Component({
    selector: 'add-notes',
    templateUrl: 'add-notes.component.html',
    styleUrls: ['./add-notes.component.scss'],
})
export class AddNotesComponent {
    status: string;
    orderId: string;
    public addNoteForm: FormGroup;
    error = false;
    form: any;
    notesLoading = false;
    notesDataReceived = false;
    constructor(
        private bottomSheetRef: MatBottomSheetRef<AddNotesComponent>,
        private _fb: FormBuilder,
        private router: Router,
        private _activatedRoute: ActivatedRoute,
        private loginService: LoginService,
        private odService: OrderDetailsService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        this.status = data.status;
        this.orderId = data.orderId;
        this.addNoteForm = this._fb.group({
            note: ['', Validators.required]
        });
    }
    addNote() {
        this.notesLoading = true;
        this.form = this.addNoteForm.value;
        const formData: FormData = new FormData();
        formData.append('appKey', `${GlobalVariable.APP_KEY}`);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('language', `${GlobalVariable.LANGUAGE}`);
        formData.append('device', `${GlobalVariable.DEVICE}`);
        formData.append('type', 'notes');
        formData.append('notes', this.form.note);
        formData.append('orderId', this.orderId);
        formData.append('status', this.status);
        this.odService.addNotes(formData).pipe(first()).subscribe(
            result => {
                if (result.response.status === 'SUCCESS') {
                    this.error = false;
                    this.notesLoading = false;
                    this.notesDataReceived = false;
                    this.bottomSheetRef.dismiss();
                    this.router.navigate(['orders/details/' + this.orderId]);
                } else {
                    this.notesLoading = false;
                    this.error = true;
                }
            },
            error => {
                console.log(error);
                this.notesLoading = false;
                this.error = true;
            }
        );
    }
}
