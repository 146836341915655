import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspOrderListService {

  constructor(
    private http: HttpClient,
  ) { }

  orderList(userKey, garageKey){
    let paramData = {      
      userKey: userKey,
      garageKey: garageKey,
      source: 'garageweb'
    };

    console.log('-----Get Order List-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/order/getAllOrders', paramData);

  }
}
