import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginService } from 'app/main/authentication/login/login.service';
import { CreateOrderService } from 'app/main/orders/create-order/create-order.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { BookingDetailsService } from '../booking-details.service';
export interface Fuel {
    value: string;
    viewValue: string;
}
export interface ConvenienceType {
    value: string;
    viewValue: string;
}
@Component({
    host: {
        '(document:click)': 'onClick($event)',
    },
    selector: 'app-create-booking',
    templateUrl: './create-booking.component.html',
    styleUrls: ['./create-booking.component.scss'],
})
export class CreateBookingComponent implements OnInit {
    constructor(
        private _formBuilder: FormBuilder,
        private bookingService: BookingDetailsService,
        private coService: CreateOrderService,
        private loginService: LoginService,
        private router: Router,
        private dialog: MatDialog
    ) {
        this.createBookingFillData = {
            rowKey: '',
            fueltype: '',
            name: '',
            mobile: '',
            email: '',
            registrationNumber: '',
            VehicleTitle: '',
            chassiNumEntered: '',
        };
        const accountData = JSON.parse(this.loginService.getLoginData());
        this.renaultMerchantId = accountData.merchant.id;
        const renaultAccount = accountData.account.name;
        if (renaultAccount.toLowerCase().indexOf('renault') > -1) {
            this.renaultConvenience = true;
        } else {
            this.renaultConvenience = false;
        }
    }
    fuel: Fuel[] = [
        { value: 'petrol', viewValue: 'Petrol' },
        { value: 'diesel', viewValue: 'Diesel' },
        { value: 'cng/petrol', viewValue: 'CNG/Petrol' },
        { value: 'lpg/petrol', viewValue: 'LPG/Petrol' },
        { value: 'electricity', viewValue: 'Electricity' },
        { value: 'hybrid', viewValue: 'Hybrid' },
    ];
    convenienceType: ConvenienceType[] = [
        { value: 'doorstep', viewValue: 'Doorstep' },
        { value: 'pickup', viewValue: 'Pickup' },
        { value: 'self', viewValue: 'Self' },
    ];
    RenaultConvenienceType: ConvenienceType[] = [
        { value: 'pickup', viewValue: 'Pickup' },
        { value: 'self', viewValue: 'Self' },
    ];
    renaultConvenience = false;
    renaultMerchantId: any;
    accountControl = new FormControl();
    bookingCars = new FormControl();
    assigendGarageControl = new FormControl();
    vehicleControl = new FormControl();
    registrationControl = new FormControl();
    mobilenumberControl = new FormControl();
    sourceControl = new FormControl();
    filteredSource: Observable<any>;
    filteredRegistrationData: Observable<any>;
    filteredChassisData: Observable<any>;
    filteredMobileData: Observable<any>;
    filteredCar: Observable<any>;
    createBooking: FormGroup;
    leadVehicleResult: any;
    totalCars: any;
    carsResults: any;
    carArray = [];
    resultVehicle = [];
    resultRegistration = [];
    resultChassis = [];
    resultMobile = [];
    loginUserData: any;
    accountAppKey: any;
    successData: any;
    carModel = [];
    sourceData = [];
    registrationArray = [];
    ChassisArray = [];
    mobilesArray = [];
    vehicleRowKey: any;
    deliveryTime: any;
    appontmentDateTime: any;
    serviceValue: any;
    isMaintenace = false;
    isAccident = false;
    loading = false;
    successMsg = false;
    noServiceSelected = false;
    disableBookingBtn = true;
    checkVehicleVal: any;
    regiteration: any;
    sourceValue: any;
    invalidReg = false;
    invalidMobi = false;
    merchantId: any;
    status: any = 6;
    createBookingFillData: any;
    selectedVehicleRegistration: any;
    noServiceSubTypeSelected = false;
    selectedRowkey: any;
    showResultMobile = false;
    showResultChassis = false;
    searchText: any;
    searchText2: any;
    showVehicleResult = false;
    showSourceResult = false;
    selectedDropVal: any;
    selectedSearchText: any;
    public carCtrl: FormControl = new FormControl();

    public vehicleFilterCtrl: FormControl = new FormControl();

    public filteredVehicle: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public sourceCtrl: FormControl = new FormControl();

    public sourceFilterCtrl: FormControl = new FormControl();

    public filteredSourceData: ReplaySubject<any[]> = new ReplaySubject<any[]>(
        1
    );

    @ViewChild('vehicleSec', { static: true }) vehicleSec;
    @ViewChild('vehcl', { static: true }) vehcl: ElementRef;
    @ViewChild('orderDateAndTime', { static: false })
    orderDateAndTime: ElementRef;
    protected _onDestroy = new Subject<void>();
    ngOnInit() {
        this.searchText = '';
        this.filteredVehicle.next(this.carModel);
        this.vehicleFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterCars();
            });
        this.filteredSourceData.next(this.sourceData);
        this.sourceFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterSources();
            });
        this.getCarData();
        this.filteredRegistrationData = this.registrationControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterRegisterData(value))
        );
        this.filteredSource = this.sourceControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterSource(value))
        );
        this.createBooking = this._formBuilder.group({
            vehicle: ['', Validators.required],
            fuel: ['', Validators.required],
            registrationNumber: ['', Validators.required],
            convenience: ['', Validators.required],
            serviceType: ['', Validators.required],
            name: ['', Validators.required],
            email: [''],
            mobile: ['', Validators.required],
            appointmetTime: ['', Validators.required],
            expectedDeliveryTime: [''],
            notes: [''],
            serviceSubtype: [''],
            chassiNumEntered: [''],
        });
    }

    autoCloseForDropdownCars(event) {
        const target = event.target;
        if (!target.closest('.vehiclToggle')) {
            this.showVehicleResult = false;
        }
    }
    autoCloseForDropdownSource(event) {
        const target = event.target;
        if (!target.closest('.sourceToggle')) {
            this.showSourceResult = false;
        }
    }
    protected filterCars() {
        if (!this.carModel) {
            return;
        }
        let search = this.vehicleFilterCtrl.value;
        if (!search) {
            this.filteredVehicle.next(this.carModel.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredVehicle.next(
            this.carModel.filter(
                (option) => option.toLowerCase().indexOf(search) > -1
            )
        );
    }
    protected filterSources() {
        if (!this.sourceData) {
            return;
        }
        let search = this.sourceFilterCtrl.value;
        if (!search) {
            this.filteredSourceData.next(this.sourceData.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredSourceData.next(
            this.sourceData.filter(
                (option) => option.toLowerCase().indexOf(search) > -1
            )
        );
    }
    private _filterRegisterData(value: string): string[] {
        const registerValue = value.toLowerCase();
        return this.registrationArray.filter((option) =>
            option.toLowerCase().includes(registerValue)
        );
    }

    private _filterSource(value: string): string[] {
        const accountValue = value.toLowerCase();
        return this.sourceData.filter((option) =>
            option.toLowerCase().includes(accountValue)
        );
    }
    onClick(event) {
        if (event.target.nativeElement !== '_eref') {
            this.showResultMobile = false;
            this.showResultChassis = false;
        }
    }
    getServiceType(event) {
        if (this.serviceValue === 'maintenance') {
            this.isMaintenace = true;
            this.noServiceSubTypeSelected = true;
        } else {
            this.isMaintenace = false;
            this.noServiceSubTypeSelected = false;
        }
        if (this.serviceValue === 'accident') {
            this.isAccident = true;
            this.noServiceSubTypeSelected = true;
        } else {
            this.isAccident = false;
        }
    }
    getLeadByRegistration(event) {
        let registrationSearch = '';
        registrationSearch += event.target.value;
        this.bookingService
            .registrationLeadData(registrationSearch)
            .subscribe((result) => {
                this.resultRegistration = result.response.body.users;
                for (let i = 0; i < this.resultRegistration.length; i++) {
                    this.registrationArray.push(
                        this.resultRegistration[i].registrationNumber
                    );
                }
            });
    }
    selectedRegistrationValue(value) {
        this.bookingService.registrationLeadData(value).subscribe((result) => {
            this.resultRegistration = result.response.body.users;
            this.selectedVehicleRegistration = result.response.body.users[0];
            this.createBookingFillData.name =
                result.response.body.users[0].name;
            this.createBookingFillData.email =
                result.response.body.users[0].email;
            this.createBookingFillData.mobile =
                result.response.body.users[0].mobile;
            this.createBookingFillData.fueltype =
                result.response.body.users[0].fuelType;
            this.createBookingFillData.rowKey =
                result.response.body.users[0].rowKey;
            this.createBookingFillData.registrationNumber =
                result.response.body.users[0].registrationNumber;
        });
        this.coService.getVehicleModel().subscribe((result) => {
            this.resultVehicle = result.response.body.data;
            for (let i = 0; i < this.resultVehicle.length; i++) {
                this.carModel.push(this.resultVehicle[i].title);
                if (
                    this.resultVehicle[i].rowKey ===
                    this.createBookingFillData.rowKey
                ) {
                    this.selectedRowkey = this.createBookingFillData.rowKey;
                    this.createBookingFillData.VehicleTitle = this.resultVehicle[
                        i
                    ].title;
                }
            }
        });
    }
    // lead by chassis number
    getLeadByChassis(event) {
        let registrationSearch = '';
        registrationSearch += event.target.value;
        if (registrationSearch.length >= 2) {
            this.bookingService
                .chassisLeadData(registrationSearch)
                .subscribe((result) => {
                    this.resultChassis = result.response.body.users;
                    if (this.resultChassis.length > 0) {
                        this.showResultChassis = true;
                    } else {
                        this.showResultChassis = false;
                    }
                    for (let i = 0; i < this.resultChassis.length; i++) {
                        if (this.resultChassis[i].chassisNo !== undefined) {
                            this.ChassisArray.push(this.resultChassis[i]);
                        }
                    }
                });
        }
    }
    selectedChassisValue(value) {
        this.createBookingFillData.chassiNumEntered = value;
        this.bookingService.chassisLeadData(value).subscribe((result) => {
            this.resultChassis = result.response.body.users;
            this.selectedVehicleRegistration = result.response.body.users[0];
            this.createBookingFillData.name =
                result.response.body.users[0].name;
            this.createBookingFillData.email =
                result.response.body.users[0].email;
            this.createBookingFillData.mobile =
                result.response.body.users[0].mobile;
            this.createBookingFillData.fueltype =
                result.response.body.users[0].fuelType;
            this.createBookingFillData.rowKey =
                result.response.body.users[0].rowKey;
            this.createBookingFillData.registrationNumber =
                result.response.body.users[0].registrationNumber;
        });
        this.coService.getVehicleModel().subscribe((result) => {
            this.resultVehicle = result.response.body.data;
            for (let i = 0; i < this.resultVehicle.length; i++) {
                this.carModel.push(this.resultVehicle[i].title);
                if (
                    this.resultVehicle[i].rowKey ===
                    this.createBookingFillData.rowKey
                ) {
                    this.selectedRowkey = this.createBookingFillData.rowKey;
                    this.createBookingFillData.VehicleTitle = this.resultVehicle[
                        i
                    ].title;
                }
            }
        });
    }
    // get lead by mobile number
    getLeadByMobile(event) {
        let mobileSearch = '';
        mobileSearch += event.target.value;
        if (mobileSearch.length >= 8) {
            this.bookingService
                .mobileLeadData(mobileSearch)
                .subscribe((result) => {
                    this.resultMobile = result.response.body.users;
                    if (this.resultMobile.length > 0) {
                        this.showResultMobile = true;
                    } else {
                        this.showResultMobile = false;
                    }
                    for (let i = 0; i < this.resultMobile.length; i++) {
                        this.mobilesArray.push(this.resultMobile[i]);
                    }
                });
        }
    }
    getmobileNumbValue(value) {
        this.bookingService.registrationLeadData(value).subscribe((result) => {
            this.resultMobile = result.response.body.users;
            this.selectedVehicleRegistration = result.response.body.users[0];
            this.createBookingFillData.name =
                result.response.body.users[0].name;
            this.createBookingFillData.email =
                result.response.body.users[0].email;
            this.createBookingFillData.mobile =
                result.response.body.users[0].mobile;
            this.createBookingFillData.fueltype =
                result.response.body.users[0].fuelType;
            this.createBookingFillData.rowKey =
                result.response.body.users[0].rowKey;
            this.createBookingFillData.registrationNumber =
                result.response.body.users[0].registrationNumber;
        });
        this.coService.getVehicleModel().subscribe((result) => {
            this.resultVehicle = result.response.body.data;
            for (let i = 0; i < this.resultVehicle.length; i++) {
                this.carModel.push(this.resultVehicle[i].title);
                if (
                    this.resultVehicle[i].rowKey ===
                    this.createBookingFillData.rowKey
                ) {
                    this.selectedRowkey = this.createBookingFillData.rowKey;
                    this.createBookingFillData.VehicleTitle = this.resultVehicle[
                        i
                    ].title;
                }
            }
        });
    }
    getCarData() {
        this.coService.getVehicleModel().subscribe((result) => {
            this.resultVehicle = result.response.body.data;
            for (let i = 0; i < this.resultVehicle.length; i++) {
                this.carModel.push(this.resultVehicle[i].title);
            }
            result.response.body.source.forEach((data) => {
                this.sourceData.push(data.source);
            });
        });
    }
    vehicleSelected(option) {
        this.showVehicleResult = false;
        const selectedCar = option;
        this.selectedDropVal = option;
        this.selectedSearchText = option;
        this.searchText = option;
        for (let j = 0; j < this.resultVehicle.length; j++) {
            if (this.resultVehicle[j].title === selectedCar) {
                this.vehicleRowKey = this.resultVehicle[j].rowKey;
            }
        }
    }
    sourceSelected(optionSource) {
        this.sourceValue = optionSource;
        this.searchText2 = optionSource;
        this.showSourceResult = false;
    }
    checkSource(event) {
        const sourceVal = this.searchText2;
        if (sourceVal === this.sourceValue) {
            this.searchText2 = sourceVal;
        } else {
            this.searchText2 = '';
        }
    }
    checkVehicle(event) {
        this.checkVehicleVal = this.searchText;
        for (let j = 0; j < this.resultVehicle.length; j++) {
            if (this.resultVehicle[j].title === this.checkVehicleVal) {
                if (!this.selectedDropVal) {
                    this.searchText = '';
                }
            } else {
                this.searchText = '';
            }
        }
    }
    checkFuel(event) {
        const val2 = event.target.value;
    }
    checkRegister(event) {
        this.regiteration = event.target.value;
        const regValue = /^(?=.*\d)(?=.*[aA-zZ]).{3,30}$/;
        if (this.regiteration.match(regValue)) {
            this.invalidReg = false;
        } else {
            this.invalidReg = true;
        }
    }
    mobileValidate(event) {
        const mobileNum = event.target.value;
        const regValue = /^(?=.*\d)(?=.*[0-9]).{8,10}$/;
        if (mobileNum.match(regValue)) {
            this.invalidMobi = false;
        } else {
            this.invalidMobi = true;
        }
    }
    openVehicleList() {
        this.showVehicleResult = true;
    }
    openSourceList() {
        this.showSourceResult = true;
    }
    submitBookingForm() {
        const checkAppointmentDateAndTime = this.orderDateAndTime.nativeElement
            .value;
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        this.merchantId = this.loginUserData.merchant.id;
        const formData = new FormData();
        this.deliveryTime = Date.parse(
            this.createBooking.get('expectedDeliveryTime').value
        );
        this.appontmentDateTime = Date.parse(
            this.createBooking.get('appointmetTime').value
        );
        formData.append('appKey', this.accountAppKey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('status', '6');
        formData.append('source', this.sourceValue);
        formData.append('subsource', '');
        formData.append('name', this.createBooking.get('name').value);
        formData.append('mobile', this.createBooking.get('mobile').value);
        formData.append('email', this.createBooking.get('email').value);
        if (this.selectedRowkey) {
            formData.append('rowKey', this.selectedRowkey);
        } else {
            formData.append('rowKey', this.vehicleRowKey);
        }
        formData.append('fuelType', this.createBooking.get('fuel').value);
        formData.append(
            'registrationNumber',
            this.createBooking.get('registrationNumber').value
        );
        formData.append('notes', this.createBooking.get('notes').value);
        formData.append(
            'convenienceType',
            this.createBooking.get('convenience').value
        );
        formData.append('nextContactDate', '');
        formData.append('pickupTime', this.appontmentDateTime);
        formData.append('prospectId', '');
        formData.append('expectedDeliveryTime', this.deliveryTime);
        formData.append(
            'serviceType',
            this.createBooking.get('serviceType').value
        );
        formData.append(
            'serviceSubtype',
            this.createBooking.get('serviceSubtype').value
        );
        formData.append('merchantId', this.merchantId);
        if (
            this.sourceValue === '' ||
            this.createBooking.get('serviceType').value === undefined ||
            this.createBooking.get('convenience').value === '' ||
            this.createBooking.get('registrationNumber').value === '' ||
            this.createBooking.get('fuel').value === '' ||
            this.createBooking.get('mobile').value === '' ||
            this.createBooking.get('name').value === '' ||
            checkAppointmentDateAndTime === ''
        ) {
            this.noServiceSelected = true;
        } else {
            this.loading = true;
            this.noServiceSelected = false;
            this.bookingService.createBooking(formData).subscribe((result) => {
                this.successData = result;
                if (this.successData.response.status === 'SUCCESS') {
                    this.successMsg = true;
                    this.loading = false;
                    setTimeout(() => {
                        this.successMsg = false;
                        this.dialog.closeAll();
                        this.router.navigate(['/bookings/new-bookings']);
                    }, 3000);
                }
            });
        }
    }
}
