import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as moment from "moment-timezone";
import { AppComponent } from "app/app.component";
import { LoginService } from "app/main/authentication/login/login.service";
import { LiveOrdersService } from "./live-orders.service";
import { PickupStatus, DoorstepStatus } from "app/main/globals";

const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

export interface LiveOrdersData {
    orderId: string;
    registration: string;
    fuelType: string;
    vehicle: string;
    orderStartTime: any;
    orderEndTime: any;
    grandTotal: string;
    status: string;
}

@Component({
    selector: "live-orders",
    templateUrl: "./live-orders.component.html",
    styleUrls: ["./live-orders.component.scss"],
})
export class LiveOrdersComponent implements OnInit {
    token: any;
    liveOrders: any;
    liveOrdersList: any;
    liveOrdersData: any = [];
    liveOrdersSource: MatTableDataSource<LiveOrdersData>;
    liveOrdersColumns: string[] = [
        "orderId",
        "registration",
        "orderStartTime",
        "orderEndTime",
        "grandTotal",
        "status",
    ];
    renderedData: any;
    noData = false;
    dialogId: number;
    rDialogData: any;
    dataCSV: any;
    timezone: any;
    format: any;
    moment = moment();
    isSingapore = false;
    @ViewChild("TABLE", { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    /**
     * Constructor
     * @param {Router} router
     * @param {LoginService} loginService
     * @param {liveOrdersService} rService
     * @param {Papa} papa
     */
    constructor(
        public router: Router,
        private loginService: LoginService,
        private loService: LiveOrdersService,
        public dialog: MatDialog,
        private appComponent: AppComponent
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(["/auth/login"]);
        }
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
        this.getLiveOrdersData();
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                this.router.navigated = false;
            }
        });
    }

    ngOnInit() {
        if (this.loginService.getTimezone().toString() === "Asia/Singapore") {
            this.isSingapore = true;
        } else {
            this.isSingapore = false;
        }
    }
    getLiveOrdersData() {
        this.liveOrdersList = this.loService.liveOrders;
        if (this.liveOrdersList.response.status === "SUCCESS") {
            this.liveOrders = this.liveOrdersList.response.body.orders;
            if (this.liveOrders.length === 0) {
                this.noData = true;
            } else {
                this.liveOrders.forEach((liveOrderList) => {
                    this.liveOrdersData.push(
                        this.createLiveOrdersData(liveOrderList)
                    );
                });
                this.liveOrdersSource = new MatTableDataSource(
                    this.liveOrdersData
                );
                this.liveOrdersSource
                    .connect()
                    .subscribe((d) => (this.renderedData = d));
                setTimeout(() => {
                    this.liveOrdersSource.paginator = this.paginator;
                    this.liveOrdersSource.sort = this.sort;
                }, 500);
            }
        } else {
            this.appComponent.openSnackBar("Session Timeout", "Redirecting...");
            setTimeout(() => {
                this.loginService.logout();
            }, 1000);
        }
    }
    applyFilter(filterValue: string) {
        this.liveOrdersSource.filter = filterValue.trim().toLowerCase();
        if (this.liveOrdersSource.paginator) {
            this.liveOrdersSource.paginator.firstPage();
        }
    }
    createLiveOrdersData(data: any): LiveOrdersData {
        let reg = "";
        const delivery = "";
        const startTime = "";
        let status = "";
        if (data.orderType.toString() === "doorstep") {
            status = DoorstepStatus[data.status - 1];
        } else {
            status = PickupStatus[data.status - 1];
        }
        if (data.registration) {
            reg = data.registration.toString();
        }
        return {
            orderId: data.orderId.toString(),
            registration: reg,
            fuelType: data.fuelType.toString(),
            vehicle: data.model.toString(),
            orderStartTime: data.orderStartTime,
            orderEndTime: data.orderEndTime,
            grandTotal: parseFloat(data.grandTotal).toFixed(2),
            status: status.toString(),
        };
    }
    exportEXCEL() {
        // for (let i = 0; i < this.renderedData.length; i++) {
        //     if (this.renderedData[i].orderStartTime.indexOf('<br>') > 0) {
        //         this.renderedData[i].orderStartTime = this.renderedData[i].orderStartTime.replace(/<br\s*\/?>/gi, ' ');
        //     }
        //     if (this.renderedData[i].orderEndTime.indexOf('<br>') > 0) {
        //         this.renderedData[i].orderEndTime = this.renderedData[i].orderEndTime.replace(/<br\s*\/?>/gi, ' ');
        //     }
        // }
        this.dataCSV = this.liveOrdersData;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            this.dataCSV
        );
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        this.saveAsExcelFile(excelBuffer, "liveOrders_list");
        setTimeout(() => {
            this.router.navigate(["orders/live-orders"]);
        }, 100);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }
    openDetails(id: number) {
        this.router.navigate(["orders/details/", id]);
    }
}
