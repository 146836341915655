import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AspHomeService } from './asp-home.service';
import { ToastrService } from 'ngx-toastr';
import { AspLoginService } from '../asp-login/asp-login.service';

@Component({
  selector: 'app-asp-home',
  templateUrl: './asp-home.component.html',
  styleUrls: ['./asp-home.component.scss']
})
export class AspHomeComponent implements OnInit {
  loading: boolean = false;
  spareSelectedGarageName: any;
  brands: any = [];
  categories: any = [];
  allCategories: any = [];
  veiwMoreCat: boolean = true;
  veiwLessCat: boolean = false;  
  formData: any = {}; 

  constructor(
    private router: Router,
    private homeService: AspHomeService,
    public toastr: ToastrService,
    private aspLoginService: AspLoginService,
  ) { }

  ngOnInit(): void {    
    // this.loading = true;
    this.aspLoginService.checkLogin();
    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName");
    this.getPopularBrandsAndCategory();
  }

  goToMake = () => {
    this.clearSelectedBrandCat();
    
    this.router.navigate(['auto-spare-parts/make']);
  }

  getPopularBrandsAndCategory = () => {
    this.loading = true;
    this.homeService.popularBrandsAndCategory().subscribe(
      res => {
        // console.log(res);
        if(res && res['code']==200){
          this.loading = false;

          if(res['data']['brands'])
            this.brands = res['data']['brands'];

          if(res['data']['categories'])
            this.categories = res['data']['categories'];

        }else{    
          // this.closePopup();
          // this.router.navigate(['auto-spare-parts/home']);  
          this.toastr.error(res['message'], 'Error');
          
          this.loading = false;
        }
      }
    )
  }

  goToAllBrands = () => {
    this.router.navigate(['auto-spare-parts/all-brands']);
  }

  getAllCategories = () => {
    this.loading = true;
    this.homeService.getAllCategories().subscribe(
      res => {
        // console.log(res);
        if(res && res['code']==200){
          this.loading = false;
          this.veiwMoreCat = false;
          this.veiwLessCat = true;

          if(res['data']){
            this.allCategories = res['data'].sort((a, b) => a.priority - b.priority);
          }
          console.log(this.allCategories);
        }else{    
          this.toastr.error(res['message'], 'Error');          
          this.loading = false;
        }
      }
    )
  }

  viewLessBrands = () => {    
    this.veiwMoreCat = true;
    this.veiwLessCat = false;
  }

  brandSparesList = (brand:any, brandImg:any) => {
    this.clearSelectedBrandCat();

    localStorage.setItem("selectedBrand",  brand);   
    localStorage.setItem("selectedBrandImg",  brandImg);   
    localStorage.setItem("vehicleType",  'universal');   
    this.router.navigate(["auto-spare-parts/brand-spares-list"]);
  }

  categorySparesList = (brand:any, brandImg:any) => {
    this.clearSelectedBrandCat();
      
    localStorage.setItem("selectedCategory",  brand);   
    localStorage.setItem("selectedCategoryImg",  brandImg);  
    localStorage.setItem("vehicleType",  'universal');    
    this.router.navigate(["auto-spare-parts/category-spares-list"]);
  }

  clearSelectedBrandCat(){
    if(localStorage.getItem("selectedBrand")){
      localStorage.removeItem('selectedBrand');
    }
    
    if(localStorage.getItem("selectedBrandImg")){
      localStorage.removeItem('selectedBrandImg');
    }
    
    if(localStorage.getItem("selectedCategory")){
      localStorage.removeItem('selectedCategory');
    }

    if(localStorage.getItem("selectedCategoryImg")){
      localStorage.removeItem('selectedCategoryImg');
    }
  }

  onSubmit() {
    if(this.formData.partNumber){
      localStorage.removeItem('searchPartNumber');
      localStorage.setItem("searchPartNumber",  this.formData.partNumber);  
      
      this.clearSelectedBrandCat();
      this.router.navigate(['auto-spare-parts/spare-parts-list']);
    }
  }

}
