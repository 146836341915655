<div id="register" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="register-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="assets/images/logos/fuse.svg">
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            Welcome to the FUSE!
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat,
            vel convallis elit fermentum pellentesque. Sed mollis velit facilisis facilisis viverra.
        </div>

    </div>

    <div id="register-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="register-form">

            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/fuse.svg">
            </div>

            <div class="title">CREATE AN ACCOUNT</div>

            <form name="registerForm" [formGroup]="registerForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>
                        Name is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="registerForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password (Confirm)</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="registerForm.get('passwordConfirm').hasError('required')">
                        Password confirmation is required
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('passwordConfirm').hasError('required') &&
                                       registerForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Passwords must match
                    </mat-error>
                </mat-form-field>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" aria-label="Accept" required>
                        <span>Accept</span>
                    </mat-checkbox>
                    <a href="#">terms and conditions</a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="CREATE AN ACCOUNT"
                    [disabled]="registerForm.invalid">
                    CREATE AN ACCOUNT
                </button>

            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Already have an account?</span>
                <a class="link" [routerLink]="'/auth/login'">Login</a>
            </div>
        </div>
    </div>
</div>