<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="garage-selected">
        <div class="garage-icon"><mat-icon>build</mat-icon></div>              
        <div class="garage-name">{{spareSelectedGarageName}}</div>      
    </div>
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="payment mt-8 p-8">            
        <h2 class="payment-title">{{'PAYMENT_INFO.TITLE' | translate}}</h2>

        <div class="payment-left">
            <div class="location-sec">
                <div class="payment-card"> 
                    <div class="location-icon"><mat-icon> location_on</mat-icon></div>
                    <div class="location-content">
                        <div class="location-title">{{'PAYMENT_INFO.SHIPPING_ADDRESS' | translate}}</div>
                        <div class="location-details">
                            {{shippingAddress}}
                        </div>
                    </div>
                </div>
            </div> 

            <div class="payment-sec">
                <div class="payment-card"> 
                    <div class="payment-title">{{'PAYMENT_INFO.MODE' | translate}}</div>
                    <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="1" (click)="getPaymentMode(1)">
                            <div class="cod-btn"> 
                                <mat-icon>account_balance_wallet</mat-icon>
                                <span>{{'PAYMENT_INFO.MOD_COD' | translate}}</span>
                            </div>
                        </mat-radio-button>
                        <mat-radio-button value="2" (click)="getPaymentMode(2)">
                            <div class="cod-btn"> 
                                <mat-icon>payment</mat-icon>
                                <span *ngIf="!prepayUpiDiscount">{{'PAYMENT_INFO.MOD_ONLINE' | translate}}</span>
                                <span *ngIf="prepayUpiDiscount">{{'PAYMENT_INFO.MOD_UPI' | translate}}</span>
                            </div>
                        </mat-radio-button>
                        <div *ngIf="prepayUpiDiscount" class="online-payment-sub-heading">
                            <img src="{{discountPercentIcon}}" />
                            {{prepayUpiDiscount}}
                        </div>
                    </mat-radio-group>

                </div>
            </div> 
        </div>
        
        <div class="payment-right">
            <div #target  class="price-details-sec" [@shakeit]="this.states['shake']" (@shakeit.done)="shakeEnd('shake', $event)">
                <div class="payment-card"> 
                    <div class="price-details-title">{{'PRICE_INFO.PRICE_DETAILS' | translate}}</div>
                    <div class="price-details">
                        <div class="price-details-left">{{'PRICE_INFO.SPARES_TOTAL' | translate}}</div>
                        <div class="price-details-right">&#8377;{{cartSubTotal | number : '1.2-2'}}</div>
                    </div>

                    <!-- Spares Discount -->
                    <div class="price-details" *ngIf="sparesDiscount>0">
                        <div class="price-details-left">{{'PRICE_INFO.SPARES_DISCOUNT' | translate}}</div>
                        <div class="price-details-right" id="discount">- &#8377;{{sparesDiscount | number : '1.2-2'}}</div>
                    </div>

                    <!-- Coupon Discount -->
                    <!-- <div class="price-details" >
                        <div class="price-details-left">Coupon Discount</div>
                        <div class="price-details-right" id="coupon-discount">-100</div>
                    </div>   -->
                             
                    <!-- Shipping Charges -->
                    <div *ngIf="isShippingCharges=='true'" class="price-details" id="shipping-charges">
                        <div class="price-details-left">{{'PRICE_INFO.SHIPPING_CHARGES' | translate}} <mat-icon (click)="shippingChargesBottomSheet()">info</mat-icon></div>
                        <div *ngIf="shippingChargesApplicable==true" class="price-details-right">&#8377;{{shippingCharges | number : '1.2-2'}}</div>
                        <div *ngIf="shippingChargesApplicable==false" class="price-details-right">
                            <span class="mrp-price">&#8377;{{shippingCharges | number : '1.2-2'}}</span>
                            <span id="free-shipping">{{'CART_INFO.FREE' | translate}}</span>
                        </div>
                    </div>  

                    <div class="price-details" id="total">
                        <div class="price-details-left">{{'PRICE_INFO.TOTAL' | translate}}</div>
                        <div class="price-details-right">&#8377;{{cartGrandTotal | number : '1.2-2'}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="confirm-order-sec" *ngIf="paymentMethod && initiatedPayment!=true && shippingAddressId && billingAddressId">
        <div class="grand-total">&#8377;{{cartGrandTotal | number : '1.2-2'}}</div>
        <div class="view-details">
            <a (click)="scroll(target)">{{'PAYMENT_INFO.VIEW_DETAILS' | translate}}</a>
        </div>
        <button class="confirm-order-btn" (click)="confirmOrder(paymentMethod)">
            <span *ngIf="paymentButtonText=='Proceed To Pay'">{{'PAYMENT_INFO.PROCEED_TO_PAY' | translate}}</span>
            <span *ngIf="paymentButtonText=='Confirm Order'">{{'PAYMENT_INFO.CONFIRM_ORDER' | translate}}</span>
        </button>
    </div>
</div>