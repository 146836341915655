import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/main/globals';
import { LoginService } from 'app/main/authentication/login/login.service';
import { map } from 'rxjs/operators';
import { promise } from 'protractor';
import { reject } from 'q';
const backDate = (numOfDays) => {
    const today1 = new Date();
    return new Date(today1.setDate(today1.getDate() - numOfDays));
};
@Injectable({ providedIn: 'root' })
export class DashboardOrdersService implements Resolve<any>
{

    paramData: any;
    dashboardData: any[];
    accountAppKey: any;
    loginData: any;
    merchantId: any;
    header: any;
    orderDashboardResult: any = [];
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {
      
    }
    private cData(res: Response) {
        const body = res;
        return body || {};
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {

            Promise.all([
                this.orderDashboardData()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );

        });
    }

    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
 

    orderDashboardData(): Promise<any> {
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        this.merchantId = this.loginData.merchant.id;
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'merchantId': this.merchantId,
            'type': 'gms'
        };
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            this.http.post<any>(`${GlobalVariable.BASE_API_URL}` + '/b2bgetorderandjobstats', formData)
                .subscribe((response: any) => {
                    this.orderDashboardResult = response;
                    resolve(response);
                }, reject);
        });

    }
    calendarOrdersData(): Observable<any> {
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        this.merchantId = this.loginData.merchant.id;
       
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'merchantId': this.merchantId,
            'type': 'gms',
            'subType': 'calender'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/b2bgetorderandjobstats', formData);
       
    }
}
