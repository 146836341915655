import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspSparesListService {

  constructor(
    private http: HttpClient,
  ) { }


  getAllSpares(rowKey, variantKey, searchKey, garageKey){
    let paramData = {      
      rowKey: rowKey,
      variantKey: variantKey,
      searchKey: searchKey,
      garageKey: garageKey
    };

    // console.log("------------paramData");
    // console.log(paramData);

    console.log('-----Get All Spares List-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/getAll?page=0&size=1000', paramData);
  }

  getItemStock(sku){
    console.log('-----Get Item Stock-----------');   
    return this.http.patch(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/inventory/item/sync/'+sku, {});
  }

  getSparesByPartNumber(partNumber, garageKey){
    let paramData = {      
      authKey: GlobalVariable.AUTH_KEY,
      garageKey: garageKey
    };

    console.log('-----Get Spares By Part Number-----------');   
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/detail/'+partNumber, paramData);
  }
}
