import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { RouterModule, Routes } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { DailyOrdersComponent } from './daily-orders.component';
import { DailyOrdersService } from './daily-orders.service';

const routes: Routes = [
    {
        path: 'daily-orders',
        component: DailyOrdersComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
            data: DailyOrdersService
        }
    }
];

@NgModule({
    declarations: [
        DailyOrdersComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseWidgetModule,
        FuseSharedModule,
        HttpClientModule,
        MatListModule,
        MatDividerModule,
        MatCardModule,
        MatGridListModule,
        MatDialogModule,
        MatChipsModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatProgressBarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatStepperModule,
        NgxMatDrpModule,
        ChartsModule,
        NgxChartsModule,
    ],
    exports: [
        DailyOrdersComponent
    ],
    providers: [
        DailyOrdersService
    ],
})

export class DailyOrdersModule {
}
