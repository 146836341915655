import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { LoginService } from 'app/main/authentication/login/login.service';
import { DailyOrdersService } from './daily-orders.service';
import { BaseChartDirective, Label } from 'ng2-charts';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment-timezone';

import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const backDate = (numOfDays) => {
    const today1 = new Date();
    return new Date(today1.setDate(today1.getDate() - numOfDays));
};
export interface DailyOrdersData {
    BR: string;
    GMS: string;
    GST: string;
    Labour: string;
    PMS: string;
    Parts: string;
    Total: string;
    account: string;
    accountId: string;
    delivery_date: string;
    makeModel: string;
    merchant_name: string;
    order_id: string;
    order_start_date: string;
    registration_number: string;
    serviceType: string;
    vehicle_status: string;
}
@Component({
    selector: 'daily-orders',
    templateUrl: './daily-orders.component.html',
    styleUrls: ['./daily-orders.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class DailyOrdersComponent implements OnInit {
    token: any;
    loading = false;
    revenueClass = '';
    range: Range = { fromDate: new Date(), toDate: new Date() };
    options: NgxDrpOptions;
    presets: Array<PresetItem> = [];
    noData = false;
    dailyOrderData: any;
    doData: any;
    DOData: any;
    dailyOrdersData: any = [];
    dailyOrdersDataSource: MatTableDataSource<DailyOrdersData>;
    dailyOrdersDataColumns: string[] = [
        'order_id', 'order_start_date', 'account', 'makeModel', 'registration_number',
        'serviceType', 'delivery_date', 'Parts', 'Labour', 'GST', 'Total',
        'BR', 'GMS', 'PMS', 'accountId', 'merchant_name', 'vehicle_status'];
    renderedData: any;
    dataCSV: any;
    timezone: any;
    format: any;
    moment = moment();
    widgets: any;
    loginUserData: any;
    isSingapore = false;
    public dData: Array<any> = [];
    public dLabels: Array<any> = [];
    public lineChartData: Array<any> = [];
    public lineChartLabels: Array<any> = [];
    public lineChartOptions: any = {
        responsive: true
    };
    public lineChartLegend = false;
    public lineChartType = 'line';
    dailReport = false;
    multi: any[];
    view: any[] = [700, 400];
    uniqueDateData=[];
    dateCount= [];
    addOne: any[];
    result: any[];
    barChartData= [];
    // options
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = true;
    xAxisLabel = 'Time';
    showYAxisLabel = true;
    yAxisLabel = 'Orders';
    ttlOrderArray = [] ;
    colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };
    public single = [
        {
          'name': 'pednig',
          'value': 100
        },
        {
            'name': 'on',
            'value': 210
        },
        {
            'name': 'ser',
            'value': 440
        }
      ];
    //   bar chart
    public barChartOptions: ChartOptions = {
        responsive: true,
        scales: { xAxes: [{}], yAxes: [{}] },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
          }
        }
      };
      public barChartLabels: Label[] = [];
      public barChartType: ChartType = 'bar';
      public barChartLegend = false;
      public barChartPlugins = [];

      public barChartOrderData: ChartDataSets[] = [
        // { data: [65, 59, 80, 81, 56, 55, 40] },
      ];

    @ViewChild('dateRangePicker', { static: false }) dateRangePicker;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
    constructor(
        private router: Router,
        private loginService: LoginService,
        private doService: DailyOrdersService
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        const today = new Date();
        const minus60 = backDate(60);
        this.setupPresets();
        this.options = {
            presets: this.presets,
            format: 'mediumDate',
            range: { fromDate: minus60, toDate: today },
            applyLabel: 'Get Reports',
            calendarOverlayConfig: {
                shouldCloseOnBackdropClick: true
            }
        };
        this.widgets = this.doService.dailyOrderData;
        // if (this.widgets.response.status === 'SUCCESS') {
        //     this.widgets.response.body.orderDetail.forEach(data => {
        //         this.dData.push(data.Total.toString());
        //     });
        //     this.widgets.response.body.orderClosureDateList.forEach(data => {
        //         this.lineChartLabels.push(data.split(' ')[0].toString());
        //     });
        //     this.lineChartData.push({ 'data': this.dData, 'label': '' });
        // }
        // else {
        //     this.router.navigate(['auth/login']);
        // }

    }
    updateRange(range: Range) {
        this.loading = true;
        this.range = range;
        const from = new Date(this.range.fromDate);
        const to = new Date(this.range.toDate);
        if (from.getTime() <= to.getTime()) {
            this.doService.getDailyOrdersData(this.range.fromDate, this.range.toDate)
                .pipe(first())
                .subscribe(
                    data => {
                        this.doData = data;
                        if (this.doData && this.doData.response.status === 'SUCCESS' && this.doData.response.body.orderDetail !== 0) {
                            this.DOData = this.doData.response.body.orderDetail;
                            console.log(this.DOData);
                            const closeData = this.doData.response.body.orderClosureDateList;
                            this.dData = [];
                            this.doData.response.body.orderDetail.forEach( data => {
                                this.dData.push(data.Total.toString());
                            });
                            this.lineChartLabels = [];
                            this.doData.response.body.orderClosureDateList.forEach( data => {
                                this.lineChartLabels.push(data.split(' ')[0].toString());
                            });
                            const distinct=(value,index,self)=>{
                                return self.indexOf(value)===index;
                            }
                               this.uniqueDateData = this.lineChartLabels.filter(distinct);
                               this.barChartLabels = this.uniqueDateData.sort();

                            // this.lineChartData.push({ 'data': this.DOData, 'label': '' });
                            if (this.DOData) {
                                this.dailReport = true;
                            }
                            this.initDailyOrdersData(this.DOData);
                            this.getDateOccurence(this.barChartLabels,this.lineChartLabels);
                            this.getDates(this.range.fromDate, this.range.toDate);


                        } else {
                            this.noData = true;
                            this.loading = false;
                            this.dailReport = false;
                        }
                    },
                    error => {
                        this.noData = true;
                        this.loading = false;
                    }
                );
        } else {
            alert('"From Date" cannot be greater than "To Date"');
            this.loading = false;
        }
    }
    getDates(start, end) {
        this.lineChartLabels = [];
        this.barChartLabels = [];
        this.dData=[];
        const datesMap = new Map();
        const startDate = new Date(start);
        while (startDate <= end) {
            datesMap.set(new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000 )).toISOString().split("T")[0], 0 );
            this.barChartLabels.push(new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000 )).toISOString().split("T")[0]);
            startDate.setDate(startDate.getDate() + 1);
         }
        //  console.log(this.barChartLabels);
        const mergedDateOrder=[];
        for (let i = 0; i < this.uniqueDateData.length; i++) {
           mergedDateOrder.push({date:this.uniqueDateData[i], order:this.dateCount[i]});
        }
    //    console.log(mergedDateOrder);
        for (let index = 0; index < mergedDateOrder.length; index++){
            const element = mergedDateOrder[index];
                    for (const key of datesMap.keys() ) {
                        if (mergedDateOrder[index].date === key)
                        {
                            datesMap.set(key, element.order);
                        }
                        }
            }
        // console.log(datesMap);
        for (const dateRevenue of datesMap.values() ) {
            this.dData.push(dateRevenue);
            this.ttlOrderArray.push(dateRevenue);
        }
//    console.log(this.ttlOrderArray);
    }
    getDateOccurence(a1, a2) {
        this.dateCount= [];
        this.ttlOrderArray = [];
        for(let i = 0; i < a1.length; i++) {
            let count = 0;
          for(let z = 0; z < a2.length; z++) {
            if (a2[z] === a1[i]) {
                count++;
            }
          }
         this.dateCount.push(count);
        }
        // console.log(this.dateCount);
        this.barChartOrderData = [];
        this.barChartOrderData.push({data: this.ttlOrderArray});
        // console.log(this.barChartOrderData);

      }
    setupPresets() {
        const today = new Date();
        const yesterday = backDate(1);
        const minus7 = backDate(7);
        const minus30 = backDate(30);
        const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        this.presets = [
            { presetLabel: 'Yesterday', range: { fromDate: yesterday, toDate: today } },
            { presetLabel: 'Last 7 Days', range: { fromDate: minus7, toDate: today } },
            { presetLabel: 'Last 30 Days', range: { fromDate: minus30, toDate: today } },
            { presetLabel: 'This Month', range: { fromDate: currMonthStart, toDate: currMonthEnd } },
            { presetLabel: 'Last Month', range: { fromDate: lastMonthStart, toDate: lastMonthEnd } }
        ];
    }
    ngOnInit() {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        }
        else {
            this.isSingapore = false;
        }
    }
    initDailyOrdersData(DOData: any) {
        DOData.forEach(doList => {
            this.dailyOrdersData.push(this.createDailyOrdersData(doList));
        });
        console.log(this.dailyOrdersData);

        this.dailyOrdersDataSource = new MatTableDataSource(this.dailyOrdersData);
        this.dailyOrdersDataSource.connect().subscribe(d => this.renderedData = d);
        for (let i = 0; i < this.renderedData.length; i++) {
            if (this.renderedData[i].makeModel.indexOf('<br>') > 0) {
                this.renderedData[i].makeModel = this.renderedData[i].makeModel.replace(/<br\s*\/?>/gi, ' ');
            }
            if (this.renderedData[i].delivery_date.indexOf('<br>') > 0) {
                this.renderedData[i].delivery_date = this.renderedData[i].delivery_date.replace(/<br\s*\/?>/gi, ' ');
            }
            if (this.renderedData[i].order_start_date.indexOf('<br>') > 0) {
                this.renderedData[i].order_start_date = this.renderedData[i].order_start_date.replace(/<br\s*\/?>/gi, ' ');
            }
        }
        this.dataCSV = this.renderedData;
        setTimeout(() => {
            this.dailyOrdersDataSource.paginator = this.paginator;
            this.dailyOrdersDataSource.sort = this.sort;
        }, 500);
        this.noData = false;
        this.loading = false;
    }
    initChart(chartData: any) {
        this.dData = [];
        this.emptyLineChart();
        chartData.forEach(data => {
            this.widgets.response.body.orderDetail.forEach(data1 => {
                this.dData.push(data1.Total.toString());
            });
            this.widgets.response.body.orderClosureDateList.forEach(data2 => {
                this.lineChartLabels.push(data2.split(' ')[0].toString());
            });
        });
        this.updateLineChart();
        this.loading = false;
    }
    applyFilter(filterValue: string) {
        this.dailyOrdersDataSource.filter = filterValue.trim().toLowerCase();
        if (this.dailyOrdersDataSource.paginator) {
            this.dailyOrdersDataSource.paginator.firstPage();
        }
    }
    createDailyOrdersData(data: any): DailyOrdersData {
        const startDate = new Date(data.order_start_date);
        const deliveryDate = new Date(data.delivery_date);
        return {
            BR: data.BR ? data.BR.toString() : '',
            GMS: data.GMS ? data.GMS.toString() : '',
            GST: data.GST ? data.GST.toString() : '',
            Labour: data.Labour ? data.Labour.toString() : '',
            PMS: data.PMS ? data.PMS.toString() : '',
            Parts: data.Parts ? data.Parts.toString() : '',
            Total: data.Total ? data.Total.toString() : '',
            account: data.account ? data.account.toString() : '',
            accountId: data.accountId ? data.accountId.toString() : '',
            delivery_date: moment(deliveryDate).format('ddd D') + '<br>' + moment(deliveryDate).format('MMM YYYY'),
            makeModel: data.make ? data.make.toString() : '' + '<br>' + data.model ? data.model.toString() : '',
            merchant_name: data.merchant_name ? data.merchant_name.toString() : '',
            order_id: data.order_id ? data.order_id.toString() : '',
            order_start_date: moment(startDate).format('ddd D') + '<br>' + moment(startDate).format('MMM YYYY'),
            registration_number: data.registration_number ? data.registration_number.toString() : '',
            serviceType: data.serviceType,
            vehicle_status: data.vehicle_status ? data.vehicle_status.toString() : '',
        };
    }
    exportEXCEL() {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'daily_orders_report');
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    emptyLineChart() {
        this.lineChartLabels.length = 0;
        this.lineChartData.length = 0;
    }
    updateLineChart() {
        this.chart.chart.config.data.labels = this.lineChartLabels;
        this.chart.chart.config.data.datasets = this.lineChartData;
        this.chart.chart.config.type = this.lineChartType;
        this.chart.chart.update();
        if (this.chart !== undefined) {
            this.chart.ngOnDestroy();
            this.chart.chart = this.chart.getChartBuilder(this.chart.ctx);
        }
    }
}
