import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariable } from 'app/main/globals';
import { LoginService } from 'app/main/authentication/login/login.service';

@Injectable({ providedIn: 'root' })
export class SearchOrdersService {
    searchOrders: any[];
    paramData: any;
    accountAppKey: any;
    loginUserData: any;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {
    }
    private searchedData(res: Response) {
        const body = res;
        return body || {};
    }
    getOrders(condition: string, query: string): Observable<any> {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        let idQuery = '';
        let mobileQuery = '';
        let regNumQuery = '';
        let statusQuery = '';
        switch (condition) {
            case 'id':
                idQuery = query;
                break;
            case 'mobile':
                mobileQuery = query;
                break;
            case 'registrationNumber':
                regNumQuery = query;
                break;
            case 'status':
                statusQuery = query;
                break;
            default:
                idQuery = '';
                mobileQuery = '';
                regNumQuery = '';
                statusQuery = '';
        }
        // this.paramData = {
        //     appKey: this.accountAppKey,
        //     sessionId: this.loginService.getToken(),
        //     language: `${GlobalVariable.LANGUAGE}`,
        //     device: `${GlobalVariable.DEVICE}`,
        //     id: idQuery,
        //     mobile: mobileQuery,
        //     registrationNumber: regNumQuery,
        //     statuses: statusQuery
        // };
        // return this.http.get(`${GlobalVariable.BASE_API_URL}` + '/gmssearchordersv2', {
        //     params: this.paramData
        // }).pipe(map(this.searchedData));
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'id': idQuery,
            'mobile': mobileQuery,
            'registrationNumber': regNumQuery,
            'statuses': statusQuery
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/gmssearchordersv2', formData);
    }
    getNotifications(offset){
        let accountAppKey;
        let  merchantId;
        if(this.loginService.getLoginData()){
        const loginUserData = JSON.parse(this.loginService.getLoginData());
         accountAppKey = loginUserData.account.appKey;
         merchantId = loginUserData.merchant.id;
        }
       const formData = new FormData();
       const data = {
        appKey: accountAppKey,
        sessionId: this.loginService.getToken(),
        merchantId: merchantId,
        offset: offset,
       };
       formData.append('sessionPost', JSON.stringify(data));
       return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/gmsgetgargenotification', formData);
    }
    postDeactivatedNOtify(messageId){
        const loginUserData = JSON.parse(this.loginService.getLoginData());
        const accountAppKey = loginUserData.account.appKey;
        const merchantId = loginUserData.merchant.id;
       const formData = new FormData();
       const data = {
        appKey: accountAppKey,
        sessionId: this.loginService.getToken(),
        merchantId: merchantId,
        ids: messageId,
       };
       formData.append('sessionPost', JSON.stringify(data));
       return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/gmsupdategargenotification', formData);
    }
    
}
