<div id="login" class="inner-scroll" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center">

    <div id="login-intro" fxFlex>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="assets/images/logos/logo.svg">
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            Welcome to <strong>GARNET 2.0</strong>
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            Garnet 2.0 is a cloud based web platform that helps you manage your garage on the net. Manage your service orders, keep your finances and accounting organised and analyse your business reports on the go.
            <!-- <br><br>
            <em>powered by <strong><a class="warn-500-fg gif_loader" href="https://www.getpitstop.com/" target="_blank">
                        <img width="200" src="assets/images/logos/pitstop.gif"></a></strong></em> -->
        </div>

    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="login-form">
            <div class="title">LOGIN</div>
            <form [class.loading]="loading" name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput formControlName="username">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error *ngIf="loginForm.get('username').hasError('required')">
                        Username is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="password-input">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" [type]="hide ? 'text' : 'password'">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>
                <!-- <mat-error class="custom-error" *ngIf="loginError" fxLayoutAlign="center">
                    Invalid Credentials. Please Try again.
                </mat-error> -->
                <mat-error class="custom-error" *ngIf="loginError" fxLayoutAlign="center">
                    {{notOwner}}
                </mat-error>
                <mat-error class="custom-error" *ngIf="serverError" fxLayoutAlign="center">
                    Server Error. Please Try again.
                </mat-error>
                <!-- <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"> -->
                <!-- <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Remember Me
                    </mat-checkbox> -->

                <!-- <a class="forgot-password" [routerLink]="'/auth/forgot-password'">
                        Forgot Password?
                    </a>
                </div> -->
                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN" [disabled]="loginForm.invalid">
                    LOGIN
                </button>
            </form>
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <!-- <div class="separator">
                <span class="text">OR</span>
            </div>

            <div fxLayout="column" fxLayoutAlign="start center">

                <button mat-raised-button class="google">
                    Log in with Google
                </button>

                <button mat-raised-button class="facebook">
                    Log in with Facebook
                </button>

            </div> -->

            <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/auth/register'">Create an account</a>
            </div> -->
        </div>
    </div>
</div>