<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="content">
        <div class="left">
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="100">
                    <span fxLayout="row wrap" fxLayoutAlign="start center" class="mat-display-1 m-0 p-16 pl-0">GST
                        Reports</span>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="25">
                    <div class="fuse-card m-0">
                        <div class="p-0" fxLayout="row wrap" fxLayoutAlign="start center">
                            <div class="p-16 pb-0 w-100-p" fxLayout="row wrap" fxLayoutAlign="start center">
                                <ngx-mat-drp color="accent" (selectedDateRangeChanged)="updateRange($event)" [options]="options" #dateRangePicker></ngx-mat-drp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="mat-elevation-z8 mt-8" *ngIf="!noData">
        <div class="p-12 pb-0 pt-0 export-block gst_date" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <div class="gst_itr">
                    <button [disabled]="noData" (click)="exportEXCEL()" mat-button color="accent" class="gstr_one common_btn">
                    GSTR1
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>
                    <button [disabled]="noData" (click)="exportEXCEL2()" mat-button color="accent" class="common_btn">
                    GSTR2
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>
                    <a class="gst_link" href="https://services.gst.gov.in/services/login"> File GST</a>

                </div>

            </div>
            <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table mat-table [dataSource]="gstDataSource" matSort matSortActive="orderid" matSortDirection="desc" class="gst_table" matSortDisableClear>
            <ng-container matColumnDef="cgst">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> <strong>CGST</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'"> <strong>{{row.cgst}}</strong>
                </td>
            </ng-container>

            <ng-container matColumnDef="cgst_rate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'">
                    <strong>CGST<br>RATE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    <strong>{{row.cgst_rate}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="gst" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>GST</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">&#8377; {{row.gst}} </td>
            </ng-container>
            <ng-container matColumnDef="gst" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>GST</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">S&#36; {{row.gst}} </td>
            </ng-container>
            <ng-container matColumnDef="gst_rate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'">
                    <strong>GST<br>RATE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    <strong>{{row.gst_rate}}</strong> </td>
            </ng-container>
            <ng-container matColumnDef="igst">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> <strong>IGST</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'"> <strong>{{row.igst}}</strong>
                </td>
            </ng-container>
            <ng-container matColumnDef="igst_rate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'">
                    <strong>IGST<br>RATE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    <strong>{{row.igst_rate}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="invoice_title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>INVOICE<br>TITLE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.invoice_title}} </td>
            </ng-container>

            <ng-container matColumnDef="item_description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'">
                    <strong>ITEM<br>DESCRIPTION</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    <strong>{{row.item_description}}</strong> </td>
            </ng-container>

            <!-- <ng-container matColumnDef="item_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ITEM<br>NAME</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.item_name}} </td>
            </ng-container> -->

            <ng-container matColumnDef="item_quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ITEM<br>QUANTITY</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.item_quantity}} </td>
            </ng-container>

            <ng-container matColumnDef="item_tax_code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> <strong>ITEM<br>TAX
                        CODE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    <strong>{{row.item_tax_code}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="item_tax_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> <strong>ITEM<br>TAX
                        TYPE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    <strong>{{row.item_tax_type}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="item_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'">
                    <strong>ITEM<br>TYPE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    <strong>{{row.item_type}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="itemtotal" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ITEM<br>TOTAL</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>&#8377; {{row.itemtotal}}</strong> </td>
            </ng-container>
            <ng-container matColumnDef="itemtotal" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ITEM<br>TOTAL</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong> S&#36; {{row.itemtotal}}</strong> </td>
            </ng-container>
            <ng-container matColumnDef="itemPrice" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ITEM<br>PRICE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">&#8377; {{row.itemPrice}} </td>
            </ng-container>
            <ng-container matColumnDef="itemPrice" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ITEM<br>PRICE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> S&#36; {{row.itemPrice}} </td>
            </ng-container>
            <ng-container matColumnDef="makeModel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>MAKE<br>MODEL</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" innerHTML="{{row.makeModel == '' ? '-' : row.makeModel}}">
                </td>
            </ng-container>

            <ng-container matColumnDef="orderid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER<br>ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.orderid}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> <strong>PRICE</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'"> <strong>{{row.price}}</strong>
                </td>
            </ng-container>

            <ng-container matColumnDef="registration_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>REG.<br>NUMBER</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.registration_number == '' ? '-' : row.registration_number}}
                </td>
            </ng-container>

            <ng-container matColumnDef="servicecompletedate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>SERVICE<br>COMPLETED</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" innerHTML="{{row.servicecompletedate == '' ? '-' : row.servicecompletedate}}"></td>
            </ng-container>

            <ng-container matColumnDef="sgst" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> <strong>SGST</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'"> <strong>{{row.sgst}}</strong>
                </td>
            </ng-container>
            <ng-container matColumnDef="sgst" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> <strong>SGST</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'"> <strong>{{row.sgst}}</strong>
                </td>
            </ng-container>
            <ng-container matColumnDef="sgst_rate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'">
                    <strong>SGST<br>RATE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row" [style.display]="'none'">
                    <strong>{{row.sgst_rate}}</strong> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="gstDataColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: gstDataColumns;"></tr>
        </table>

        <mat-paginator class="order_paginator" [pageSizeOptions]="[10, 20]" showFirstLastButtons *ngIf="!noData"></mat-paginator>

    </div>
    <div *ngIf="noData" class="bg_color_border margn_top">
        <div class="txt_center">
            <mat-error *ngIf="!gstNotConfig"> Report has not been generated</mat-error>
            <mat-error *ngIf="gstNotConfig">GST not configured</mat-error>
        </div>
    </div>
</div>