<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="content">
        <div class="left">
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between start">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="24">
                    <span fxLayout="row wrap" fxLayoutAlign="start center" class="mat-display-1 m-0 p-16 pl-0">Payouts</span>
                </div>
                <div *ngIf="payoutsSource" class="widget stat" fxFlex="100" fxFlex.gt-sm="24" (click)="applyFilters('')" [class.mat-elevation-z8]="allActive">
                    <div class="stat-card stat_card_payout">
                        <div class="stat-card__desc">All</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            {{netPayouts == 0 ? '0' : netPayouts | currency:"&#8377; "}}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            {{netPayouts == 0 ? '0' : netPayouts | currency:"S&#36; "}}
                        </div>
                    </div>
                </div>
                <!-- <div class="widget stat clickable" fxFlex="100" fxFlex.gt-sm="24" (click)="applyFilters('pending')"
                    [class.mat-elevation-z8]="pendingActive">
                    <div class="stat-card stat-card--red">
                        <div class="stat-card__desc">Pending</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            {{pending == 0 ? '0' : pending | currency:"&#8377; "}}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            {{pending == 0 ? '0' : pending | currency:"&#36; "}}
                        </div>
                    </div>
                </div> -->
                <div class="widget stat clickable" fxFlex="100" fxFlex.gt-sm="24" (click)="applyFilters('paid')" [class.mat-elevation-z8]="paidActive">
                    <!-- <div class="stat-card stat-card--green">
                        <div class="stat-card__desc">Paid</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            {{paid == 0 ? '0' : paid | currency:"&#8377; "}}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            {{paid == 0 ? '0' : paid | currency:"&#36; "}}
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8 mt-8 bg_color_border margn_top" *ngIf="payoutsSource">
        <div class="p-12 pb-0 export-block" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <button [disabled]="noData" class="common_btn" (click)="exportEXCEL()" mat-button color="accent">
                    Export
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>
            </div>
            <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table class="clickable" mat-table [dataSource]="payoutsSource" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear>
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.id}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>DATE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.created}}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.amount == 0 ? '-' : row.amount | currency:"&#8377; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="amount" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.amount == 0 ? '-' : row.amount | currency:"S&#36; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="commission" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>COMMISSION</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.commission == 0 ? '-' : row.commission | currency:"&#8377; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="commission" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>COMMISSION</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.commission == 0 ? '-' : row.commission | currency:"S&#36; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="commissionGst" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>COMMISSION GST</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.commissionGst == 0 ? '-' : row.commissionGst | currency:"&#8377; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="commissionGst" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>COMMISSION GST</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.commissionGst == 0 ? '-' : row.commissionGst | currency:"S&#36; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="payout" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>PAYOUT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.payout == 0 ? '-' : row.payout | currency:"&#8377; "}}</td>
            </ng-container>
            <ng-container matColumnDef="payout" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>PAYOUT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.payout == 0 ? '-' : row.payout | currency:"S&#36; "}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="payoutsColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: payoutsColumns;" (click)="openDialog(row.id)"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons *ngIf="payoutsSource"></mat-paginator>

    </div>
    <div *ngIf="!payoutsSource" class="bg_color_border margn_top">
        <div class="text-center">
            <mat-error>No Data to display</mat-error>
        </div>
    </div>
</div>