import { Component, Inject, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { ReceivablesService } from 'app/main/accounts/receivables/receivables.service';
import { LoginService } from 'app/main/authentication/login/login.service';
import { fuseAnimations } from '@fuse/animations';
import { GlobalVariable } from 'app/main/globals';
import { Lightbox } from 'ngx-lightbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ReceivableDialogData {
    id: string;
    rdata: any[];
}

@Component({
    selector: 'receivable-details',
    templateUrl: './receivable-details.component.html',
    styleUrls: ['./receivable-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})

export class ReceivableDetailsComponent {
    uploader: FileUploader;
    response: string;
    receivableDetails: any;
    url: any;
    filesData: any;
    loading = false;
    fileError = false;
    dataError = false;
    serverError = false;
    selectedFiles = [];
    _albums = [];
    updateData = {};
    cdnUrls: Array<any> = [];
    items: any[] = [];
    hide: any;
    allComplete = false;
    urls = [];
    isSingapore = false;
    @ViewChild('filesToUpload', { static: false }) filesToUpload: ElementRef;
    @ViewChild('image', { static: false }) image: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<ReceivableDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReceivableDialogData,
        private rService: ReceivablesService,
        private loginService: LoginService,
        public router: Router,
        private _lightbox: Lightbox,
        private sanitizer: DomSanitizer
    ) {
        this.receivableDetails = data.rdata;
        console.log(this.receivableDetails);
        this.uploader = new FileUploader({ url: '' });
        this.urls = [];
        this._albums = [];
        this.uploader.onAfterAddingFile = (file) => {
            this.selectedFiles.push(file);
            this.urls.push(this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file._file))));
            const sFiles = {
                src: this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file._file)))
            };
            this._albums.push(sFiles);
        };
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        }
        else {
            this.isSingapore = false;
        }
    }
    // ngOnInit (){
    //     if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
    //         this.isSingapore = true;
    //     }
    //     else {
    //         this.isSingapore = false;
    //     }
    // }
    open(index: number): void {
        this._lightbox.open(this._albums, index);
    }

    close(): void {
        this._lightbox.close();
    }

    removeImgEntry(id: number) {
        this.urls.splice(id, 1);
        this._albums.splice(id, 1);
        this.selectedFiles.splice(id, 1);
        console.log(this.selectedFiles);
    }

    uploadInvoices(id: number, orderId: number, orderType: string) {
        this.loading = true;
        const formData: FormData = new FormData();
        if (!this.fileError) {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                formData.append('fileToUpload[]', this.selectedFiles[i]._file, this.selectedFiles[i]._file.name);
            }
        }
        formData.append('appKey', `${GlobalVariable.APP_KEY}`);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('entity', 'order');
        formData.append('id', this.receivableDetails.id.toString());
        formData.append('orderId', this.receivableDetails.orderId.toString());
        formData.append('identifier', '');
        formData.append('tag', this.receivableDetails.orderType.toString());
        formData.append('description', '');
        formData.append('process', '');
        formData.append('status', '1');
        this.rService.uploadReceivableInvoice(formData)
            .pipe(first())
            .subscribe(
                data => {
                    if (data !== 500 || data.status === '200') {
                        const resultData: FormData = new FormData();
                        this.cdnUrls.push(data.cdn_link.toString());
                        this.items.push({
                            'payoutId': id.toString(),
                            'status': '3',
                            'approvalSignImageUrl': '',
                            'invoiceImageUrl': this.cdnUrls.toString()
                        });
                        this.updateData['items'] = this.items;
                        resultData.append('appKey', `${GlobalVariable.APP_KEY}`);
                        resultData.append('sessionId', this.loginService.getToken());
                        resultData.append('language', `${GlobalVariable.LANGUAGE}`);
                        resultData.append('device', `${GlobalVariable.DEVICE}`);
                        resultData.append('data', JSON.stringify(this.updateData));
                        this.rService.updateReceivables(resultData).pipe(first()).subscribe(
                            result => {
                                if (result.response.status === 'SUCCESS') {
                                    if (result.response.body.changed === '1') {
                                        this.allComplete = true;
                                        this.loading = false;
                                        this.onNoClick();
                                    }
                                }
                            },
                            error => {
                                console.log(error);
                            }
                        );
                    } else {
                        this.dataError = true;
                        setTimeout(() => {
                            this.dataError = false;
                        }, 2000);
                    }
                },
                error => {
                    this.loading = false;
                    this.serverError = true;
                    setTimeout(() => {
                        this.serverError = false;
                    }, 2000);
                });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
