import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GlobalVariable } from "app/main/globals";
import { LoginService } from "app/main/authentication/login/login.service";
@Injectable({ providedIn: "root" })
export class OrderDetailsService implements Resolve<any> {
    id: number;
    liveOrderDetails: any[];
    paramData: any;
    loginUserData: any;
    accountAppKey: any;
    private extractData(res: Response) {
        const body = res;
        return body || {};
    }
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(private http: HttpClient, private loginService: LoginService) {}
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            this.id = route.params.id;
            Promise.all([this.getOrderDetails()]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
    updateFCMToken(token: string) {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;

        const data = {
            appKey: this.accountAppKey,
            sessionId: this.loginService.getToken(),
            registrationId: "",
            deviceId: "",
            userKey: this.loginUserData.agentUserKey,
            agentId: this.loginUserData.agent,
            webRegistrationId: token,
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/registeragentdevice",
            formData
        );
    }
    getOrderDetails(): Promise<any> {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        const data = {
            appKey: this.accountAppKey,
            sessionId: this.loginService.getToken(),
            language: `${GlobalVariable.LANGUAGE}`,
            device: `${GlobalVariable.DEVICE}`,
            orderId: this.id,
            type: "web",
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return new Promise((resolve, reject) => {
            this.http
                .post(`${GlobalVariable.BASE_API_URL}` + "/hgetorder", formData)
                .subscribe((response: any) => {
                    this.liveOrderDetails = response;
                    resolve(response);
                }, reject);
        });
    }
    getPaymentLog() {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        const data = {
            appKey: this.accountAppKey,
            sessionId: this.loginService.getToken(),
            language: `${GlobalVariable.LANGUAGE}`,
            device: `${GlobalVariable.DEVICE}`,
            orderId: this.id,
            type: "web",
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/gmspaymentlog",
            formData
        );
    }
    getQuotationData() {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;

        const data = {
            appKey: this.accountAppKey,
            sessionId: this.loginService.getToken(),
            language: `${GlobalVariable.LANGUAGE}`,
            device: `${GlobalVariable.DEVICE}`,
            orderId: this.id,
            type: "gms",
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/gstquotation",
            formData
        );
    }
    addQuotation(formData: any): Observable<any> {
        return this.http
            .post(`${GlobalVariable.BASE_API_URL}` + "/gstquotation", formData)
            .pipe(map(this.extractData));
    }
    addNotes(formData: any): Observable<any> {
        return this.http
            .post(
                `${GlobalVariable.BASE_API_URL}` + "/hupdateorderstatus",
                formData
            )
            .pipe(map(this.extractData));
    }
    addImages(formData: any): Observable<any> {
        return this.http
            .post(
                `${GlobalVariable.BASE_FILE_UPLOAD_URL}` +
                    "/process-upload.php",
                formData
            )
            .pipe(map(this.extractData));
    }
    updateInvoice(formData: any): Observable<any> {
        return this.http
            .post(`${GlobalVariable.BASE_API_URL}` + "/hupdateorder", formData)
            .pipe(map(this.extractData));
    }
    applyCoupon(formData: any) {
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/b2bapplydiscount",
            formData
        );
    }
    removeCoupon(formData: any) {
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/removediscount",
            formData
        );
    }
    getJobCards(orderKey: any) {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        const data = {
            appKey: this.accountAppKey,
            sessionId: this.loginService.getToken(),
            orderKey: orderKey,
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/getjobcard",
            formData
        );
    }
    assignTechnician(formData: any): Observable<any> {
        return this.http
            .post(
                `${GlobalVariable.BASE_API_URL}` + "/gmsupdateorder",
                formData
            )
            .pipe(map(this.extractData));
    }
    assignTechnicianPickupOrder(formData: any): Observable<any> {
        return this.http
            .post(
                `${GlobalVariable.BASE_API_URL}` + "/gmsupdateorder",
                formData
            )
            .pipe(map(this.extractData));
    }
    changeOrderStatus(formData: any) {
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/hupdateorderstatus",
            formData
        );
    }
    getInvoiceData(id: number) {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;

        const data = {
            appKey: this.accountAppKey,
            sessionId: this.loginService.getToken(),
            language: `${GlobalVariable.LANGUAGE}`,
            device: `${GlobalVariable.DEVICE}`,
            orderId: id,
            type: "gms",
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/gstinvoice",
            formData
        );
    }
    updateAddress(formData) {
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/gmsupdatecustomeraddress",
            formData
        );
    }
    getItemCategoy(): Observable<any> {
        let appkey;
        if (this.loginService.getLoginData()) {
            const loginsData = JSON.parse(this.loginService.getLoginData());
            appkey = loginsData.account.appKey;
        }

        const paramData = {
            appKey: appkey,
            sessionId: this.loginService.getToken(),
        };
        return this.http.get(
            `${GlobalVariable.BASE_API_URL}` + "/gmsgetitemcategories",
            { params: paramData }
        );
    }
}
