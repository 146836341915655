import { AgmCoreModule } from '@agm/core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ProfileAboutComponent } from 'app/main/profile/about/about.component';
import { ProfileSettingsComponent } from 'app/main/profile/settings/settings.component';
import { ChartsModule } from 'ng2-charts';
import { ProfileComponent } from './profile.component';
import { ProfileService } from './profile.service';
const routes: Routes = [
    {
        path: 'profile',
        component: ProfileComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
            data: ProfileService
        },
    }
];
@NgModule({
    declarations: [
        ProfileComponent,
        ProfileAboutComponent,
        ProfileSettingsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseWidgetModule,
        BrowserAnimationsModule,
        MatListModule,
        MatDividerModule,
        MatCardModule,
        MatGridListModule,
        MatDialogModule,
        MatChipsModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatProgressBarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatStepperModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatBottomSheetModule,
        MatMenuModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        MatSlideToggleModule,
        ChartsModule,
        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyA-FwkEuPz6efVsc-NszVSD-Fgo4-gXcvA',
            libraries: ['places'],
            region: 'IN'
        })
    ],
    providers: [ProfileService],
    exports: [
        ProfileComponent
    ]
})
export class ProfileModule {
}
