import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { GlobalVariable } from 'app/main/globals';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AspLoginService {
  paramData: any;


  constructor(
    private http: HttpClient, 
    private router: Router,
  ) { }

  getOTP(mobile) {
    this.paramData = {
      mobile: mobile,
      // device: `${GlobalVariable.DEVICE}`
    };
    
    console.log('-----Request OTP 11-----------');
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/login/v2/getOtp', this.paramData);
    
  }

  login(mobile, otp){
    this.paramData = {
      mobile: mobile,
      otp: otp
    };
    console.log(this.paramData);
    console.log('-----Login 11-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/login/v2/validateOtp', this.paramData);
  }

  thisCheckLogin(){
    let spareLoginToken = localStorage.getItem('spareLoginToken');
    if(!spareLoginToken){
      this.router.navigate(['auto-spare-parts/login']);
    }else{
      this.router.navigate(['auto-spare-parts/home']);
    }
  }

  checkLogin(){
    let spareLoginToken = localStorage.getItem('spareLoginToken');
    if(!spareLoginToken){
      this.router.navigate(['auto-spare-parts/login']);
    }
  }

}
