import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { AuthenticationGuard } from 'app/main/authentication/authentication.guard';
import { ChartsModule } from 'ng2-charts';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { CalendarService } from './calendar.service';
import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './dashboard.service';
import { CalendarEventFormDialogComponent } from './event-form/event-form.component';
const routes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
            data: DashboardService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        DashboardComponent,
        CalendarEventFormDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseWidgetModule,
        BrowserAnimationsModule,
        MatProgressBarModule,
        NgxMatDrpModule,
        ChartsModule,
        NgxChartsModule,
        MatListModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        NgbModule,
        FlatpickrModule.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        })
    ],
    providers: [DashboardService, CalendarService],
    exports: [
        DashboardComponent,
        CalendarEventFormDialogComponent
    ],
    entryComponents: [
        CalendarEventFormDialogComponent
    ]
})

export class DashboardModule {
}
