import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AspModelService} from './asp-model.service';
import {AspLoginService} from '../asp-login/asp-login.service';

@Component({
  selector: 'app-asp-model',
  templateUrl: './asp-model.component.html',
  styleUrls: ['./asp-model.component.scss']
})
export class AspModelComponent implements OnInit {
  loading:boolean = false;
  selectedModel: any;
  searchText:string = '';
  modelList:any;
  openSearch:boolean = true;
  clearSearch:boolean = false;
  selectedData = '';
  spareSelectedGarageName: any;
  selectedMake: any;

  constructor(
    private router: Router,
    private aspModelService: AspModelService,
    private aspLoginService: AspLoginService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.aspLoginService.checkLogin();

    if(localStorage.getItem('newMake'))
      this.selectedMake = localStorage.getItem('newMake');
    else
      this.selectedMake = localStorage.getItem('selectedMake');

    if(localStorage.getItem('selectedModel')){
      this.selectedData = localStorage.getItem('selectedModel');
    }

    this.getModelList();
    
    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName");
  
  }

  // Get Model List   
  getModelList(){
    let result = this.aspModelService.getModel();
    this.loading = false;
    let res = JSON.parse(result); 
    this.modelList = res.data[this.selectedMake];
  }  
  // End Get Model List

  searchFilterType(){
    this.openSearch = false;
    this.clearSearch = true;
  }

  clearSearchFilter(){
    this.openSearch = true;
    this.clearSearch = false;
    this.searchText = '';
  }

  
  selectModel(model: any) {
    if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant'))
      localStorage.setItem('newModel', model); 
    else
      localStorage.setItem('selectedModel', model); 

    this.router.navigate(['/auto-spare-parts/year']);

  }

  goToMake(){      
    this.router.navigate(['/auto-spare-parts/make']);
  }
    
}