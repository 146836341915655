<h2 class="headi_ng">
    UPDATE BOOKING DETAILS
    <button mat-raised-button matDialogClose>
        <span class="cancel_icon"
            ><i class="material-icons">
                cancel
            </i></span
        >
    </button>
</h2>
<mat-progress-bar
    *ngIf="loading"
    mode="indeterminate"
    class="prog_ress"
></mat-progress-bar>
<!-- ----------------------stepper-------------------- -->

<mat-horizontal-stepper #stepper class="update_stepper">
    <mat-step [stepControl]="updateBookingForm">
        <form [formGroup]="updateBookingForm">
            <ng-template matStepLabel class="label_heading"
                >Booking Details</ng-template
            >
            <div class="update_fields sourc_sectn">
                <span
                    class="full_fields sourceToggle border_inpt"
                    (click)="openSourceList()"
                    ><span *ngIf="!editLeadResult.source">Select Source </span
                    ><span *ngIf="editLeadResult.source">{{
                        editLeadResult.source
                    }}</span></span
                >
                <div class="vehicle_ps sourceToggle">
                    <div
                        class="suggestedResult_booking search_fild bkng_fild"
                        *ngIf="showSourceResult"
                        (window:mouseup)="autoCloseForDropdownSource($event)"
                    >
                        <mat-form-field class="full_fields sourceToggle">
                            <input
                                type="text"
                                class="ddd"
                                placeholder="Search Source"
                                aria-label="Number"
                                matInput
                                #vehicleSec
                                [formControl]="sourceControl"
                                [(ngModel)]="searchText"
                            />
                        </mat-form-field>
                        <ul>
                            <li
                                *ngFor="
                                    let source of accountData
                                        | filter: searchText;
                                    let i = index
                                "
                                (click)="sourceSelected(source)"
                                #vehcl
                            >
                                <p class="chassis_p">{{ source }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="update_fields sourc_sectn">
                <span
                    class="full_fields vehiclToggle border_inpt"
                    (click)="openVehicleList()"
                    ><span *ngIf="!selectedSearchText && !selectedRowkeyTtitle"
                        >Select Vehicle
                    </span>
                    <span *ngIf="selectedRowkeyTtitle && !selectedSearchText">{{
                        selectedRowkeyTtitle
                    }}</span>
                    <span *ngIf="selectedSearchText">{{
                        selectedSearchText
                    }}</span>
                </span>
                <div class="vehicle_ps vehiclToggle">
                    <div
                        class="suggestedResult_booking search_fild"
                        *ngIf="showVehicleResult"
                        (window:mouseup)="autoCloseForDropdownCars($event)"
                    >
                        <mat-form-field class="full_fields vehiclToggle">
                            <input
                                type="text"
                                class="ddd"
                                placeholder="Search Vehicle"
                                aria-label="Number"
                                matInput
                                #vehicleSec
                                [formControl]="vehicleControl"
                                [(ngModel)]="searchText"
                                (click)="openVehicleList()"
                            />
                        </mat-form-field>
                        <ul>
                            <li
                                *ngFor="
                                    let vehicle of carModel
                                        | filter: searchText;
                                    let i = index
                                "
                                (click)="vehicleSelected(vehicle)"
                                #vehcl
                            >
                                <p class="chassis_p">{{ vehicle }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <mat-form-field class="update_fields">
                <mat-label>Select Fuel</mat-label>
                <mat-select
                    formControlName="fuel"
                    [(ngModel)]="editLeadResult.fuelType"
                >
                    <mat-option *ngFor="let fuel of fuel" [value]="fuel.value">
                        {{ fuel.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="update_fields">
                <input
                    matInput
                    type="text"
                    class="cps_text"
                    placeholder="Registration No."
                    formControlName="registrationNumber"
                    #vehReg
                    [(ngModel)]="editLeadResult.registrationNumber"
                    required
                    (change)="checkRegistraion($event)"
                />
            </mat-form-field>
            <mat-form-field class="update_fields" *ngIf="!renaultConvenience">
                <mat-label>Convenience Type</mat-label>
                <mat-select
                    formControlName="convenience"
                    [(ngModel)]="editLeadResult.convenienceType"
                >
                    <mat-option
                        *ngFor="let convenience of convenienceType"
                        [value]="convenience.value"
                    >
                        {{ convenience.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field
                class="full_fields"
                required
                *ngIf="renaultConvenience"
            >
                <mat-label>Convenience Type</mat-label>
                <mat-select
                    formControlName="convenience"
                    [(ngModel)]="convenienceFilled"
                >
                    <mat-option
                        *ngFor="let convenience of RenaultConvenienceType"
                        [value]="convenience.value"
                    >
                        {{ convenience.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <h4 class="serv_type">
                Service Type <small class="s_require">*</small>
            </h4>
            <mat-radio-group
                aria-label="Select an option"
                class="radio_grp"
                required
                [(ngModel)]="editLeadResult.serviceType"
                name="radio"
                formControlName="serviceType"
                (change)="getServiceType($event)"
            >
                <mat-radio-button value="general_service"
                    >General</mat-radio-button
                >
                <mat-radio-button value="maintenance" *ngIf="renewal"
                    >Maintenance</mat-radio-button
                >
                <mat-radio-button value="accident" *ngIf="renewal"
                    >Accident</mat-radio-button
                >
                <mat-radio-button value="inspection"
                    >Inspection</mat-radio-button
                >
                <mat-radio-button value="body_repair" *ngIf="renewal"
                    >Denting & Painting
                </mat-radio-button>
            </mat-radio-group>
            <p *ngIf="noServiceSelected">
                <mat-error>Please Select Service Type</mat-error>
            </p>
            <div *ngIf="isMaintenace || isAccident">
                <h4 class="serv_type">
                    Service Sub Type <small class="s_require">*</small>
                </h4>
                <mat-radio-group
                    aria-label="Select an option"
                    class="radio_grp"
                    formControlName="serviceSubtype"
                    [(ngModel)]="editLeadResult.serviceSubtype"
                >
                    <mat-radio-button value="repair" *ngIf="isMaintenace"
                        >Repair and Replace
                    </mat-radio-button>
                    <mat-radio-button value="insurance" *ngIf="isAccident"
                        >Insurance</mat-radio-button
                    >
                    <mat-radio-button value="noinsurance" *ngIf="isAccident"
                        >Non-Insurance
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <p *ngIf="noServiceSubTypeSelected">
                <mat-error>Please Select Service Sub Type</mat-error>
            </p>
            <div class="buttn_sec">
                <button
                    mat-button
                    matStepperNext
                    class="update_booking"
                    [disabled]="isRegistration"
                >
                    Next
                </button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="updateBookingForm2">
        <form [formGroup]="updateBookingForm2">
            <ng-template matStepLabel> Address Details</ng-template>
            <h3 class="details_cate mrg_btm">Pickup Address</h3>
            <mat-form-field class="update_fields">
                <mat-label>Colony/Street/Locality</mat-label>

                <input
                    matInput
                    matGoogleMapsAutocomplete
                    country="{{ country }}"
                    #pickupLocation
                    id="localityAddress"
                    (onAutocompleteSelected)="onAutocompleteSelected($event)"
                    (onLocationSelected)="onLocationSelected($event)"
                    formControlName="pickLocality"
                    [(ngModel)]="editLeadResult.address2"
                    (keypress)="checkSelectedAddressEnter()"
                    required
                />
            </mat-form-field>
            <mat-form-field class="update_fields">
                <input
                    matInput
                    type="text"
                    placeholder="Flat/House No./Floor/Building"
                    formControlName="pickupFlat"
                    [(ngModel)]="editLeadResult.address1"
                    required
                />
            </mat-form-field>
            <span *ngIf="selectDropdownMessage">
                <mat-error>Please select location from the drop-down</mat-error>
            </span>
            <h3 class="details_cate">Drop Address</h3>

            <mat-checkbox
                #same
                [checked]="isSamePickup"
                (change)="checkPickUpValue($event)"
                >Same as Pickup
            </mat-checkbox>
            <div *ngIf="!same.checked">
                <mat-form-field class="update_fields">
                    <mat-label>Colony/Street/Locality</mat-label>
                    <input
                        matInput
                        matGoogleMapsAutocomplete
                        [country]="de"
                        (onAutocompleteSelected)="
                            onAutocompleteSelected2($event)
                        "
                        #dropLocation
                        (onLocationSelected)="onLocationSelected2($event)"
                        formControlName="dropLocality"
                        [(ngModel)]="editLeadResult.dropAddress2"
                    />
                </mat-form-field>
                <mat-form-field class="update_fields">
                    <input
                        matInput
                        type="text"
                        placeholder="Flat/House No./Floor/Building"
                        formControlName="dropFlat"
                        [(ngModel)]="editLeadResult.dropAddress1"
                    />
                </mat-form-field>
            </div>
            <div *ngIf="same.checked" class="same_pick">
                <mat-form-field class="update_fields">
                    <mat-label>Colony/Street/Locality</mat-label>
                    <input
                        matInput
                        matGoogleMapsAutocomplete
                        [country]="de"
                        (onAutocompleteSelected)="
                            onAutocompleteSelected2($event)
                        "
                        #dropLocation
                        (onLocationSelected)="onLocationSelected2($event)"
                        formControlName="dropLocality"
                        [(ngModel)]="pickupAddress"
                    />
                </mat-form-field>
                <mat-form-field class="update_fields">
                    <input
                        matInput
                        type="text"
                        placeholder="Flat/House No./Floor/Building"
                        formControlName="dropFlat"
                        [(ngModel)]="editLeadResult.address1"
                    />
                </mat-form-field>
            </div>
            <div class="buttn_sec">
                <button mat-button matStepperPrevious class="back_booking">
                    Back
                </button>
                <button
                    mat-button
                    matStepperNext
                    class="update_booking"
                    [disabled]="updateBookingForm2.invalid || validAddress"
                >
                    Next
                </button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="updateBookingForm3">
        <form [formGroup]="updateBookingForm3">
            <ng-template matStepLabel> Appointment Details</ng-template>
            <mat-form-field class="update_fields">
                <span class="selected_time" *ngIf="showAppointmentFormat">
                    {{ appointmentTime | date: "MM/dd/yyyy - hh: mmaaa" }}</span
                >
                <input
                    [(ngModel)]="appointmentTime"
                    matInput
                    [owlDateTime]="dt1"
                    [owlDateTimeTrigger]="dt1"
                    placeholder=" Appointment Date & Time"
                    formControlName="oppointmentDate"
                    (focusout)="getAppointment()"
                    #appointmentBookingDate
                    (click)="getSelectedAppointment()"
                />
                <owl-date-time #dt1></owl-date-time>
            </mat-form-field>
            <mat-form-field class="update_fields">
                <span class="selected_time" *ngIf="showExpectedDeliveryFormat">
                    {{ exDelDates | date: "MM/dd/yyyy - hh: mmaaa" }}</span
                >
                <input
                    [(ngModel)]="exDelDates"
                    matInput
                    [owlDateTime]="dt2"
                    [owlDateTimeTrigger]="dt2"
                    placeholder=" Expected Delivery Date"
                    formControlName="oppointmentEpectDate"
                    #expectedDeliveryDates
                    (click)="getExpectedSelectedDate()"
                />
                <owl-date-time #dt2></owl-date-time>
            </mat-form-field>

            <textarea
                placeholder="Customer Notes"
                rows="3"
                class="update_fields text_width"
                formControlName="notes"
                [(ngModel)]="editLeadResult.notes"
            ></textarea>
            <mat-progress-bar
                *ngIf="succesLoading"
                mode="indeterminate"
                class="prog_ress"
            ></mat-progress-bar>
            <p *ngIf="errorMesg" class="txt_cntr">
                <mat-error>Please fill all required field</mat-error>
            </p>
            <p *ngIf="messageSuccess" class="succes_mesg">
                Booking details updated
            </p>
            <div class="buttn_sec">
                <button mat-button matStepperPrevious class="back_booking">
                    Back
                </button>
                <button
                    mat-button
                    matStepperNext
                    class="update_booking"
                    (click)="submitEditBookingForm()"
                >
                    Update Booking
                </button>
            </div>
        </form>
    </mat-step>
</mat-horizontal-stepper>
