import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { fuseAnimations } from '@fuse/animations';
import { LoginService } from 'app/main/authentication/login/login.service';
import { PayoutsService } from 'app/main/accounts/payouts/payouts.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface PayoutDialogData {
    id: string;
}

export interface PayoutDetailsData {
    orderId: string;
    grandTotal: string;
    partsTotal: string;
    labourTotal: string;
    partsCommission: string;
    labourCommission: string;
    commission: string;
    commissionGst: string;
    tdsValue: string;
    payout: string;
}

@Component({
    selector: 'payout-details',
    templateUrl: './payout-details.component.html',
    styleUrls: ['./payout-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class PayoutDetailsComponent implements OnInit {
    payoutDetails: any;
    payoutDetailsList: any;
    payoutDetailsData: any = [];
    pId: any;
    noData = false;
    renderedData: any;
    loading = false;
    dataCSV: any;
    isSingapore = false;
    payoutDetailsSource: MatTableDataSource<PayoutDetailsData>;
    payoutDetailsColumns: string[] = ['orderId', 'grandTotal', 'partsTotal', 'labourTotal',
        'partsCommission', 'labourCommission', 'commission', 'commissionGst', 'tdsValue', 'payout'];

    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(
        public dialogRef: MatDialogRef<PayoutDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PayoutDialogData,
        public router: Router,
        private pService: PayoutsService,
        private loginService: LoginService
    ) {
        this.pId = data.id;
        this.loading = true;
        this.pService.getPayout(this.pId).pipe(first()).subscribe(
            details => {
                this.getPayoutsDetails(details);
            }
        );
    }

    ngOnInit() {
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        }
        else {
            this.isSingapore = false;
        }
    }

    getPayoutsDetails(details: any) {
        this.payoutDetailsList = details;
        if (this.payoutDetailsList.response.status === 'SUCCESS') {
            this.payoutDetails = this.payoutDetailsList.response.body.payouts;
            if (this.payoutDetails.length === 0) {
                this.noData = true;
                this.loading = false;
            } else {
                this.noData = false;
                this.payoutDetails.forEach(payoutList => {
                    this.payoutDetailsData.push(this.createPayoutDetailData(payoutList));
                });
                this.payoutDetailsSource = new MatTableDataSource(this.payoutDetailsData);
                this.payoutDetailsSource.connect().subscribe(d => this.renderedData = d);
                this.dataCSV = this.renderedData;
                this.payoutDetailsSource.paginator = this.paginator;
                this.payoutDetailsSource.sort = this.sort;
                this.loading = false;
            }
        } else {
            this.loginService.logout();
        }
    }

    applyFilter(filterValue: string) {
        this.payoutDetailsSource.filter = filterValue.trim().toLowerCase();
        if (this.payoutDetailsSource.paginator) {
            this.payoutDetailsSource.paginator.firstPage();
        }
    }

    createPayoutDetailData(data: any): PayoutDetailsData {
        return {
            orderId: data.orderId.toString(),
            grandTotal: parseFloat(data.grandTotal).toFixed(2),
            partsTotal: parseFloat(data.partsTotal).toFixed(2),
            labourTotal: parseFloat(data.labourTotal).toFixed(2),
            partsCommission: parseFloat(data.partsCommission).toFixed(2),
            labourCommission: parseFloat(data.labourCommission).toFixed(2),
            commission: parseFloat(data.commission).toFixed(2),
            commissionGst: parseFloat(data.commissionGst).toFixed(2),
            tdsValue: parseFloat(data.tdsValue).toFixed(2),
            payout: parseFloat(data.payout).toFixed(2)
        };
    }

    exportEXCEL() {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'payouts_list_details');
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
