<div class="page-layout blank p-12" fusePerfectScrollbar>
    <div class="content">
        <div class="left">
            <div fxLayout="column" fxLayoutAlign="center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between start">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="24">
                    <div class="fuse-card mb-16" [class.gt-sm]="'mb-0 mr-32'">
                        <div class="p-16 pb-0" fxLayout="row wrap" fxLayoutAlign="start center">
                            <ngx-mat-drp (selectedDateRangeChanged)="updateRange($event)" [options]="options" #dateRangePicker></ngx-mat-drp>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <!-- ------------------revenue section------------- -->
    <div class="content">
        <div class="left mrgin_tabs">
            <div fxLayout="column" fxLayoutAlign="center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between start">
                <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24" (click)="dataToggle('revenue')">
                    <div [class]="revenueNum > 0 ? 'stat-card stat-card--revenue ' : 'stat-card stat-card--red '">
                        <div class="stat-card__desc">Revenue</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            &#8377; {{revenueInr }}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            S&#36; {{revenue | number }}
                        </div>
                        <div *ngIf="!isSingapore" class="discount">(+ &#8377; {{discountInr}} discount)</div>
                        <div *ngIf="isSingapore" class="discount">(+ S&#36; {{discount}} discount)</div>
                    </div>
                </div>
                <div class="widget stat" fxFlex="100" fxFlex.gt-sm="30" (click)="dataToggle('revenue')">
                    <div [class]="revenueNum > 0 ? 'stat-card stat-card--earning ' : 'stat-card stat-card--red '">
                        <div class="stat-card__desc">Earnings</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            &#8377; {{creditInr }}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            S&#36; {{credit | number }}
                        </div>
                        <div *ngIf="!isSingapore" class="discount">(+ &#8377;{{tdsInr}} TDS + &#8377;{{tcsInr}} TCS + &#8377;{{commisionGstInr}} Commission GST )</div>
                        <div *ngIf="isSingapore" class="discount">(+ S&#36; S&#36;{{tds}} TDS + S&#36;{{tcs}} TCS+ S&#36;{{commisionGst}} Commission GST)</div>
                    </div>
                </div>
                <!-- <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24" (click)="dataToggle('orders')"> -->
                <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24">
                    <div class="stat-card stat-card--order ">
                        <div class="stat-card__desc">Costs</div>
                        <div class="stat-card__stat paddng_bottom" *ngIf="!isSingapore">
                            &#8377; {{debitInr }}
                        </div>
                        <div class="stat-card__stat paddng_bottom" *ngIf="isSingapore">
                            S&#36; {{debit | number }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ------------------calendar--------------------- -->

    <div class="dash_ratings">


    </div>
    <!-- --------------pie chart------------------- -->
    <div class="chart_section dash_ratings">
        <div class="bg_color_border margn_top orderEmpty">
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <span class="stocks"> Revenue </span><br>
            <mat-divider></mat-divider><br>
            <div class="position-relative p-24 graph-box" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
                <!-- <div fxLayout.gt-sm="column" fxLayoutAlign.gt-sm="start start">
                            <span class="h2">Revenue</span>
                        </div> -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div *ngIf="hideDailyWeek" class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer" (click)="dailyData()" [ngClass]="{'accent': chartTime === 'daily'}">
                        Daily
                    </div>
                    <div *ngIf="hideDailyWeek" class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer" (click)="weeklyData()" [ngClass]="{'accent': chartTime === 'weekly'}">
                        Weekly
                    </div>
                    <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer" (click)="monthlyData()" [ngClass]="{'accent': chartTime === 'monthly'}">
                        Monthly
                    </div>
                </div>
            </div>
            <div class="graph position-relative">
                <canvas baseChart height="150vh" [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
            </div>
        </div>

        <!-- <div class="inventory_sec bg_color_border chart_sec">
            <div class="coming_soon">
                Coming Soon...
            </div>
            <p class="stocks">Stock</p>
            <mat-divider></mat-divider>
            <mat-list role="list">
                <mat-list-item role="listitem" class="dash_ratings">
                    <div class="graph_sec">
                        <span> Number of unique spare parts in stock</span>
                    </div>
                    <div class="item_um">
                        14
                    </div>
                </mat-list-item>
                <mat-list-item role="listitem" class="dash_ratings">
                    <div class="graph_sec">
                        Number of spares
                    </div>
                    <div class="item_um">
                        34
                    </div>
                </mat-list-item>
                <mat-list-item role="listitem" class="dash_ratings">
                    <div class="graph_sec">
                        Value of spares
                    </div>
                    <div class="item_um">
                        29
                    </div>
                </mat-list-item>
                <mat-list-item role="listitem" class="dash_ratings">
                    <div class="graph_sec">
                        Low inventory
                    </div>
                    <div class="item_um">
                        25
                    </div>
                </mat-list-item>
                <mat-list-item role="listitem" class="dash_ratings">
                    <div class="graph_sec">
                        <span> Out of stock</span>
                    </div>
                    <div class="item_um">
                        13
                    </div>
                </mat-list-item>
            </mat-list>
        </div> -->
        <div class="bg_color_border margn_top rating_sec">
            <h2 class="rate_h">Ratings</h2>
            <p class="rating_text">
                Feedback received from customers on closed orders!
            </p>
            <div class="ranking">
                <div>
                    <span class="trophy_img"><img src="./assets/icons/trophy.png" alt="Trophy">
                    </span><span class="rank_num">{{rank}}{{rankingNum==1?'st':rankingNum==11?'th':rankingNum==2?'nd':rankingNum==3?'third':'th'}}</span>
                </div>
                <span class="rnk_sml">Rank out of {{totalRankCount}}</span>
            </div>
            <div class="avg_rate">
                <div class="avg_text">
                    <div class="avg">
                        <h2 class="rate_h">{{avgRating | number:'1.1-1'}}</h2>
                        <span class="rnk_sml">Avg. Rating</span>
                    </div>
                    <div class="rated_num">
                        <h2 class="rate_h">{{totalRatingGiven}} </h2>
                        <span class="rnk_sml"> Ratings</span>
                    </div>
                </div>
                <div class="stars">
                    <div class="row_rate">
                        <div class="side">
                            <div>5 <i class="material-icons">
                                    star
                                </i></div>
                        </div>
                        <div class="middle">
                            <div class="bar-container">
                                <div class="bar-5" [style.width.%]="fiveRatingBar"></div>
                            </div>
                        </div>
                        <div class="side right">
                            <div>{{ratingFiveTotal}}</div>
                        </div>
                        <div class="side">
                            <div>4 <i class="material-icons">
                                    star
                                </i></div>
                        </div>
                        <div class="middle">
                            <div class="bar-container">
                                <div class="bar-4" [style.width.%]="fourRatingBar"></div>
                            </div>
                        </div>
                        <div class="side right">
                            <div>{{ratingFourTotal}}</div>
                        </div>
                        <div class="side">
                            <div>3 <i class="material-icons">
                                    star
                                </i></div>
                        </div>
                        <div class="middle">
                            <div class="bar-container">
                                <div class="bar-3" [style.width.%]="threeRatingBar"></div>
                            </div>
                        </div>
                        <div class="side right">
                            <div>{{ratingThreeTotal}}</div>
                        </div>
                        <div class="side">
                            <div>2 <i class="material-icons">
                                    star
                                </i></div>
                        </div>
                        <div class="middle">
                            <div class="bar-container">
                                <div class="bar-2" [style.width.%]="twoRatingBar"></div>
                            </div>
                        </div>
                        <div class="side right">
                            <div>{{ratingTwoTotal}}</div>
                        </div>
                        <div class="side">
                            <div>1 <i class="material-icons">
                                    star
                                </i></div>
                        </div>
                        <div class="middle">
                            <div class="bar-container">
                                <div class="bar-1" [style.width.%]="oneRatingBar"></div>
                            </div>
                        </div>
                        <div class="side right">
                            <div>{{ratingOneTotal}}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="chart_section dash_ratings">
        <div class="pie_chart bg_color_border margn_top chart_sec">
            <!-- <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"></canvas> -->
            <span class="stocks"> Orders </span><br>
            <mat-divider></mat-divider><br>
            <span> Total Orders - </span><span>{{totalOrders}}</span>
            <div class="count_order">
                <div class="order_name">
                    <p>Confirmed</p>
                    <p>{{orderConfirmed}}</p>
                </div>
                <div class="order_name">
                    <p>Ongoing</p>
                    <p>{{ongoingOrder}}</p>
                </div>
                <div class="order_name">
                    <p>Service Completed</p>
                    <p>{{completedOrder}}</p>
                </div>
                <div class="order_name">
                    <p>Delivered</p>
                    <p>{{totalDelivered}}</p>
                </div>

            </div>
            <div>
                <div>
                    <div class="chart" *ngIf="hideOrderWidget">
                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">
                    </canvas>
                    </div>
                    <p class="text_note ordr">* All values in the pie chart are in percentage</p>
                    <div *ngIf="barGraphShow">
                        <!-- <span>WIP</span>
                        <mat-divider></mat-divider>
                        <div style="height: 100%; width:100%">
                            <div style="display:flex; flex-direction:column;">
                                <div style="height:30vh" class="barChart">
                                    <ngx-charts-bar-horizontal [scheme]="colorScheme" [results]="result" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                                        (select)="onPieSliceSelect($event)">
                                    </ngx-charts-bar-horizontal>
                                </div>
                                <input>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="pie_chart bg_color_border margn_top chart_sec">

            <span class="stocks"> Parts v/s Labour </span><br>
            <mat-divider></mat-divider><br>
            <div class="parts_section">
                <span class="labour_sec" *ngIf="!isSingapore"> Parts -  &#8377; {{partsPriceInr}}</span>
                <span class="labour_sec" *ngIf="isSingapore"> Parts - S&#36; {{partsPrice}}</span>
                <span *ngIf="!isSingapore"> Labour -  &#8377; {{labourPriceInr}}</span>
                <span *ngIf="isSingapore"> Labour -  S&#36; {{labourPrice}}</span>
            </div>
            <div *ngIf="noPartsLabour">
                <div>
                    <div class="chart">
                        <canvas baseChart [data]="pieChartPartsLabourData" [labels]="pieChartPartsLabourLabels" [chartType]="pieChartPartLabourType" [options]="pieChartOptions" [plugins]="pieChartPartsLabourPlugins" [colors]="pieChartPartsLabourColors" [legend]="pieChartPartLabourLegend">
                    </canvas>
                    </div>

                </div>
            </div>
            <p class="text_note labr">* All values in the pie chart are in percentage</p>
        </div>
        <div class="pie_chart bg_color_border margn_top chart_sec">
            <span class="stocks"> Service Categories </span><br>
            <mat-divider></mat-divider><br>
            <div class="parts_section">
                <p class="service_sec"> <span>General Service</span> <br>
                    <span *ngIf="!isSingapore"> &#8377; {{generalServiceTotalInr}}</span>
                    <span *ngIf="isSingapore"> S&#36; {{generalServiceTotal}}</span>
                </p>
                <p class="service_sec"> <span>Body Repair</span> <br>
                    <span *ngIf="!isSingapore"> &#8377; {{bodyRepairTotalInr}}</span>
                    <span *ngIf="isSingapore"> S&#36; {{bodyRepairTotal}}</span>
                </p>
                <p class="service_sec"> <span>General Repair</span> <br>
                    <span *ngIf="!isSingapore"> &#8377; {{generalRepairTotalInr}}</span>
                    <span *ngIf="isSingapore"> S&#36; {{generalRepairTotal}}</span>
                </p>

            </div>

            <div *ngIf="noServiceCate">
                <div>
                    <div class="chart">
                        <canvas baseChart [data]="pieChartServiceCatData" [labels]="pieChartServiceCateLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartServiceCatePlugins" [colors]="pieChartServiceCateColors" [legend]="pieChartServiceCateLegend">
                    </canvas>
                    </div>

                </div>
            </div>
            <p class="text_note">* All values in the pie chart are in percentage</p>
        </div>
    </div>


</div>