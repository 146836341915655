import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs } from "file-saver";
import * as XLSX from 'xlsx';
import * as moment from 'moment-timezone';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import { PayoutsService } from 'app/main/accounts/payouts/payouts.service';
import { PayoutDetailsComponent } from './payout-details/payout-details.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface PayoutData {
    id: string;
    created: any;
    amount: string;
    commission: string;
    commissionGst: string;
    payout: string;
    color: string;
}

@Component({
    selector: 'payouts',
    templateUrl: './payouts.component.html',
    styleUrls: ['./payouts.component.scss']
})

export class PayoutsComponent implements OnInit {
    token: any;
    netPayouts: number;
    netArr: any = [];
    pendingArr: any = [];
    paidArr: any = [];
    pending: number;
    paid: number;
    payouts: any;
    payoutsList: any;
    payoutsData: any = [];
    payoutsSource: MatTableDataSource<PayoutData>;
    payoutsColumns: string[] = ['id', 'created', 'amount', 'commission', 'commissionGst', 'payout',];
    renderedData: any;
    noData = false;
    dialogId: number;
    pDialogData: any[];
    payoutDetails: any;
    dataCSV: any;
    timezone: any;
    format: any;
    moment = moment();
    allActive = false;
    pendingActive = false;
    paidActive = false;
    isSingapore = false;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        public router: Router,
        private loginService: LoginService,
        private pService: PayoutsService,
        public dialog: MatDialog,
        private appComponent: AppComponent
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
        this.getPayoutsData();
        this.allActive = true;
    }

    ngOnInit() {
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        }
        else {
            this.isSingapore = false;
        }
    }

    getPayoutsData() {
        this.payoutsList = this.pService.payouts;
        if (this.payoutsList.response.status === 'SUCCESS') {
            this.payouts = this.payoutsList.response.body.payouts;
            if (this.payouts.length === 0) {
                this.noData = true;
            } else {
                this.payouts.forEach(payoutList => {
                    if (payoutList.status.toString() === '3' || payoutList.status.toString() === '4') {
                        this.payoutsData.push(this.createPayoutData(payoutList));
                    }
                }
                );
                this.payoutsSource = new MatTableDataSource(this.payoutsData);
                this.payoutsSource.connect().subscribe(d => this.renderedData = d);
                this.dataCSV = this.renderedData;
                this.netPayouts = this.netArr.reduce((a, b) => a + b, 0).toFixed(2);
                this.pending = this.pendingArr.reduce((a, b) => a + b, 0).toFixed(2);
                this.paid = this.paidArr.reduce((a, b) => a + b, 0).toFixed(2);
                setTimeout(() => {
                    this.payoutsSource.paginator = this.paginator;
                    this.payoutsSource.sort = this.sort;
                }, 500);
            }
        } else {
            this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
            setTimeout(() => {
                this.loginService.logout();
            }, 1000);
        }
    }

    applyFilter(filterValue: string) {
        this.payoutsSource.filter = filterValue.trim().toLowerCase();
        if (this.payoutsSource.paginator) {
            this.payoutsSource.paginator.firstPage();
        }
    }

    applyFilters(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        if (filterValue === 'pending') {
            this.allActive = false;
            this.pendingActive = true;
            this.paidActive = false;
        } else if (filterValue === 'paid') {
            this.allActive = false;
            this.pendingActive = false;
            this.paidActive = true;
        } else {
            this.allActive = true;
            this.pendingActive = false;
            this.paidActive = false;
        }
        this.payoutsSource.filter = filterValue;
    }

    createPayoutData(data: any): PayoutData {
        let status = '';
        let color = '';
        this.netArr.push(data.payout);
        if (data.status.toString() === '3') {
            status = 'Pending';
            color = 'warn';
            this.pendingArr.push(data.payout);
        }
        else {
            status = 'Paid';
            color = 'green';
            this.paidArr.push(data.payout);
        }
        return {
            id: data.id.toString(),
            created: moment(data.created).tz(this.timezone).format('ddd D MMM YYYY'),
            payout: parseFloat(data.payout).toFixed(2),
            commission: parseFloat(data.commission).toFixed(2),
            commissionGst: parseFloat(data.commissionGst).toFixed(2),
            amount: parseFloat(data.amount).toFixed(2),
            // status: status,
            color: color
        };
    }

    exportEXCEL() {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'payouts_list');
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        saveAs.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    openDialog(id: number) {
        const dialogRef = this.dialog.open(PayoutDetailsComponent, {
            height: 'auto',
            width: '80vw',
            data: {
                id: id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.router.navigate(['accounts/payouts']);
            this.dialogId = result;
        });
    }
}
