import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormGroupDirective } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { ProfileService } from 'app/main/profile/profile.service';
import { LoginService } from 'app/main/authentication/login/login.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
@Component({
    selector: 'profile-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ProfileSettingsComponent implements OnInit, OnDestroy {
    resetPasswordFormLoading = false;
    resetPasswordFormSubmitted = false;
    resetPasswordFormError = false;
    resetPasswordCurrentPasswordError = false;
    resetPasswordForm: FormGroup;
    succesMesg=false;
    hide: any;
    hide1: any;
    currPass: any;
    result: any;
    fileName :any;
    changeProfileForm:FormGroup;
    uploadLoading=false;
    resData:any;
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {ProfileService} pService
     */
    constructor(
        private pService: ProfileService,
        private loginService: LoginService,
        private _formBuilder: FormBuilder,
        private appComponent: AppComponent,
        private router: Router
    ) {
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
        this.resetPasswordForm = this._formBuilder.group({
            passwordExisting: ['', Validators.required],
            password: ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });
        this.changeProfileForm=this._formBuilder.group({
            profileImage:['', Validators.required]
        });
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }
    resetPasswordFormSubmit(formData: any, formDirective: FormGroupDirective): void {
        this.resetPasswordFormSubmitted = true;
        this.resetPasswordFormLoading = true;
        const passwords = formData.value;
        this.currPass = this.loginService.getPassHash();
        if (passwords.passwordExisting === window.atob(this.currPass)) {
            this.resetPasswordCurrentPasswordError = false;
            this.pService.updatePassword(passwords.password).pipe(first()).subscribe(
                result => {
                    this.resetPasswordFormError = false;
                    this.result = result;
                    if (this.result.response.status === 'SUCCESS') {
                        this.resetPasswordFormLoading = false;
                        this.loginService.savePassHash(window.btoa(passwords.password));
                        formDirective.resetForm();
                        this.resetPasswordForm.reset();
                        setTimeout(() => {
                            this.resetPasswordFormSubmitted = false;
                        }, 5000);
                    } else {
                        this.resetPasswordFormError = true;
                        this.resetPasswordFormLoading = false;
                        this.resetPasswordFormSubmitted = false;
                        this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                        setTimeout(() => {
                            this.loginService.logout();
                        }, 1000);
                    }
                },
                error => {
                    console.log(error);
                    this.resetPasswordFormError = true;
                    this.resetPasswordFormLoading = false;
                    this.resetPasswordFormSubmitted = false;
                }
            );
        } else {
            this.resetPasswordCurrentPasswordError = true;
            this.resetPasswordFormError = false;
            this.resetPasswordFormLoading = false;
            this.resetPasswordFormSubmitted = false;
        }
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getImage(event){
    if (event.target.files.length > 0) {
        this.fileName = event.target.files[0];
      }
    }
    profileFormSubmit(){
        this.uploadLoading=true;
        const loginUserData = JSON.parse(this.loginService.getLoginData());
        console.log(loginUserData);
        delete loginUserData['agentPhoto'];
        const merchantId=loginUserData.merchant.id;
        const accountAppKey = loginUserData.account.appKey;
        const formData=new FormData;
        formData.append('appKey', accountAppKey);
        formData.append('merchantId', merchantId);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('fileToUpload[]',this.fileName);
        this.loginService.uploadProfile(formData).subscribe(result=>{
            if(result.status.toString()=== '200'){
                console.log(result.cdn_link[0]);
                const uploadedUrl=result.cdn_link[0];
                if(uploadedUrl){
                    this.sendUploadedUrl(uploadedUrl );
                }
            }
        },
        error=>{
            console.log(error);
            
        });
    }
    sendUploadedUrl(fileUrl){
        const logedinUserData = JSON.parse(this.loginService.getLoginData());
        console.log(logedinUserData);
        const appKey = logedinUserData.account.appKey;
        const userKey = logedinUserData.agentUserKey;
        const userId = logedinUserData.agent;
        const formData = new FormData;
        formData.append('appKey', appKey);
        formData.append('userKey', userKey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('userId', userId);
        formData.append('imageUrl', fileUrl);
        this.loginService.sendUserImage(formData).subscribe(response=>{
            this.resData = response;
            if(this.resData.response.status ==='SUCCESS'){
                this.uploadLoading=false;
                logedinUserData['agentPhoto']=this.resData.response.body.agentPhoto;
                localStorage.setItem("LoggedInUserData",JSON.stringify(logedinUserData));
                console.log(logedinUserData);
                this.succesMesg=true;
                setTimeout(() => {
                    location.reload();
                    // this.router.navigate(['/toolbar']);
                    // this.router.navigateByUrl('/AppComponent', { skipLocationChange: true }).then(() => {
                    //     this.router.navigate(['profile']);
                    // }); 
                    this.succesMesg=false;
                  }, 2500);
            }
        },
        error=>{
            console.log(error);
        })
    }
    
}


/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }
    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');
    if (!password || !passwordConfirm) {
        return null;
    }
    if (passwordConfirm.value === '') {
        return null;
    }
    if (password.value === passwordConfirm.value) {
        return null;
    }
    return { 'passwordsNotMatching': true };
};
