import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { first } from 'rxjs/operators';
import { InvoicesService } from './invoices.service';

const backDate = (numOfDays) => {
    const today1 = new Date();
    return new Date(today1.setDate(today1.getDate() - numOfDays));
};

export interface InvoicesData {
    select: string;
    orderId: string;
    title: string;
    actions: string;
}

@Component({
    selector: 'invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class InvoicesComponent implements OnInit {

    /**
     * Constructor
     *
     */
    constructor(
        private router: Router,
        private loginService: LoginService,
        private iService: InvoicesService,
        private _http: HttpClient,
        private appComponent: AppComponent
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        const today = new Date();
        const minus7 = backDate(7);
        this.setupPresets();
        this.options = {
            presets: this.presets,
            format: 'mediumDate',
            range: { fromDate: minus7, toDate: today },
            applyLabel: 'Get Invoices',
            calendarOverlayConfig: {
                shouldCloseOnBackdropClick: true
            }
        };
    }
    token: any;
    loading = false;
    revenueClass = '';
    range: Range = { fromDate: new Date(), toDate: new Date() };
    options: NgxDrpOptions;
    presets: Array<PresetItem> = [];
    noData = false;
    noDataSelect = true;
    invoiceData: any;
    iDashData: any;
    iData: any;
    invoicesData: any = [];
    invoicesDataSource: MatTableDataSource<InvoicesData>;
    invoicesDataColumns: string[] = ['select', 'orderId', 'title', 'actions'];
    renderedData: any;
    dataCSV: any;
    zipFile: JSZip = new JSZip();
    data_urls: string[];
    getRequests = [];
    allData_urls = [];
    checked = true;
    checkedDownload = true;
    selection = new SelectionModel<InvoicesData>(true, []);
    @ViewChild('dateRangePicker', { static: false }) dateRangePicker;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild('checkRef', { static: false }) checkRef: ElementRef;
    updateRange(range: Range) {
        this.loading = true;
        this.range = range;
        const from = new Date(this.range.fromDate);
        const to = new Date(this.range.toDate);
        if (from.getTime() <= to.getTime()) {
            this.iService.getInvoicesCustomData(this.range.fromDate, this.range.toDate)
                .pipe(first())
                .subscribe(
                    data => {
                        this.iDashData = data;
                        if (this.iDashData && this.iDashData.response.status === 'SUCCESS') {
                            this.noData = false;
                            this.loading = false;
                            this.iData = this.iDashData.response.body.gstInvoiceList;
                            this.initInvoicesData(this.iData);
                        } else {
                            this.noData = true;
                            this.loading = false;
                            if (this.iDashData.response.message !== 'Cannot process request') {
                                this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                                setTimeout(() => {
                                    this.loginService.logout();
                                }, 1000);
                            }
                        }
                    },
                    error => {
                        this.noData = true;
                        this.loading = false;
                    }
                );
        } else {
            alert('"From Date" cannot be greater than "To Date"');
            this.loading = false;
        }
    }
    // DatePicker helper function to create initial presets
    setupPresets() {
        const today = new Date();
        const yesterday = backDate(1);
        const minus7 = backDate(7);
        const minus30 = backDate(30);
        const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        this.presets = [
            { presetLabel: 'Yesterday', range: { fromDate: yesterday, toDate: today } },
            { presetLabel: 'Last 7 Days', range: { fromDate: minus7, toDate: today } },
            { presetLabel: 'Last 30 Days', range: { fromDate: minus30, toDate: today } },
            { presetLabel: 'This Month', range: { fromDate: currMonthStart, toDate: currMonthEnd } },
            { presetLabel: 'Last Month', range: { fromDate: lastMonthStart, toDate: lastMonthEnd } }
        ];
    }

    ngOnInit() {

    }

    initInvoicesData(iData: any) {
        this.data_urls = [];
        this.invoicesData = [];
        iData.forEach(iList => {
            this.invoicesData.push(this.createInvoicesData(iList));
        });
        this.invoicesDataSource = new MatTableDataSource(this.invoicesData);
        this.invoicesDataSource.connect().subscribe(d => this.renderedData = d);
        this.dataCSV = this.renderedData;
        this.loading = false;
        setTimeout(() => {
            this.invoicesDataSource.paginator = this.paginator;
            this.invoicesDataSource.sort = this.sort;
        }, 500);
    }
    applyFilter(filterValue: string) {
        this.invoicesDataSource.filter = filterValue.trim().toLowerCase();
        if (this.invoicesDataSource.paginator) {
            this.invoicesDataSource.paginator.firstPage();
        }
    }

    createInvoicesData(data: any): InvoicesData {
        this.allData_urls.push(data.invoiceUrl);
        return {
            select: 'select',
            orderId: data.orderId.toString(),
            title: data.invoiceTitle,
            actions: data.invoiceUrl,
        };
    }
    getCheckedValue(event, orderId) {
        if (event.checked === true) {
            this.allData_urls = [];
            this.noDataSelect = false;
            for (let i = 0; i < this.iData.length; i++) {
                const eleId = this.iData[i].orderId;
                if (orderId === eleId) {
                    this.data_urls.push(this.iData[i].invoiceUrl);
                    return {
                        select: 'select',
                        orderId: this.iData[i].orderId.toString(),
                        title: this.iData[i].invoiceTitle,
                        actions: this.iData[i].invoiceUrl,
                    };
                }
            }
        }
        else {
            if(this.data_urls.length > 1){
                this.noDataSelect = false;
            }
            else{
                this.noDataSelect = true;
            }

            for (let i = 0; i < this.iData.length; i++) {
                const eleId = this.iData[i].orderId;
                if (orderId === eleId) {
                    this.data_urls.pop();
                    console.log(this.data_urls);
                    return {
                        select: 'select',
                        orderId: this.iData[i].orderId.toString(),
                        title: this.iData[i].invoiceTitle,
                        actions: this.iData[i].invoiceUrl,
                    };
                }
            }
        }
    }

    downloadAll() {
        this.createGetRequets(this.allData_urls);
    }

    private createGetRequets(data: string[]) {
        const zip = new JSZip();
        let count = 0;
        data.forEach((url) =>{
              let response = this._http.get(url, { responseType: 'blob' });
              this.getRequests.push(response);
              response.subscribe((pdffile: Blob) => {
                zip.file(url.substring(url.lastIndexOf('/') + 1),pdffile);
                count++;
                if(count === data.length){
                   zip.generateAsync({ type: 'blob' })
                   .then(function(content) {
                        saveAs(content, "allinvoices.zip");
                   });
                   }
             });
         });
    }
    // download selected-------------
    downloadSelected() {
        this.createGetRequetsSelect(this.data_urls);
    }

    private createGetRequetsSelect(data: string[]) {
        const zip = new JSZip();
        let count = 0;
        
        data.forEach((url) =>{
              let response = this._http.get(url, { responseType: 'blob' });
              this.getRequests.push(response);
              response.subscribe((pdffile: Blob) => {
                zip.file(url.substring(url.lastIndexOf('/') + 1),pdffile);
                count++;
                if(count === data.length){
                   zip.generateAsync({ type: 'blob' })
                   .then(function(content) {
                        saveAs(content, "invoices.zip");
                   });
                   }
             });
         });
    }

    // -------------end-----------
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.invoicesDataSource.data.length;
        return numSelected === numRows;
    }
    masterToggle(event) {
        if (event.checked === true) {
            this.data_urls = [];
            this.checkedDownload = false;
            this.noDataSelect = false;
        }
        else {
            this.checkedDownload = true;
            this.noDataSelect = true;
        }
        this.isAllSelected() ?
            this.selection.clear() :
            this.invoicesDataSource.data.forEach(row => this.selection.select(row));
    }
    checkboxLabel(row?: InvoicesData): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.orderId + 1}`;
    }
}
