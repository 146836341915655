<div class="page-layout carded fullwidth p-12" fusePerfectScrollbar>
    <div class="page-title detail_title" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-display-1 m-0 p-16 pl-0 font_size" fxLayout="column" fxLayoutAlign="center start">Live
            Orders</span>
    </div>
    <div class="bg_color_border margn_top">
        <div *ngIf="liveOrdersSource" class="p-12 pb-0 export-block" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <button [disabled]="noData" class="common_btn" (click)="exportEXCEL()" mat-button color="accent">
                    Export
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>
            </div>
            <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table *ngIf="liveOrdersSource" class="clickable" mat-table [dataSource]="liveOrdersSource" matSort matSortActive="orderId" matSortDirection="desc" matSortDisableClear>
            <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong></strong>{{row.orderId}} </td>
            </ng-container>

            <ng-container matColumnDef="registration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>VEHICLE&nbsp;REG.&nbsp;NO.</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row">{{row.registration | uppercase}}
                    <!-- <div><small id="time_show">{{row.vehicle | uppercase}}</small> </div> -->
                </td>
            </ng-container>

            <ng-container matColumnDef="orderStartTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>APPOINTMENT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.orderStartTime|date: 'd MMMM yy' }}
                    <span id="time_show">{{row.orderStartTime== '' ? '-' :row.orderStartTime|date: 'h:mm a'}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="orderEndTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>EXPECTED DELIVERY</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.orderEndTime == 0 ? '-' : row.orderEndTime|date:'d MMMM yy'}}
                    <span id="time_show">{{row.orderEndTime | date: 'h:mm a'}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="grandTotal" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.grandTotal == '' ? '-' : row.grandTotal | currency:"&#8377; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="grandTotal" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.grandTotal == 0 ? '-' : row.grandTotal | currency:"S&#36; "}}
                </td>

            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>STATUS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <span class=" {{ row.status}}"> {{ row.status}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="liveOrdersColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: liveOrdersColumns;" (click)="openDetails(row.orderId)" id="created_on"></tr>
        </table>
        <div *ngIf="!liveOrdersSource" class="txt_center">
            <mat-error> No Data to display</mat-error>
        </div>
        <mat-paginator class="order_paginator" [pageSizeOptions]="[10, 20]" showFirstLastButtons *ngIf="liveOrdersSource"></mat-paginator>
    </div>
</div>