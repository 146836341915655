import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GlobalVariable } from "app/main/globals";
import { LoginService } from "app/main/authentication/login/login.service";

@Injectable({ providedIn: "root" })
export class NewBookingsService {
    searchOrders: any[];
    paramData: any;
    merchantLogin: any;
    merchantId: any;
    userAppKey: any;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(private http: HttpClient, private loginService: LoginService) {
        this.merchantId = "";
        this.merchantLogin = JSON.parse(this.loginService.getLoginData());
        this.merchantId = this.merchantLogin.merchant.id;
        this.userAppKey = this.merchantLogin.account.appKey;
    }
    private searchedData(res: Response) {
        const body = res;
        return body || {};
    }
    getNewLeads(): Observable<any> {
        const localData = JSON.parse(this.loginService.getLoginData());
        const merchantId = localData.merchant.id;
        const data = {
            appKey: this.userAppKey,
            sessionId: this.loginService.getToken(),
            statuses: "6",
            page: "searchAll",
            merchants: merchantId,
            userType: "gms",
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/crmleadsearch",
            formData
        );
    }
    uploadExcelSheet(formData: any): Observable<any> {
        return this.http.post(
            `${GlobalVariable.BASE_FILE_UPLOAD_URL}` +
                "/gms-bulk-lead-create.php",
            formData
        );
    }
    uploadLeadData(filePath): Observable<any> {
        const localLoginData = JSON.parse(this.loginService.getLoginData());
        const merchantIds = localLoginData.merchant.id;
        const data = {
            appKey: this.userAppKey,
            sessionId: this.loginService.getToken(),
            path: filePath,
            merchantId: merchantIds,
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/gmscreatebulklead",
            formData
        );
    }
    saveFailedUploadData(data: any) {
        localStorage.setItem("FailedData", data);
    }
    getFailedUploadData() {
        return localStorage.getItem("FailedData");
    }
}
