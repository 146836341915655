<div id="login" class="inner-scroll" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center">
    <div id="login-form-wrapper" fusePerfectScrollbar>

        <div id="login-form">
            <div class="title">{{'LOGIN_INFO.TITLE' | translate}}</div>
            <form *ngIf="!enableOTP" [class.loading]="loading" name="requestOTPForm" [formGroup]="requestOTPForm" (ngSubmit)="requestOTP()" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>{{'LOGIN_INFO.MOBILE_NUMBER' | translate}}</mat-label>
                    <input matInput formControlName="mobile" maxlength="10" >
                    <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                    <mat-error *ngIf="requestOTPForm.get('mobile').hasError('required')">
                        Mobile Number is required
                    </mat-error>
                    <mat-error *ngIf="requestOTPForm.get('mobile').hasError('pattern')">
                        Not a valid mobile number
                    </mat-error>
                    <mat-error *ngIf="!requestOTPForm.get('mobile').hasError('pattern') && requestOTPForm.get('mobile').hasError('minlength')">
                        Mobile Number is less than 10 digits
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button color="accent" class="submit-button" aria-label="Request OTP" [disabled]="requestOTPForm.invalid">
                    {{'LOGIN_INFO.REQUEST_OTP' | translate}}
                </button>                
            </form>
            
            <form *ngIf="enableOTP" [class.loading]="loading" name="loginForm" [formGroup]="loginForm" (ngSubmit)="verifyOTP()" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>{{'LOGIN_INFO.MOBILE_NUMBER' | translate}}</mat-label>
                    <input matInput formControlName="mobile" maxlength="10">
                    <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                    <mat-error *ngIf="loginForm.get('mobile').hasError('required')">
                        Mobile Number is required
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('mobile').hasError('pattern')">
                        Not a valid mobile number
                    </mat-error>
                    <mat-error *ngIf="!loginForm.get('mobile').hasError('pattern') && loginForm.get('mobile').hasError('minlength')">
                        Mobile Number is less than 10 digits
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="otp-input">
                    <mat-label>{{'LOGIN_INFO.OTP' | translate}}</mat-label>
                    <input matInput type="password" formControlName="otp" maxlength="6" [type]="hide ? 'text' : 'password'" autocomplete="false">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                    <mat-error *ngIf="loginForm.get('otp').hasError('required')" >
                        OTP is required
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('otp').hasError('minlength')">
                        {{'LOGIN_INFO.OTP_VALIDATION' | translate}}
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN" [disabled]="this.loginForm.invalid">
                    {{'LOGIN_INFO.LOGIN' | translate}}
                </button>
                
            </form>
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
</div>