import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { LoginService } from 'app/main/authentication/login/login.service';
import { ProfileService } from './profile.service';
import { MatDialog } from '@angular/material/dialog';
import {AspSwitchGarageComponent} from '../auto-spare-parts/asp-switch-garage/asp-switch-garage.component';

@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit {
    token: any;
    loading = false;
    about: any;
    account: any;
    pitstopCertified: any;
    isGarageLogin: boolean = false;
    constructor(
        private router: Router,
        private loginService: LoginService,
        private pService: ProfileService,
        private sanitization: DomSanitizer,
        public dialog: MatDialog,
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
    }
    ngOnInit() {
        this.about = this.pService.about;
        this.account = JSON.parse(this.loginService.getLoginData());
        this.pitstopCertified = this.about.pitstopCertified;
        let spareLoginToken = localStorage.getItem('spareLoginToken');
        let garageKey = localStorage.getItem('garageKey');
        if(spareLoginToken && garageKey){
            this.isGarageLogin = true;
        }
    }

    changeGarage(){
        this.dialog.open(AspSwitchGarageComponent);
    }
}
