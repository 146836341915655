import { NgModule } from '@angular/core';
import { LiveOrdersModule } from 'app/main/orders/live-orders/live-orders.module';
import { PastOrdersModule } from 'app/main/orders/past-orders/past-orders.module';
import { SearchOrdersModule } from 'app/main/orders/search-orders/search-orders.module';
import { OrderDetailsModule } from 'app/main/orders/order-details/order-details.module';
import { CreateOrderModule } from 'app/main/orders/create-order/create-order.module';
import { DashboardOrdersModule } from './dashboard-orders/dashboard-orders.module';
@NgModule({
    imports: [
        DashboardOrdersModule,
        LiveOrdersModule,
        PastOrdersModule,
        SearchOrdersModule,
        OrderDetailsModule,
        CreateOrderModule
    ]
})
export class OrdersModule {
}
