import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import {
    AfterViewInit, Component,
    ElementRef, OnDestroy, OnInit,
    ViewChild
} from '@angular/core';
import {
    FormArray, FormBuilder,
    FormControl, FormGroup,
    FormGroupDirective, Validators
} from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import { BookingStatus, GlobalVariable } from 'app/main/globals';
import { SearchOrdersService } from 'app/main/orders/search-orders/search-orders.service';
import * as moment from 'moment-timezone';
import { Lightbox } from 'ngx-lightbox';
import { Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { AddImagesComponent } from './add-images/add-images.component';
import { AddNotesComponent } from './add-notes/add-notes.component';
import { BookingDetailsService } from './booking-details.service';
import { EditBookingComponent } from './edit-booking/edit-booking.component';
import { JobsListComponent } from './jobs-list/jobs-list.component';


import PlaceResult = google.maps.places.PlaceResult;
export interface ItemName {
    itemName: string;
    lineItemId: number;
    created: string;
    itemCategoryId: number;
    type: string;
}
export interface BookingStatus {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'booking-details',
    templateUrl: './booking-details.component.html',
    styleUrls: ['./booking-details.component.scss'],
    animations: fuseAnimations,
})
export class BookingDetailsComponent
    implements OnInit, AfterViewInit, OnDestroy {
    bookingStatus: BookingStatus[] = [
        { value: '6', viewValue: 'Enquiry' },
        { value: '7', viewValue: 'Confirmed' },
        { value: '2', viewValue: 'Cancelled' },
        { value: '1', viewValue: 'Invalid' },
    ];

    reasonControl = new FormControl();
    invalidControl = new FormControl();
    reasons = [
        'Price higher than market',
        'Customer will call himself',
        'Refusing inspection cost',
        'Price higher than authorized service station',
        'Delay in receiving spare parts',
        'Delay in follow up - Pitstop',
        'Delay in follow up - Pitstop',
        'Followed up - customer stopped responding',
        'Service Postponed',
        'Out of scope service from pitstop',
        'Emergency - Cannot meet customer date and time request',
        'Customer got it done elsewhere',
        'Customer does not need service',
        'Authorized Service Station - Paid Service',
        'Road Side Assistance',
        'Slot not available',
    ];
    invalidReason = [
        'Authorized service station - Free Service',
        'Showroom details - Non Service',
        'Asking previous service history',
        'Looking for driver',
        'Looking for rental cars',
        'Out of scope service location',
        'Duplicate Lead',
        'Dialled by mistake',
        'Testing',
        'Tie up with Pitstop',
        'Marketing',
        'Wrong data',
        'Insurance',
        'Buy/Sell car',
        'Non Automotive Service',
        'Non-Served Vehicle',
        'Car Accessories',
        'Tyre Puncher',
        'Music System',
        'Seat Cover',
    ];
    filteredResions: Observable<any>;
    filteredInvalid: Observable<any>;
    navigationSubscription;
    timezone: any;
    format: any;
    moment = moment;
    token: any;
    noData = false;
    id: number;
    private sub: any;
    loading = false;
    details: any;
    detailList: any;
    orderDetail: any;
    accountImage: string;
    defaultAccountImage = 'https://www.getpitstop.com/img/pitstop-logo.png';
    // Status
    assignedTechnician = false;
    assignedDriver = false;
    doorStep = false;
    pickUp = false;
    reachedTechnician = false;
    vehiclePicked = false;
    ongoing = false;
    completed = false;
    closed = false;
    // Details
    serviceType: string;
    serviceSubType: string;
    orderType: string;
    total: number;
    locality: string;
    orderStartTime: any;
    // Service Details
    customerAddress: string;
    // Customer Details
    customer: any;
    customerName: string;
    customerEmail = '';
    customerMobile: string;
    // Vehicle Details
    vehicle: any;
    vehicleOdometer: string;
    vehicleRegNum: string;
    vehicleModel: string;
    vehicleFuelType: string;
    vehicleChasisNum: string;
    // Pickup Driver Details
    noPickupDriverData = false;
    ptData: any;
    pickName: string;
    pickMobile: number;
    returnName: string;
    returnMobile: number;
    noReturnData = true;
    noTechData = true;
    noPickupData = true;
    assignedPickUp = false;
    // Technician Details
    techData: any;
    assignedTech = false;
    selfData: any;
    // Order Timeline Details
    orderTimeline: any = [];
    bookingTimeline: any = [];
    audioNote = false;
    all = true;
    notes = false;
    updates = false;
    // Quotation Data
    quotationLoading = false;
    noQuotationData = false;
    quotationData: any;
    quotationDetails: any;
    quotationItems: any;
    quotationArray = [];
    quotationSubTotal: number;
    quotationTotalTax: number;
    quotationGrandTotal: number;
    totalDiscount: number;
    // Quotation Form
    prefilled = false;
    public quotationForm: FormGroup;
    numberPatern = '^[0-9.,]+$';
    myFormValueChanges$;
    totalSum: number;
    totalUnitPrice: number;
    grandTotal: number;
    newUnit = 0;
    lineItemsData: any;
    lineItemNames: any = [];
    filteredOptions: Observable<ItemName[]>[] = [];
    filterValue: string;
    items: any[] = [];
    updateData: Object = {};
    userId: number;
    dataReceived = false;
    // Job Details
    noJobs = false;
    jobsCount: number;
    jobsData: any;
    jobs: [];
    // Image Details
    images: any;
    noImages = false;
    imageLoading = false;
    imgCount: number;
    fileError = false;
    _albums = [];
    selectedFiles = [];
    leadGen: any;
    self = false;
    doostepPickup = true;
    changeSatatus = false;
    currentStatus: any;
    nextStatus: any;
    statusShowHide = true;
    jobImages: any;
    jobProcess = [];
    totalJobImages = [];
    statusOrderResult: any;
    error = false;
    changeStatusLoading = false;
    responseMessage: any;
    editBooking = false;
    merchant: any;
    bookingImages: any;
    imagesTotal = [];
    leadgenAccount: any;
    noAddress = false;
    noName = false;
    noEmail = false;
    noMobile = false;
    editBookingId: any;
    showLoader = false;
    isData = false;
    cancelledReason = false;
    invalReason = false;
    merchantName: any;
    merchantLoginData: any;
    bookingStatusForm: FormGroup;
    loginUserData: any;
    accountAppKey: any;
    confirmed = false;
    cancelled = false;
    invalid = false;
    statusResult: any;
    errorMessage: any;
    erroMSG = false;
    succesLoading = false;
    enquiry = false;
    confirmSucMessage: any;
    showstatusMsg = false;
    reasonsForStatus: any;
    statusValue: any;
    zoneNo: any;
    orderId: any;
    status: any;
    notConfirmed: any;
    isSingapore = false;
    exotelModal = false;
    errorCall = false;
    exotelSid: any;
    exotelToken: any;
    callSuccess: any;
    showInitiateCall = false;
    addresError = false;
    applyCouponSec = false;
    public isQuotation: number;
    applyCouponForm: FormGroup;
    couponLoading = false;
    couponResponse: any;
    errorCoupon: any;
    couponError = false;
    applyCouponMsg: any;
    discountType: any;
    confirmRemove = false;
    couponApplied = false;
    removeCouponResult: any;
    editCustomer = false;
    customerEdit: any;
    customerEditForm: FormGroup;
    successEdit: any;
    country: any;
    isLeasing: any;
    latitude: any;
    longitude: any;
    custPickupPoint: any;
    selectedLocation: any;
    failEditMsg: any;
    editCustomerLoad = false;
    fullAddress: any;
    agentName: any;
    pickupTime: any;
    deliveryTime: any;
    pickupPoint: any;
    name: any;
    userEmail: any;
    responseData: any;
    notificationData: any;
    totalNotification: any;
    showNotifaction = false;
    notificationId: any;
    deactivateResult: any;
    showPaginations = false;
    showNotification = false;
    selectSuggession = false;
    validAddress = false;
    @ViewChild('quotationSave', { static: false })
    quotationSave: FormGroupDirective;
    @ViewChild('reasonToStatus', { static: false }) reasonToStatus: ElementRef;
    constructor(
        private bookingService: BookingDetailsService,
        private loginService: LoginService,
        public router: Router,
        private route: ActivatedRoute,
        private appComponent: AppComponent,
        private elem: ElementRef,
        private _fb: FormBuilder,
        public dialog: MatDialog,
        private _lightbox: Lightbox,
        private bottomSheet: MatBottomSheet,
        private fuseService: FuseProgressBarService,
        private _formBuilder: FormBuilder,
        private soService: SearchOrdersService
    ) {
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.getOrderDetail();
            }
        }); 
    }
    ngOnInit() {
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        } else {
            this.isSingapore = false;
        }
        this.merchantLoginData = JSON.parse(this.loginService.getLoginData());
        this.agentName = this.merchantLoginData.agentName;
        this.isLeasing = this.merchantLoginData.account.isLeasing;
        if (this.isLeasing === 1) {
            this.country = 'sg';
        } else {
            this.country = 'in';
        }
        this.merchantName = this.merchantLoginData.merchant.name;
        this.exotelSid = this.merchantLoginData.account.exotelSid;
        this.exotelToken = this.merchantLoginData.account.exotelToken;
        this.sub = this.route.params.subscribe((params) => {
            this.id = +params['id'];
        });
        this.quotationForm = this._fb.group({
            rows: this._fb.array([]),
        });
        this.getQuotationData();
        this.myFormValueChanges$ = this.quotationForm.controls[
            'rows'
        ].valueChanges;
        this.myFormValueChanges$.subscribe((rows) =>
            this.updateCalculations(rows)
        );

        this.filteredResions = this.reasonControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filters(value))
        );
        this.filteredInvalid = this.invalidControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filtersInvalid(value))
        );
        this.bookingStatusForm = this._formBuilder.group({
            bookingStatus: ['', Validators.required],
            reason: ['', Validators.required],
        });
        this.applyCouponForm = this._formBuilder.group({
            couponCode: ['', Validators.required],
        });
        this.customerEditForm = this._formBuilder.group({
            customerName: ['', Validators.required],
            customerMail: ['', Validators.required],
            customerHouse: [''],
            customerLocation: [''],
        });
    }
    onLocationSelected(location: Location) {
        this.latitude = location.latitude;
        this.longitude = location.longitude;
        this.custPickupPoint = this.latitude + ',' + this.longitude;
        this.selectSuggession = false;
        this.validAddress = false;
    }
    private _filters(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.reasons.filter((option) =>
            option.toLowerCase().includes(filterValue)
        );
    }
    private _filtersInvalid(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.invalidReason.filter((option) =>
            option.toLowerCase().includes(filterValue)
        );
    }
    ngAfterViewInit() {
        this.elem.nativeElement
            .querySelectorAll('.md-step.active')
            [
                this.elem.nativeElement.querySelectorAll('.md-step.active')
                    .length - 1
            ].classList.add('last');
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }
    closeEditCustomer() {
        this.editCustomer = false;
        this.successEdit = '';
        this.failEditMsg = '';
    }
    getCustomerAdd(location) {
        this.selectedLocation = location;
    }
    checkAddressSelection() {
        this.selectSuggession = true;
        this.custPickupPoint = undefined;
        if (this.custPickupPoint === undefined) {
            this.selectSuggession = true;
        } else {
            this.selectSuggession = false;
        }
        this.validAddress = true;
    }

    submitCustomerEdit() {
        const loginsData = JSON.parse(this.loginService.getLoginData());
        const appkey = loginsData.account.appKey;
        const formData = new FormData();
        this.editCustomerLoad = true;
        formData.append('appKey', appkey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append(
            'name',
            this.customerEditForm.get('customerName').value
        );
        if (this.pickupTime) {
            formData.append('pickupTime', this.pickupTime);
        }
        if (this.deliveryTime) {
            formData.append('expectedDeliveryTime', this.deliveryTime);
        }
        formData.append(
            'email',
            this.customerEditForm.get('customerMail').value
        );
        formData.append(
            'address1',
            this.customerEditForm.get('customerHouse').value
        );
        formData.append('status', this.leadGen.status);
        if (this.selectedLocation) {
            formData.append('address2', this.selectedLocation);
        } else {
            formData.append('address2', this.leadGen.address2);
        }
        if (this.pickupPoint) {
            formData.append('pickupPoint', this.pickupPoint);
            formData.append('dropPoint', this.pickupPoint);
        } else {
            formData.append('pickupPoint', this.custPickupPoint);
            formData.append('dropPoint', this.custPickupPoint);
        }
        formData.append('id', this.id.toString());
        formData.append('userType', 'gms');
        this.bookingService.updateBookingLead(formData).subscribe(
            (result) => {
                this.customerEdit = result;
                if (this.customerEdit.response.status === 'SUCCESS') {
                    this.editCustomerLoad = false;
                    this.successEdit = this.customerEdit.response.message;
                    setTimeout(() => {
                        this.editCustomer = false;
                        this.successEdit = '';
                        this.router.navigate(['bookings/details/' + this.id]);
                    }, 2000);
                } else {
                    this.editCustomerLoad = false;
                    this.failEditMsg = this.customerEdit.response.message;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    openApplyCoupon() {
        this.applyCouponSec = true;
    }
    closeCoupon() {
        this.applyCouponSec = false;
        this.couponError = false;
    }
    applyCouponSubmit() {
        this.couponLoading = true;
        const loginsData = JSON.parse(this.loginService.getLoginData());
        const appkey = loginsData.account.appKey;
        const formData = new FormData();
        formData.append('appKey', appkey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('leadId', this.id.toString());
        formData.append(
            'couponCode',
            this.applyCouponForm.get('couponCode').value
        );
        formData.append('type', 'gms');
        this.bookingService.applyCoupon(formData).subscribe(
            (result) => {
                this.couponResponse = result;
                if (this.couponResponse.response.status === 'SUCCESS') {
                    this.couponLoading = false;
                    this.applyCouponMsg = this.couponResponse.response.message;
                    setTimeout(() => {
                        this.applyCouponSec = false;
                        this.getQuotationData();
                        this.router.navigate(['/bookings/details/' + this.id]);
                        this.applyCouponMsg = '';
                    }, 2000);
                } else {
                    this.couponLoading = false;
                    this.couponError = true;
                    this.errorCoupon = this.couponResponse.response.errorMessage;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    openRemoveCoupon() {
        this.confirmRemove = true;
    }
    closeRemoveCoupon() {
        this.confirmRemove = false;
    }
    removeCouponSubmit() {
        this.couponLoading = true;
        const loginsData = JSON.parse(this.loginService.getLoginData());
        const appkey = loginsData.account.appKey;
        const formData = new FormData();
        formData.append('appKey', appkey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('leadId', this.id.toString());
        formData.append('leadLevel', 'true');
        formData.append('type', 'gms');
        this.bookingService.removeCoupon(formData).subscribe(
            (result) => {
                this.removeCouponResult = result;
                if (this.removeCouponResult.response.status === 'SUCCESS') {
                    this.couponLoading = false;
                    this.applyCouponMsg = this.removeCouponResult.response.message;
                    setTimeout(() => {
                        this.applyCouponSec = false;
                        this.confirmRemove = false;
                        this.getQuotationData();
                        this.router.navigate(['/bookings/details/' + this.id]);
                    }, 2000);
                } else {
                    this.couponLoading = false;
                    this.couponError = true;
                    this.errorCoupon = this.couponResponse.response.errorMessage;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    openEditCustomer() {
        this.editCustomer = true;
    }
    getOrderDetail() {
        this.details = this.bookingService.bookingDetails;
        if (this.details.response.status === 'SUCCESS') {
            this.detailList = this.details.response.body;
            this.leadGen = this.detailList.leadgen;
            this.pickupTime = this.leadGen.pickupTime;
            this.deliveryTime = this.leadGen.expectedDeliveryTime;
            this.pickupPoint = this.leadGen.pickupPoint;
            this.fullAddress =
                this.leadGen.address1 + ',' + this.leadGen.address2;
            this.name = this.leadGen.name;
            this.userEmail = this.leadGen.email;
            this.zoneNo = this.detailList.leadgen.zoneNo;
            this.orderId = this.detailList.leadgen.orderId;
            this.status = this.detailList.leadgen.status;
            if(this.orderId < 1 && this.leadGen.status.toString() === '7'){
                this.appComponent.openSnackBar('Order Confirmation Failed','OK');
            }
            if (this.orderId > 0 && this.leadGen.status.toString() === '7') {
                this.router.navigate(['/orders/details/' + this.orderId]);
            }
            if (this.leadGen.status.toString() < '7') {
                this.notConfirmed = true;
            } else {
                this.notConfirmed = false;
            }
            if (this.leadGen.status.toString() === '6') {
                this.enquiry = true;
            }
            if (this.leadGen.status.toString() === '7') {
                this.confirmed = true;
                this.enquiry = true;
            }
            if (this.leadGen.status.toString() === '2') {
                this.cancelled = true;
                this.enquiry = false;
                this.confirmed = false;
                this.invalid = false;
            }
            if (this.leadGen.status.toString() === '1') {
                this.invalid = true;
                this.cancelled = false;
                this.enquiry = false;
                this.confirmed = false;
            }
            if (this.leadGen.pickupAddress) {
                this.noAddress = false;
            } else {
                this.noAddress = true;
            }
            if (this.leadGen.name === '') {
                this.noName = true;
            } else {
                this.noName = false;
            }
            if (this.leadGen.email === '') {
                this.noEmail = true;
            } else {
                this.noEmail = false;
            }
            if (this.leadGen.mobile === '') {
                this.noMobile = true;
            } else {
                this.noMobile = false;
            }
            this.merchant = this.detailList.merchant;
            this.leadgenAccount = this.detailList.leadgenAccount;
            this.bookingImages = this.detailList.images;
            this.bookingTimeline = [];
            this.detailList.status.forEach((element) => {
                element.status = BookingStatus[element.status - 1];
                element.agentRole = this.getAgentRole(element.agentId);
                this.bookingTimeline.push(element);
            });
            for (let i = 0; i < this.bookingTimeline.length; i++) {
                if (this.bookingTimeline[i].type === 'call') {
                    this.audioNote = true;
                } else {
                    this.audioNote = false;
                }
            }
            if (this.bookingImages.length === 0) {
                this.noImages = true;
            }
            for (let i = 0; i < this.bookingImages.length; i++) {
                this.imagesTotal.push(this.bookingImages[i]);
            }
            if (this.imgCount === 0) {
                this.noImages = true;
            } else {
                this.noImages = false;
                this._albums = [];
                this.bookingImages.forEach((image) => {
                    const srcs = {
                        src: image.url,
                    };
                    this._albums.push(srcs);
                });
            }
        } else {
            this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
            setTimeout(() => {
                this.loginService.logout();
            }, 1000);
        }
    }
    getAgentRole(agentId) {
        return agentId !== 0
            ? this.detailList.leadStatusAgentList[agentId]
            : '';
    }

    getQuotationData() {
        this.quotationLoading = true;
        this.quotationData = [];
        this.lineItemsData = '';
        this.lineItemsData = JSON.parse(this.loginService.getLineItems());
        this.lineItemsData.forEach((item) => {
            this.lineItemNames.push(this.createLineItems(item));
        });
        this.bookingService.getQuotationData().subscribe((data: {}) => {
            this.quotationData = data;
            if (this.quotationData.response.status === 'SUCCESS') {
                this.quotationDetails = this.quotationData.response.body;
                this.quotationArray = this.quotationDetails.items;
                this.quotationGrandTotal = 0;
                this.quotationSubTotal = 0;
                this.quotationTotalTax = 0;
                this.totalDiscount = 0;
                if (this.quotationArray.length > 0) {
                    this.totalSum = 0;
                    this.quotationGrandTotal = this.quotationDetails.estimate.grandTotal.toFixed(
                        2
                    );
                    this.totalDiscount = this.quotationDetails.leadgen.discount.toFixed(
                        2
                    );
                    if (this.totalDiscount > 0) {
                        this.couponApplied = true;
                    } else {
                        this.couponApplied = false;
                    }
                    this.discountType = this.quotationDetails.leadgen.discountDescription;
                    this.quotationSubTotal = this.quotationDetails.estimate.subtotal.toFixed(
                        2
                    );
                    this.quotationArray.forEach((quotation) => {
                        if (quotation.status === 1 || quotation.status === 2) {
                            this.quotationTotalTax += quotation.gst;
                        }
                        this.prefillUnit(quotation);
                        this.prefilled = true;
                        this.dataReceived = true;
                    });
                } else {
                    this.noQuotationData = true;
                }
                this.quotationLoading = false;
            } else {
                this.quotationLoading = false;
                this.noQuotationData = true;
            }
        });
    }

    updateCalculations(rows: any) {
        this.totalSum = 0;
        const control = <FormArray>this.quotationForm.get('rows');
        for (let i = 0; i < rows.length; i++) {
            const totalUnitPrice = rows[i].itemQuantity * rows[i].itemUnitPrice;
            if (control.at(+i).get('itemName').value) {
                control
                    .at(+i)
                    .get('itemTotal')
                    .setValue(totalUnitPrice, {
                        onlySelf: true,
                        emitEvent: false,
                    });
            }
            if (rows[i].status === 2 || rows[i].status === 1) {
                this.totalSum += rows[i].itemTotal;
            }
        }
        this.totalSum = this.totalSum - this.quotationTotalTax;
    }

    createLineItems(item: any): ItemName {
        return {
            itemName: item.name,
            lineItemId: item.id,
            created: item.created,
            itemCategoryId: item.quotecategoryId,
            type: item.type,
        };
    }
    private prefillUnit(quotation) {
        const rows = this.quotationForm.get('rows') as FormArray;
        rows.push(
            this._fb.group({
                approved: quotation.approved,
                barcode: quotation.barcode,
                created: quotation.created,
                id: quotation.id,
                imageUrls: quotation.imageUrls,
                isLocked: quotation.isLocked,
                itemCategoryId: quotation.itemCategoryId,
                itemDescription: quotation.itemDescription,
                itemName: quotation.itemName,
                itemQuantity: quotation.itemQuantity,
                itemTotal: parseFloat(quotation.itemTotal).toFixed(2),
                itemUnitPrice: parseFloat(quotation.itemUnitPrice).toFixed(2),
                lineitemId: quotation.lineitemId,
                orderId: quotation.orderId,
                status: quotation.status,
                userId: quotation.userId,
                version: quotation.version,
            })
        );
        this.manageNameControl(rows.length - 1);
    }
    addUnit() {
        this.dataReceived = true;
        this.noQuotationData = false;
        this.newUnit += 1;
        const numberPatern = '^[0-9.,]+$';
        const rows = this.quotationForm.get('rows') as FormArray;
        rows.push(
            this._fb.group({
                approved: ['1'],
                barcode: [null],
                created: [null],
                id: [null],
                imageUrls: [null],
                isLocked: ['0'],
                itemCategoryId: [null],
                itemDescription: [null, Validators.required],
                itemName: [null, Validators.required],
                itemQuantity: [
                    1,
                    [
                        Validators.required,
                        Validators.pattern(numberPatern),
                        Validators.min(0),
                    ],
                ],
                itemTotal: [0],
                itemUnitPrice: [0],
                lineitemId: [null],
                orderId: [null],
                status: ['1'],
                userId: [null],
                version: ['1'],
            })
        );
        this.manageNameControl(rows.length - 1);
        setTimeout(() => {
            this.elem.nativeElement
                .querySelectorAll('[formcontrolname="itemName"]')
                [
                    this.elem.nativeElement.querySelectorAll(
                        '[formcontrolname="itemName"]'
                    ).length - 1
                ].focus();
        }, 600);
    }
    radioChange(event) {
        this.isQuotation = event.value;
    }
    manageNameControl(index: number) {
        const arrayControl = this.quotationForm.get('rows') as FormArray;
        this.filteredOptions[index] = arrayControl
            .at(index)
            .get('itemName')
            .valueChanges.pipe(
                startWith<string | ItemName>(''),
                map((value) =>
                    typeof value === 'string' ? value : value.itemName
                ),
                map((name) =>
                    name ? this._filter(name) : this.lineItemNames.slice()
                )
            );
    }
    displayFn(item?: ItemName): string | undefined {
        return item ? item.itemName : undefined;
    }
    private _filter(itemName: string): ItemName[] {
        const filterValue = itemName.toLowerCase();
        return this.lineItemNames.filter(
            (option) => option.itemName.toLowerCase().indexOf(filterValue) === 0
        );
    }
    private removeUnit(i: number) {
        const rows = this.quotationForm.get('rows') as FormArray;
        rows.removeAt(i);
        this.newUnit -= 1;
    }
    private editUnit() {
        this.quotationForm.enable();
    }
    onQuotationSubmit() {
        this.items = [];
        this.quotationLoading = true;
        this.createQuotationData(this.quotationForm.value);
    }
    createQuotationData(quotation) {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        let created = '';
        let categoryId = '';
        let type = '';
        const formData: FormData = new FormData();
        quotation.rows.forEach((element) => {
            if (element.itemName != null) {
                const items = this.findItem(element.itemName);
                if (items === undefined) {
                    created = element.created;
                    categoryId = element.itemCategoryId;
                    type = '';
                } else {
                    created = element.created;
                    categoryId = element.itemCategoryId;
                    type = element.type;
                }

                this.items.push({
                    approved: element.approved,
                    barcode: '',
                    created: created,
                    imageUrls: '',
                    isLocked: '0',
                    itemCategoryId: items.itemCategoryId,
                    itemDescription: element.itemDescription,
                    itemName: element.itemName,
                    itemQuantity: element.itemQuantity,
                    itemTotal: element.itemTotal,
                    itemUnitPrice: element.itemUnitPrice,
                    lineitemId: items.lineItemId,
                    orderId: this.id,
                    status: element.status,
                    type: type,
                    userId: this.userId,
                    version: '1',
                });
            }
        });
        this.updateData['items'] = this.items;
        formData.append('appKey', this.accountAppKey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('language', `${GlobalVariable.LANGUAGE}`);
        formData.append('device', `${GlobalVariable.DEVICE}`);
        formData.append('type', 'gms');
        formData.append('customerApproval', '0');
        formData.append('data', JSON.stringify(this.updateData));
        formData.append('leadId', this.id.toString());
        formData.append('date', Math.round(new Date().getTime()).toString());
        this.bookingService
            .addQuotation(formData)
            .pipe(first())
            .subscribe(
                (result) => {
                    if (result.response.status === 'SUCCESS') {
                        this.quotationLoading = false;
                        this.dataReceived = false;
                        this.newUnit = 0;
                        this.ngOnInit();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    findItem(obj: any) {
        for (let i = 0; i < this.lineItemNames.length; i++) {
            if (this.lineItemNames[i].itemName.indexOf(obj) !== -1) {
                return this.lineItemNames[i];
            }
        }
    }
    open(index: number): void {
        this._lightbox.open(this._albums, index);
    }
    close(): void {
        this._lightbox.close();
    }
    openBottomSheet(): void {
        this.bottomSheet.open(AddNotesComponent, {
            panelClass: 'bottom-sheet',
            data: {
                status: this.leadGen.status,
                leadId: this.id,
            },
        });
    }
    toggleFilter(type: string) {
        this.bookingTimeline = [];
        if (type === 'all') {
            this.detailList.status.forEach((element) => {
                if (this.doorStep) {
                    element.status = element.status;
                } else {
                    element.status = element.status;
                }
                element.created = element.created;
                this.bookingTimeline.push(element);
            });
        } else {
            if (type === 'notes') {
                this.detailList.status.forEach((element) => {
                    if (element.type === 'notes') {
                        this.bookingTimeline.push(element);
                    }
                });
                this.notes = true;
                this.updates = false;
            } else if (type === 'updates') {
                this.detailList.status.forEach((element) => {
                    if (element.type === 'auto-update') {
                        this.bookingTimeline.push(element);
                    }
                });

                this.updates = true;
                this.notes = false;
            } else {
                this.updates = false;
                this.notes = false;
            }
            this.detailList.status.forEach((element) => {
                if (element.type === type) {
                    if (this.doorStep) {
                        element.status = element.status;
                    } else {
                        element.status = element.status;
                    }
                    element.created = moment(element.created)
                        .tz(this.timezone)
                        .format(this.format);
                    this.orderTimeline.push(element);
                }
            });
        }
    }
    pickupDetails() {
        if (this.ptData.length > 0) {
            this.ptData.forEach((ptElement) => {
                if (this.pickUp) {
                    if (ptElement.orderStatus <= 6 && this.assignedDriver) {
                        this.pickName = ptElement.driverName;
                        this.pickMobile = ptElement.driverMobile;
                        this.noPickupData = false;
                    }
                    if (ptElement.orderStatus > 6 && this.assignedDriver) {
                        this.noReturnData = false;
                        this.returnName = ptElement.driverName;
                        this.returnMobile = ptElement.driverMobile;
                    }
                }
            });
        } else {
            this.noPickupDriverData = true;
        }
    }
    openImageBottomSheet() {
        this.bottomSheet.open(AddImagesComponent, {
            panelClass: 'bottom-sheet',
            data: {
                status: this.leadGen.status,
                bookingId: this.id,
            },
        });
    }
    getStatusType(value) {
        this.statusValue = value;
        if (value === '2') {
            this.cancelledReason = true;
        } else {
            this.cancelledReason = false;
        }
        if (value === '1') {
            this.invalReason = true;
        } else {
            this.invalReason = false;
        }
    }
    openJobsBottomSheet() {
        this.bottomSheet.open(JobsListComponent, {
            panelClass: 'bottom-sheet',
            data: {
                data: this.jobs,
            },
        });
    }
    openInvoice() {
        this.router.navigate(['/orders/details/invoice/' + this.id]);
    }
    showChangeStatus() {
        this.changeSatatus = true;
    }
    closeChangeStatus() {
        this.changeSatatus = false;
        this.erroMSG = false;
        this.addresError = false;
        this.showLoader = true;
        this.succesLoading = false;
    }
    getNotificationData() {
        const noticationIdArray = [];
        const offset = 1;
        this.soService.getNotifications(offset).subscribe(
            (result) => {
                this.responseData = result;

                if (this.responseData.response.status === 'SUCCESS') {
                    this.notificationData = this.responseData.response.body.notifications;
                    this.notificationData.forEach((element) => {
                        noticationIdArray.push(element.id);
                    });
                    this.notificationId = noticationIdArray.join();
                    this.totalNotification = this.responseData.response.body.notificationsCount;

                    if (this.totalNotification > 10) {
                        this.showPaginations = true;
                    } else {
                        this.showPaginations = false;
                    }
                    if (this.totalNotification > 0) {
                        this.showNotification = true;
                    } else {
                        this.showNotification = false;
                    }
                } else {
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    showEditBooking(bookingId) {
        this.fuseService.show();
        const dialogRef = this.dialog.open(EditBookingComponent, {
            height: 'auto',
            width: '70vw',
            data: {
                id: bookingId,
            },
        });
    }
    getId() {
        return this.editBookingId;
    }
    closeEditBooking() {
        this.changeSatatus = false;
        this.error = false;
    }
    updateBookingStatus() {
        if (this.statusValue === 2 || this.statusValue === 1) {
            this.reasonsForStatus = this.reasonToStatus.nativeElement.value;
        } else {
            this.reasonsForStatus = '';
        }
        this.succesLoading = true;
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        const formData = new FormData();
        formData.append('appKey', this.accountAppKey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('id', this.id.toString());
        formData.append(
            'status',
            this.bookingStatusForm.get('bookingStatus').value
        );
        formData.append('notes', '');
        formData.append('type', 'update');
        formData.append('subject', '');
        formData.append('reason', this.reasonsForStatus);
        formData.append('zoneNo', this.zoneNo);
        formData.append('userType', 'gms');
        if (
            this.detailList.leadgen.pickupAddress ||
            this.detailList.leadgen.pickupAddress !== ''
        ) {
            this.bookingService.changeBookingStatus(formData).subscribe(
                (result) => {
                    this.statusResult = result;
                    if (this.statusResult.response.status === 'SUCCESS') {
                        this.getNotificationData();
                        this.router.navigate(['/bookings/details/' + this.id]);
                        this.changeSatatus = false;
                        this.succesLoading = false;
                        this.confirmSucMessage = this.statusResult.response.message;
                        this.showstatusMsg = true;
                        setTimeout(() => {
                            this.showstatusMsg = false;
                            location.reload();
                        }, 4000);
                    } else {
                        this.errorMessage = this.statusResult.response.message;
                        this.erroMSG = true;
                        this.succesLoading = false;
                        this.showstatusMsg = false;
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            this.succesLoading = false;
            this.addresError = true;
        }
    }
    connectExoCall() {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        const formData = new FormData();
        this.accountAppKey = this.loginUserData.account.appKey;
        this.exotelSid = this.loginUserData.account.exotelSid;
        this.exotelToken = this.loginUserData.account.exotelToken;
        formData.append('exotel_sid', this.exotelSid);
        formData.append('exotel_token', this.exotelToken);
        formData.append('from', this.loginUserData.agentMobile);
        formData.append('to', this.leadGen.mobile);
        formData.append('leadId', this.leadGen.id);
        formData.append('status', this.leadGen.status);
        formData.append('type', 'call');
        formData.append('subject', '');
        formData.append('baseUrl', `${GlobalVariable.BASE_API_URL}`);
        formData.append('appKey', this.accountAppKey);
        formData.append('sessionId', this.loginService.getToken());
        this.bookingService.initiateExoCall(formData).subscribe((result) => {
            this.callSuccess = result;
            if (this.callSuccess.status.toString() === '200') {
                this.showInitiateCall = true;
                this.exotelModal = false;
                setTimeout(() => {
                    this.showInitiateCall = false;
                }, 3000);
            } else {
                this.errorCall = true;
            }
        });
    }
    showExotelModal() {
        this.exotelModal = true;
    }
    cancelExoCall() {
        this.exotelModal = false;
    }
}
