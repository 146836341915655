<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="content">
        <div class="left mrgin_tabs">
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between start">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="24">
                    <span fxLayout="row wrap" fxLayoutAlign="start center" class="mat-display-1 m-0 p-16 pl-0">Receivables</span>
                </div>
                <div class="widget stat clickable" fxFlex="100" fxFlex.gt-sm="24" (click)="applyFilters('')" [class.mat-elevation-z8]="allActive">
                    <div class="stat-card stat-card--light-blue">
                        <div class="stat-card__desc">All</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            {{netReceivables == 0 ? '0' : netReceivables | currency:"&#8377; "}}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            {{netReceivables == 0 ? '0' : netReceivables | currency:"S&#36; "}}
                        </div>
                    </div>
                </div>
                <div class="widget stat clickable" fxFlex="100" fxFlex.gt-sm="24" (click)="applyFilters('pending')" [class.mat-elevation-z8]="pendingActive">
                    <div class="stat-card stat-card--red">
                        <div class="stat-card__desc">Pending</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            {{pending == 0 ? '0' : pending | currency:"&#8377; "}}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            {{pending == 0 ? '0' : pending | currency:"S&#36; "}}
                        </div>
                    </div>
                </div>
                <div class="widget stat clickable" fxFlex="100" fxFlex.gt-sm="24" (click)="applyFilters('approved')" [class.mat-elevation-z8]="approvedActive">
                    <div class="stat-card stat-card--green">
                        <div class="stat-card__desc">Approved</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            {{approved == 0 ? '0' : approved | currency:"&#8377; "}}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            {{approved == 0 ? '0' : approved | currency:"S&#36; "}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8 mt-8" *ngIf="receivablesSource">
        <div class="p-12 pb-0 export-block" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <button [disabled]="noData" class="common_btn" (click)="exportEXCEL()" mat-button color="accent">
                    Export
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>
            </div>
            <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table class="clickable" mat-table [dataSource]="receivablesSource" matSort matSortActive="orderId" matSortDirection="desc" matSortDisableClear>
            <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.orderId}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="registrationNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>VEHICLE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.registrationNumber | uppercase}}</strong>
                    <div><small>{{row.vehicle | uppercase}}</small> (<small>{{row.fuelType | lowercase}}</small>)</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="grandTotal" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>GRAND TOTAL</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.grandTotal == 0 ? '-' : row.grandTotal | currency:"&#8377; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="grandTotal" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>GRAND TOTAL</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.grandTotal == 0 ? '-' : row.grandTotal | currency:"S&#36; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="commission" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>COMMISSION</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.commission == 0 ? '-' : row.commission | currency:"&#8377; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="commission" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>COMMISSION</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.commission == 0 ? '-' : row.commission | currency:"S&#36; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="receivables" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>RECEIVABLE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.receivables == 0 ? '-' : row.receivables | currency:"&#8377; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="receivables" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>RECEIVABLE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.receivables == 0 ? '-' : row.receivables | currency:"S&#36; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>STATUS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.status}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="receivablesColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: receivablesColumns;" (click)="openDialog(row.orderId)"></tr>
        </table>


        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
    <div *ngIf="!receivablesSource" class="bg_color_border margn_top">
        <div class="text-center">
            <mat-error>No Data to display</mat-error>
        </div>
    </div>
</div>