<div class="page-layout carded fullwidth p-12" fusePerfectScrollbar>
    <div
        class="page-title detail_title"
        fxLayout="row"
        fxLayoutAlign="space-between center"
    >
        <span
            class="mat-display-1 m-0 p-16 pl-0 font_size"
            fxLayout="column"
            fxLayoutAlign="center start"
        >
            Renewal Bookings</span
        >
        <span class="createBtn" *ngIf="uploadRenewalLead">
            <button mat-button (click)="upladLeadSheet()">
                <mat-icon>cloud_upload</mat-icon> Upload Bookings
            </button>
        </span>
    </div>
    <!-- ---------------upload lead message  ---------------->

    <div class="status_container" *ngIf="showUploadExcel">
        <div class="show_upload">
            <h3 class="headi_ng dont_print">
                Upload file
                <span
                    class="cancel_icon"
                    (click)="cancelExcelUpload(); uploader.clearQueue()"
                    ><i class="material-icons">
                        cancel
                    </i></span
                >
            </h3>
            <mat-nav-list class="form-container">
                <div mat-list-item class="mat-display-2 mb-8">
                    Choose Sheet
                </div>
                <div mat-list-item>
                    <span
                        *ngIf="uploader?.queue?.length != 0"
                        class="text-capitalized"
                        >(file - {{ fileName }})</span
                    >
                </div>
                <div class="uploade_count" *ngIf="wentWrong">
                    <mat-error>
                        Something went wrong. Please try again!
                    </mat-error>
                </div>
                <div *ngIf="fileNotUploaded" class="uploade_count">
                    <mat-error>
                        Something went wrong. Please try again !
                    </mat-error>
                </div>
                <div mat-list-item>
                    <input
                        hidden
                        type="file"
                        ng2FileSelect
                        [uploader]="uploader"
                        #fileInput
                        multiple
                    />
                </div>
                <div mat-list-item class="text-left">
                    <button
                        mat-raised-button
                        color="accent"
                        class="mt-8"
                        (click)="fileInput.click()"
                        [disabled]="uploader?.queue?.length > 0"
                    >
                        Select File
                    </button>
                </div>
                <div
                    mat-list-item
                    class="approve-actions text-right"
                    [hidden]="!uploader.getNotUploadedItems().length"
                >
                    <button
                        mat-raised-button
                        class="green-600 mr-8"
                        [disabled]="imagesLoading"
                        (click)="uploadSheet()"
                    >
                        Upload
                    </button>
                    <button
                        mat-raised-button
                        class="grey-600"
                        [disabled]="imagesLoading"
                        (click)="uploader.clearQueue(); removeSelection()"
                    >
                        Remove
                    </button>
                </div>
                <div mat-list-item class="text-left" *ngIf="error">
                    <mat-error>
                        Something went wrong. Please try again!
                    </mat-error>
                </div>
                <mat-progress-bar
                    class="mt-8"
                    *ngIf="imagesLoading"
                    mode="indeterminate"
                ></mat-progress-bar>
            </mat-nav-list>
        </div>
    </div>
    <!-- *ngIf="showFailureMsg" -->
    <div class="status_container" *ngIf="showFailureMsg">
        <div class="show_status">
            <h3 class="headi_ng dont_print" *ngIf="!bulkSuccess">
                Bulk upload Status
                <span class="cancel_icon" (click)="cancelMsgUpload()"
                    ><i class="material-icons">
                        cancel
                    </i></span
                >
            </h3>
            <h3 class="headi_ng dont_print text_centr" *ngIf="bulkSuccess">
                Bulk upload Success
                <span class="cancel_icon" (click)="cancelMsgUpload()"
                    ><i class="material-icons">
                        cancel
                    </i></span
                >
            </h3>
            <div *ngIf="bulkSuccess">
                <h3>Data is uploaded Succesfully</h3>
            </div>
            <div class="uploade_count" *ngIf="!bulkSuccess">
                <span
                    ><strong>Uploaded - </strong> {{ totalSuccessUpload }}</span
                >
            </div>
            <div class="uploade_count" *ngIf="!bulkSuccess">
                <span><strong> Failed - </strong> {{ totalFailedUpload }}</span>
            </div>
            <div *ngIf="!bulkSuccess">
                <div class="content_section">
                    <h3>
                        Due to insufficient data, system was unable to upload
                        the following Renewal Bookings
                    </h3>
                    <table class="upload_fail" id="divName">
                        <tr>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Vehicle Registration No.</th>
                            <th>Model</th>
                        </tr>
                        <tr *ngFor="let item of uploadResponse">
                            <td>{{ item.customerName }}</td>
                            <td>{{ item.customerMobile }}</td>
                            <td>{{ item.regNo }}</td>
                            <td>{{ item.model }}</td>
                        </tr>
                    </table>
                </div>
                <div class="upldBtn dont_print">
                    <button mat-button (click)="printUploadFailure()">
                        <mat-icon>print</mat-icon> Print
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- ------------/---------upload lead message--------- -->
    <mat-progress-bar
        class="mt_progres"
        *ngIf="renewalDataLoading"
        mode="indeterminate"
    ></mat-progress-bar>
    <div class="content rnwl_tabs">
        <div class="left">
            <div
                fxLayout="column"
                fxLayoutAlign="center"
                fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="space-between start"
            >
                <div
                    class="widget stat"
                    fxFlex="100"
                    fxFlex.gt-sm="24"
                    (click)="getCallsDueData()"
                >
                    <div
                        class="stat-card due_color"
                        [class.due_bgcolor]="callDueActive"
                    >
                        <div class="stat-card__desc">Calls Due</div>
                        <div class="stat-card__stat">
                            {{ totalCallDue }}
                        </div>
                    </div>
                </div>
                <div
                    class="widget stat"
                    fxFlex="100"
                    fxFlex.gt-sm="24"
                    (click)="getCallsMadeData()"
                >
                    <div
                        class="stat-card calls_made_color"
                        [class.calls_made_bgcolor]="callMadeActive"
                    >
                        <div class="stat-card__desc">Calls Made</div>
                        <div class="stat-card__stat">
                            {{ totalcallsMade }}
                        </div>
                    </div>
                </div>
                <div
                    class="widget stat"
                    fxFlex="100"
                    fxFlex.gt-sm="24"
                    (click)="getRenewalMissedData()"
                >
                    <div
                        class="stat-card missed_color"
                        [class.missed_bgcolor]="missedActive"
                    >
                        <div class="stat-card__desc">Total Renewals Missed</div>
                        <div class="stat-card__stat">
                            {{ totalMissed }}
                        </div>
                    </div>
                </div>
                <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24">
                    <div
                        class="stat-card conversion_color"
                        [class.conversion_bgcolor]="conversionActive"
                    >
                        <div class="stat-card__desc">Conversion Rate</div>
                        <div class="stat-card__stat">{{ converRate }} %</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_color_border margn_top">
        <div
            *ngIf="!noData"
            class="p-12 pb-0 pt-0 export-block"
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <div
                class="export-button"
                fxLayout="column"
                fxLayoutAlign="center start"
            >
                <button
                    [disabled]="noData"
                    (click)="exportEXCEL()"
                    mat-raised-button
                    class="grg_btn"
                >
                    Export
                    <mat-icon class="ml-8 font-size-20"
                        >cloud_download</mat-icon
                    >
                </button>
            </div>
            <mat-form-field>
                <input
                    [disabled]="noData"
                    matInput
                    (keyup)="applyFilter($event.target.value)"
                    placeholder="Filter"
                />
            </mat-form-field>
        </div>
        <table
            *ngIf="!noData"
            class="clickable"
            mat-table
            [dataSource]="renewalLeadSource"
            matSort
            matSortActive="orderId"
            matSortDirection="desc"
            matSortDisableClear
        >
            <ng-container matColumnDef="leadId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>BOOKING ID</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{ row.leadId }}
                </td>
            </ng-container>
            <ng-container matColumnDef="refId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>DMS ID</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{ row.refId === "null" ? "-" : row.refId }}
                </td>
            </ng-container>
            <ng-container matColumnDef="serviceType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>SERVICE</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{
                        row.serviceType === "general_service"
                            ? "General Service"
                            : row.serviceType === "doorstep_inspection"
                            ? "Doorstep Inspection"
                            : row.serviceType
                    }}
                </td>
            </ng-container>
            <ng-container matColumnDef="make">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>CAR DETAILS</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <img
                        [src]="
                            makeUrl +
                            '' +
                            (row.make | removewhitespaces) +
                            '.png'
                        "
                        alt="car make"
                        class="cars_img"
                        *ngIf="row.make && row.make !== 'undefined'"
                    />
                    <span id="make_show" *ngIf="row.make">{{
                        row.make === "undefined" ? " " : row.make + " "
                    }}</span
                    ><span *ngIf="row.model !== 'undefined'">{{
                        " " + row.model
                    }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="leadStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>STATUS</strong>
                </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <span class="status{{ row.leadStatus }}"
                        ><strong>{{
                            row.leadStatus == 1
                                ? "Invalid"
                                : row.leadStatus == 2
                                ? "Cancelled"
                                : row.leadStatus == 7
                                ? "Confirmed"
                                : row.leadStatus == 6
                                ? "Enquiry"
                                : ""
                        }}</strong>
                    </span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="searchOrdersColumns; sticky: true"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: searchOrdersColumns"
                (click)="openDetails(row.leadId)"
                id="created_on"
            ></tr>
        </table>

        <mat-paginator
            class="order_paginator"
            [pageSizeOptions]="[10, 20]"
            showFirstLastButtons
            *ngIf="!noData"
        ></mat-paginator>
        <div *ngIf="noData" class="txt_center">
            <mat-error> No Data to display</mat-error>
        </div>
    </div>
</div>
<!-- <div *ngIf="!uploadRenewalLead" class="no_renewal">
    <mat-error>This feature is not applicable for the logged-in user</mat-error>
</div> -->
<!-- -----------------upload excel sheets------------- -->
