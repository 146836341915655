import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as moment from "moment-timezone";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppComponent } from "app/app.component";
import { LoginService } from "app/main/authentication/login/login.service";
import { PastOrdersService } from "app/main/orders/past-orders/past-orders.service";
import { PickupStatus } from "app/main/globals";

const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

export interface PastOrdersData {
    orderId: string;
    registration: string;
    fuelType: string;
    vehicle: string;
    orderStartTime: any;
    orderEndTime: any;
    grandTotal: string;
    status: string;
}

@Component({
    selector: "past-orders",
    templateUrl: "./past-orders.component.html",
    styleUrls: ["./past-orders.component.scss"],
})
export class PastOrdersComponent implements OnInit {
    token: any;
    pastOrders: any;
    pastOrdersList: any;
    pastOrdersData: any = [];
    pastOrdersSource: MatTableDataSource<PastOrdersData>;
    pastOrdersColumns: string[] = [
        "orderId",
        "registration",
        "orderStartTime",
        "orderEndTime",
        "grandTotal",
        "status",
    ];
    renderedData: any;
    noData = false;
    dataCSV: any;
    timezone: any;
    format: any;
    moment = moment();
    isSingapore = false;
    @ViewChild("TABLE", { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        public router: Router,
        private loginService: LoginService,
        private poService: PastOrdersService,
        public dialog: MatDialog,
        private appComponent: AppComponent
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(["/auth/login"]);
        }
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
        this.getPastOrdersData();
    }

    ngOnInit() {
        if (this.loginService.getTimezone().toString() === "Asia/Singapore") {
            this.isSingapore = true;
        } else {
            this.isSingapore = false;
        }
        if (!this.noData) {
            this.pastOrdersSource.paginator = this.paginator;
            this.pastOrdersSource.sort = this.sort;
        }
    }

    getPastOrdersData() {
        this.pastOrdersList = this.poService.pastOrders;
        if (this.pastOrdersList.response.status === "SUCCESS") {
            this.pastOrders = this.pastOrdersList.response.body.orders;
            if (this.pastOrders.length === 0) {
                this.noData = true;
            } else {
                this.pastOrders.forEach((pastOrder) => {
                    this.pastOrdersData.push(
                        this.createPastOrdersData(pastOrder)
                    );
                });
                this.pastOrdersSource = new MatTableDataSource(
                    this.pastOrdersData
                );
                this.pastOrdersSource
                    .connect()
                    .subscribe((d) => (this.renderedData = d));
                this.dataCSV = this.renderedData;
            }
        } else {
            this.appComponent.openSnackBar("Session Timeout", "Redirecting...");
            setTimeout(() => {
                this.loginService.logout();
            }, 1000);
        }
    }

    applyFilter(filterValue: string) {
        this.pastOrdersSource.filter = filterValue.trim().toLowerCase();
        if (this.pastOrdersSource.paginator) {
            this.pastOrdersSource.paginator.firstPage();
        }
    }

    createPastOrdersData(data: any): PastOrdersData {
        let reg = "";
        let delivery = "";
        let startTime = "";
        let fuel = "";
        let status = "";
        status = PickupStatus[data.status - 1];
        if (data.registration) {
            reg = data.registration.toString();
        }
        if (data.orderEndTime) {
            delivery = moment
                .unix(data.orderEndTime / 1000)
                .tz(this.timezone)
                .format(this.format);
        }
        if (data.orderStartTime) {
            startTime = moment
                .unix(data.orderStartTime / 1000)
                .tz(this.timezone)
                .format(this.format);
        }
        if (data.fuelType) {
            fuel = data.fuelType.toString();
        }
        return {
            orderId: data.orderId.toString(),
            registration: reg,
            fuelType: fuel,
            vehicle: data.model.toString(),
            orderStartTime: startTime,
            orderEndTime: delivery,
            grandTotal: parseFloat(data.grandTotal).toFixed(2),
            status: status.toString(),
        };
    }

    exportEXCEL() {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            this.dataCSV
        );
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        this.saveAsExcelFile(excelBuffer, "pastOrders_list");
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }

    openDetails(id: number) {
        this.router.navigate(["orders/details/", id]);
    }
}
