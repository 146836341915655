<div class="page-layout carded fullwidth p-12" fusePerfectScrollbar>
    <mat-progress-bar *ngIf="loadingDashboard" mode="indeterminate"></mat-progress-bar>
    <br>
    <div class="calendar_section">
        <button class="view_btn" (click)="openCalendarView()"><i class="material-icons">
            calendar_today
            </i> <span>Calendar View</span></button>
    </div>
    <div class="order_calendar" *ngIf="showCalendarView">
        <div class="bg_color_border margn_top calender_part">
            <div class="row_s text-center">
                <div class="col-md-4">
                    <div class="btn-group">
                        <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                            Previous </div>
                        <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                            Today
                        </div>
                        <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                            Next
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <h3 class="selected_month">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                </div>
                <div class="col-md-4">
                    <div class="btn-group">
                        <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                            Month
                        </div>
                        <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                            Week
                        </div>
                        <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                            Day
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div [ngSwitch]="view">
                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-month-view>
                <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-week-view>
                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-day-view>
            </div>
        </div>
    </div>
    <!-- <ng-template #modalContent let-close="close">
        <div class="modal-header">
            <h5 class="modal-title">Event action occurred</h5>
            <button type="button" class="close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                Action:
                <pre>{{ modalData?.action }}</pre>
            </div>
            <div>
                Event:
                <pre>{{ modalData?.event | json }}</pre>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="close()">
                OK
            </button>
        </div>
    </ng-template> -->
    <div *ngIf="!showCalendarView">
        <div class="content">
            <div class="left">
                <div fxLayout="column" fxLayoutAlign="center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between start">
                    <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24" (click)="pendingApproval()">
                        <div class="stat-card stat-card--light-blue" [class.pendig_apprvl]="activeApproval">
                            <div class="stat-card__desc">Pending Quotation</div>
                            <div class="stat-card__stat">
                                {{totalPendingApprovalOrder}}
                            </div>
                        </div>
                    </div>
                    <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24" (click)="pendingQuotation()">
                        <div class="stat-card stat-card--blue " [class.pendig_bg]="activeQuotation">
                            <div class="stat-card__desc">Pending Approval</div>
                            <div class="stat-card__stat">
                                {{totalpendingQuotationOrder}}
                            </div>
                        </div>
                    </div>
                    <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24" (click)="delayedData()">
                        <div class="stat-card stat-card--yellow " [class.active_delyd]="activeDelayed">
                            <div class="stat-card__desc">Delayed</div>
                            <div class="stat-card__stat">
                                {{totalOngoingOrder}}
                            </div>
                        </div>
                    </div>
                    <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24" (click)="deliveryData()">
                        <div class="stat-card stat-card--green" [class.active_delivery]="activeDelivery">
                            <div class="stat-card__desc">Delivery</div>
                            <div class="stat-card__stat">
                                {{totalCompletedOrder}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br>
        <div class="bg_color_border tble_height" *ngIf="!noCompletedOrderData">
            <div class="p-12 pb-0 export-block mrgn-0" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                    <button [disabled]="noCompletedExpData" (click)="exportCompletedEXCEL()" class="common_btn" mat-button color="accent">
                                Export
                                <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                            </button>
                </div>
                <mat-form-field>
                    <input [disabled]="noCompletedExpData" matInput (keyup)="applyFilterCompleted($event.target.value)" placeholder="Filter">
                </mat-form-field>
            </div>
            <table class="clickable" mat-table [dataSource]="completedOrderDataSource" matSort #completeSort="matSort" matSortActive="orderId" matSortDirection="desc" matSortDisableClear>
                <ng-container matColumnDef="orderId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER ID</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.orderId}}</strong> </td>
                </ng-container>
                <ng-container matColumnDef="registrationNumer">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>REGISTRATION</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.registrationNumer | uppercase}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="merchantName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ACCOUNT NAME</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row" [innerHTML]="row.merchantName == '' ? '-' : row.merchantName"></td>
                </ng-container>
                <ng-container matColumnDef="ownerName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AGENT NAME</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row" [innerHTML]="row.ownerName == '' ? '-' : row.ownerName"></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="completedOrdersColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: completedOrdersColumns;" (click)="openDetails(row.orderId)">
                </tr>
            </table>
            <mat-paginator class="order_paginator" #completedPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="bg_color_border" *ngIf="noCompletedOrderData">
            <div class="txt_center">
                <mat-error> No Data to display</mat-error>
            </div>
        </div>
    </div>
</div>