<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="content">
        <div class="left">
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
                <div class="widget detail_title" fxFlex="100" fxFlex.gt-sm="100">
                    <span fxLayout="row wrap" fxLayoutAlign="start center" class="mat-display-1 m-0 p-16 pl-0 font_size">Search
                        Orders</span>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="100">
                    <div class="bg_color_border margn_top">
                        <div class="p-0 auto-width" fxLayout="row wrap" fxLayoutAlign="start center">
                            <form [class.loading]="loading" name="searchForm" [formGroup]="searchForm" (ngSubmit)="onSubmit()" novalidate>
                                <mat-form-field>
                                    <mat-select placeholder="Select" [(value)]="selectedValue" formControlName="condition">
                                        <mat-option value="id">Order ID</mat-option>
                                        <mat-option value="mobile">Customer Mobile Number</mat-option>
                                        <mat-option value="registrationNumber">Vehicle Reg. Number</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field *ngIf="selectedValue == 'id' || selectedValue == 'mobile'">
                                    <mat-label>Enter here</mat-label>
                                    <input matInput type="number" min="0" formControlName="query">
                                </mat-form-field>
                                <mat-form-field *ngIf="selectedValue == 'registrationNumber'">
                                    <mat-label>Enter here</mat-label>
                                    <input matInput formControlName="query">
                                </mat-form-field>
                                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN" [disabled]="searchForm.invalid">
                                    Search
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="bg_color_border" *ngIf="!loading && submitted">
        <div *ngIf="searchOrdersSource" class="p-12 pb-0 pt-0 export-block" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <button [disabled]="noData" (click)="exportEXCEL()" mat-raised-button color="accent">
                    Export
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>
            </div>
            <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table *ngIf="searchOrdersSource" class="clickable" mat-table [dataSource]="searchOrdersSource" matSort matSortActive="orderId" matSortDirection="desc" matSortDisableClear>
            <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.orderId}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="registration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>VEHICLE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.registration | uppercase}}</strong>
                    <div><small>{{row.vehicle | uppercase}}</small> (<small>{{row.fuelType | lowercase}}</small>)</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="orderStartTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>APPOINTMENT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.orderStartTime == '' ? '-' : row.orderStartTime}}
                </td>
            </ng-container>

            <ng-container matColumnDef="orderEndTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>EXPECTED DELIVERY</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.orderEndTime == '' ? '-' : row.orderEndTime}}
                </td>
            </ng-container>

            <ng-container matColumnDef="grandTotal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.grandTotal == 0 ? '-' : row.grandTotal | currency:"&#8377; "}}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>STATUS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <mat-chip-list>
                        <mat-chip class="{{row.status}} mat-chip mat-standard-chip mat-chip-selected" selected [innerHTML]="row.status"></mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="searchOrdersColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: searchOrdersColumns;" (click)="openDetails(row.orderId)"></tr>
        </table>
        <div *ngIf="!searchOrdersSource" class="txt_center">
            <mat-error> No Data to display</mat-error>
        </div>
        <mat-paginator class="order_paginator" [pageSizeOptions]="[10, 20]" showFirstLastButtons *ngIf="searchOrdersSource"></mat-paginator>
    </div>
</div>