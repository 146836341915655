import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment-timezone';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import { StatementService } from 'app/main/accounts/statement/statement.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface StatementData {
    id: string;
    created: any;
    notes: string;
    referenceId: string;
    debit: string;
    credit: string;
    balance: string;
}

@Component({
    selector: 'statement',
    templateUrl: './statement.component.html',
    styleUrls: ['./statement.component.scss']
})

export class StatementComponent implements OnInit {
    token: any;
    netBalance: any;
    youGetArr: any = [];
    youOweArr: any = [];
    youGet: number;
    youOwe: number;
    statement: any;
    statementList: any;
    sList: any;
    statementData: any = [];
    statementSource: MatTableDataSource<StatementData>;
    statementColumns: string[] = ['id', 'created', 'notes', 'referenceId', 'debit', 'credit', 'balance'];
    renderedData: any;
    dataCSV: any;
    noData = false;
    timezone: any;
    format: any;
    noStatmenetFound = false;
    moment = moment();
    isSingapore = false;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public router: Router,
        private loginService: LoginService,
        private sService: StatementService,
        private appComponent: AppComponent
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
        this.getStatementData();
    }

    ngOnInit() {
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        }
        else {
            this.isSingapore = false;
        }
    }


    getStatementData() {
        this.statementList = this.sService.statement;
        if(this.statementList.response.body.statement.length != 0){
        this.netBalance = this.statementList.response.body.lastLine.balance.toFixed(2);
        this.youGet = this.statementList.response.body.lastLine.debit.toFixed(2);
        this.youOwe = this.statementList.response.body.lastLine.credit.toFixed(2);
        if (this.statementList.response.status === 'SUCCESS') {
            this.statement = this.statementList.response.body.statement;
            if (this.statement.length === 0) {
                this.noData = true;
            } else {
                this.statement.forEach(sList => {
                    this.statementData.push(this.createStatementData(sList));
                });
                this.statementSource = new MatTableDataSource(this.statementData);
                this.statementSource.connect().subscribe(d => this.renderedData = d);
                this.dataCSV = this.renderedData;
                setTimeout(() => {
                    this.statementSource.paginator = this.paginator;
                    this.statementSource.sort = this.sort;
                }, 500);
            }
        }
        else {
            if (this.statementList.response.errorMessage === 'SESSION_TIMEOUT') {
                this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                setTimeout(() => {
                    this.router.navigate(['/auth/login']);
                }, 1000);
            }
        }
    }
    else{
        this.noStatmenetFound = true;
    }
    }

    applyFilter(filterValue: string) {
        this.statementSource.filter = filterValue.trim().toLowerCase();
        if (this.statementSource.paginator) {
            this.statementSource.paginator.firstPage();
        }
    }

    createStatementData(data: any): StatementData {
        let rId = '';
        let notes = '';
        if (data.statementReferenceId) {
            rId = data.statementReferenceId.toString();
        }
        if (data.statementNotes) {
            notes = data.statementNotes.toString();
        }
        return {
            id: data.statementId.toString(),
            created: moment(data.statementCreated).tz(this.timezone).format('ddd D MMM YYYY'),
            notes: notes,
            referenceId: rId,
            debit: parseFloat(data.statementDebit).toFixed(2),
            credit: parseFloat(data.statementCredit).toFixed(2),
            balance: parseFloat(data.statementBalance).toFixed(2),
        };
    }

    exportEXCEL() {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'statement_list');
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}
