import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { GlobalVariable } from "app/main/globals";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LoginService } from "app/main/authentication/login/login.service";
const backDate = (numOfDays) => {
    const today1 = new Date();
    return new Date(today1.setDate(today1.getDate() - numOfDays));
};
@Injectable({ providedIn: "root" })
export class DailyOrdersService implements Resolve<any> {
    dailyOrderData: any[];
    paramData: any;
    loginData: any;
    accountAppKey: any;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(private http: HttpClient, private loginService: LoginService) {}
    private doData(res: Response) {
        const body = res;
        return body || {};
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                // this.getDailyOrders()
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    convertDate(t: any) {
        const date = new Date(t);
        const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    getDailyOrdersData(cToday: any, cLast: any) {
        const st = this.convertDate(cToday);
        const et = this.convertDate(cLast);
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        const data = {
            appKey: this.accountAppKey,
            sessionId: this.loginService.getToken(),
            language: `${GlobalVariable.LANGUAGE}`,
            device: `${GlobalVariable.DEVICE}`,
            merchantId: this.loginData.merchant.id,
            fromDate: st,
            toDate: et,
            reportId: "15",
            reportFor: "gms",
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/gmsdeliveryreport",
            formData
        );
    }
}
