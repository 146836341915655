import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { map, catchError, tap } from 'rxjs/operators';
import { GlobalVariable } from 'app/main/globals';

// const md5 = new Md5();

@Injectable({ providedIn: 'root' })
export class LoginService {
    paramData: any;
    loginResponse: any;

    constructor(private http: HttpClient, private router: Router, ) { }

    private loginData(res: Response) {
        const body = res;
        return body || {};
    }

    getUser(username: string, password: string): Observable<any> {
        this.paramData = {
            appKey: `${GlobalVariable.APP_KEY}`,
            username: username,
            password: Md5.hashStr(password),
            language: `${GlobalVariable.LANGUAGE}`,
            device: `${GlobalVariable.DEVICE}`
        };
        return this.http.get(`${GlobalVariable.BASE_API_URL}` + '/hlogin', { params: this.paramData }).pipe(
            map(this.loginData));
    }
 
    uploadProfile(formData: any): Observable<any> {
        return this.http.post(`${GlobalVariable.BASE_FILE_UPLOAD_URL}` + '/process-upload.php', formData);
    }
    sendUserImage(formData){
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/hchangeinfo ', formData);
    }
    saveTimeZone(zone: any, format: any) {
        localStorage.setItem('timezone', zone);
        localStorage.setItem('dateFormat', 'ddd D MMM YYYY, h:mm A');
    }
    getTimezone() {
        return localStorage.getItem('timezone');
    }
    getFormat() {
        return localStorage.getItem('dateFormat');
    }

    savePassHash(data: any) {
        localStorage.setItem('passHash', data);
    }
    getPassHash() {
        return localStorage.getItem('passHash');
    }

    saveMerchantData(data: any) {
        localStorage.setItem('MerchantData', data);
    }
    getMerchantData() {
        return localStorage.getItem('MerchantData');
    }

    saveLoginData(data: any) {
        localStorage.setItem('LoggedInUserData', data);
    }

    saveSpareLoginNumber(agentMobile: any) {        
        localStorage.setItem('spareLoginNumber', agentMobile);
    }

    getSpareLoginNumber() {
        return localStorage.getItem('spareLoginNumber');
    }

    getLoginData() {
        return localStorage.getItem('LoggedInUserData');
    }

    sendToken(token: string) {
        localStorage.setItem('LoggedInUser', token);
    }
    getToken() {
        return localStorage.getItem('LoggedInUser');
    }
    isLoggednIn() {
        return this.getToken() !== null;
    }

    saveLineItems(data: any) {
        localStorage.setItem('LineItems', data);
    }
    getLineItems() {
        return localStorage.getItem('LineItems');
    }

    saveMarketPlaceAppKey(data: any) {
        localStorage.setItem('MarketPlaceAppKey', data);
    }
    getMarketPlaceAppKey() {
        return localStorage.getItem('MarketPlaceAppKey');
    }

    saveMarketPlaceAccountId(data: any) {
        localStorage.setItem('MarketPlaceAccountId', data);
    }
    getMarketPlaceAccountId() {
        return localStorage.getItem('MarketPlaceAccountId');
    }
    saveMapKey(data: any){
        localStorage.setItem('mapKey', data);
    }

    logout() {
        localStorage.removeItem('LoggedInUser');
        localStorage.removeItem('LoggedInUserData');
        localStorage.removeItem('timezone');
        localStorage.removeItem('dateFormat');
        localStorage.removeItem('LineItems');
        localStorage.removeItem('MerchantData');
        localStorage.removeItem('passHash');
        
        localStorage.removeItem('mapKey');

        // Spares
        localStorage.removeItem('spareLoginToken');
        localStorage.removeItem('spareUserKey');
        localStorage.removeItem('spareUserType');
        localStorage.removeItem('selectedMake');
        localStorage.removeItem('selectedModel');
        localStorage.removeItem('selectedYear');
        localStorage.removeItem('variant');
        localStorage.removeItem('variantKey');
        localStorage.removeItem('rowKey');
        localStorage.removeItem('aspSpareAdded');
        localStorage.removeItem('spareRefreshToken');
        localStorage.removeItem('garageKey');
        localStorage.removeItem('spareLoginNumber');
        localStorage.removeItem('spareSelectedGarageName');
        localStorage.removeItem('newMake');
        localStorage.removeItem('newModel');
        localStorage.removeItem('newYear');
        localStorage.removeItem('newVariant');
        localStorage.removeItem('newVariantKey');
        localStorage.removeItem('newRowKey');
        localStorage.removeItem('isShippingCharges');
        localStorage.removeItem('prepayUpiDiscount');
        localStorage.removeItem('spareUserId');

        sessionStorage.clear();
        // End Spares

        this.router.navigate(['/auth/login']);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
