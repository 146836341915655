<div *ngIf="!noStatmenetFound" class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="content">
        <div class="left">
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between start">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="24">
                    <span fxLayout="row wrap" fxLayoutAlign="start center" class="mat-display-1 m-0 p-16 pl-0">Statement</span>
                </div>
                <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24">
                    <div class="stat-card stat-card--light-blue">
                        <div class="stat-card__desc">Net Balance</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            {{netBalance | currency:"&#8377; "}}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            {{netBalance | currency:"S&#36; "}}
                        </div>
                    </div>
                </div>
                <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24">
                    <div class="stat-card stat-card--red">
                        <div class="stat-card__desc">You Owe</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            {{youOwe | currency:"&#8377; "}}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            {{youOwe | currency:"S&#36; "}}
                        </div>
                    </div>
                </div>
                <div class="widget stat" fxFlex="100" fxFlex.gt-sm="24">
                    <div class="stat-card stat-card--green">
                        <div class="stat-card__desc">You Get</div>
                        <div class="stat-card__stat" *ngIf="!isSingapore">
                            {{youGet | currency:"&#8377; "}}
                        </div>
                        <div class="stat-card__stat" *ngIf="isSingapore">
                            {{youGet | currency:"S&#36; "}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8 mt-8">
        <div class="p-12 pb-0 export-block" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <button [disabled]="noData" (click)="exportEXCEL()" class="common_btn" mat-button color="accent">
                    Export
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>
            </div>
            <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table mat-table [dataSource]="statementSource" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear>
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.id}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>DATE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.created | date: 'd MMMM yy'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>NOTES</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">{{row.notes == '' ? '-' : row.notes}}</td>
            </ng-container>

            <ng-container matColumnDef="referenceId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>TRANSACTION ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">{{row.referenceId == '' ? '-' : row.referenceId}}</td>
            </ng-container>

            <ng-container matColumnDef="debit" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>YOU GET</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.debit == 0 ? '-' : row.debit | currency:"&#8377; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="debit" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>YOU GET</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.debit == 0 ? '-' : row.debit | currency:"S&#36; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="credit" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>YOU OWE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.credit == 0 ? '-' : row.credit | currency:"&#8377; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="credit" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>YOU OWE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.credit == 0 ? '-' : row.credit | currency:"S&#36; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>BALANCE</strong> </th>
                <td [class]="row.balance > 0 ? 'mat-cell cdk-column-created mat-column-created ng-star-inserted green-500-fg' : 
                    'mat-cell cdk-column-created mat-column-created ng-star-inserted warn-500-fg'" mat-ripple mat-cell *matCellDef="let row">
                    {{row.balance == 0 ? '-' : row.balance | currency:"&#8377; "}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="statementColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: statementColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>

<div *ngIf="noStatmenetFound">
    <div class="text_center bg_color_border margn_stmnt">
        <mat-error>No Data to display</mat-error>
    </div>
</div>