import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule
} from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
// import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { AccountsModule } from 'app/main/accounts/accounts.module';
import { AuthenticationGuard } from 'app/main/authentication/authentication.guard';
import { AuthenticationModule } from 'app/main/authentication/authentication.module';
import { LoginService } from 'app/main/authentication/login/login.service';
import { BulkDownloadsModule } from 'app/main/bulk-downloads/bulk-downloads.module';
import { DashboardModule } from 'app/main/dashboard/dashboard.module';
import { OrdersModule } from 'app/main/orders/orders.module';
import { ProfileModule } from 'app/main/profile/profile.module';
import { ReportsModule } from 'app/main/reports/reports.module';
import { environment } from 'environments/environment';
// import 'hammerjs';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ToastrModule } from 'ngx-toastr';
import { BookingsModule } from './main/bookings/bookings.module';
import { AutoSparePartsModule } from './main/auto-spare-parts/auto-spare-parts.module';
import { HttpInterceptorService } from './shared/http-interceptor.service';
import { MessagingService } from './shared/messaging.service';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

export function httpTranslateLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }

const appRoutes: Routes = [
    {
        path: 'reports',
        loadChildren: () =>
            import('app/main/reports/reports.module').then(
                (m) => m.ReportsModule
            ),
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'bulk-downloads',
        loadChildren: () =>
            import('app/main/bulk-downloads/bulk-downloads.module').then(
                (m) => m.BulkDownloadsModule
            ),
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'bookings',
        loadChildren: () =>
            import('app/main/bookings/bookings.module').then(
                (m) => m.BookingsModule
            ),
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'orders',
        loadChildren: () =>
            import('app/main/orders/orders.module').then((m) => m.OrdersModule),
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'inventory',
        loadChildren: () =>
            import('app/main/inventory/inventory.module').then(
                (m) => m.InventoryModule
            ),
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'accounts',
        loadChildren: () =>
            import('app/main/accounts/accounts.module').then(
                (m) => m.AccountsModule
            ),
    },
    {
        path: 'profile',
        pathMatch: 'full',
        loadChildren: () =>
            import('app/main/profile/profile.module').then(
                (m) => m.ProfileModule
            ),
    },
    {
        path: 'dashboard',
        pathMatch: 'full',
        loadChildren: () =>
            import('app/main/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            ),
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('app/main/authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            ),
    },
    {
        path: 'customer',
        loadChildren: () =>
            import('app/main/customer-details/customer-details.module').then(
                (m) => m.CustomerDetailsModule
            ),
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/dashboard',
    },


    {
        path: 'auto-spare-parts',
        loadChildren: () =>
            import('app/main/auto-spare-parts/auto-spare-parts.module').then(
                (m) => m.AutoSparePartsModule
            ),
        runGuardsAndResolvers: 'always',
    },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),

        // TranslateModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: httpTranslateLoaderFactory,
            deps: [HttpClient]
        }
        }),
        ToastrModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        // Material
        MatSnackBarModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        GooglePlaceModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AuthenticationModule,
        DashboardModule,
        ProfileModule,
        AccountsModule,
        OrdersModule,
        BookingsModule,
        AutoSparePartsModule,
        BulkDownloadsModule,
        ReportsModule,
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyA-FwkEuPz6efVsc-NszVSD-Fgo4-gXcvA",
            libraries: ["places"],
            region: "IN",
        }),
        MatGoogleMapsAutocompleteModule,
    ],
    providers: [
        LoginService,
        AuthenticationGuard,
        MessagingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
