import { Component, OnInit, HostListener, ElementRef, ViewChild, ViewChildren, NgZone } from '@angular/core';
import { AspLoginService } from '../asp-login/asp-login.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AspCategorySparesListService } from './asp-category-spares-list.service';
import { AspAllCategoriesPopupComponent } from '../asp-all-categories-popup/asp-all-categories-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-asp-category-spares-list',
  templateUrl: './asp-category-spares-list.component.html',
  styleUrls: ['./asp-category-spares-list.component.scss']
})
export class AspCategorySparesListComponent implements OnInit {
  loading: boolean = false;
  spareSelectedGarageName: any = '';
  searchText:string = '';
  openSearch:boolean = true;
  clearSearch:boolean = false;
  make: string = '';
  model: string = '';
  year: string = ''; 
  variant: string = '';
  makeImage:string = '';  
  vehicleType:any = localStorage.getItem('vehicleType');
  sparesList = [];
  brands = [];
  addedCart = [];
  cartList = [];
  cartCount = 0;
  cartSubTotal = 0;
  cartGrandTotal = 0;  
  newData = {};  
  isCartCount:boolean = false;
  selectedCategory:any = localStorage.getItem('selectedCategory');
  selectedCategoryImg:any = localStorage.getItem('selectedCategoryImg');
  selectedBrands: string[] = [];
  isShippingCharges:boolean = false;


  @ViewChild('tabRight') tabRight: ElementRef;
  @ViewChildren('catListItems') catListItems;

  @HostListener("window:scroll", [])
    onWindowScroll(event) {
      // let top = this.tabRight.nativeElement.offsetTop;
      let top = this.tabRight.nativeElement.scrollTop+250;
      this.catListItems.toArray().forEach(element => {
        let offset = element.nativeElement.offsetTop;
        let height = element.nativeElement.offsetHeight;
        let id = element.nativeElement.getAttribute('id');

        if(top>=offset && top<offset+height){
          // console.log("id: "+id); 
          this.mouseEnter(id);
        }  
      });
    }

  constructor(
    private aspLoginService: AspLoginService,
    private aspCategorySparesListService: AspCategorySparesListService,
    private toastr: ToastrService,
    private router: Router,
    private zone: NgZone,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.aspLoginService.checkLogin();

    if(localStorage.getItem('newMake'))
      this.make = localStorage.getItem('newMake');
    else if(localStorage.getItem('selectedMake'))
      this.make = localStorage.getItem('selectedMake');

    if(localStorage.getItem('newModel'))
      this.model = localStorage.getItem('newModel');
    else if(localStorage.getItem('selectedModel'))
      this.model = localStorage.getItem('selectedModel');

    if(localStorage.getItem('newYear'))
      this.year = localStorage.getItem('newYear');
    else if(localStorage.getItem('selectedYear'))
      this.year = localStorage.getItem('selectedYear');

    if(localStorage.getItem('newVariant'))
      this.variant = localStorage.getItem('newVariant');
    else if(localStorage.getItem('variant'))
      this.variant = localStorage.getItem('variant');

    if(this.make)
      this.makeImage = 'https://cube.getpitstop.com/assets/img/'+(this.make.toLowerCase()).replace(' ','')+'.png';

    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName");
    this.getCategorySparesList();
    this.getCart();
  }

  getCategorySparesList = () => {
    let rowKey = localStorage.getItem('rowKey');
    let variantKey = localStorage.getItem('variantKey');
    let selectedCategory = localStorage.getItem('selectedCategory');
    let garageKey = localStorage.getItem('garageKey');
    let vehicleType = localStorage.getItem('vehicleType');

    let universal = false;
    if(vehicleType=='universal')
      universal = true;


    this.aspCategorySparesListService.getCategorySparesList(rowKey, variantKey, selectedCategory, garageKey, universal, this.selectedBrands).subscribe(
      result => {
        this.loading = false;
        // console.log(result);

        this.sparesList = [];
        this.brands = [];

        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code==200){    

          Object.values(res.data.brands).forEach(brand => {
            if(this.selectedBrands.includes(brand['brandKey'])){
              brand['checked'] = true;
            }else{
              brand['checked'] = false;
            }
            
            this.brands.push(brand);
          });
          
          // get stock
          let spareStocks = [];
          Object.values(res.data.spareStocks).forEach(stock => {
            spareStocks[stock['partNumber']] = stock;
          });
          // end get stock

          Object.values(res.data.brands).forEach(brand => {

            let data = [];
            Object.values(res.data.spares).forEach(spares => {
  
              if(spareStocks[spares['partNumber']])
                spares['spareStocks'] = spareStocks[spares['partNumber']];
              else
                spares['spareStocks'] = [];
                                
              if(brand['brandKey']==spares['brand']['key'] && spares['spareStocks']['quantity']>0){
                data.push(spares);
              }
  
            });

            if(data && data.length>0){
              this.sparesList.push(data);
            }
          });

          // console.log(this.brands)
          // console.log(this.sparesList)
        }
      }
    );
  }

  searchFilterType(){
    let searchText = (document.getElementsByName("searchText")[0]  as HTMLInputElement).value;
    if(searchText.length<=0 ){
      this.clearSearchFilter();
    }else{      
      this.openSearch = false;
      this.clearSearch = true;
    }
  }

  clearSearchFilter(){
    this.openSearch = true;
    this.clearSearch = false;
    this.searchText = '';
  }

  
  changeVehicle(){
    this.router.navigate(['auto-spare-parts/make']);
  }

  removeItem(count, item){ 
    this.addedCart = [];    
    let data;
    
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    if(count>1){
      let countval = count-1;
      data[item].cart_qty = countval;

      if(this.vehicleType=='universal'){
        data[item].type = 'universal';
      }

      this.addedCart[0] = data;
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
    }else{
      this.deleteItem(item);
      this.toastr.error(data[item].name+' removed from cart', 'Cart Item');
    }
  }

  deleteItem(item){
    this.newData = {};
    let cartData;

    cartData = JSON.parse(sessionStorage.getItem('addedCart'));
    Object.values(cartData[0]).forEach(cart => { 
      if(cart['key']!=item) {
        this.newData[cart['key']] = cart;
      }
    });
    
    this.addedCart[0] = this.newData;
    sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    this.getCart();
    // console.log(this.addedCart);
    
  }

  addItem(count, item){
    let countval = count+1;
    
    this.addedCart = [];
    let data;
    
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    if(countval<=data[item].spareStocks.quantity){
      data[item].cart_qty = countval;

      if(this.vehicleType=='universal'){
        data[item].type = 'universal';
      }

      this.addedCart[0] = data;
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
    }else{
      this.toastr.error('Only '+data[item].spareStocks.quantity+' unit available for this item', 'Error');
    }
  }


  addCart(items, count){
    // if(this.isReplacePopup==true){
    //   sessionStorage.setItem('replaceCart', JSON.stringify(items));
    //   this.zone.run(() => {
    //     this.dialog.open(AspReplaceCartPopupComponent);
    //   });
    // }else{
      this.setNewMakeAsMake();
        
      this.addedCart = [];
      if(this.vehicleType=='universal'){
        items.type = 'universal';
      }
      if(sessionStorage.getItem('addedCart')){
        this.newData = JSON.parse(sessionStorage.getItem('addedCart'))[0];
        items.cart_qty = count;
        this.newData[items.key] = items;
        this.addedCart.push(this.newData);
        sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      }else{
        items.cart_qty = count;
        this.newData[items.key] = items;
        this.addedCart.push(this.newData);
        sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      }      
     
      this.getCart();
    // }
  }

  getCart(){
    let cartData;  
    this.cartList = [];
    this.cartCount = 0;
    this.cartSubTotal = 0;
    this.cartGrandTotal = 0;
    
    if(sessionStorage.getItem('addedCart')){   
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      if(Object.keys(cartData[0]).length==0){
        this.isCartCount = false;
      } 
      
      Object.values(cartData[0]).forEach(cart => { 
        if(cart){
          this.isCartCount = true;
        }

        this.cartCount += cart['cart_qty'];
        if(cart['spareStocks']['sellingPrice']>0){
          this.cartSubTotal += (cart['spareStocks']['sellingPrice']*cart['cart_qty']);
        }else{
          this.cartSubTotal += (cart['spareStocks']['mrp']*cart['cart_qty']);
        }
      });      

      this.cartList = cartData;
      this.cartGrandTotal = this.cartSubTotal;
      // console.log(this.cartList);

      (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+'';
    }
  }


  goToCart(){
    this.router.navigate(['auto-spare-parts/cart']);
  }

  setNewMakeAsMake(){
    if(localStorage.getItem('newMake') && localStorage.getItem('newModel') && localStorage.getItem('newYear') && localStorage.getItem('newVariant')){
      localStorage.setItem('selectedMake', localStorage.getItem('newMake'));
      localStorage.setItem('selectedModel', localStorage.getItem('newModel'));
      localStorage.setItem('selectedYear', localStorage.getItem('newYear'));
      localStorage.setItem('variant', localStorage.getItem('newVariant'));
      localStorage.setItem('variantKey', localStorage.getItem('newVariantKey'));
      localStorage.setItem('rowKey', localStorage.getItem('newRowKey'));

      localStorage.removeItem('newMake');
      localStorage.removeItem('newModel');
      localStorage.removeItem('newYear');
      localStorage.removeItem('newVariant');
      localStorage.removeItem('newVariantKey');
      localStorage.removeItem('newRowKey');   
    } 
  }

  updateSelectedBrands(itemText: string, isChecked: boolean) {
    this.loading = true;
    if (isChecked) {
      this.selectedBrands.push(itemText);
    } else {
      const index = this.selectedBrands.indexOf(itemText);
      if (index !== -1) {
        this.selectedBrands.splice(index, 1);
      }
    }
    
    this.getCategorySparesList();
  }

  getAllCatPopup = () => {
    this.zone.run(() => {
      this.dialog.open(AspAllCategoriesPopupComponent);
    });
  }

  
  getStock(sku){
    this.loading = true;
    this.aspCategorySparesListService.getItemStock(sku).subscribe(
      result => {
        this.loading = false;
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code==200){
          let qty = res.data[0].quantity; 
          this.updateStock(sku,qty);
        }
      }
    );
  }

  updateStock(sku,qty){
    let itemUpdateStatus = 0;
    this.sparesList.forEach(spare => {
      spare.forEach(list => {
        if(list.spareStocks.partNumber==sku){
          list.spareStocks.quantity = qty;
          itemUpdateStatus = 1;
        }
      });
    });

    if(itemUpdateStatus==1){      
      this.toastr.success('Stock updated for SKU: '+sku, 'Stock Update');
    }else{
      this.toastr.error('Stock failed to update for SKU: '+sku, 'Stock Update');
    }
  }

  // scroll(id) {
  //   this.mouseEnter(id);

  //   let cat = id.split(' ').join('-');
  //   let el = document.getElementById(cat);
  //   el.scrollIntoView();
  // }

  mouseEnter(id){
    let oldElement = document.querySelectorAll('.tab-label');
    oldElement.forEach((ele) => {
      let hasClass = ele.classList.contains('tab-active');     
      if(hasClass){
        ele.classList.remove('tab-active');
      }
    });
    
    let cat = id.split(' ').join('-');
    let element = document.getElementsByClassName(cat)[0];
    element.classList.add("tab-active");
  }

}
