import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GlobalVariable } from 'app/main/globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from 'app/main/authentication/login/login.service';
const backDate = (numOfDays) => {
    const today1 = new Date();
    return new Date(today1.setDate(today1.getDate() - numOfDays));
};
@Injectable({ providedIn: 'root' })
export class GSTService implements Resolve<any> {
    gstData: any[];
    paramData: any;
    loginData: any;
    accountAppKey: any;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {
    }
    private gData(res: Response) {
        const body = res;
        return body || {};
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {
            Promise.all([
                this.getGst()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    getGst() {
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        const today = new Date();
        const minus7 = backDate(7);
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'merchantId': this.loginData.merchant.id,
            'fromDate': minus7.toISOString().split('T')[0].toString(),
            'toDate': today.toISOString().split('T')[0].toString(),
            'reportId': '21',
            'reportFor': 'gms'
        };
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            this.http.post(`${GlobalVariable.BASE_API_URL}` + '/gmsdeliveryreport', formData)
                .subscribe((response: any) => {
                    this.gstData = response;
                    resolve(response);
                }, reject);
        });
    }
    convertDate(t: any) {
        const date = new Date(t);
        const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join('-');
    }
    getGstCustomData(cToday: any, cLast: any) {
        const st = this.convertDate(cToday);
        const et = this.convertDate(cLast);
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        // this.paramData = {
        //     appKey: this.accountAppKey,
        //     sessionId: this.loginService.getToken(),
        //     language: `${GlobalVariable.LANGUAGE}`,
        //     device: `${GlobalVariable.DEVICE}`,
        //     merchantId: this.loginData.merchant.id,
        //     fromDate: st,
        //     toDate: et,
        //     reportId: '21',
        //     reportFor: 'gms'
        // };
        // return this.http.get(`${GlobalVariable.BASE_API_URL}` + '/gmsdeliveryreport', { params: this.paramData }).pipe(
        //     map(this.gData));
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'merchantId': this.loginData.merchant.id,
            'fromDate': st,
            'toDate': et,
            'reportId': '21',
            'reportFor': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/gmsdeliveryreport', formData);
    }

    getGstr2Data(cToday: any, cLast: any) {
        const st = this.convertDate(cToday);
        const et = this.convertDate(cLast);
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        // this.paramData = {
        //     appKey: this.accountAppKey,
        //     sessionId: this.loginService.getToken(),
        //     language: `${GlobalVariable.LANGUAGE}`,
        //     device: `${GlobalVariable.DEVICE}`,
        //     merchantId: this.loginData.merchant.id,
        //     fromDate: st,
        //     toDate: et,
        //     reportId: '22',
        //     reportFor: 'gms'
        // };
        // return this.http.get(`${GlobalVariable.BASE_API_URL}` + '/gmsgstreport', { params: this.paramData }).pipe(
        //     map(this.gData));
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'merchantId': this.loginData.merchant.id,
            'fromDate': st,
            'toDate': et,
            'reportId': '22',
            'reportFor': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/gmsgstreport', formData);
    }
    getGstr1Data(cToday: any, cLast: any) {
        const st = this.convertDate(cToday);
        const et = this.convertDate(cLast);
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        // this.paramData = {
        //     appKey: this.accountAppKey,
        //     sessionId: this.loginService.getToken(),
        //     language: `${GlobalVariable.LANGUAGE}`,
        //     device: `${GlobalVariable.DEVICE}`,
        //     merchantId: this.loginData.merchant.id,
        //     fromDate: st,
        //     toDate: et,
        //     reportId: '23',
        //     reportFor: 'gms'
        // };
        // return this.http.get(`${GlobalVariable.BASE_API_URL}` + '/gmsgstreport', { params: this.paramData }).pipe(
        //     map(this.gData));
        const data = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`,
            'merchantId': this.loginData.merchant.id,
            'fromDate': st,
            'toDate': et,
            'reportId': '23',
            'reportFor': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/gmsgstreport', formData);
    }
}
