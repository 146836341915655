<!-- <div class="not_allowed_msg" *ngIf="NotAllowMessage">
    <p>You are not authorized to create order.</p>
</div> -->
<div class="page-layout carded fullwidth bg_gray">
    <div class="top-bg order_bg"></div>
    <div class="center">
        <div
            class="header order_bg p-24"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <h2 class="m-0 txt_colr">Create New Order</h2>
        </div>
        <div class="bg_color_border border_radius">
            <div class="content">
                <mat-vertical-stepper [linear]="isLinear" #stepper>
                    <mat-step [stepControl]="firstFormGroup">
                        <form [formGroup]="firstFormGroup">
                            <ng-template matStepLabel
                                >Vehicle Registration No/Mobile
                                Number</ng-template
                            >
                            <div class="input_container form_section">
                                <div class="form_section">
                                    <mat-form-field class="example-full-width">
                                        <input
                                            type="text"
                                            placeholder="Vehicle Registration Number"
                                            aria-label="Number"
                                            formContolName="registrationVehicle"
                                            matInput
                                            [formControl]="myControl"
                                            [matAutocomplete]="auto"
                                            name="registrationNumber"
                                            [(ngModel)]="registrationNumber"
                                            (keyup)="getCeateOrderData($event)"
                                            (focus)="blankMobile()"
                                            (focusout)="
                                                checkAlphanumericFocus($event)
                                            "
                                            class="upper_text"
                                        />
                                        <mat-autocomplete
                                            autoActiveFirstOption
                                            #auto="matAutocomplete"
                                        >
                                            <mat-option
                                                *ngFor="
                                                    let option of orderResult
                                                "
                                                [value]="
                                                    option.registrationNumber
                                                "
                                            >
                                                {{ option.registrationNumber }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <div
                                        class="text_danger"
                                        *ngIf="registraitionError"
                                    >
                                        <small
                                            >Enter Valid Registration
                                            Number</small
                                        >
                                    </div>
                                </div>
                                <div class="form_section optionl">
                                    <h3>OR</h3>
                                </div>
                                <div class="form_section">
                                    <mat-form-field>
                                        <input
                                            type="text"
                                            matInput
                                            placeholder="Mobile Number"
                                            formControlName="mobileNumber"
                                            [(ngModel)]="mobileNumber"
                                            (keyup)="
                                                getVehicleDataByMobile(
                                                    mobileNumber,
                                                    $event
                                                )
                                            "
                                            (keypress)="onlyNumberAllow($event)"
                                            (focusout)="
                                                numberCheckFocus($event)
                                            "
                                        />
                                    </mat-form-field>
                                    <div
                                        class="suggestedResult"
                                        *ngIf="showResultSearch"
                                        id="hideMe"
                                        #_eref
                                    >
                                        <ul>
                                            <li
                                                *ngFor="
                                                    let mobileData of orderResult
                                                "
                                                (click)="
                                                    getmobileNumbValue($event)
                                                "
                                            >
                                                {{ mobileData.mobile }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="text_danger"
                                        *ngIf="mobileValueError"
                                    >
                                        <small>Enter Valid Mobile Number</small>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="button_section"
                                *ngIf="proceedByRegistration"
                            >
                                <button
                                    mat-button
                                    matStepperNext
                                    class="nextBtn reg"
                                    [disabled]="disableBtn"
                                    (click)="
                                        getVehicleRegData(registrationNumber)
                                    "
                                >
                                    Proceed
                                </button>
                            </div>
                            <div class="button_section" *ngIf="proceedByMobile">
                                <button
                                    mat-button
                                    matStepperNext
                                    class="nextBtn"
                                    [disabled]="firstFormGroup.invalid"
                                    (click)="getVehicleMobileData(mobileNumber)"
                                >
                                    Proceed
                                </button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="secondFormGroup">
                        <form [formGroup]="secondFormGroup">
                            <div class="selected_vehicle">
                                <div class="registration_num">
                                    <p class="v_text">
                                        Vehicle Registration Number
                                    </p>
                                    <p class="numb" *ngIf="vanRegistration">
                                        {{ registrationNumber }}
                                    </p>
                                    <p class="numb" *ngIf="mobileRegistration">
                                        {{
                                            this.vehicleRegAutoData
                                                .vehicleRegistration
                                        }}
                                    </p>
                                </div>
                                <div class="change_num">
                                    <button
                                        mat-button
                                        matStepperPrevious
                                        class="change_btn"
                                    >
                                        <i class="material-icons">
                                            edit </i
                                        >Change
                                    </button>
                                </div>
                            </div>
                            <ng-template matStepLabel
                                >Vehicle Details</ng-template
                            >
                            <div class="input_container form_section">
                                <div class="input_fields field_v">
                                    <span
                                        class="full_fields vehiclToggle border_inpt"
                                        (click)="openVehicleList()"
                                        ><span *ngIf="!selectedSearchText"
                                            >Select Vehicle </span
                                        ><span *ngIf="selectedSearchText">{{
                                            selectedSearchText
                                        }}</span></span
                                    >
                                    <div class="vehicle_ps vehiclToggle">
                                        <div
                                            class="suggestedResult_booking search_fild"
                                            *ngIf="showVehicleResult"
                                            (window:mouseup)="
                                                autoCloseForDropdownCars($event)
                                            "
                                        >
                                            <mat-form-field
                                                class="full_fields vehiclToggle"
                                            >
                                                <input
                                                    type="text"
                                                    class="ddd"
                                                    placeholder="Search Vehicle"
                                                    aria-label="Number"
                                                    matInput
                                                    #vehicleSec
                                                    [formControl]="
                                                        vehicleControl
                                                    "
                                                    [(ngModel)]="searchText"
                                                    (click)="openVehicleList()"
                                                />
                                            </mat-form-field>
                                            <ul>
                                                <li
                                                    *ngFor="
                                                        let vehicle of carModel
                                                            | filter
                                                                : searchText;
                                                        let i = index
                                                    "
                                                    (click)="
                                                        vehicleSelected(vehicle)
                                                    "
                                                    #vehcl
                                                >
                                                    <p class="chassis_p">
                                                        {{ vehicle }}
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="input_fields">
                                    <mat-form-field class="no_border">
                                        <mat-select
                                            placeholder="Select Fuel Type"
                                            formControlName="fuelType"
                                            [(ngModel)]="vehicleFuelType"
                                        >
                                            <mat-option
                                                *ngFor="
                                                    let fueltype of vehiclefuelType
                                                "
                                                [value]="fueltype.value"
                                                id="vh_model"
                                            >
                                                {{ fueltype.viewValue }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="input_fields">
                                    <mat-form-field>
                                        <input
                                            type="number"
                                            matInput
                                            placeholder="Odometer Reading"
                                            formControlName="odometer"
                                            [(ngModel)]="odometer"
                                            required
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="button_section">
                                <button
                                    mat-button
                                    matStepperPrevious
                                    class="prevBtn"
                                >
                                    Previous
                                </button>
                                <button
                                    mat-button
                                    matStepperNext
                                    class="nextBtn"
                                    [disabled]="secondFormGroup.invalid"
                                >
                                    Proceed
                                </button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="thirdFormGroup">
                        <form [formGroup]="thirdFormGroup">
                            <div class="selected_vehicle">
                                <div class="registration_num">
                                    <p class="v_text">Vehicle Details</p>
                                    <p class="numb">{{ registrationNumber }}</p>
                                    <p>
                                        {{ this.selectedTitle }}({{
                                            vehicleFuelType
                                        }})
                                    </p>
                                    <p>{{ odometer }} KMS</p>
                                </div>
                                <div class="change_num">
                                    <button
                                        mat-button
                                        matStepperPrevious
                                        class="change_btn"
                                    >
                                        <i class="material-icons">
                                            edit </i
                                        >Change
                                    </button>
                                </div>
                            </div>
                            <ng-template matStepLabel
                                >Customer Details</ng-template
                            >
                            <div class="input_container form_section">
                                <div class="input_fields">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            placeholder=" Customer Name"
                                            formControlName="name"
                                            [(ngModel)]="
                                                this.vehicleRegAutoData.name
                                            "
                                            required
                                        />
                                    </mat-form-field>
                                </div>
                                <!-- <div class="input_fields">
                                                   
                                                    <mat-form-field>
                                                        <mat-select placeholder="Country Code" #mycountryCode formControlName="countryCode" name="countryCode" (ngModelChange)=getCountryCode(mycountryCode.value) required>
                                                            <mat-option *ngFor="let code of countryCodes" [value]="code.value" >
                                                                  {{code.viewValue}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                             </div> -->
                                <div class="input_fields mobil_code">
                                    <select
                                        class="country_code"
                                        #mycountryCode
                                        formControlName="countryCode"
                                        name="countryCode"
                                        (ngModelChange)="
                                            getCountryCode(mycountryCode.value)
                                        "
                                    >
                                        <option value="91">91</option>
                                        <option value="65">65</option>
                                    </select>
                                    <mat-form-field>
                                        <input
                                            matInput
                                            placeholder="Customer Mobile Number"
                                            #mobileNum
                                            formControlName="mobile"
                                            (keypress)="onlyNumberAllow($event)"
                                            [(ngModel)]="mobileNumber"
                                            (focusout)="
                                                numberCheckFocus($event)
                                            "
                                            required
                                        />
                                    </mat-form-field>
                                    <div
                                        class="text_danger"
                                        *ngIf="mobileValueError"
                                    >
                                        <small>Enter Valid Mobile Number</small>
                                    </div>
                                </div>
                                <div class="registraion_f">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            placeholder=" Vehicle Rgistration No"
                                            class="upper_text"
                                            formControlName="registrationNumber"
                                            [(ngModel)]="registrationNumber"
                                            required
                                        />
                                    </mat-form-field>
                                    <div
                                        class="text_danger"
                                        *ngIf="registraitionError"
                                    >
                                        <small
                                            >Enter Valid Registration
                                            Number</small
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="input_container form_section">
                                <div class="input_fields">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            type="email"
                                            placeholder="Customer Email ID"
                                            formControlName="email"
                                            [(ngModel)]="
                                                this.vehicleRegAutoData.email
                                            "
                                            required
                                        />
                                    </mat-form-field>
                                </div>
                                <div class="input_fields auto_complet">
                                    <!-- <mat-label>Colony/Street/Locality</mat-label> -->
                                    <mat-google-maps-autocomplete
                                        [appearance]="appearance.STANDARD"
                                        (onAutocompleteSelected)="
                                            onAutocompleteSelected($event)
                                        "
                                        (onLocationSelected)="
                                            onLocationSelected($event)
                                        "
                                        placeholder="Locality"
                                    >
                                    </mat-google-maps-autocomplete>
                                </div>

                                <div class="input_fields">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            placeholder=" House No./Flat No."
                                            formControlName="line1"
                                            required
                                        />
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="button_section">
                                <button
                                    mat-button
                                    matStepperPrevious
                                    class="prevBtn"
                                >
                                    Previous
                                </button>
                                <button
                                    mat-button
                                    matStepperNext
                                    class="nextBtn"
                                    [disabled]="thirdFormGroup.invalid"
                                    (click)="addGmsUpdateUser($event)"
                                >
                                    Proceed
                                </button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="fourthFormGroup">
                        <form [formGroup]="fourthFormGroup">
                            <div class="selected_vehicle">
                                <div class="registration_num">
                                    <p class="v_text">Customer Details</p>
                                    <p class="numb">
                                        {{ this.vehicleRegAutoData.name }}
                                    </p>
                                    <p>+91 {{ mobileNumber }}</p>
                                    <p>{{ this.fullAddress }}</p>
                                </div>
                                <div class="change_num">
                                    <button
                                        mat-button
                                        matStepperPrevious
                                        class="change_btn"
                                    >
                                        <i class="material-icons">
                                            edit </i
                                        >Change
                                    </button>
                                </div>
                            </div>
                            <ng-template matStepLabel
                                >Appointment Details</ng-template
                            >
                            <p class="choose_type">Select Order Type</p>
                            <div class="input_container form_section">
                                <div class="input_fields">
                                    <label class="order_type"
                                        >Pickup
                                        <input
                                            type="radio"
                                            value="pickup"
                                            formControlName="orderType"
                                        />
                                        <span class="checkmark_order"></span>
                                    </label>
                                </div>

                                <div
                                    class="input_fields"
                                    *ngIf="!renaultConvenience"
                                >
                                    <label class="order_type"
                                        >Doorstep
                                        <input
                                            type="radio"
                                            value="doorstep"
                                            formControlName="orderType"
                                        />
                                        <span class="checkmark_order"></span>
                                    </label>
                                </div>
                                <div
                                    class="input_fields"
                                    *ngIf="renaultConvenience"
                                >
                                    <label class="order_type"
                                        >Self
                                        <input
                                            type="radio"
                                            value="self"
                                            formControlName="orderType"
                                        />
                                        <span class="checkmark_order"></span>
                                    </label>
                                </div>
                            </div>
                            <p class="choose_type">Select Service Type</p>
                            <div class="input_container form_section">
                                <div class="input_fields">
                                    <label class="service_type"
                                        >PMS
                                        <input
                                            type="radio"
                                            value="general_service"
                                            name="serviceType"
                                            formControlName="serviceType"
                                        />
                                        <span class="checkmark_service"></span>
                                    </label>
                                </div>

                                <div class="input_fields">
                                    <label class="service_type"
                                        >Body Repair
                                        <input
                                            type="radio"
                                            value="body_repair"
                                            name="serviceType"
                                            formControlName="serviceType"
                                        />
                                        <span class="checkmark_service"></span>
                                    </label>
                                </div>
                                <div class="input_fields">
                                    <label class="service_type"
                                        >Inspection
                                        <input
                                            type="radio"
                                            value="inspection"
                                            name="serviceType"
                                            formControlName="serviceType"
                                        />
                                        <span class="checkmark_service"></span>
                                    </label>
                                </div>
                                <div class="input_fields">
                                    <label class="service_type"
                                        >Maintenance
                                        <input
                                            type="radio"
                                            value="maintenance"
                                            id="radio_m1"
                                            name="serviceType"
                                            formControlName="serviceType"
                                        />
                                        <span class="checkmark_service"></span>
                                    </label>
                                </div>
                                <div class="input_fields">
                                    <label class="service_type"
                                        >Emergency
                                        <input
                                            type="radio"
                                            value="rsa"
                                            name="serviceType"
                                            formControlName="serviceType"
                                        />
                                        <span class="checkmark_service"></span>
                                    </label>
                                </div>
                                <div class="input_fields">
                                    <label class="service_type"
                                        >Washing
                                        <input
                                            type="radio"
                                            value="washing"
                                            id="radio_m2"
                                            name="serviceType"
                                            formControlName="serviceType"
                                        />
                                        <span class="checkmark_service"></span>
                                    </label>
                                </div>
                                <div class="input_fields">
                                    <label class="service_type"
                                        >Other
                                        <input
                                            type="radio"
                                            value="other"
                                            id="radio_m3"
                                            name="serviceType"
                                            formControlName="serviceType"
                                        />
                                        <span class="checkmark_service"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="input_container form_section">
                                <div class="input_fields">
                                    <input
                                        [owlDateTime]="dt1"
                                        [owlDateTimeTrigger]="dt1"
                                        class="date_time"
                                        placeholder="Select Appointment Date & Time"
                                        formControlName="orderStartTime"
                                        #orderStartTime
                                        required
                                    />
                                    <owl-date-time #dt1></owl-date-time>
                                </div>
                                <div class="input_fields text_area">
                                    <mat-form-field class="example-full-width">
                                        <textarea
                                            matInput
                                            placeholder="Customer Notes"
                                            formControlName="customerNotes"
                                        ></textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="button_section">
                                <button
                                    mat-button
                                    matStepperPrevious
                                    class="prevBtn"
                                >
                                    Previous
                                </button>
                                <button
                                    mat-button
                                    class="nextBtn"
                                    (click)="createOrder($event)"
                                    [disabled]="fourthFormGroup.invalid"
                                >
                                    Create Order
                                </button>
                            </div>
                        </form>
                    </mat-step>
                </mat-vertical-stepper>
            </div>
        </div>
    </div>
</div>
<div class="loader" *ngIf="showLoader">
    <mat-spinner></mat-spinner>
</div>
<div class="success_container" *ngIf="showSuccessMessage">
    <div class="success_section mat-dialog-container">
        <h3 class="success_msg text_danger" *ngIf="orderError">
            {{ successMessage }}
        </h3>
        <h3 class="success_msg text_success" *ngIf="ordersuccess">
            {{ successMessage }}
        </h3>
    </div>
</div>
