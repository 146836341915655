
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import { GlobalVariable } from 'app/main/globals';
import * as FileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import * as XLSX from 'xlsx';
import { SearchBookingsService } from './search-bookings.service';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface SearchOrdersData {
  leadId: string;
  leadCreated: string;
  make: string;
  accountName: any;
  model: string;
  leadStatus: any;
}

@Component({
  selector: 'app-search-bookings',
  templateUrl: './search-bookings.component.html',
  styleUrls: ['./search-bookings.component.scss']
}

) export class SearchBookingsComponent implements OnInit {
  token: any;
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  searchOrders: any;
  searchOrdersList: any;
  searchOrdersData: any = [];
  searchOrdersSource: MatTableDataSource<SearchOrdersData>;
  searchOrdersColumns: string[] = ['leadId', 'leadCreated', 'make', 'accountName', 'leadStatus'];
  renderedData: any;
  dataAvialable = true;
  noData = false;
  dialogId: number;
  rDialogData: any;
  dataCSV: any;
  timezone: any;
  format: any;
  moment = moment();
  selectedValue = '';
  makeImg: any = [];
  makeUrl: any;
  @ViewChild('TABLE', { static: false }) table: ElementRef;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort; s;
  /**
   * Constructor
   * @param {Router} router
   * @param {LoginService} loginService
   * @param {SearchOrdersService} rService
   * @param {Papa} papa
   */
  constructor(
    public router: Router,
    private loginService: LoginService,
    private soService: SearchBookingsService,
    public dialog: MatDialog,
    private appComponent: AppComponent,
    private _formBuilder: FormBuilder,
  ) {
    this.token = this.loginService.getToken();
    if (!this.token) {
      this.router.navigate(['/auth/login']);
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.timezone = this.loginService.getTimezone();
    this.format = this.loginService.getFormat();
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
    this.searchForm = this._formBuilder.group({
      condition: ['', [Validators.required]],
      query: ['', [Validators.required]]
    });
  }
  ngOnInit() {
  }
  get f() { return this.searchForm.controls; }
  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }
    this.loading = true;
    this.getSearchOrdersData(this.f.condition.value, this.f.query.value);
    this.submitted = true;
  }
  getSearchOrdersData(condition: string, query: string) {
    this.soService.getOrders(condition, query).subscribe((data: {}) => {
      this.searchOrdersList = data;
      if (this.searchOrdersList.response.status === 'SUCCESS') {
        this.searchOrders = this.searchOrdersList.response.body.leads;
        if (this.searchOrders.length === 0) {
          this.dataAvialable = false;
          this.noData = true;
          this.loading = false;
        } else {
          this.dataAvialable = true;
          this.noData = false;
          this.searchOrdersData = [];
          this.searchOrders.forEach(searchOrderList => {
            this.searchOrdersData.push(this.createSearchbookingData(searchOrderList));
          });
          this.makeUrl = `${GlobalVariable.BASE_MAKE_IMAGE_URL}`;
          this.searchOrdersSource = new MatTableDataSource(this.searchOrdersData);
          this.searchOrdersSource.connect().subscribe(d => this.renderedData = d);
          this.dataCSV = this.renderedData;
          this.loading = false;
          setTimeout(() => {
            this.searchOrdersSource.paginator = this.paginator;
            this.searchOrdersSource.sort = this.sort;
            this.makeImg.paginator = this.paginator;
          }, 500);
        }
      }
      else {
        this.loading = false;
        this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
        setTimeout(() => {
          this.loginService.logout();
        }, 1000);
      }
    });
  }
  applyFilter(filterValue: string) {
    this.searchOrdersSource.filter = filterValue.trim().toLowerCase();
    if (this.searchOrdersSource.paginator) {
      this.searchOrdersSource.paginator.firstPage();
    }
  }
  createSearchbookingData(data: any): SearchOrdersData {
    return {
      leadId: data.leadId.toString(),
      leadCreated: moment.utc(data.leadCreated).tz(this.timezone).format(),
      make: data.make.toString(),
      accountName: data.accountName.toString(),
      model: data.model.toString(),
      leadStatus: data.leadStatus.toString()
    };
  }
  exportEXCEL() {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Searched_Orders_list');
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  openDetails(id: number) {
    this.router.navigate(['bookings/details/', id]);
  }
}
