import { Component, OnInit } from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-asp-shipping-charges-bottom-sheet',
  templateUrl: './asp-shipping-charges-bottom-sheet.component.html',
  styleUrls: ['./asp-shipping-charges-bottom-sheet.component.scss']
})
export class AspShippingChargesBottomSheetComponent implements OnInit {

  constructor(    
    private _bottomSheetRef: MatBottomSheetRef<AspShippingChargesBottomSheetComponent>
  ) { }

  ngOnInit(): void {
  }

  closePopup(){
      this._bottomSheetRef.dismiss();
  }


}
