<mat-dialog-content class="mat-typography">
    <div class="payout-details page-layout carded fullwidth p-0" fusePerfectScrollbar>
        <div class="page-title" fxLayout="row" fxLayoutAlign="flex-start center">
            <div class="f-block text-left" fxLayout="row" fxLayoutAlign="flex-start center">
                <span class="mat-display-1 m-0 p-16 pl-0">Payout Details</span>
                <div class="export-button ml-16">
                    <button [disabled]="noData" (click)="exportEXCEL()" mat-raised-button color="accent">
                        Export
                        <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
            <div class="s-block text-right" fxLayout="row" fxLayoutAlign="flex-end center">
                <div class="p-0">
                    <mat-form-field>
                        <input [disabled]="noData" autofocus="false" matInput (keyup)="applyFilter($event.target.value)"
                            placeholder="Filter">
                    </mat-form-field>
                </div>
                <div class="close-btn ml-16" (click)="onNoClick()" mat-dialog-close>
                    <mat-icon>close</mat-icon>
                </div>
            </div>
        </div>
        <div class="mat-elevation-z8 mt-8" *ngIf="!isSingapore">
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <table [hidden]="loading" class="clickable" mat-table [dataSource]="payoutDetailsSource" matSort
                matSortActive="orderId" matSortDirection="desc" matSortDisableClear>
                <ng-container matColumnDef="orderId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER ID</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.orderId}}</strong> </td>
                </ng-container>

                <ng-container matColumnDef="grandTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.grandTotal == 0 ? '-' : row.grandTotal | currency:"&#8377; "}}</td>
                </ng-container>

                <ng-container matColumnDef="partsTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>PARTS TOTAL</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.partsTotal == 0 ? '-' : row.partsTotal | currency:"&#8377; "}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="labourTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>LABOUR TOTAL</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.labourTotal == 0 ? '-' : row.labourTotal | currency:"&#8377; "}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="partsCommission">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>PARTS COMMISSION</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.partsCommission == 0 ? '-' : row.partsCommission | currency:"&#8377; "}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="labourCommission">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>LABOUR COMMISSION</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.labourCommission == 0 ? '-' : row.labourCommission | currency:"&#8377; "}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="commission">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>COMMISSION</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.commission == 0 ? '-' : row.commission | currency:"&#8377; "}}</td>
                </ng-container>

                <ng-container matColumnDef="commissionGst">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>COMMISSION GST</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.commissionGst == 0 ? '-' : row.commissionGst | currency:"&#8377; "}}</td>
                </ng-container>

                <ng-container matColumnDef="tdsValue">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>TDS</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.tdsValue == 0 ? '-' : row.tdsValue | currency:"&#8377; "}}</td>
                </ng-container>

                <ng-container matColumnDef="payout">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>PAYOUT</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.payout == 0 ? '-' : row.payout | currency:"&#8377; "}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="payoutDetailsColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: payoutDetailsColumns;"></tr>
            </table>
            <div [hidden]="loading" *ngIf="noData">
                <div class="message-box error mt-16 text-center font-weight-700">
                    No Data to display
                </div>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>

        <!-- ----------------singapore----------------- -->
        <div class="mat-elevation-z8 mt-8" *ngIf="isSingapore">
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <table [hidden]="loading" class="clickable" mat-table [dataSource]="payoutDetailsSource" matSort
                matSortActive="orderId" matSortDirection="desc" matSortDisableClear>
                <ng-container matColumnDef="orderId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER ID</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.orderId}}</strong> </td>
                </ng-container>

                <ng-container matColumnDef="grandTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.grandTotal == 0 ? '-' : row.grandTotal | currency:"S&#36; "}}</td>
                </ng-container>

                <ng-container matColumnDef="partsTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>PARTS TOTAL</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.partsTotal == 0 ? '-' : row.partsTotal | currency:"S&#36; "}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="labourTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>LABOUR TOTAL</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.labourTotal == 0 ? '-' : row.labourTotal | currency:"S&#36; "}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="partsCommission">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>PARTS COMMISSION</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.partsCommission == 0 ? '-' : row.partsCommission | currency:"S&#36; "}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="labourCommission">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>LABOUR COMMISSION</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.labourCommission == 0 ? '-' : row.labourCommission | currency:"S&#36; "}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="commission">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>COMMISSION</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.commission == 0 ? '-' : row.commission | currency:"S&#36; "}}</td>
                </ng-container>

                <ng-container matColumnDef="commissionGst">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>COMMISSION GST</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.commissionGst == 0 ? '-' : row.commissionGst | currency:"S&#36; "}}</td>
                </ng-container>

                <ng-container matColumnDef="tdsValue">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>TDS</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.tdsValue == 0 ? '-' : row.tdsValue | currency:"S&#36; "}}</td>
                </ng-container>

                <ng-container matColumnDef="payout">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>PAYOUT</strong> </th>
                    <td mat-ripple mat-cell *matCellDef="let row">
                        {{row.payout == 0 ? '-' : row.payout | currency:"S&#36; "}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="payoutDetailsColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: payoutDetailsColumns;"></tr>
            </table>
            <div [hidden]="loading" *ngIf="noData">
                <div class="message-box error mt-16 text-center font-weight-700">
                    No Data to display
                </div>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</mat-dialog-content>