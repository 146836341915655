import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AspVariantService} from './asp-variant.service';
import {AspLoginService} from '../asp-login/asp-login.service';

@Component({
  selector: 'app-asp-variant',
  templateUrl: './asp-variant.component.html',
  styleUrls: ['./asp-variant.component.scss']
})
export class AspVariantComponent implements OnInit {

  loading:boolean = false;
  searchText:string = '';
  variantListPetrol:any = [];
  variantListDiesel:any = [];
  variantListCNG:any = [];
  openSearch:boolean = true;
  clearSearch:boolean = false;
  selectedMake: any;
  selectedModel: any;
  selectedYear: any;
  rowKey: any;
  spareSelectedGarageName: any;

  constructor(
    private router: Router,
    private aspVariantService: AspVariantService,
    private aspLoginService: AspLoginService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.aspLoginService.checkLogin();

    if(localStorage.getItem('newMake'))
      this.selectedMake = localStorage.getItem('newMake');
    else
      this.selectedMake = localStorage.getItem('selectedMake');

    if(localStorage.getItem('newModel'))
      this.selectedModel = localStorage.getItem('newModel');
    else
      this.selectedModel = localStorage.getItem('selectedModel');

    if(localStorage.getItem('newYear'))
      this.selectedYear = localStorage.getItem('newYear');
    else
      this.selectedYear = localStorage.getItem('selectedYear');
      
    if(localStorage.getItem('newRowKey'))
      this.rowKey = localStorage.getItem('newRowKey');
    else
      this.rowKey = localStorage.getItem('rowKey');


    this.getVariantData(this.rowKey, this.selectedYear);   
    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName"); 
  }

    
  getVariantData(rowKey, year){
    this.aspVariantService.getVariants(rowKey, year).subscribe(
      result => {
        this.loading = false;
        let res = JSON.parse(JSON.stringify(result)); 

        Object.values(res.data).forEach(list => {
          // Petrol
          if(list['fuelType'] == 'Petrol'){
            this.variantListPetrol.push(list);  
          }

          // Diesel
          if(list['fuelType'] == 'Diesel'){
            this.variantListDiesel.push(list);  
          }

          // CNG
          if(list['fuelType'] == 'CNG'){
            this.variantListCNG.push(list);  
          }        
        });    
        // console.log(this.variantListCNG);    
      }
    );    
  }  

  selectVariant(variantKey: any, variant: any, fuelType: any) {    
    variant = variant+' - '+fuelType;
    if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant') && localStorage.getItem('variantKey')){
      localStorage.setItem('newVariantKey', variantKey);
      localStorage.setItem('newVariant', variant);
    }else{
      localStorage.setItem('variantKey', variantKey);
      localStorage.setItem('variant', variant);
    }

    if(localStorage.getItem('selectedBrand')){
      this.router.navigate(['/auto-spare-parts/brand-spares-list']);
    }else if(localStorage.getItem('selectedCategory')){
      this.router.navigate(['/auto-spare-parts/category-spares-list']);
    }else{
      this.router.navigate(['/auto-spare-parts/spare-parts-list']);
    }

  }

  searchFilterType(){
    this.openSearch = false;
    this.clearSearch = true;
  }

  clearSearchFilter(){
    this.openSearch = true;
    this.clearSearch = false;
    this.searchText = '';
  }

  goToMake(){      
    this.router.navigate(['/auto-spare-parts/make']);
  }

  goToModel(){      
    this.router.navigate(['/auto-spare-parts/model']);
  }

  goToYear(){      
    this.router.navigate(['/auto-spare-parts/year']);
  }
    
}