import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { PayoutDetailsComponent } from './payout-details/payout-details.component';
import { PayoutsComponent } from './payouts.component';
import { PayoutsService } from './payouts.service';


const routes: Routes = [
    {
        path: 'payouts',
        component: PayoutsComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
            data: PayoutsService
        }
    }
];

@NgModule({
    declarations: [
        PayoutsComponent,
        PayoutDetailsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatListModule,
        MatDividerModule,
        MatCardModule,
        MatGridListModule,
        MatDialogModule,
        MatChipsModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatProgressBarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule
    ],
    exports: [
        PayoutsComponent,
        PayoutDetailsComponent
    ],
    providers: [PayoutsService],
    entryComponents: [PayoutDetailsComponent]
})

export class PayoutsModule {
}
