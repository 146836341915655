import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment-timezone';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import { DashboardOrdersService } from './dashboard-orders.service';
import { PickupStatus, DoorstepStatus } from 'app/main/globals';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { DashboardService } from 'app/main/dashboard/dashboard.service';
import { first } from 'rxjs/operators';
import { BaseChartDirective } from 'ng2-charts';
import { CalendarView, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { startOfDay, subDays, addDays, endOfMonth, addHours, endOfDay, isSameMonth, isSameDay } from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface LiveOrdersData {
    orderId: string;
    registration: string;
    fuelType: string;
    vehicle: string;
    orderStartTime: any;
    orderEndTime: any;
}
export interface PendingApprovalOrdersData {
    orderId: string;
    registrationNumer: string;
    merchantName: string;
    ownerName: any;

}
export interface CompletedOrders {
    ownerName: any;
    orderId: string;
    registrationNumer: string;
    merchantName: string;
}
export interface PendingQuotation {
    ownerName: any;
    orderId: string;
    registrationNumer: string;
    merchantName: string;
}

export interface OngoingOrders {
    ownerName: any;
    orderId: string;
    registrationNumer: string;
    merchantName: string;
}
const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#973bcc',
        secondary: '#973bcc'
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
    },
    green: {
        primary: '#6dcb99',
        secondary: '#6dcb99'
    },
    brown: {
        primary: '#ccab6e',
        secondary: '#ccab6e'
    }

};
const backDate = (numOfDays) => {
    const today1 = new Date();
    return new Date(today1.setDate(today1.getDate() - numOfDays));
};
@Component({
    selector: 'dashboard-orders',
    templateUrl: './dashboard-orders.component.html',
    styleUrls: ['./dashboard-orders.component.scss']
}

) export class DashboardOrdersComponent implements OnInit {
    token: any;
    liveOrders: any;
    liveOrdersList: any;
    liveOrdersData: any = [];
    PendingApprovalOrdersData: any = [];
    liveOrdersSource: MatTableDataSource<LiveOrdersData>;
    pendingApprovalOrderSource: MatTableDataSource<PendingApprovalOrdersData>;
    CompletedOrders: any = [];
    completedOrderDataSource: MatTableDataSource<CompletedOrders>;
    PendingQuotation: any = [];
    pendingQuotationDataSource: MatTableDataSource<PendingQuotation>;
    OngoingOrders: any = [];
    ongoingDataSource: MatTableDataSource<OngoingOrders>;
    ongoingColumns: string[] = ['orderId', 'registrationNumer', 'merchantName', 'ownerName'];
    pendingQuotationColumns: string[] = ['orderId', 'registrationNumer', 'merchantName', 'ownerName'];
    completedOrdersColumns: string[] = ['orderId', 'registrationNumer', 'merchantName', 'ownerName'];
    pendingApprovalOrdersColumns: string[] = ['orderId', 'registrationNumer', 'merchantName', 'ownerName'];
    liveOrdersColumns: string[] = ['orderId', 'registration', 'orderStartTime', 'orderEndTime'];
    renderedData: any;
    renderedData2: any;
    renderedData3: any;
    renderedData4: any;
    noQuoteExpData = false;
    dialogId: number;
    rDialogData: any;
    dataCSV: any;
    timezone: any;
    format: any;
    moment = moment();
    isSingapore = false;
    presets: Array<PresetItem> = [];
    options: NgxDrpOptions;
    dashData: any;
    widgets: any;
    revenue: string;
    orders: string;
    npsRating: string;
    rDashData: any;
    rWidgets: any;
    revenueNum: number;
    revenueActive = false;
    chartTime = 'daily';
    daily = 'daily';
    weekly = 'weekly';
    monthly = 'monthly';
    loading = false;
    public dData: Array<any> = [];
    public dLabels: Array<any> = [];
    public wData: Array<any> = [];
    public wLabels: Array<any> = [];
    public mData: Array<any> = [];
    public mLabels: Array<any> = [];
    public lineChartData: Array<any> = [];
    public lineChartLabels: Array<any> = [];
    orderStatsDashboard: any;
    range: Range = { fromDate: new Date(), toDate: new Date() };
    orderDashboardResult: any;
    noQuotationOrders: any = [];
    pendingApprovalOrders: any = [];
    totalOngoingOrder: any;
    totalpendingQuotationOrder: any;
    totalPendingApprovalOrder: any;
    totalCompletedOrder: any;
    orderAprovalPending: any;
    noPendingQuotation = false;
    noCompletedOrderData = false;
    noOngoingData = false;
    noPendingApproval = false;
    noApprovalExpData = false;
    noOngoingExpData = false;
    noCompletedExpData = false;
    loadingDashboard = true;
    activeQuotation = false;
    activeApproval = false;
    activeDelayed = false;
    activeDelivery = true;
    showCalendarView = false;
    calendarDataResult: any;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild('pendingApprovalPaginator', { static: false }) pendingApprovalPaginator: MatPaginator;
    @ViewChild('ongoingPaginator', { static: false }) ongoingPaginator: MatPaginator;
    @ViewChild('completedPaginator', { static: false }) completedPaginator: MatPaginator;
    @ViewChild('quotationSort', { static: false }) quotationSort: MatSort;
    @ViewChild('approvalSort', { static: false }) approvalSort: MatSort;
    @ViewChild('ongoingSort', { static: false }) ongoingSort: MatSort;
    @ViewChild('completeSort', { static: false }) completeSort: MatSort;
    @ViewChild('dateRangePicker', { static: false }) dateRangePicker;
    @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
    // -------calendar----------------
    @ViewChild("modalContent", { static: true }) modalContent: TemplateRef<any>;
    view: CalendarView = CalendarView.Month;
    View = CalendarView;
    viewDate: Date = new Date();
    // view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;
    modalData: {
        action: string;
        event: CalendarEvent;
    };
    refresh: Subject<any> = new Subject();

    events: CalendarEvent[] = [
    ];
    activeDayIsOpen = true;
    // -----------calendar-----------------
    dayClicked({
        date,
        events
    }: {
        date: Date;
        events: CalendarEvent[];
    }): void {
        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) &&
                    this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }
    eventTimesChanged({
        event,
        newStart,
        newEnd
    }: CalendarEventTimesChangedEvent): void {
        this.events = this.events.map(iEvent => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd
                };
            }
            return iEvent;
        });
        // this.handleEvent("Dropped or resized", event);
    }

    addEvent(): void {
        this.events = [
            ...this.events,
            {
                title: "New event",
                start: startOfDay(new Date()),
                end: endOfDay(new Date()),
                color: colors.red,
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true
                }
            }
        ];
    }

    deleteEvent(eventToDelete: CalendarEvent) {
        this.events = this.events.filter(event => event !== eventToDelete);
    }

    setView(view: CalendarView) {
        this.view = view;
    }
    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }
    // -------------/calendar----------------
    /**
     * Constructor
     * @param {Router} router
     * @param {LoginService} loginService
     * @param {DashboardOrdersService} rService
     * @param {Papa} papa
     */
    constructor(
        public router: Router,
        private loginService: LoginService,
        private doService: DashboardOrdersService,
        public dialog: MatDialog,
        // private modal: NgbModal,
        private appComponent: AppComponent,
    ) {
        this.getOrderDashboardData();
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        const today = new Date();
        const lastMonth = backDate(30);
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
    }
    ngOnInit() {
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        }
        else {
            this.isSingapore = false;
        }
        this.getCalendarData();
    }
    openCalendarView(){
     this.showCalendarView = !this.showCalendarView;
    }
    getCalendarData(){
        this.doService.calendarOrdersData().subscribe(result => {
            this.calendarDataResult = result;
            if(this.calendarDataResult.response.status === 'SUCCESS'){
                this.loadingDashboard = false;
                this.calendarDataResult.response.body.calenderData.forEach(data => {
                this.events = [
                    ...this.events,
                    {
                        title: 'Order Id - ' + data.orderId + ' ' + data.jobStatus + ' ' + ( data.drivername ? ' ( ' + data.drivername + ' ) ' : '') + ' ',
                        start: new Date(data.date),
                        end: new Date(data.date),
                        color: data.jobStatus === ' Technician Not Assigned ' ? colors.red: data.jobStatus === 'Technician Assigned' ? colors.green :
                        data.jobStatus == 'Driver Not Assigned' ? colors.blue : data.jobStatus == 'Driver Assigned' ? colors.brown : colors.yellow,
                        draggable: false,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true
                        }
                    }
                ];
                });
            }
            else{
                this.loginService.logout();
            }
        },
        error=>{
            console.log(error);
        });
    }
    getOrderDashboardData() {
        this.orderDashboardResult = this.doService.orderDashboardResult;
        if (this.orderDashboardResult.response.status === 'SUCCESS') {
            this.loadingDashboard = false;
            this.orderStatsDashboard = this.orderDashboardResult.response.body.ordersStats;
            this.orderStatsDashboard.noQuotaionUploadedOrders.forEach(data => {
                this.PendingQuotation.push(data);
            });
            this.totalpendingQuotationOrder = this.PendingQuotation.length;
            if (this.totalpendingQuotationOrder < 1) {
                this.noPendingQuotation = true;
                this.noQuoteExpData = true;
            }
            else {
                this.noPendingQuotation = false;
                this.noQuoteExpData = false;
            }
            this.pendingQuotationDataSource = new MatTableDataSource(this.PendingQuotation);
            this.pendingQuotationDataSource.connect().subscribe(d => this.renderedData = d);
            setTimeout(() => {
                this.pendingQuotationDataSource.paginator = this.paginator;
                this.pendingQuotationDataSource.sort = this.quotationSort;
            }, 500);
            if (this.orderStatsDashboard.pendingApprovalOrders) {
                this.orderStatsDashboard.pendingApprovalOrders.forEach(data => {
                    this.PendingApprovalOrdersData.push(data);
                });
            }
            this.totalPendingApprovalOrder = this.PendingApprovalOrdersData.length;
            if (this.totalPendingApprovalOrder < 1) {
                this.noPendingApproval = true;
                this.noApprovalExpData = true;
            }
            else {
                this.noPendingApproval = false;
                this.noApprovalExpData = false;
            }
            this.pendingApprovalOrderSource = new MatTableDataSource(this.PendingApprovalOrdersData);
            this.pendingApprovalOrderSource.connect().subscribe(d => this.renderedData2 = d);
            setTimeout(() => {
                this.pendingApprovalOrderSource.paginator = this.pendingApprovalPaginator;
                this.pendingApprovalOrderSource.sort = this.approvalSort;
            }, 500);

            this.orderStatsDashboard.ongoingOrders.forEach(data => {
                this.OngoingOrders.push(data);
            });
            this.totalOngoingOrder = this.OngoingOrders.length;
            if (this.totalOngoingOrder < 1) {
                this.noOngoingData = true;
                this.noOngoingExpData = true;
            }
            else {
                this.noOngoingData = false;
                this.noOngoingExpData = false;
            }
            this.ongoingDataSource = new MatTableDataSource(this.OngoingOrders);
            this.ongoingDataSource.connect().subscribe(d => this.renderedData3 = d);
            setTimeout(() => {
                this.ongoingDataSource.paginator = this.ongoingPaginator;
                this.ongoingDataSource.sort = this.ongoingSort;
            }, 500);

            this.orderStatsDashboard.completedOrders.forEach(data => {
                this.CompletedOrders.push(data);
            });
            this.totalCompletedOrder = this.CompletedOrders.length;
            if (this.totalCompletedOrder < 1) {
                this.noCompletedOrderData = true;
                this.noCompletedExpData = true;
            }
            else {
                this.noCompletedOrderData = false;
                this.noCompletedExpData = false;
            }
            this.completedOrderDataSource = new MatTableDataSource(this.CompletedOrders);
            this.completedOrderDataSource.connect().subscribe(d => this.renderedData4 = d);
            setTimeout(() => {
                this.completedOrderDataSource.paginator = this.completedPaginator;
                this.completedOrderDataSource.sort = this.completeSort;
            }, 500);
        }
    }
    pendingQuotation(){
        this.activeQuotation=true;
        this.activeApproval = false;
        this.activeDelayed = false;
        this.activeDelivery = false;
        this.loadingDashboard = true;
        this.PendingQuotation = [];
        this.orderDashboardResult = this.doService.orderDashboardResult;
        if (this.orderDashboardResult.response.status === 'SUCCESS') {
            this.loadingDashboard = false;
            this.orderStatsDashboard = this.orderDashboardResult.response.body.ordersStats;
            this.orderStatsDashboard.noQuotaionUploadedOrders.forEach(data => {
                this.PendingQuotation.push(data);
            });
            if (this.PendingQuotation.length < 1) {
                this.noCompletedOrderData = true;
                this.noCompletedExpData = true;
            }
            else {
                this.noCompletedOrderData = false;
                this.noCompletedExpData = false;
            }
            this.completedOrderDataSource = new MatTableDataSource(this.PendingQuotation);
            this.completedOrderDataSource.connect().subscribe(d => this.renderedData4 = d);
            setTimeout(() => {
                this.completedOrderDataSource.paginator = this.completedPaginator;
                this.completedOrderDataSource.sort = this.completeSort;
            }, 500);
        }

    }
    pendingApproval(){
        this.activeApproval = true;
        this.activeQuotation=false;
        this.activeDelayed = false;
        this.activeDelivery = false;
        this.PendingApprovalOrdersData = [];
        this.loadingDashboard = true;
        this.orderDashboardResult = this.doService.orderDashboardResult;
        if (this.orderDashboardResult.response.status === 'SUCCESS') {
            this.loadingDashboard = false;
            this.orderStatsDashboard = this.orderDashboardResult.response.body.ordersStats;
            if (this.orderStatsDashboard.pendingApprovalOrders) {
                this.orderStatsDashboard.pendingApprovalOrders.forEach(data => {
                    this.PendingApprovalOrdersData.push(data);

                });
            }
            if (this.PendingApprovalOrdersData.length < 1) {
                this.noCompletedOrderData = true;
                this.noCompletedExpData = true;
            }
            else {
                this.noCompletedOrderData = false;
                this.noCompletedExpData = false;
            }
            this.completedOrderDataSource = new MatTableDataSource(this.PendingApprovalOrdersData);
            this.completedOrderDataSource.connect().subscribe(d => this.renderedData4 = d);
            setTimeout(() => {
                this.completedOrderDataSource.paginator = this.completedPaginator;
                this.completedOrderDataSource.sort = this.completeSort;
            }, 500);
        }
        else{

        }

    }
    delayedData(){
        this.activeDelayed = true;
        this.activeQuotation=false;
        this.activeApproval = false;
        this.activeDelivery = false;
        this.OngoingOrders=[];
        this.loadingDashboard = true;
        this.orderDashboardResult = this.doService.orderDashboardResult;
        if (this.orderDashboardResult.response.status === 'SUCCESS') {
            this.loadingDashboard = false;
            this.orderStatsDashboard = this.orderDashboardResult.response.body.ordersStats;
            this.orderStatsDashboard.ongoingOrders.forEach(data => {
                this.OngoingOrders.push(data);
            });
            if (this.OngoingOrders.length < 1) {
                this.noCompletedOrderData = true;
                this.noCompletedExpData = true;
            }
            else {
                this.noCompletedOrderData = false;
                this.noCompletedExpData = false;
            }
            this.completedOrderDataSource = new MatTableDataSource(this.OngoingOrders);
            this.completedOrderDataSource.connect().subscribe(d => this.renderedData4 = d);
            setTimeout(() => {
                this.completedOrderDataSource.paginator = this.completedPaginator;
                this.completedOrderDataSource.sort = this.completeSort;
            }, 500);
        }
    }
    deliveryData(){
        this.activeDelivery = true;
        this.activeQuotation = false;
        this.activeApproval = false;
        this.activeDelayed = false;
        this.CompletedOrders= [];
        this.loadingDashboard = true;
        this.orderDashboardResult = this.doService.orderDashboardResult;
        if (this.orderDashboardResult.response.status === 'SUCCESS') {
            this.loadingDashboard = false;
            this.orderStatsDashboard = this.orderDashboardResult.response.body.ordersStats;
            this.orderStatsDashboard.completedOrders.forEach(data => {
                this.CompletedOrders.push(data);
            });
            if(this.CompletedOrders.length < 1 ){
                this.noCompletedOrderData = true;
                this.noCompletedExpData = true;
              }
              else{
                  this.noCompletedOrderData = false;
                  this.noCompletedExpData = false;
              }
            this.completedOrderDataSource = new MatTableDataSource(this.CompletedOrders);
            this.completedOrderDataSource.connect().subscribe(d => this.renderedData4 = d);
            setTimeout(() => {
                this.completedOrderDataSource.paginator = this.completedPaginator;
                this.completedOrderDataSource.sort = this.completeSort;
            }, 500);
        }
        else{

        }


    }
    // testing data---------
    applyFilterQuotation(filterValue: string) {
        this.pendingQuotationDataSource.filter = filterValue.trim().toLowerCase();
        if (this.pendingQuotationDataSource.paginator) {
            this.pendingQuotationDataSource.paginator.firstPage();
        }
    }
    applyFilterApproval(filterValue: string) {
        this.pendingApprovalOrderSource.filter = filterValue.trim().toLowerCase();
        if (this.pendingApprovalOrderSource.paginator) {
            this.pendingApprovalOrderSource.paginator.firstPage();
        }
    }
    applyFilterOngoing(filterValue: string) {
        this.ongoingDataSource.filter = filterValue.trim().toLowerCase();
        if (this.ongoingDataSource.paginator) {
            this.ongoingDataSource.paginator.firstPage();
        }
    }
    applyFilterCompleted(filterValue: string) {
        this.completedOrderDataSource.filter = filterValue.trim().toLowerCase();
        if (this.completedOrderDataSource.paginator) {
            this.completedOrderDataSource.paginator.firstPage();
        }
    }
    // quotation excel------------------
    exportQuotationEXCEL() {
        this.dataCSV = this.renderedData;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelQuotationFile(excelBuffer, 'pendingQuotationOrders_list');

    }
    private saveAsExcelQuotationFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear() + EXCEL_EXTENSION);
    }
    // ----approval excel------------
    exportApprovalEXCEL() {
        this.dataCSV = this.renderedData2;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelApprovalFile(excelBuffer, 'pendingApprovalOrders_list');
    }
    private saveAsExcelApprovalFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear() + EXCEL_EXTENSION);
    }
    // ----ongoing excel------------
    exportOngoingEXCEL() {
        this.dataCSV = this.renderedData3;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelOngoingFile(excelBuffer, 'ongoingOrders_list');
    }
    private saveAsExcelOngoingFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear() + EXCEL_EXTENSION);
    }
    // ----completed excel------------
    exportCompletedEXCEL() {
        this.dataCSV = this.renderedData4;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelCompletedFile(excelBuffer, 'completedOrders_list');
    }
    private saveAsExcelCompletedFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear() + EXCEL_EXTENSION);
    }
    openDetails(id: number) {
        this.router.navigate(['orders/details/', id]);
    }
}
