import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspVariantService {

  constructor(
    private http: HttpClient,
  ) { }

  getVariants(rowKey, year){
    let paramData = {      
      rowKey : rowKey,
      year: year
    };

    console.log('-----Get Year List-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/vehicle/getVehicleVariants', paramData);
  }
}
