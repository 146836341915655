import { Component, OnInit, NgZone, HostListener, ElementRef, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import {FormControl, FormBuilder} from '@angular/forms';
import {Subscription} from 'rxjs';
// import {startWith, map} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
// import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {AspSparesListService} from './asp-spares-list.service';
import {AspLoginService} from '../asp-login/asp-login.service';
import { ToastrService } from 'ngx-toastr';
// import { AspShippingChargesBottomSheetComponent } from "../asp-shipping-charges-bottom-sheet/asp-shipping-charges-bottom-sheet.component";
import {AspReplaceCartPopupComponent} from '../asp-replace-cart-popup/asp-replace-cart-popup.component';
import { ReplaceCartItemsService } from "../../../shared/replace-cart-items.service";
import { AspShippingChargesPopupComponent } from "../asp-shipping-charges-popup/asp-shipping-charges-popup.component";

@Component({
  selector: 'app-asp-spares-list',
  templateUrl: './asp-spares-list.component.html',
  styleUrls: ['./asp-spares-list.component.scss']
})

export class AspSparesListComponent implements OnInit {
  loading: boolean;
  control = new FormControl('');
  spares: string[] = [];
  make: string = '';
  model: string = '';
  year: string = ''; 
  variant: string = '';
  makeImage:string = '';
  aspSpareAdded = [];
  sparesList = [];
  addedCart = [];
  cartList = [];
  cartCount = 0;
  cartSubTotal = 0;
  cartGrandTotal = 0;  
  newData = {};  
  searchText:string = '';
  openSearch:boolean = true;
  clearSearch:boolean = false;
  isAddedCart:boolean = false;
  isCartCount:boolean = false;
  spareSelectedGarageName: any;
  categories = [];
  defaultToggle:boolean = true;
  labelPosition = 'before';
  isIncludeOutOfStock:boolean = true;
  isReplacePopup:boolean = false;
  vehicleType:any = localStorage.getItem('vehicleType');
  clickEventSubscription:Subscription;
  isShippingCharges:boolean = false;
  prepayUpiDiscount: any = "";
  searchPartNumber: any = localStorage.getItem('searchPartNumber');

  @ViewChild('tabRight') tabRight: ElementRef;
  @ViewChildren('catListItems') catListItems;

  @HostListener("window:scroll", [])
    onWindowScroll(event) {
      // let top = this.tabRight.nativeElement.offsetTop;
      let top = this.tabRight.nativeElement.scrollTop+250;
      this.catListItems.toArray().forEach(element => {
        let offset = element.nativeElement.offsetTop;
        let height = element.nativeElement.offsetHeight;
        let id = element.nativeElement.getAttribute('id');

        if(top>=offset && top<offset+height){
          // console.log("id: "+id); 
          this.mouseEnter(id);
        }  
      });
    }

  constructor(
    private router: Router,
    private zone: NgZone,
    private aspSparesListService: AspSparesListService,
    public dialog: MatDialog,
    // private _bottomSheet: MatBottomSheet,
    private aspLoginService: AspLoginService,
    private toastr: ToastrService,
    private replaceCartItemsService: ReplaceCartItemsService,
  ) { 
    this.clickEventSubscription = this.replaceCartItemsService.getClickEvent().subscribe(()=>{
      this.partValueToggle(this.isIncludeOutOfStock);
      this.getCart();
      this.isReplacePopup = false;
    })
  }

  ngOnInit(): void {
    this.loading = true;
    this.aspLoginService.checkLogin();
    
    localStorage.removeItem('aspSpareAdded');

    
    if(this.searchPartNumber){
      this.getSparesByPartNumber();
    }else{
      this.partValueToggle(this.isIncludeOutOfStock);
    }
    
    this.getCart();

    if(localStorage.getItem('newMake'))
      this.make = localStorage.getItem('newMake');
    else if(localStorage.getItem('selectedMake'))
      this.make = localStorage.getItem('selectedMake');

    if(localStorage.getItem('newModel'))
      this.model = localStorage.getItem('newModel');
    else if(localStorage.getItem('selectedModel'))
      this.model = localStorage.getItem('selectedModel');

    if(localStorage.getItem('newYear'))
      this.year = localStorage.getItem('newYear');
    else if(localStorage.getItem('selectedYear'))
      this.year = localStorage.getItem('selectedYear');

    if(localStorage.getItem('newVariant'))
      this.variant = localStorage.getItem('newVariant');
    else if(localStorage.getItem('variant'))
      this.variant = localStorage.getItem('variant');

    if(this.make)
      this.makeImage = 'https://cube.getpitstop.com/assets/img/'+(this.make.toLowerCase()).replace(' ','')+'.png';

    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName"); 

    // if(localStorage.getItem('newRowKey') && localStorage.getItem('newVariantKey') && this.isCartCount==true){
    //   this.isReplacePopup = true;
    // }

  }

  partValueToggle(toggle){
    this.loading = true;
    this.isIncludeOutOfStock = toggle;
    this.getAllSpares(toggle);
  }

  getAllSpares(toggle, searchKey=''){    
    let data = sessionStorage.getItem('sparePartsDetails');    
        
    if(data && this.vehicleType!='universal'){
      // Local data mapping
      this.loading = false;
      this.sparesList = [];
      this.categories = [];
      let sparePartsDetails;

      sparePartsDetails = data;
      let res = JSON.parse(sparePartsDetails); 
      if(res.code==200){

        // get stock
        let spareStocks = [];
        Object.values(res.data.spareStocks).forEach(stock => {
          spareStocks[stock['partNumber']] = stock;
        });
        // end get stock

        // Save to local
        Object.values(res.data.spares).forEach(spares => {

          if(spareStocks[spares['partNumber']])
            spares['spareStocks'] = spareStocks[spares['partNumber']];
          else
            spares['spareStocks'] = [];            

          if(toggle==false){
            if (this.categories.indexOf(spares['category']) === -1 && spares['spareStocks']['quantity']>0) {
              this.categories.push(spares['category']);
            }
          }

          if(toggle==true){
            if (this.categories.indexOf(spares['category']) === -1) {
              this.categories.push(spares['category']);
            }
          }
        });   

        Object.values(this.categories).forEach(cat => {
          let data = [];
          Object.values(res.data.spares).forEach(spares => {

            if(spareStocks[spares['partNumber']])
              spares['spareStocks'] = spareStocks[spares['partNumber']];
            else
              spares['spareStocks'] = [];
              
              
            if(toggle==false){
              if(spares['category']==cat && spares['spareStocks']['quantity']>0){
                data.push(spares);
              }
            }

            if(toggle==true){
              if(spares['category']==cat){
                data.push(spares);
              }
            }
          });
          this.sparesList.push(data);
        });

        if(res.data.shippingCharges.length!=undefined && res.data.shippingCharges.length>0){
          this.isShippingCharges = true;
        }

        if(res.data.prepayUpiDiscount && res.data.prepayUpiDiscount!='' && res.data.prepayUpiDiscount!=undefined){
          this.prepayUpiDiscount = res.data.prepayUpiDiscount;
        }
        
        localStorage.setItem("prepayUpiDiscount",  this.prepayUpiDiscount);
        
      }else{
        // API data mapping
        this.getSparesAPI(toggle, searchKey);        
      }
    }else{
      // API data mapping
      this.getSparesAPI(toggle, searchKey);   
    }

    localStorage.setItem("isShippingCharges",  this.isShippingCharges.toString());
  }

  // API call for getAllSpares
  getSparesAPI(toggle, searchKey){
    let rowKey:any = '';
    let variantKey:any = '';    

    if(this.vehicleType!='universal'){
      if(localStorage.getItem('newRowKey')){
        rowKey = localStorage.getItem('newRowKey');
      }else{
        rowKey = localStorage.getItem('rowKey');
      }

      if(localStorage.getItem('newVariantKey')){
        variantKey = localStorage.getItem('newVariantKey');
      }else{
        variantKey = localStorage.getItem('variantKey');
      }
    }

    let garageKey = localStorage.getItem('garageKey');
    let sparePartsDetails;
      
    this.aspSparesListService.getAllSpares(rowKey, variantKey, searchKey, garageKey).subscribe(
      result => {
        this.loading = false;
        // console.log(result);
        this.sparesList = [];
        this.categories = [];
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code==200){
          // Save to local
          sparePartsDetails = JSON.stringify(result);
          this.setSparePartsDetails(sparePartsDetails);

          // get stock
          let spareStocks = [];
          Object.values(res.data.spareStocks).forEach(stock => {
            spareStocks[stock['partNumber']] = stock;
          });
          // end get stock

          Object.values(res.data.spares).forEach(spares => {  
            if(spareStocks[spares['partNumber']])
              spares['spareStocks'] = spareStocks[spares['partNumber']];
            else
              spares['spareStocks'] = [];
              
            if(toggle==false){
              if (this.categories.indexOf(spares['category']) === -1 && spares['spareStocks']['quantity']>0) {
                this.categories.push(spares['category']);
              }
            }

            if(toggle==true){
              if (this.categories.indexOf(spares['category']) === -1) {
                this.categories.push(spares['category']);
              }
            }
          });   

          Object.values(this.categories).forEach(cat => {
            let data = [];
            Object.values(res.data.spares).forEach(spares => {

              if(spareStocks[spares['partNumber']])
                spares['spareStocks'] = spareStocks[spares['partNumber']];
              else
                spares['spareStocks'] = [];
              
              if(toggle==false){
                if(spares['category']==cat && spares['spareStocks']['quantity']>0){
                  data.push(spares);
                }
              }

              if(toggle==true){
                if(spares['category']==cat){
                  data.push(spares);
                }
              }
            });
            this.sparesList.push(data);
          });

          if(res.data.shippingCharges.length){
            this.isShippingCharges = true;
          }
          console.log("-----------prepayUpiDiscount-----------");
          console.log(res.data.prepayUpiDiscount)
          if(res.data.prepayUpiDiscount && res.data.prepayUpiDiscount!='' && res.data.prepayUpiDiscount!=undefined){
            this.prepayUpiDiscount = res.data.prepayUpiDiscount;
          }
             
          localStorage.setItem("prepayUpiDiscount",  this.prepayUpiDiscount);
        }
      }
    );
  }

  getSparesByPartNumber(){

    let garageKey = localStorage.getItem('garageKey');
    let sparePartsDetails;
      
    this.aspSparesListService.getSparesByPartNumber(this.searchPartNumber, garageKey).subscribe(
      result => {
        this.loading = false;
        // console.log(result);
        this.sparesList = [];
        this.categories = [];
        let res = JSON.parse(JSON.stringify(result)); 

        if(res.code==200){
          let data = [];
          data[0] = res.data;
          data[0]['spareStocks'] = {'partNumber': res['data']['partNumber'], 'quantity': res['data']['count'], 'discountType': res['data']['discountType'], 'discountAmount': res['data']['discount'],'mrp': res['data']['mrp'],'sellingPrice': res['data']['sellingPrice']}; 
            
          // Save to local
          sparePartsDetails = JSON.stringify(data);
          this.setSparePartsDetails(sparePartsDetails);  

          this.categories.push(res['data']['category']);
          this.sparesList.push(data);

          if(res.data.shippingCharges && res.data.shippingCharges.length){
            this.isShippingCharges = true;
          }
          // console.log("-----------prepayUpiDiscount-----------");
          // console.log(res.data.prepayUpiDiscount)
          if(res.data.prepayUpiDiscount && res.data.prepayUpiDiscount!='' && res.data.prepayUpiDiscount!=undefined){
            this.prepayUpiDiscount = res.data.prepayUpiDiscount;
          }
             
          localStorage.setItem("prepayUpiDiscount",  this.prepayUpiDiscount);
        }
      }
    );
  }

  changeVehicle(){
    if(this.searchPartNumber){
      this.router.navigate(['auto-spare-parts/home']);
    }else{
      this.router.navigate(['auto-spare-parts/make']);
    }
  }

  removeItem(count, item){ 
    this.addedCart = [];    
    let data;
    
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    if(count>1){
      let countval = count-1;
      data[item].cart_qty = countval;

      if(this.vehicleType=='universal'){
        data[item].type = 'universal';
      }

      this.addedCart[0] = data;
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
    }else{
      this.deleteItem(item);
      this.toastr.error(data[item].name+' removed from cart', 'Cart Item');
    }
  }

  deleteItem(item){
    this.newData = {};
    let cartData;

    cartData = JSON.parse(sessionStorage.getItem('addedCart'));
    Object.values(cartData[0]).forEach(cart => { 
      if(cart['key']!=item) {
        this.newData[cart['key']] = cart;
      }
    });
    
    this.addedCart[0] = this.newData;
    sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    this.getCart();
    // console.log(this.addedCart);
    
  }

  addItem(count, item){
    let countval = count+1;
    
    this.addedCart = [];
    let data;
    
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    if(countval<=data[item].spareStocks.quantity){
      data[item].cart_qty = countval;

      if(this.vehicleType=='universal'){
        data[item].type = 'universal';
      }

      this.addedCart[0] = data;
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
    }else{
      this.toastr.error('Only '+data[item].spareStocks.quantity+' unit available for this item', 'Error');
    }
  }


  addCart(items, count){
    // if(this.isReplacePopup==true){
    //   sessionStorage.setItem('replaceCart', JSON.stringify(items));
    //   this.zone.run(() => {
    //     this.dialog.open(AspReplaceCartPopupComponent);
    //   });
    // }else{
      this.setNewMakeAsMake();
        
      this.addedCart = [];
      if(this.vehicleType=='universal'){
        items.type = 'universal';
      }
      if(sessionStorage.getItem('addedCart')){
        this.newData = JSON.parse(sessionStorage.getItem('addedCart'))[0];
        items.cart_qty = count;
        this.newData[items.key] = items;
        this.addedCart.push(this.newData);
        sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      }else{
        items.cart_qty = count;
        this.newData[items.key] = items;
        this.addedCart.push(this.newData);
        sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      }      
     
      this.getCart();
    // }
  }

  getCart(){
    let cartData;  
    this.cartList = [];
    this.cartCount = 0;
    this.cartSubTotal = 0;
    this.cartGrandTotal = 0;
    
    if(sessionStorage.getItem('addedCart')){   
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      if(Object.keys(cartData[0]).length==0){
        this.isCartCount = false;
      } 
      
      Object.values(cartData[0]).forEach(cart => { 
        if(cart){
          this.isCartCount = true;
        }

        this.cartCount += cart['cart_qty'];
        if(cart['spareStocks']['sellingPrice']>0){
          this.cartSubTotal += (cart['spareStocks']['sellingPrice']*cart['cart_qty']);
        }else{
          this.cartSubTotal += (cart['spareStocks']['mrp']*cart['cart_qty']);
        }
      });      

      this.cartList = cartData;
      this.cartGrandTotal = this.cartSubTotal;
      // console.log(this.cartList);

      (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+'';
    }
  }


  goToCart(){
    this.router.navigate(['auto-spare-parts/cart']);
  }

  searchFilterType(){
    this.openSearch = false;
    this.clearSearch = true;
    let searchText = (document.getElementsByName("searchText")[0]  as HTMLInputElement).value;
    if(searchText.length>0 && searchText.length<=2){
      this.getAllSpares(true);
    }

    if(searchText.length==0){
      this.getAllSpares(this.isIncludeOutOfStock);
    }
  }

  clearSearchFilter(){
    this.openSearch = true;
    this.clearSearch = false;
    this.searchText = '';
    this.getAllSpares(this.isIncludeOutOfStock);
    // this.control.reset();
  }

  scroll(id) {
    this.mouseEnter(id);

    let cat = id.split(' ').join('-');
    let el = document.getElementById(cat);
    el.scrollIntoView();
  }

  mouseEnter(id){
    let oldElement = document.querySelectorAll('.tab-label');
    oldElement.forEach((ele) => {
      let hasClass = ele.classList.contains('tab-active');     
      if(hasClass){
        ele.classList.remove('tab-active');
      }
    });
    
    let cat = id.split(' ').join('-');
    let element = document.getElementsByClassName(cat)[0];
    element.classList.add("tab-active");
  }

  setSparePartsDetails(data){
    sessionStorage.setItem('sparePartsDetails', data); 
  }

  // shippingChargesBottomSheet(){
  //   this._bottomSheet.open(AspShippingChargesBottomSheetComponent);
  // }

  setNewMakeAsMake(){
    if(localStorage.getItem('newMake') && localStorage.getItem('newModel') && localStorage.getItem('newYear') && localStorage.getItem('newVariant')){
      localStorage.setItem('selectedMake', localStorage.getItem('newMake'));
      localStorage.setItem('selectedModel', localStorage.getItem('newModel'));
      localStorage.setItem('selectedYear', localStorage.getItem('newYear'));
      localStorage.setItem('variant', localStorage.getItem('newVariant'));
      localStorage.setItem('variantKey', localStorage.getItem('newVariantKey'));
      localStorage.setItem('rowKey', localStorage.getItem('newRowKey'));

      localStorage.removeItem('newMake');
      localStorage.removeItem('newModel');
      localStorage.removeItem('newYear');
      localStorage.removeItem('newVariant');
      localStorage.removeItem('newVariantKey');
      localStorage.removeItem('newRowKey');   
    } 
  }

  shippingChargesPopup(){
    this.zone.run(() => {
      this.dialog.open(AspShippingChargesPopupComponent);
    });
  }

  getStock(sku){
    this.loading = true;
    this.aspSparesListService.getItemStock(sku).subscribe(
      result => {
        this.loading = false;
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code==200){
          let qty = res.data[0].quantity; 
          this.updateStock(sku,qty);
        }
      }
    );
  }

  updateStock(sku,qty){
    let itemUpdateStatus = 0;
    this.sparesList.forEach(spare => {
      spare.forEach(list => {
        if(list.spareStocks.partNumber==sku){
          list.spareStocks.quantity = qty;
          itemUpdateStatus = 1;
        }
      });
    });

    if(itemUpdateStatus==1){      
      this.toastr.success('Stock updated for SKU: '+sku, 'Stock Update');
    }else{
      this.toastr.error('Stock failed to update for SKU: '+sku, 'Stock Update');
    }
  }

}
