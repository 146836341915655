<div id="profile" class="page-layout simple tabbed">
    <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between end">
        <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="start center">
            <img class="profile-image img_log" [src]="account.account.imageUrl"
                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            <div class="name" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                {{about.name}}
            </div>
            <div class="change-garage" *ngIf="isGarageLogin==true">
                <button mat-raised-button color="accent"  aria-label="Change" (click)="changeGarage()">
                    Change
                </button> 
            </div>
        </div>
        <div class="ribbon-container" *ngIf="pitstopCertified === 1">
            <br /><br />
            <div class="ribbon-wrapper" *ngIf="pitstopCertified === 1">
                <div class="glow">&nbsp;</div>
                <div class="ribbon-front">
                    Pitstop Certified
                </div>
                <div class="ribbon-edge-topleft"></div>
                <div class="ribbon-edge-topright"></div>
                <div class="ribbon-edge-bottomleft"></div>
                <div class="ribbon-edge-bottomright"></div>
            </div>
        </div>
    </div>
    <div class="content">
        <mat-tab-group dynamicHeight="true">
            <mat-tab label="About">
                <profile-about></profile-about>
            </mat-tab>
            <mat-tab label="Settings">
                <profile-settings></profile-settings>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>