<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>    
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    
    <!-- Search By Car -->
    <mat-card class="mat-elevation-z8 mt-8 p-8 home">  
        <div class="garage-selected mb-12">
            <div class="garage-icon"><mat-icon>build</mat-icon></div>              
            <div class="garage-name">{{spareSelectedGarageName}}</div>      
        </div>
        <!-- <mat-card-header>
            <mat-card-title>Search By Car</mat-card-title>
        </mat-card-header>
            
        <mat-card-actions>
            <div class="home-list-sec text-center" >
                <button mat-button (click)="goToMake()">Search By Car</button>
            </div>
        </mat-card-actions> -->

        <mat-tab-group mat-stretch-tabs animationDuration="0ms">
            <mat-tab label="Search By Car"> 
                <div class="home-list-sec text-center" >
                    <button mat-button (click)="goToMake()">Search By Car</button>
                </div>
            </mat-tab>
            <mat-tab label="Search By Part No.">
                <form (ngSubmit)="onSubmit()">
                    <mat-form-field class="get-spares-by-part-no">
                        <mat-label>Search By Part Number</mat-label>
                        <input matInput type="text" id="partNumber" name="partNumber" [(ngModel)]="formData.partNumber" required>
                    </mat-form-field>

                    <button mat-button type="submit"><mat-icon>search</mat-icon></button>
                </form>
            </mat-tab>
        </mat-tab-group>
        
    </mat-card>

    <!-- Popular Brands -->
    <mat-card class="mat-elevation-z8 mt-8 p-8 home"> 
        <mat-card-header>
            <mat-card-title>Popular Brands</mat-card-title>
            <span class="view-all-brands" (click)="goToAllBrands()">View All</span>
        </mat-card-header>
            
        <mat-card-content>
            <div fxLayout="column" fxLayout.xs="column" style="padding: 10px;">
                <div fxLayout="row wrap" fxLayoutGap="10px">
                    <div *ngFor="let brand of brands; let i = index" fxFlex="23.7%">                        
                        <mat-card *ngIf="i <= 7" (click)="brandSparesList(brand.name, brand.imageUrl)">
                            <img src="{{brand.imageUrl}}">
                            <div class="brand_name">{{brand.name}}</div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    
    <!-- Shop By Categories -->
    <mat-card class="mat-elevation-z8 mt-8 p-8 home"> 
        <mat-card-header>
            <mat-card-title>Shop By Categories</mat-card-title>
        </mat-card-header>
            
        <mat-card-content>
            <div *ngIf="veiwMoreCat" fxLayout="column" fxLayout.xs="column" style="padding: 10px;">
                <div fxLayout="row wrap" fxLayoutGap="10px">
                    <div *ngFor="let cat of categories; let i = index" fxFlex="23.7%">
                        <mat-card *ngIf="i <= 7" (click)="categorySparesList(cat.name, cat.imageUrl)">
                            <img src="{{cat.imageUrl}}">
                            <div class="cat_name">{{cat.name}}</div>
                        </mat-card>
                    </div>                      
                </div>
                <button mat-button (click)="getAllCategories()" class="view-more-cat">View More</button>
            </div>

            <div *ngIf="veiwLessCat" fxLayout="column" fxLayout.xs="column" style="padding: 10px;">
                <div fxLayout="row wrap" fxLayoutGap="10px">
                    <div *ngFor="let cat of allCategories; let i = index" fxFlex="23.7%">
                        <mat-card (click)="categorySparesList(cat.name, cat.imageUrl)">
                            <img src="{{cat.imageUrl}}">
                            <div class="cat_name">{{cat.name}}</div>
                        </mat-card>
                    </div>                      
                </div>
                <button mat-button (click)="viewLessBrands()" class="view-less-cat">View Less</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>

    