import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspBrandSparesListService {

  constructor(
    private http: HttpClient,
  ) { }

  getBrandSparesList(rowKey, variantKey, selectedBrand, garageKey, universal, categories){
    let paramData = {      
      rowKey: rowKey,
      variantKey: variantKey,
      brand: selectedBrand,
      categories: categories,
      searchKey: "",
      sortingOption: "",
      universal:universal,
      garageKey: garageKey
    };

    // console.log("------------paramData");
    // console.log(paramData);

    console.log('-----Get All Brand Spares List-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/getBrandSpares?page=0&size=20', paramData);
  
  }

  getItemStock(sku){
    console.log('-----Get Item Stock-----------');   
    return this.http.patch(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/inventory/item/sync/'+sku, {});
  }
  
}
