import { NgModule } from '@angular/core';
import { AspMakeModule } from './asp-make/asp-make.module';
import { AspModelModule } from './asp-model/asp-model.module';
import { AspYearModule } from './asp-year/asp-year.module';
import { AspVariantModule } from './asp-variant/asp-variant.module';
import { AspSparesListModule } from './asp-spares-list/asp-spares-list.module';
import { AspReplaceCartPopupModule } from './asp-replace-cart-popup/asp-replace-cart-popup.module';
import { AspCartModule } from './asp-cart/asp-cart.module';
import { AspPaymentModule } from './asp-payment/asp-payment.module';
import { AspOrderListModule } from './asp-order-list/asp-order-list.module';
import { AspLoginModule } from './asp-login/asp-login.module';
import { AspSwitchGarageModule } from './asp-switch-garage/asp-switch-garage.module';
import { AspConfirmOrderPopupModule } from './asp-confirm-order-popup/asp-confirm-order-popup.module';
// import { AspShippingChargesBottomSheetComponent } from './asp-shipping-charges-bottom-sheet/asp-shipping-charges-bottom-sheet.component';
import { AspShippingChargesPopupComponent } from './asp-shipping-charges-popup/asp-shipping-charges-popup.component';
import { AspOrderSummaryModule } from './asp-order-summary/asp-order-summary.module';
import { AspCouponDiscountPopupComponent } from './asp-coupon-discount-popup/asp-coupon-discount-popup.component';
import { AspHomeModule } from './asp-home/asp-home.module';
import { AspBrandsModule } from './asp-brands/asp-brands.module';
import { AspBrandSparesListModule } from './asp-brand-spares-list/asp-brand-spares-list.module';
import { AspCategorySparesListModule } from './asp-category-spares-list/asp-category-spares-list.module';
import { AspAllCategoriesPopupModule } from './asp-all-categories-popup/asp-all-categories-popup.module';
@NgModule({
  imports: [
    AspMakeModule,
    AspModelModule,
    AspYearModule,
    AspVariantModule,
    AspSparesListModule,
    AspCartModule,
    AspPaymentModule,
    AspOrderListModule,
    AspLoginModule,
    AspSwitchGarageModule,
    AspConfirmOrderPopupModule,
    AspReplaceCartPopupModule,
    AspOrderSummaryModule,
    AspHomeModule,
    AspBrandsModule,
    AspBrandSparesListModule,
    AspCategorySparesListModule,
    AspAllCategoriesPopupModule
  ],
  declarations: [AspShippingChargesPopupComponent, AspCouponDiscountPopupComponent],
})
export class AutoSparePartsModule { }
