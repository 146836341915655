import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AspYearService} from './asp-year.service'
import {AspLoginService} from '../asp-login/asp-login.service';

@Component({
  selector: 'app-asp-year',
  templateUrl: './asp-year.component.html',
  styleUrls: ['./asp-year.component.scss']
})
export class AspYearComponent implements OnInit {
  loading:boolean = false;
  searchText:any = '';
  yearList:any = [];
  openSearch:boolean = true;
  clearSearch:boolean = false;  
  selectedData = '';
  selectedMake: any;
  selectedModel: any;
  spareSelectedGarageName: any;

  constructor(
    private router: Router,
    private aspYearService: AspYearService,
    private aspLoginService: AspLoginService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.aspLoginService.checkLogin();

    if(localStorage.getItem('newMake'))
      this.selectedMake = localStorage.getItem('newMake');
    else
      this.selectedMake = localStorage.getItem('selectedMake');

    if(localStorage.getItem('newModel'))
      this.selectedModel = localStorage.getItem('newModel');
    else
      this.selectedModel = localStorage.getItem('selectedModel');
    
    this.getYearData();  

    if(localStorage.getItem('selectedYear')){
      this.selectedData = localStorage.getItem('selectedYear');
    }
    
    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName");
  }
  
  // Get Year List       
  getYearData(){
    let result = this.aspYearService.getYear();
    this.loading = false;
    let res = JSON.parse(result); 
    Object.values(res.data[this.selectedMake]).forEach(list => {
      if(list['model']===this.selectedModel){
       
        if(localStorage.getItem('rowKey'))
          localStorage.setItem('newRowKey', list['rowKey']);
        else
          localStorage.setItem('rowKey', list['rowKey']);

        let yearTo;
        if(!list['yearTo']){
          yearTo = new Date().getFullYear();
        }else{
          yearTo = list['yearTo'];
        }

        for(let i=list['yearFrom']; i<=yearTo; i++){
          this.yearList.push({'title':i.toString()});
        }
      }
    });
  }
  // End Get Year List
  
  selectYear(year: any) {
    if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant'))
      localStorage.setItem('newYear', year); 
    else
      localStorage.setItem('selectedYear', year); 

    this.router.navigate(['/auto-spare-parts/variant']);

  }

  searchFilterType(){
    this.openSearch = false;
    this.clearSearch = true;
  }

  clearSearchFilter(){
    this.openSearch = true;
    this.clearSearch = false;
    this.searchText = '';
  }

  goToMake(){      
    this.router.navigate(['/auto-spare-parts/make']);
  }

  goToModel(){      
    this.router.navigate(['/auto-spare-parts/model']);
  }
    
}