import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariable } from 'app/main/globals';
import { LoginService } from 'app/main/authentication/login/login.service';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';

@Injectable({ providedIn: 'root' })
export class CreateOrderService {
    paramData: any;
    carData: any[];
    parameterData: any;
    paramModel: any;
    accountAppKey: any;
    loginUserData: any;
    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {
    }

    getCarsData() {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        this.paramData = {
            appKey: this.accountAppKey,
        };
        return this.http.get(`${GlobalVariable.BASE_API_URL}` + '/cardata', { params: this.paramData }).pipe(
            map(this.extractData));
    }

    getOrderData(VehicleNumber: string): Observable<any> {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        const paramData = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'vehicledetails.registrationNumber': VehicleNumber,
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`
        };
        const formData=new FormData;
        formData.append('sessionPost', JSON.stringify(paramData));
        return this.http.post<any>(`${GlobalVariable.BASE_API_URL}` + '/gmssearchusers', formData);
    }

    getOrderMobileData(mobileNumber: string): Observable<any> {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        const paramData = {
            'appKey': this.accountAppKey,
            'sessionId': this.loginService.getToken(),
            'customer.mobile': mobileNumber,
            'language': `${GlobalVariable.LANGUAGE}`,
            'device': `${GlobalVariable.DEVICE}`
        };
        const formData=new FormData;
        formData.append('sessionPost', JSON.stringify(paramData));
        return this.http.post<any>(`${GlobalVariable.BASE_API_URL}` + '/gmssearchusers', formData);
    }
    getVehicleModel(): Observable<any> {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        this.paramData = {
            appKey: this.accountAppKey,
            language: `${GlobalVariable.LANGUAGE}`,
            device: `${GlobalVariable.DEVICE}`
        };
        return this.http.get<any>(`${GlobalVariable.BASE_API_URL}` + '/webdata', { params: this.paramData });
    }

}
