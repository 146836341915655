import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { LightboxModule } from 'ngx-lightbox';
import { FileUploadModule } from 'ng2-file-upload';
import { ReceivablesComponent } from './receivables.component';
import { ReceivableDetailsComponent } from './receivable-details/receivable-details.component';
import { ReceivablesService } from './receivables.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

const routes: Routes = [
    {
        path: 'receivables',
        component: ReceivablesComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
            data: ReceivablesService
        },
        children: [
            {
                path: ':id',
                component: ReceivableDetailsComponent
            },
        ]
    }
];

@NgModule({
    declarations: [
        ReceivablesComponent,
        ReceivableDetailsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatListModule,
        MatDividerModule,
        MatCardModule,
        MatGridListModule,
        MatDialogModule,
        MatChipsModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatProgressBarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        LightboxModule,
        FileUploadModule
    ],
    exports: [
        ReceivablesComponent,
        ReceivableDetailsComponent
    ],
    providers: [
        ReceivablesService
    ],
    entryComponents: [ReceivableDetailsComponent]
})

export class ReceivablesModule {
}
