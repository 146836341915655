import { NgModule } from '@angular/core';
import { ReceivablesModule } from 'app/main/accounts/receivables/receivables.module';
import { PayoutsModule } from 'app/main/accounts/payouts/payouts.module';
import { StatementModule } from 'app/main/accounts/statement/statement.module';

@NgModule({
    imports: [
        ReceivablesModule,
        PayoutsModule,
        StatementModule
    ]
})
export class AccountsModule {
}
