import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/main/globals';
import { LoginService } from 'app/main/authentication/login/login.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AssignedBookingsService {
    liveOrders: any[];
    paramData: any;
    merchantLogin: any;
    merchantId: any;
    userAppKey: any;
    agentId: any;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */


    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
    private searchedData(res: Response) {
        const body = res;
        return body || {};
    }
    getOrders(): Observable<any> {
        this.merchantLogin = JSON.parse(this.loginService.getLoginData());
        this.merchantId = this.merchantLogin.merchant.id;
        this.userAppKey = this.merchantLogin.account.appKey;
        this.agentId = this.merchantLogin.agent;
        // const idQuery = '';
        // const mobileQuery = '';
        // const statusQuery = '';
        // switch (condition) {
        //     case 'id':
        //         idQuery = query;
        //         break;
        //     case 'mobile':
        //         mobileQuery = query;
        //         break;
        //     case 'status':
        //         statusQuery = query;
        //         break;
        //     default:
        //         idQuery = '';
        //         mobileQuery = '';
        //         statusQuery = '';
        // }
 
        const data = {
            'appKey': this.userAppKey,
            'sessionId': this.loginService.getToken(),
            'statuses': '6',
            'source': '',
            'merchants': this.merchantId,
            'skipstatus': '1',
            'assignedAgent': this.agentId,
            'userType': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/crmleadsearch', formData);
    }
}
