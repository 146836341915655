<div id="settings" class="p-24" fxLayout="row wrap">
    <div
        class="settings-content"
        fxLayout="column"
        fxFlex="100"
        fxFlex.gt-sm="50"
        fxFlex.gt-md="65"
    >
        <div class="profile-box info-box" fxLayout="column">
            <header
                class="accent"
                fxLayout="row"
                fxLayoutAlign="space-between center"
            >
                <div class="title">Update Password</div>
            </header>
            <div class="content">
                <form
                    name="resetPasswordForm"
                    #formDirective="ngForm"
                    [formGroup]="resetPasswordForm"
                    novalidate
                    (ngSubmit)="
                        resetPasswordFormSubmit(
                            resetPasswordForm,
                            formDirective
                        )
                    "
                >
                    <mat-form-field
                        appearance="standard"
                        class="password-input"
                    >
                        <mat-label>Current Password</mat-label>
                        <input
                            autocomplete="off"
                            matInput
                            type="password"
                            formControlName="passwordExisting"
                            [type]="hide1 ? 'text' : 'password'"
                        />
                        <mat-icon matSuffix (click)="hide1 = !hide1"
                            >{{ hide1 ? "visibility" : "visibility_off" }}
                        </mat-icon>
                        <mat-error
                            *ngIf="
                                resetPasswordForm
                                    .get('passwordExisting')
                                    .hasError('required')
                            "
                        >
                            Current Password is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field
                        appearance="standard"
                        class="password-input"
                    >
                        <mat-label>New Password</mat-label>
                        <input
                            autocomplete="off"
                            matInput
                            type="password"
                            formControlName="password"
                            [type]="hide ? 'text' : 'password'"
                        />
                        <mat-icon matSuffix (click)="hide = !hide">{{
                            hide ? "visibility" : "visibility_off"
                        }}</mat-icon>
                        <mat-error>
                            Password is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>New Password Confirmed</mat-label>
                        <input
                            autocomplete="off"
                            matInput
                            type="password"
                            formControlName="passwordConfirm"
                        />
                        <mat-icon matSuffix class="secondary-text"
                            >vpn_key</mat-icon
                        >
                        <mat-error
                            *ngIf="
                                resetPasswordForm
                                    .get('passwordConfirm')
                                    .hasError('required')
                            "
                        >
                            Password confirmation is required
                        </mat-error>
                        <mat-error
                            *ngIf="
                                !resetPasswordForm
                                    .get('passwordConfirm')
                                    .hasError('required') &&
                                resetPasswordForm
                                    .get('passwordConfirm')
                                    .hasError('passwordsNotMatching')
                            "
                        >
                            Passwords must match
                        </mat-error>
                    </mat-form-field>
                    <mat-error
                        class="custom-error"
                        *ngIf="resetPasswordFormError"
                        fxLayoutAlign="center"
                    >
                        Server Error. Please Try again.
                    </mat-error>
                    <mat-error
                        class="custom-error"
                        *ngIf="resetPasswordCurrentPasswordError"
                        fxLayoutAlign="center"
                    >
                        Wrong Current Password. Please try again.
                    </mat-error>
                    <strong
                        class="custom-success green-800-fg"
                        *ngIf="
                            !resetPasswordCurrentPasswordError &&
                            !resetPasswordFormError &&
                            !resetPasswordFormLoading &&
                            resetPasswordFormSubmitted
                        "
                        fxLayoutAlign="center"
                    >
                        Password Reset Successful.
                    </strong>
                    <div class="text-right">
                        <button
                            mat-raised-button
                            class="submit-button"
                            color="accent"
                            aria-label="RESET MY PASSWORD"
                            [disabled]="resetPasswordForm.invalid"
                        >
                            RESET MY PASSWORD
                        </button>
                    </div>
                </form>
            </div>
            <mat-progress-bar
                *ngIf="resetPasswordFormLoading"
                mode="indeterminate"
            ></mat-progress-bar>
        </div>
    </div>
    <div
        class="settings-sidebar"
        fxLayout="column"
        fxFlex="100"
        fxFlex.gt-sm="50"
        fxFlex.gt-md="35"
    ></div>
    <div
        class="settings-content"
        fxLayout="column"
        fxFlex="100"
        fxFlex.gt-sm="50"
        fxFlex.gt-md="65"
    >
        <div class="profile-box info-box" fxLayout="column">
            <header
                class="accent"
                fxLayout="row"
                fxLayoutAlign="space-between center"
            >
                <div class="title">Change Profile Image</div>
            </header>
            <mat-progress-bar
                *ngIf="uploadLoading"
                mode="indeterminate"
            ></mat-progress-bar>
            <div class="img_upld" *ngIf="succesMesg">
                Profile Changed Successfully
            </div>
            <div class="content">
                <form
                    name="changeProfileForm"
                    #formDirective="ngForm"
                    [formGroup]="changeProfileForm"
                    novalidate
                    (ngSubmit)="profileFormSubmit()"
                >
                    <input
                        type="file"
                        formControlName="profileImage"
                        (change)="getImage($event)"
                    />
                    <div class="text-right">
                        <button
                            mat-raised-button
                            class="submit-button"
                            color="accent"
                            aria-label="Change Profile"
                            [disabled]="changeProfileForm.invalid"
                        >
                            Change Profile
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div
        class="settings-sidebar"
        fxLayout="column"
        fxFlex="100"
        fxFlex.gt-sm="50"
        fxFlex.gt-md="35"
    ></div>
</div>
