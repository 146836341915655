import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FileUploadModule } from 'ng2-file-upload';
import { AddImagesComponent } from './add-images/add-images.component';
import { AddNotesComponent } from './add-notes/add-notes.component';
import { AssignTechnicianComponent } from './assign-technician/assign-technician.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { JobsListComponent } from './jobs-list/jobs-list.component';
import { OrderDetailsComponent } from './order-details.component';
import { OrderDetailsService } from './order-details.service';
const routes: Routes = [
    {
        path: 'orders/details/:id',
        runGuardsAndResolvers: 'always',
        component: OrderDetailsComponent,
        resolve: {
            data: OrderDetailsService,
        },
    },
    {
        path: 'orders/details/invoice/:id',
        component: InvoiceDetailsComponent,
    },
];
@NgModule({
    declarations: [
        OrderDetailsComponent,
        JobsListComponent,
        AddNotesComponent,
        AddImagesComponent,
        AssignTechnicianComponent,
        InvoiceDetailsComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatListModule,
        MatDividerModule,
        MatCardModule,
        MatGridListModule,
        MatDialogModule,
        MatChipsModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatProgressBarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatStepperModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatBottomSheetModule,
        MatMenuModule,
        MatRadioModule,
        FileUploadModule,
        MatSelectModule,
        MatTooltipModule,
        MatTabsModule,
        MatGoogleMapsAutocompleteModule,
    ],
    exports: [
        OrderDetailsComponent,
        JobsListComponent,
        AddNotesComponent,
        AddImagesComponent,
        AssignTechnicianComponent,
    ],
    providers: [OrderDetailsService, CurrencyPipe],
    entryComponents: [
        JobsListComponent,
        AddNotesComponent,
        AddImagesComponent,
        AssignTechnicianComponent,
    ],
})
export class OrderDetailsModule {}
