<mat-nav-list class="form-container">
    <div mat-list-item class="mat-display-2 mb-8">
        Add Notes
    </div>
    <form [formGroup]="addNoteForm" class="mb-8" novalidate (ngSubmit)="addNote()">
        <div mat-list-item>
            <mat-form-field class="w-100-p">
                <input matInput formControlName="note" type="text" placeholder="Type here">
            </mat-form-field>
        </div>
        <div mat-list-item class="text-right">
            <button mat-raised-button color="accent" class="submit-button" aria-label="Add Note" [disabled]="addNoteForm.invalid">
                Add Note
            </button>
        </div>
        <div mat-list-item class="text-left" *ngIf="error">
            <mat-error>
                Something went wrong. Please try again!
            </mat-error>
        </div>
    </form>
    <mat-progress-bar *ngIf="notesLoading" mode="indeterminate"></mat-progress-bar>
</mat-nav-list>