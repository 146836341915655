import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    // Dashboard
    {
        id: "dashboard",
        title: "Dashboard",
        translate: "NAV.DASHBOARD",
        type: "item",
        icon: "dashboard",
        url: "/dashboard",
    },

    // Auto Spare Parts
    {
        id: "auto-spare-parts",
        title: "Buy Auto Spare Parts",
        translate: "NAV.ASP.TITLE",
        type: "group",  
        children: [
            {
                id: "auto-spare-parts",
                title: "Buy Auto Spare Parts",
                translate: "NAV.ASP.TITLE",
                type: "item",
                icon: "store",                   
                url: "/auto-spare-parts/login",
            }
        ]
    },

    // booking
    {
        id: "bookings",
        title: "Bookings",
        translate: "NAV.BOOKINGS",
        type: "group",
        icon: "dashboard",
        children: [
            {
                id: "manage-bookings",
                title: "Bookings",
                translate: "NAV.MBOOKINGS.TITLE",
                type: "collapsable",
                icon: "class",
                children: [
                    {
                        id: "new-bookings",
                        title: "New Bookings",
                        translate: "NAV.NEWBOOKINGS.TITLE",
                        type: "item",
                        icon: "note_add",
                        url: "/bookings/new-bookings",
                    },

                    {
                        id: "search-bookings",
                        title: "Search Bookings",
                        translate: "NAV.SEARCHBOOKINGS.TITLE",
                        type: "item",
                        icon: "search",
                        url: "/bookings/search-bookings",
                    },
                    {
                        id: "assigned-bookings",
                        title: "Assigned Bookings",
                        translate: "NAV.ASSIGNEDBOOKINGS.TITLE",
                        type: "item",
                        icon: "playlist_add_check",
                        url: "/bookings/assigned-bookings",
                    },
                    {
                        id: "renewal-bookings",
                        title: "Renewal Bookings",
                        translate: "NAV.RENEWALBOOKINGS.TITLE",
                        type: "item",
                        icon: "replay",
                        url: "/bookings/renewal-bookings",
                    },
                ],
            },
        ],
    },
    // Orders
    {
        id: "orders",
        title: "Orders",
        translate: "NAV.ORDERS",
        type: "group",
        children: [
            {
                id: "manage-orders",
                title: "Orders",
                translate: "NAV.MORDERS.TITLE",
                type: "collapsable",
                icon: "list_alt",
                children: [
                    {
                        id: "dashboard-orders",
                        title: "Orders Dashboard",
                        translate: "NAV.DASHBOARDORDERS.TITLE",
                        type: "item",
                        icon: "dashboard",
                        url: "/orders/dashboard-orders",
                    },
                    {
                        id: "live-orders",
                        title: "Live Orders",
                        translate: "NAV.LIVEORDERS.TITLE",
                        type: "item",
                        icon: "play_circle_filled_white",
                        url: "/orders/live-orders",
                    },
                    // {
                    //     id: 'past-orders',
                    //     title: 'Past Orders',
                    //     translate: 'NAV.PASTORDERS.TITLE',
                    //     type: 'item',
                    //     icon: 'remove_circle_outline',
                    //     url: '/orders/past-orders',
                    // },
                    {
                        id: "search-orders",
                        title: "Search Orders",
                        translate: "NAV.SEARCHORDERS.TITLE",
                        type: "item",
                        icon: "search",
                        url: "/orders/search-orders",
                    },
                    {
                        id: "create-order",
                        title: "Create Order",
                        translate: "NAV.CREATEORDER.TITLE",
                        type: "item",
                        icon: "note_add",
                        url: "/orders/create-order",
                    },
                ],
            },
        ],
    },
    // inventory
    // {
    //     id: 'inventory',
    //     title: 'Inventory',
    //     translate: 'NAV.INVENTORY',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'manage-inventory',
    //             title: 'Inventory',
    //             translate: 'NAV.MINVENTORY.TITLE',
    //             type: 'collapsable',
    //             icon: 'store',
    //             children: [

    // {
    //     id: 'dashboard-inventory',
    //     title: 'Dashboard',
    //     translate: 'NAV.DASHBOARDINVENTORY.TITLE',
    //     type: 'item',
    //     icon: 'apps',
    //     url: '/inventory/dashboard-inventory',
    // },
    // {
    //     id: 'product-catalogue',
    //     title: 'Product Catalogue',
    //     translate: 'NAV.PRODUCTCATALOGUE.TITLE',
    //     type: 'item',
    //     icon: 'book',
    //     url: '/inventory/product-catalogue',
    // },
    // {
    //     id: 'vendors-list',
    //     title: 'Vendor List',
    //     translate: 'NAV.VENDORSLIST.TITLE',
    //     type: 'item',
    //     icon: 'people',
    //     url: '/inventory/vendors-list',
    // },

    // {
    //     id: 'purchase-order',
    //     title: 'Purchase Order',
    //     translate: 'NAV.PURCHASEORDER.TITLE',
    //     type: 'item',
    //     icon: 'menu_open',
    //     url: '/inventory/purchase-order',
    // },
    // {
    //     id: 'invoices',
    //     title: 'Invoices ',
    //     translate: 'NAV.INVOICES.TITLE',
    //     type: 'item',
    //     icon: 'receipt',
    //     url: '/inventory/invoices',
    // },
    //             ]
    //         }
    //     ]
    // },
    // Accounts
    {
        id: "accounts",
        title: "Accounts",
        translate: "NAV.ACCOUNTS",
        type: "group",
        children: [
            {
                id: "finances",
                title: "Finances",
                translate: "NAV.MFINANCES.TITLE",
                type: "collapsable",
                icon: "attach_money",
                children: [
                    {
                        id: "receivables",
                        title: "Receivables",
                        translate: "NAV.RECEIVABLES.TITLE",
                        type: "item",
                        icon: "call_received",
                        url: "/accounts/receivables",
                    },
                    {
                        id: "payouts",
                        title: "Payouts",
                        translate: "NAV.PAYOUTS.TITLE",
                        type: "item",
                        icon: "call_made",
                        url: "/accounts/payouts",
                    },
                    {
                        id: "statement",
                        title: "Statement",
                        translate: "NAV.STATEMENT.TITLE",
                        type: "item",
                        icon: "assignment",
                        url: "/accounts/statement",
                    },
                ],
            },
        ],
    },
    // Tools
    {
        id: "tools",
        title: "Tools",
        translate: "NAV.TOOLS",
        type: "group",
        children: [
            {
                id: "bulk-downloads",
                title: "Bulk Downloads",
                translate: "NAV.BDOWNLOADS.TITLE",
                type: "collapsable",
                icon: "cloud_download",
                children: [
                    {
                        id: "invoices-downloads",
                        title: "Invoices",
                        translate: "NAV.INVOICESD.TITLE",
                        type: "item",
                        icon: "receipt",
                        url: "/bulk-downloads/invoices",
                    },
                ],
            },
        ],
    },
    // Reports
    {
        id: "reports",
        title: "Reports",
        translate: "NAV.REPORTS",
        icon: "assessment",
        type: "group",

        children: [
            {
                id: "manage-reports",
                title: "Reports",
                translate: "NAV.MREPORTS.TITLE",
                type: "collapsable",
                icon: "assessment",
                children: [
                    {
                        id: "gst",
                        title: "GST",
                        translate: "NAV.GST.TITLE",
                        type: "item",
                        icon: "description",
                        url: "/reports/gst",
                    },
                    {
                        id: "daily-orders",
                        title: "Daily Orders",
                        translate: "NAV.DAILYORDERS.TITLE",
                        type: "item",
                        icon: "calendar_today",
                        url: "/reports/daily-orders",
                    },
                    // {
                    //     id: "driver-status-report",
                    //     title: "Driver Status Report",
                    //     translate: "NAV.DRIVERSTATUS.TITLE",
                    //     type: "collapsable",
                    //     icon: "calendar_today",
                    //     children: [
                    //         {
                    //             id: "trip-duration",
                    //             title: "Trip Duration",
                    //             translate: "NAV.TRIPDURATION.TITLE",
                    //             type: "collapsable",
                    //             icon: "calendar_today",
                    //             children: [
                    //                 {
                    //                     id: "customer-to-wokshop",
                    //                     title: "From Customer to Workshop",
                    //                     translate:
                    //                         "NAV.CUSTOMERTOWORKSHOP.TITLE",
                    //                     type: "item",
                    //                     icon: "calendar_today",
                    //                     url: "/reports/customer-to-wokshop",
                    //                 },
                    //                 {
                    //                     id: "workshop-to-customer",
                    //                     title: "From Workshop to Customer",
                    //                     translate:
                    //                         "NAV.WORKSHOPTOCUSTOMER.TITLE",
                    //                     type: "item",
                    //                     icon: "calendar_today",
                    //                     url: "/reports/daily-orders",
                    //                 },
                    //             ],
                    //         },
                    //         {
                    //             id: "trip-perday",
                    //             title: "Number of Trips Per Day",
                    //             translate: "NAV.TRIPSPERDAY.TITLE",
                    //             type: "collapsable",
                    //             icon: "calendar_today",
                    //             children: [
                    //                 {
                    //                     id: "pickup-to-garage",
                    //                     title:
                    //                         "Pickup Customer Vehicle to Garage",
                    //                     translate: "NAV.PICKUPTOGARAGE.TITLE",
                    //                     type: "item",
                    //                     icon: "calendar_today",
                    //                     url: "/reports/daily-orders",
                    //                 },
                    //                 {
                    //                     id: "drop-off-vehicle",
                    //                     title: "Drop Off of Customer Vehicle",
                    //                     translate: "NAV.DROPOFFVEHICLE.TITLE",
                    //                     type: "item",
                    //                     icon: "calendar_today",
                    //                     url: "/reports/daily-orders",
                    //                 },
                    //             ],
                    //         },
                    //     ],
                    // },
                ],
            },
        ],
    },

];
