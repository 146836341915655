import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { GlobalVariable } from "app/main/globals";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Md5 } from "ts-md5/dist/md5";
import { LoginService } from "app/main/authentication/login/login.service";
@Injectable({ providedIn: "root" })
export class ProfileService implements Resolve<any> {
    profileData: any[];
    paramData: any;
    loginData: any;
    about: any;
    data: any;
    loginUserData: any;
    accountAppKey: any;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(private http: HttpClient, private loginService: LoginService) {
        this.data = JSON.parse(this.loginService.getLoginData());
    }
    private sData(res: Response) {
        const body = res;
        return body || {};
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([this.getProfileData()]).then(() => {
                resolve();
            }, reject);
        });
    }
    getProfileData() {
        return new Promise((resolve, reject) => {
            this.about = JSON.parse(this.loginService.getMerchantData());
            resolve(this.about);
        });
    }
    updatePassword(pass: any) {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        const data = {
            appKey: this.accountAppKey,
            sessionId: this.loginService.getToken(),
            userKey: this.data.agentUserKey,
            userId: this.data.agent,
            password: Md5.hashStr(pass),
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return this.http.post(
            `${GlobalVariable.BASE_API_URL}` + "/hchangeinfo",
            formData
        );
    }
    updateMerchantInfo(formData: any) {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        formData.append("appKey", this.accountAppKey);
        formData.append("sessionId", this.loginService.getToken());
        formData.append("userKey", this.data.agentUserKey);
        formData.append("userId", this.data.agent);
        formData.append("id", this.data.merchant.id);
        return this.http
            .post(
                `${GlobalVariable.BASE_API_URL}` + "/gmsupdatemerchant",
                formData
            )
            .pipe(map(this.sData));
    }
}
