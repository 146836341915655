import { Component, Inject } from "@angular/core";
import {
    MatBottomSheetRef,
    MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";
import { OrderDetailsService } from "app/main/orders/order-details/order-details.service";
@Component({
    selector: "jobs-list",
    templateUrl: "./jobs-list.component.html",
    styleUrls: ["./jobs-list.component.scss"],
})
export class JobsListComponent {
    jobs: [];
    constructor(
        private bottomSheetRef: MatBottomSheetRef<JobsListComponent>,
        private odService: OrderDetailsService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        this.jobs = data.data;
    }
    close_sheet() {
        this.bottomSheetRef.dismiss();
    }
}
