import {
    Appearance,
    Location
} from '@angular-material-extensions/google-maps-autocomplete';
import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { LoginService } from 'app/main/authentication/login/login.service';
import { CreateOrderService } from 'app/main/orders/create-order/create-order.service';
import { Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { BookingDetailsService } from '../booking-details.service';

import PlaceResult = google.maps.places.PlaceResult;

export interface Fuel {
    value: string;
    viewValue: string;
}
export interface ConvenienceType {
    value: string;
    viewValue: string;
}
export interface BookingDetailsDialogData {
    id: string;
}
@Component({
    selector: 'app-edit-booking',
    templateUrl: './edit-booking.component.html',
    styleUrls: ['./edit-booking.component.scss'],
})
export class EditBookingComponent implements OnInit {
    constructor(
        private _formBuilder: FormBuilder,
        private bookingService: BookingDetailsService,
        private coService: CreateOrderService,
        private loginService: LoginService,
        private router: Router,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<EditBookingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BookingDetailsDialogData,
        private fuseService: FuseProgressBarService
    ) {
        this.showLoader = true;
        this.bId = data.id;
        this.loading = true;
        this.bookingService
            .getBookingLeadIdData(this.bId)
            .pipe(first())
            .subscribe((details) => {
                this.getEditLeadById(details);
            });
        const accountData = JSON.parse(this.loginService.getLoginData());
        this.renaultMerchantId = accountData.merchant.id;
        const renaultAccount = accountData.account.name;
        if (renaultAccount.toLowerCase().indexOf('renault') > -1) {
            this.renaultConvenience = true;
        } else {
            this.renaultConvenience = false;
        }
    }
    renaultMerchantId: any;
    renaultConvenience = false;
    public appearance = Appearance;
    public zoom: number;
    public latitude: number;
    public longitude: number;
    public selectedAddress: PlaceResult;
    fuel: Fuel[] = [
        { value: 'petrol', viewValue: 'Petrol' },
        { value: 'diesel', viewValue: 'Diesel' },
        { value: 'cng/petrol', viewValue: 'CNG/Petrol' },
        { value: 'lpg/petrol', viewValue: 'LPG/Petrol' },
        { value: 'electricity', viewValue: 'Electricity' },
        { value: 'hybrid', viewValue: 'Hybrid' },
    ];
    convenienceType: ConvenienceType[] = [
        { value: 'doorstep', viewValue: 'Doorstep' },
        { value: 'pickup', viewValue: 'Pickup' },
        { value: 'self', viewValue: 'Self' },
    ];
    RenaultConvenienceType: ConvenienceType[] = [
        { value: 'pickup', viewValue: 'Pickup' },
        { value: 'self', viewValue: 'Self' },
    ];
    accountControl = new FormControl();
    bookingCars = new FormControl();
    assigendGarageControl = new FormControl();
    vehicleControl = new FormControl();
    sourceControl = new FormControl();
    accountData = [];
    filteredAccount: Observable<any>;
    filteredVehicle: Observable<any>;
    filteredCar: Observable<any>;
    updateBookingForm: FormGroup;
    updateBookingForm2: FormGroup;
    updateBookingForm3: FormGroup;
    editLeadResult: any;
    appointmentTime: any;
    merchantId: any;
    vehicleRowKey: any;
    resultVehicle = [];
    carModel = [];
    serviceValue: any;
    isMaintenace = false;
    isAccident = false;
    selectedRowkeyTtitle: any;
    loginUserData: any;
    accountAppKey: any;
    noServiceSelected = false;
    deliveryTime: any;
    appontmentDateTime: any;
    checkVehicleVal: any;
    regiteration: any;
    sourceValue: any;
    loading = false;
    successData: any;
    successMsg = false;
    leadGenId: any;
    make: any;
    model: any;
    pincode: any;
    pickupLocality: any;
    dropLocality: any;
    latitude2: any;
    longitude2: any;
    status: any;
    userid: any;
    showLoader;
    bId: any;
    name: any;
    email: any;
    mobile: any;
    merchant: any;
    editLeadDetails: any;
    errorMesg = false;
    messageSuccess = false;
    pickAddress: any;
    pickupAddress: any;
    succesLoading = false;
    showSourceResult = false;
    DateAppointment: any;
    exDelDates: any;
    merchantLoginData: any;
    merchantID: any;
    pickupPoint: any;
    pickLocality: any;
    apExpDateTime: any;
    droplocalityAddress: any;
    merchantName: any;
    showdropAddress = false;
    dropAddress1: any;
    addresDropLocality: any;
    isSamePickup = false;
    dropLocalityAddress2: any;
    showAppointmentFormat = true;
    showExpectedDeliveryFormat = true;
    noServiceSubTypeSelected = false;
    editAppointmentTime: any;
    accountLeadId: any;
    resultPincode: any;
    dropPoint: any;
    dropLocalityResult: any;
    dropAddressResult: any;
    options: any;
    input: any;
    autocomplete: any;
    countryRestrict: any;
    isLeasing: any;
    country: any;
    renewal = true;
    isRegistration = false;
    showVehicleResult = false;
    selectedDropVal: any;
    selectedSearchText: any;
    searchText: any;
    vehicleRowKey2: any;
    sourceFilled: any;
    fuelTypeFilled: any;
    registrationFilled: any;
    convenienceFilled: any;
    serviceTypeFilled: any;
    address2Filled: any;
    dropAddress2Filled: any;
    address1Filled: any;
    notesFilled: any;
    validAddress = false;
    selectDropdownMessage = false;
    @ViewChild('pickupLocation', { static: false }) pickupLocation: ElementRef;
    @ViewChild('appointmentBookingDate', { static: false })
    appointmentBookingDate: ElementRef;
    @ViewChild('expectedDeliveryDates', { static: false })
    expectedDeliveryDates: ElementRef;
    @ViewChild('dropLocation', { static: false }) dropLocation: ElementRef;
    @ViewChild('autocompleteInput', { static: false })
    autocompleteInput: ElementRef;
    @ViewChild('vehReg', { static: false }) vehReg: ElementRef;
    ngOnInit() {
        this.searchText = '';
        this.merchantLoginData = JSON.parse(this.loginService.getLoginData());
        this.isLeasing = this.merchantLoginData.account.isLeasing;
        if (this.isLeasing === 1) {
            this.country = 'sg';
        } else {
            this.country = 'in';
        }
        this.merchantID = this.merchantLoginData.merchant.id;
        this.merchantName = this.merchantLoginData.merchant.name;
        this.getCarData();

        this.filteredVehicle = this.vehicleControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterVehicle(value))
        );
        this.filteredAccount = this.accountControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterAccount(value))
        );
        this.updateBookingForm = this._formBuilder.group({
            source: ['', Validators.required],
            vehicle: ['', Validators.required],
            registrationNumber: ['', Validators.required],
            serviceType: ['', Validators.required],
            fuel: ['', Validators.required],
            convenience: ['', Validators.required],
            serviceSubtype: [''],
        });
        this.updateBookingForm2 = this._formBuilder.group({
            pickLocality: ['', Validators.required],
            pickupFlat: ['', Validators.required],
            dropLocality: [''],
            dropFlat: [''],
        });
        this.updateBookingForm3 = this._formBuilder.group({
            notes: ['', Validators.required],
            oppointmentDate: [''],
            oppointmentEpectDate: [''],
        });
        this.zoom = 10;
        this.latitude = 52.520008;
        this.longitude = 13.404954;
        this.setCurrentPosition();
        this.setCurrentPosition2();
    }

    private _filterVehicle(value: string): string[] {
        const vehicleValue = value.toLowerCase();
        return this.carModel.filter((option) =>
            option.toLowerCase().includes(vehicleValue)
        );
    }
    private _filterAccount(value: string): string[] {
        const accountValue = value.toLowerCase();
        return this.accountData.filter((option) =>
            option.toLowerCase().includes(accountValue)
        );
    }
    openSourceList() {
        this.showSourceResult = true;
    }
    openVehicleList() {
        this.showVehicleResult = true;
    }
    getServiceType(event) {
        if (this.editLeadResult.serviceType === 'maintenance') {
            this.isMaintenace = true;
            this.noServiceSubTypeSelected = true;
        } else {
            this.isMaintenace = false;
            this.noServiceSubTypeSelected = false;
        }
        if (this.editLeadResult.serviceType === 'accident') {
            this.isAccident = true;
            this.noServiceSubTypeSelected = true;
        } else {
            this.isAccident = false;
        }
    }
    sourceSelected(option) {
        this.sourceValue = option;
        this.editLeadResult.source = option;
        this.showSourceResult = false;
    }
    checkFuel(event) {
        const val2 = event.target.value;
    }
    checkRegister(event) {
        this.regiteration = event.target.value;
    }
    autoCloseForDropdownCars(event) {
        const target = event.target;
        if (!target.closest('.vehiclToggle')) {
            this.showVehicleResult = false;
        }
    }
    autoCloseForDropdownSource(event) {
        const target = event.target;
        if (!target.closest('.sourceToggle')) {
            this.showSourceResult = false;
        }
    }
    getEditLeadById(details: any) {
        this.fuseService.hide();
        this.editLeadDetails = details;
        if (this.editLeadDetails.response.status === 'SUCCESS') {
            this.editLeadResult = this.editLeadDetails.response.body.leadgen;
            if (this.editLeadResult.length !== 0) {
                this.loading = false;
            }
            if (this.editLeadResult.type === 'renewal') {
                this.renewal = false;
            } else {
                this.renewal = true;
            }
            this.sourceFilled = this.editLeadResult.source;
            this.fuelTypeFilled = this.editLeadResult.fuelType;
            this.registrationFilled = this.editLeadResult.registrationNumber;
            this.convenienceFilled = this.editLeadResult.convenienceType;
            this.serviceTypeFilled = this.editLeadResult.serviceType;
            this.address2Filled = this.editLeadResult.address2;
            this.address1Filled = this.editLeadResult.address1;
            this.dropAddress2Filled = this.editLeadResult.dropAddress2;
            this.notesFilled = this.editLeadResult.notes;
            this.accountLeadId = this.editLeadResult.accountId;
            this.status = this.editLeadResult.status;
            this.userid = this.editLeadResult.userId;
            this.merchantId = this.editLeadResult.merchantId;
            this.name = this.editLeadResult.name;
            this.email = this.editLeadResult.email;
            this.mobile = this.editLeadResult.mobile;
            this.merchant = this.editLeadDetails.response.body.merchant;
            const dates = this.editLeadResult.pickupTime;
            this.exDelDates = this.editLeadResult.expectedDeliveryTime;
            const date = new Date(dates);
            this.appointmentTime = this.editLeadResult.pickupTime;
            this.pickupPoint = this.editLeadResult.pickupPoint;
            this.pickLocality = this.editLeadResult.pickupLocality;
            this.droplocalityAddress = this.editLeadResult.dropAddress2;
            this.dropAddress1 = this.editLeadResult.dropAddress1;
            this.resultPincode = this.editLeadResult.pincode;
            this.dropPoint = this.editLeadResult.dropPoint;
            this.dropLocalityResult = this.editLeadResult.dropLocality;
            this.dropAddressResult = this.editLeadResult.dropAddress;
            if (this.editLeadResult.address2 !== undefined) {
                if (
                    this.editLeadResult.address2 ===
                    this.editLeadResult.dropAddress2
                ) {
                    this.isSamePickup = true;
                    this.pickupAddress = this.editLeadResult.address2;
                } else {
                    this.isSamePickup = false;
                }
            }
            for (let j = 0; j < this.resultVehicle.length; j++) {
                if (
                    this.resultVehicle[j].rowKey === this.editLeadResult.rowKey
                ) {
                    this.selectedRowkeyTtitle = this.resultVehicle[j].title;
                    this.make = this.resultVehicle[j].make;
                    this.model = this.resultVehicle[j].model;
                    this.vehicleRowKey = this.resultVehicle[j].rowKey;
                }
            }
            this.leadGenId = this.editLeadDetails.leadgenId;
            if (this.editLeadResult.serviceType === 'maintenance') {
                this.isMaintenace = true;
            } else {
                this.isMaintenace = false;
            }
            if (this.editLeadResult.serviceType === 'accident') {
                this.isAccident = true;
            } else {
                this.isAccident = false;
            }
        }
    }
    getCarData() {
        this.coService.getVehicleModel().subscribe((result) => {
            this.resultVehicle = result.response.body.data;
            for (let i = 0; i < this.resultVehicle.length; i++) {
                this.carModel.push(this.resultVehicle[i].title);
            }
            result.response.body.source.forEach((data) => {
                this.accountData.push(data.source);
            });
        });
    }
    checkVehicle(event) {
        this.checkVehicleVal = event.target.value;
    }

    // vehicleSelected(option: MatOption) {
    //   this.showVehicleResult = false;
    //   const selectedCar = option.value;
    //   for (let j = 0; j < this.resultVehicle.length; j++) {
    //     if (this.resultVehicle[j].title === selectedCar) {
    //       this.vehicleRowKey = this.resultVehicle[j].rowKey;
    //     }
    //   }
    // }
    vehicleSelected(option) {
        this.showVehicleResult = false;
        const selectedCar = option;
        this.selectedDropVal = option;
        this.selectedSearchText = option;
        this.searchText = option;
        for (let j = 0; j < this.resultVehicle.length; j++) {
            if (this.resultVehicle[j].title === selectedCar) {
                this.vehicleRowKey2 = this.resultVehicle[j].rowKey;
            }
        }
    }
    private setCurrentPosition() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
            });
        }
    }

    onAutocompleteSelected(result: PlaceResult) {
        this.pickAddress = result.formatted_address;
        for (let i = 0; i < result.address_components.length; i++) {
            if (i == result.address_components.length - 1) {
                this.pincode = result.address_components[i].long_name;
            }
            if (result.address_components[i].types[0] == 'locality') {
                this.pickupLocality = result.address_components[i].long_name;
            }
        }
        this.selectDropdownMessage = false;
        this.validAddress = false;
    }
    onLocationSelected(location: Location) {
        this.latitude = location.latitude;
        this.longitude = location.longitude;
    }
    checkSelectedAddressEnter() {
        this.selectDropdownMessage = true;
        if (this.pickupAddress === undefined && !this.pickupLocality) {
            this.selectDropdownMessage = true;
        }
        this.validAddress = true;
    }
    // drop---------------------
    private setCurrentPosition2() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
            });
        }
    }

    onAutocompleteSelected2(result: PlaceResult) {
        for (let i = 0; i < result.address_components.length; i++) {
            if (result.address_components[i].types[0] == 'locality') {
                this.dropLocality = result.address_components[i].long_name;
            }
        }
    }

    onLocationSelected2(location: Location) {
        this.latitude2 = location.latitude;
        this.longitude2 = location.longitude;
    }
    getSelectedAppointment() {
        this.showAppointmentFormat = false;
    }
    getExpectedSelectedDate() {
        this.showExpectedDeliveryFormat = false;
        this.apExpDateTime = this.expectedDeliveryDates.nativeElement.value;
    }
    getAppointment() {
        this.DateAppointment = this.appointmentBookingDate.nativeElement.value;
    }
    checkPickUpValue(event) {
        this.pickupAddress = this.pickupLocation.nativeElement.value;
    }
    checkRegistraion(event) {
        const registValue = this.vehReg.nativeElement.value;
        if (registValue === '') {
            this.isRegistration = true;
        } else {
            this.isRegistration = false;
        }
    }
    submitEditBookingForm() {
        this.pickupAddress = this.pickupLocation.nativeElement.value;
        this.dropLocalityAddress2 = this.dropLocation.nativeElement.value;
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginUserData.account.appKey;
        const formData = new FormData();
        formData.append('appKey', this.accountAppKey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('status', this.status);
        formData.append('id', this.leadGenId);
        formData.append('source', this.editLeadResult.source);
        formData.append('subsource', '');
        formData.append('userId', this.userid);
        formData.append(
            'pickupAddress',
            this.updateBookingForm2.get('pickupFlat').value +
                ', ' +
                this.pickupAddress
        );
        if (this.pickupPoint) {
            formData.append('pickupPoint', this.pickupPoint);
        } else {
            formData.append(
                'pickupPoint',
                this.latitude + ',' + this.longitude
            );
        }
        formData.append('name', this.name);
        formData.append('mobile', this.mobile);
        formData.append('email', this.email);
        if (this.vehicleRowKey2) {
            formData.append('rowKey', this.vehicleRowKey2);
        } else {
            formData.append('rowKey', this.vehicleRowKey);
        }

        formData.append('fuelType', this.updateBookingForm.get('fuel').value);
        formData.append(
            'registrationNumber',
            this.updateBookingForm.get('registrationNumber').value
        );
        formData.append('notes', this.updateBookingForm3.get('notes').value);
        formData.append(
            'convenienceType',
            this.updateBookingForm.get('convenience').value
        );
        formData.append('nextContactDate', '');
        formData.append('merchantId', this.merchantID);
        if (this.appointmentTime) {
            if (this.DateAppointment) {
                this.appontmentDateTime = Date.parse(
                    this.updateBookingForm3.get('oppointmentDate').value
                );
                formData.append('pickupTime', this.appontmentDateTime);
            } else {
                formData.append('pickupTime', this.appointmentTime);
            }
        } else {
            this.appontmentDateTime = Date.parse(
                this.updateBookingForm3.get('oppointmentDate').value
            );
            formData.append('pickupTime', this.appontmentDateTime);
        }
        formData.append('accountId', this.accountLeadId);
        formData.append('prospectId', '');
        if (this.apExpDateTime != undefined) {
            const apExpDateDel = Date.parse(
                this.updateBookingForm3.get('oppointmentEpectDate').value
            );
            formData.append('expectedDeliveryTime', apExpDateDel.toString());
        } else {
            formData.append('expectedDeliveryTime', this.exDelDates);
        }

        formData.append(
            'serviceType',
            this.updateBookingForm.get('serviceType').value
        );

        if (
            this.serviceValue === 'maintenance' ||
            this.serviceValue === 'accident'
        ) {
            formData.append(
                'serviceSubtype',
                this.updateBookingForm.get('serviceSubtype').value
            );
        } else {
            formData.append('serviceSubtype', '');
        }
        formData.append('complaintNotes', '');
        formData.append(
            'address1',
            this.updateBookingForm2.get('pickupFlat').value
        );
        formData.append('address2', this.pickupAddress);
        formData.append('hasReplacement', '');
        formData.append('isCompositeOrder', '0');
        if (this.latitude2 !== undefined || this.longitude2 !== undefined) {
            formData.append(
                'dropPoint',
                this.latitude2 + ',' + this.longitude2
            );
            formData.append(
                'dropAddress',
                this.updateBookingForm2.get('dropFlat').value +
                    ', ' +
                    this.dropLocalityAddress2
            );
            formData.append('dropAddress2', this.dropLocalityAddress2);
        } else {
            if (this.dropAddressResult) {
                formData.append('dropAddress', this.dropAddressResult);
            } else {
                formData.append(
                    'dropAddress',
                    this.updateBookingForm2.get('pickupFlat').value +
                        ', ' +
                        this.pickupAddress
                );
            }
            if (this.dropPoint) {
                formData.append('dropPoint', this.dropPoint);
            } else {
                formData.append(
                    'dropPoint',
                    this.latitude + ',' + this.longitude
                );
            }
            if (this.droplocalityAddress) {
                formData.append('dropAddress2', this.droplocalityAddress);
            } else {
                formData.append('dropAddress2', this.pickupAddress);
            }
        }
        if (this.updateBookingForm2.get('dropFlat').value !== undefined) {
            formData.append(
                'dropAddress1',
                this.updateBookingForm2.get('dropFlat').value
            );
        } else {
            if (this.dropAddress1) {
                formData.append('dropAddress1', this.dropAddress1);
            } else {
                formData.append(
                    'dropAddress1',
                    this.updateBookingForm2.get('pickupFlat').value
                );
            }
        }
        formData.append('chassisNo', '');
        if (this.pickLocality) {
            formData.append('pickupLocality', this.pickLocality);
        } else {
            formData.append('pickupLocality', this.pickupLocality);
        }
        if (this.dropLocality !== undefined) {
            if (this.dropLocalityResult) {
                formData.append('dropLocality', this.dropLocalityResult);
            } else {
                formData.append('dropLocality', this.dropLocality);
            }
        } else {
            if (this.pickLocality) {
                formData.append('dropLocality', this.pickLocality);
            } else {
                formData.append('dropLocality', this.pickupLocality);
            }
        }
        formData.append('make', this.make);
        formData.append('model', this.model);
        formData.append('pincode', this.pincode);
        formData.append('services', '');
        formData.append('userType', 'gms');
        if (
            (this.vehicleRowKey === undefined &&
                this.vehicleRowKey2 === undefined) ||
            this.updateBookingForm.get('serviceType').value === undefined ||
            this.updateBookingForm.get('convenience').value === '' ||
            this.updateBookingForm.get('registrationNumber').value === '' ||
            this.updateBookingForm.get('fuel').value === '' ||
            this.updateBookingForm2.get('pickupFlat').value === '' ||
            this.updateBookingForm2.get('pickLocality').value === '' ||
            this.DateAppointment === ''
        ) {
            this.errorMesg = true;
        } else {
            this.errorMesg = false;
            this.succesLoading = true;
            this.bookingService
                .updateBookingLead(formData)
                .subscribe((result) => {
                    this.successData = result;
                    if (this.successData.response.status === 'SUCCESS') {
                        this.succesLoading = false;
                        this.successMsg = true;
                        this.loading = false;
                        this.messageSuccess = true;
                        setTimeout(() => {
                            this.messageSuccess = false;
                            this.dialog.closeAll();
                            this.router.navigate([
                                '/bookings/details',
                                this.leadGenId,
                            ]);
                        }, 3000);
                    }
                });
        }
    }
}
