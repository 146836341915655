<mat-nav-list class="form-container">
    <div mat-list-item class="mat-display-2 mb-8">
        Add Images
    </div>
    <div mat-list-item>
        <span *ngIf="uploader?.queue?.length != 0" class="text-capitalized">(Added:
            {{ uploader?.queue?.length }})</span>
    </div>
    <div mat-list-item>
        <div class="w-60 image-data-section m-8 ml-0" *ngFor="let item of uploader.queue;let i=index;">
            <img class="auto-width h-60 image" [src]="urls[i]" (click)="open(i)" />
            <button mat-button color="warn" class="w-20 h-20" (click)="item.remove();removeImgEntry(i)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div mat-list-item>
        <input hidden type="file" ng2FileSelect [uploader]="uploader" #fileInput multiple />
    </div>
    <div mat-list-item class="text-left">
        <button mat-raised-button color="accent" class="mt-8" (click)="fileInput.click()"
            [disabled]="imagesLoading">{{ uploader?.queue?.length == 0 ? 'Upload' : 'Add More' }}</button>
    </div>
    <div mat-list-item class="approve-actions text-right" [hidden]="!uploader.getNotUploadedItems().length">
        <button mat-raised-button class="green-600 mr-8" [disabled]="imagesLoading" (click)="addImages()">Upload All
            Images</button>
        <button mat-raised-button class="grey-600" [disabled]="imagesLoading" (click)="uploader.clearQueue()">Remove
            all</button>
    </div>
    <div mat-list-item class="text-left" *ngIf="error">
        <mat-error>
            Something went wrong. Please try again!
        </mat-error>
    </div>
    <mat-progress-bar class="mt-8" *ngIf="imagesLoading" mode="indeterminate"></mat-progress-bar>
</mat-nav-list>