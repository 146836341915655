<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>    
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="mat-elevation-z8 mt-8 p-8 make">  
        <div class="garage-selected">
            <div class="garage-icon"><mat-icon>build</mat-icon></div>              
            <div class="garage-name">{{spareSelectedGarageName}}</div>      
        </div>
        <!-- Tabs -->
        <div class="row vehicle-detail-header">
            <div class="vehicle-detail-label justify-content-center d-flex fw-600 text-center make_active">
                <div class="t-b ff-p" style="width: fit-content;">{{'VEHICLE_INFO.MAKE' | translate}}
                    <hr class="active_hr">
                </div>
            </div>
            <div class="vehicle-detail-label justify-content-center d-flex text-center ">
                <div class="ff-p" style="width: fit-content;"> {{'VEHICLE_INFO.MODEL' | translate}}
                    <hr class="non_active_hr">
                </div>
            </div>
            <div class="vehicle-detail-label justify-content-center d-flex text-center ">
                <div lass="ff-p" style="width: fit-content;">{{'VEHICLE_INFO.YEAR' | translate}}
                    <hr class="non_active_hr">
                </div>
            </div>
            <div class="vehicle-detail-label justify-content-center d-flex text-center ">
                <div lass="ff-p" style="width: fit-content;">{{'VEHICLE_INFO.VARIANT' | translate}}
                    <hr class="non_active_hr">
                </div>
            </div>
        </div>
        <!-- End Tabs -->
        
        <h2 class="make-title">{{'MAKE_INFO.TITLE' | translate}}</h2>

        <form class="searchMake-form">
            <input type="text" class ="search-bar" placeholder="{{'MAKE_INFO.SEARCH' | translate}}" [(ngModel)]='searchText' name="searchText" autocomplete="off" (keyup)="searchFilterType()">
            <mat-icon *ngIf="openSearch" style="float: right; margin-top: 10px; z-index: 9999999; position: absolute; margin-left: -35px;">search</mat-icon>
            <mat-icon *ngIf="clearSearch" style="float: right; margin-top: 10px; z-index: 9999999; position: absolute; margin-left: -35px; cursor: pointer;" (click)="clearSearchFilter()">close</mat-icon>
        </form>       
        <div class="text-left" style="float: left; width: 100%;">
            <div class="make-list-sec text-center" *ngFor="let make of makeList|filter:searchText">
                <div *ngIf="!make.make && make.title=='Universal'" class="make-sec" (click)="selectUniversalVehicle()">
                    <!-- <img class="img-fluid make-image" src="{{make.imageUrlPath}}" /> -->
                    <mat-icon style="font-size: 50px; height: 50px; width: 50px;"> directions_car</mat-icon>
                    <div class="make_name ff-p">{{make.title}}</div>
                </div>
                <div *ngIf="make.make && make.title!='Universal'" class="make-sec" (click)="selectMake(make.make)">
                    <img class="img-fluid make-image" src="{{make.imageUrlPath}}" />
                    <div class="make_name ff-p">{{make.make}}</div>
                </div>
            </div>
        </div>    
    </div>
</div>

    