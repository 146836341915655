<div *ngIf="cartCount>0" class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="garage-selected">
        <div class="garage-icon"><mat-icon>build</mat-icon></div>              
        <div class="garage-name">{{spareSelectedGarageName}}</div>      
    </div>
    
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="cart mt-8 p-8">            
        <h2 class="cart-title">{{'CART_INFO.TITLE' | translate}}</h2>
        <div class="cart-left">
            <div *ngIf="makeImage && model && year && variant" class="vehicle-details-sec cart-card">
                <div class="make-sec">
                    <img class="img-fluid make-image" src="{{makeImage}}" />
                </div>
                <div class="model-sec">
                    {{model}},
                </div>
                <div class="year-sec">
                    {{year}},
                </div>
                <div class="variant-sec">
                    {{variant}}
                </div> 
            </div>

            <div *ngIf="vehicleType=='universal' && !make" class="vehicle-details-sec cart-card">
                <div class="universal-make-sec">
                    <mat-icon style="font-size: 35px;"> directions_car</mat-icon>
                </div>
                <div class="model-sec universal-sec">Universal</div>                              
            </div>
            <!-- <div class="location-sec">
                <div class="cart-card"> 
                    <div class="location-icon"><mat-icon> location_on</mat-icon></div>
                    <div class="location-content">
                        <div class="location-title">Location</div>
                        <div class="location-details">
                            {{shippingAddress}}
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="spares-added-sec cart-card">
                <div class="spares-added-title" >
                    <div class="spares-count">{{cartCount}}</div>
                    <h4>{{'CART_INFO.SPARE_ADDED' | translate}}</h4>
                </div>
                <div class="add-more-sec">
                    <button mat-button (click)="addMore()">{{'CART_INFO.ADD_MORE' | translate}}</button>
                </div>
            </div>  

            <div class="spares-added" *ngFor="let cart of cartList">
                <div class="cart-card mt-8 cart-instock"> 
                    <div class="spares-part"> 
                        <img *ngIf="cart.imageUrl" class="img-fluid part-image" src="{{cart.imageUrl}}" />
                        <img *ngIf="!cart.imageUrl" class="img-fluid part-image" src="../../../../assets/images/logos/logo.png" />
                    </div>
                    <div class="spare-details-sec">
                        <div class="spare-brand">{{cart.brand.name}}</div>
                        <div class="spare-part-name">{{cart.name}}</div>
                        <div class="spare-price" *ngIf="cart.spareStocks.discountAmount>0; else onlyMRP">
                            <span class="selling-price">&#8377;{{cart.spareStocks.sellingPrice | number : '1.2-2'}}</span>
                            <span class="mrp-price">&#8377;{{cart.spareStocks.mrp | number : '1.2-2'}}</span>
                            <span class="discount-price">(You save &#8377;{{cart.spareStocks.discountAmount | number : '1.2-2'}})</span>
                        </div>
                        <ng-template #onlyMRP>
                            <div class="spare-price-mrp">
                                &#8377;{{cart.spareStocks.mrp | number : '1.2-2'}}
                            </div>
                        </ng-template>
                    </div>
                    <!-- <div class="spare-add-cart" (click)="removeItem(cart.name)">
                        <mat-icon>delete_sweep</mat-icon>
                    </div>  -->
                    <div class="spare-add-cart" >
                        <div class="btn-group">
                            <button mat-raised-button (click)="removeItem(cart.cart_qty, cart.key)"><mat-icon>remove</mat-icon></button> 
                            <input type="text" class="part-count" [(ngModel)]="cart.cart_qty" readonly>
                            <button mat-raised-button (click)="addItem(cart.cart_qty, cart.key)"><mat-icon>add</mat-icon></button> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cart-right">
            <div class="discount-sec" *ngIf="sparesDiscount>0">
                <p>You have saved - &#8377;{{sparesDiscount | number : '1.2-2'}} on this order</p>
            </div>
            <div #target  class="price-details-sec" [@shakeit]="this.states['shake']" (@shakeit.done)="shakeEnd('shake', $event)">
                <div class="cart-card"> 
                    <div class="price-details-title">{{'PRICE_INFO.PRICE_DETAILS' | translate}}</div>

                    <div class="price-details">
                        <div class="price-details-left">{{'PRICE_INFO.SPARES_TOTAL' | translate}}</div>
                        <div class="price-details-right">&#8377;{{cartSubTotal | number : '1.2-2'}}</div>
                    </div>

                    <!-- Spares Discount -->
                    <div class="price-details" *ngIf="sparesDiscount>0">
                        <div class="price-details-left">{{'PRICE_INFO.SPARES_DISCOUNT' | translate}}</div>
                        <div class="price-details-right" id="discount">- &#8377;{{sparesDiscount | number : '1.2-2'}}</div>
                    </div>     
                    
                    <!-- Coupon Discount -->
                    <!-- <div class="price-details" >
                        <div class="price-details-left">Coupon Discount</div>
                        <div class="price-details-right" id="coupon-discount" (click)="couponDiscountPopup()">Apply Coupon</div>
                    </div>   -->
                                
                    <!-- Shipping Charges -->
                    <div *ngIf="isShippingCharges=='true'" class="price-details" id="shipping-charges">
                        <div class="price-details-left">{{'PRICE_INFO.SHIPPING_CHARGES' | translate}} <mat-icon (click)="shippingChargesPopup()">info</mat-icon></div>
                        <div *ngIf="shippingChargesApplicable==true" class="price-details-right">&#8377;{{shippingCharges | number : '1.2-2'}}</div>
                        <div *ngIf="shippingChargesApplicable==false" class="price-details-right">
                            <span class="mrp-price">&#8377;{{shippingCharges}}</span>
                            <span id="free-shipping">{{'CART_INFO.FREE' | translate}}</span>
                        </div>
                    </div>    

                    <div class="price-details" id="total">
                        <div class="price-details-left">{{'PRICE_INFO.TOTAL' | translate}}</div>
                        <div class="price-details-right">&#8377;{{cartGrandTotal | number : '1.2-2'}}</div>
                    </div>
                </div>
            </div>
        </div>  

    </div>
    <div class="place-order-sec" *ngIf="cartList.length>0">
        <div class="grand-total">&#8377;{{cartGrandTotal | number : '1.2-2'}}</div>
        <div class="view-details">
            <a (click)="scroll(target)">{{'CART_INFO.VIEW_DETAILS' | translate}}</a>
        </div>
        <button class="place-order-btn" (click)="payment()">{{'CART_INFO.PLACE_ORDER' | translate}}</button>
    </div>
</div>

<!-- Empty Cart -->
<div *ngIf="cartCount==0" class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="garage-selected">
        <div class="garage-icon"><mat-icon>build</mat-icon></div>              
        <div class="garage-name">{{spareSelectedGarageName}}</div>      
    </div>
    <div class="empty-cart">
        <img src="{{emptyImg}}" />
        <div class="sec1">{{'CART_INFO.EMPTY_TITLE' | translate}}</div>
        <div class="sec2">{{'CART_INFO.EMPTY_MSG' | translate}}</div>
        <button mat-button class="browse-parts-btn" (click)="browseParts()">{{'CART_INFO.EMPTY_BTN' | translate}}</button>
    </div>
</div>