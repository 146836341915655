<div class="page-layout carded fullwidth p-12" fusePerfectScrollbar>
    <div class="page-title" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-display-1 m-0 p-16 pl-0" fxLayout="column" fxLayoutAlign="center start">Past Orders</span>
    </div>
    <div class="mat-elevation-z8 mt-8">
        <div class="p-12 pb-0 export-block" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <button [disabled]="noData" (click)="exportEXCEL()" mat-raised-button color="accent">
                    Export
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button>
            </div>
            <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table mat-table [dataSource]="pastOrdersSource" matSort matSortActive="orderId" matSortDirection="desc"
            matSortDisableClear>
            <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.orderId}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="registration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>VEHICLE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.registration | uppercase}}</strong>
                    <div><small>{{row.vehicle | uppercase}}</small> (<small>{{row.fuelType | lowercase}}</small>)</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="orderStartTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>APPOINTMENT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.orderStartTime}}
                </td>
            </ng-container>

            <ng-container matColumnDef="orderEndTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>EXPECTED DELIVERY</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.orderEndTime}}
                </td>
            </ng-container>

            <ng-container matColumnDef="grandTotal" *ngIf="!isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.grandTotal | currency:"&#8377; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="grandTotal" *ngIf="isSingapore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.grandTotal | currency:"S&#36; "}}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>STATUS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <mat-chip-list>
                        <mat-chip [color]="row.status=='Approved' ? 'green':'accent'" selected [innerHTML]="row.status">
                        </mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="pastOrdersColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: pastOrdersColumns;" (click)="openDetails(row.orderId)"></tr>
        </table>
        <div *ngIf="!pastOrdersSource">
            <div class="message-box error mt-16 text-center font-weight-700">
                No Data to display
            </div>
        </div>
        <mat-paginator [pageSizeOptions]="[50, 100]" showFirstLastButtons></mat-paginator>
    </div>
</div>