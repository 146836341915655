<mat-progress-bar
    class="mt_progres"
    *ngIf="changeStatusLoading"
    mode="indeterminate"
></mat-progress-bar>
<div class="page-layout carded fullwidth p-16" fusePerfectScrollbar>
    <div
        class="page-title detail_title"
        fxLayout="row"
        fxLayoutAlign="space-between center"
    >
        <span
            class="mat-display-1 m-0 p-16 pl-0 font_size"
            fxLayout="column"
            fxLayoutAlign="center start"
        >
            Order Details</span
        >
        <div class="step_frwd">
            <span class="caps_text">Manage orders</span>
            <span class="caps_text dtl_color">order details</span>
        </div>
    </div>
    <div class="auto-width mt-20" *ngIf="!noData">
        <div class="position-relative p-16">
            <div class="md-stepper-horizontal orange-progress">
                <div class="md-step position-relative p-24 pb-0 pl-0 active">
                    <div class="md-step-circle">
                        <mat-icon>playlist_add_check</mat-icon>
                    </div>
                    <div
                        class="md-step-title font-size-13 font-weight-700 mt-8"
                    >
                        Confirmed
                    </div>
                    <div class="md-step-bar-left mr-20"></div>
                    <div class="md-step-bar-right ml-20"></div>
                </div>
                <div
                    class="md-step position-relative p-24 pb-0"
                    [class.active]="ongoing"
                >
                    <div class="md-step-circle ongoing">
                        <mat-icon>loop</mat-icon>
                    </div>
                    <div
                        class="md-step-title font-size-13 font-weight-700 mt-8"
                    >
                        Ongoing
                    </div>
                    <div class="md-step-bar-left mr-20"></div>
                    <div class="md-step-bar-right ml-20"></div>
                </div>
                <div
                    class="md-step position-relative p-24 pb-0"
                    [class.active]="completed"
                >
                    <div class="md-step-circle completed">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div
                        class="md-step-title font-size-13 font-weight-700 mt-8"
                    >
                        Service
                    </div>
                    <div class="md-step-title font-size-13 font-weight-700">
                        Completed
                    </div>
                    <div class="md-step-bar-left mr-20"></div>
                    <div class="md-step-bar-right ml-20"></div>
                </div>
                <div
                    class="md-step position-relative p-24 pb-0 pr-8"
                    [class.active]="preClosed"
                >
                    <div class="md-step-circle payment">
                        <mat-icon>payment</mat-icon>
                    </div>
                    <div
                        class="md-step-title font-size-13 font-weight-700 mt-8"
                    >
                        Payment
                    </div>
                    <div class="md-step-bar-left mr-20"></div>
                    <div class="md-step-bar-right ml-20"></div>
                </div>
                <div
                    class="md-step position-relative p-24 pb-0 pr-8"
                    [class.active]="closed"
                >
                    <div class="md-step-circle close">
                        <mat-icon>lock</mat-icon>
                    </div>
                    <div
                        class="md-step-title font-size-13 font-weight-700 mt-8"
                    >
                        Closed
                    </div>
                    <div class="md-step-bar-left mr-20"></div>
                    <div class="md-step-bar-right ml-20"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="order_details">
        <!-- -------------left secction start-------------- -->
        <div class="summary_section">
            <div class="bg_color_border">
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="svg_section">
                            <svg
                                xmlns:dc="http://purl.org/dc/elements/1.1/"
                                xmlns:cc="http://creativecommons.org/ns#"
                                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                                xmlns:svg="http://www.w3.org/2000/svg"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                                xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                                viewBox="0 0 32 40.00000125"
                                version="1.1"
                                x="0px"
                                y="0px"
                            >
                                <g transform="translate(0,-1020.3622)">
                                    <path
                                        d="m 9.499996,1026.6122 c -0.831,0 -1.5,0.6691 -1.5,1.5 l 0,20 c 0,0.8309 0.669,1.5 1.5,1.5 l 13,0 c 0.831,0 1.5,-0.6691 1.5,-1.5 l 0,-20 c 0,-0.8309 -0.669,-1.5 -1.5,-1.5 l -4.125,0 0,1 c 0,1.0252 -0.84995,1.875 -1.875,1.875 l -1,0 c -1.02505,0 -1.875,-0.8498 -1.875,-1.875 l 0,-1 z m 6.488951,4.5027 c 1.09906,0 2,0.9029 2,2.002 0,1.099 -0.90094,2 -2,2 -1.09906,0 -2,-0.901 -2,-2 0,-1.0991 0.90094,-2.002 2,-2.002 z m -4,4.502 1,0 0,2.5 c 2e-5,0.282 0.21794,0.5 0.5,0.5 l 5,0 c 0.28206,0 0.49998,-0.218 0.5,-0.5 l 0,-2.5 1,0 0,2.5 c -6e-5,0.8224 -0.67754,1.5 -1.5,1.5 l -5,0 c -0.82246,0 -1.49994,-0.6776 -1.5,-1.5 z m -1.5,6 11,0 0,1 -11,0 z m 0,2.5 11,0 0,1 -11,0 z m 2,2.498 7,0 0,1 -7,0 z"
                                        style="
                                            opacity: 1;
                                            fill: #0000008a;
                                            fill-opacity: 1;
                                            stroke: none;
                                            stroke-width: 0.99999994;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 4;
                                            stroke-dasharray: none;
                                            stroke-opacity: 1;
                                        "
                                    />
                                    <path
                                        d="m 14.499996,1023.1122 0,4.5 c 0,0.5523 0.44771,1 1,1 l 1,0 c 0.55227,0 1,-0.4477 1,-1 l 0,-4.5 z m 1,1.002 1,0 0,1 -1,0 z"
                                        style=""
                                        fill="#0000008a"
                                        fill-opacity="1"
                                        fill-rule="evenodd"
                                        stroke="none"
                                    />
                                </g>
                            </svg>
                        </span>
                        <span class="caps_text txt_bold blk_dspl"
                            >Order Id: {{ id }}</span
                        >
                    </div>
                    <div class="order_price">
                        <span class="currency" *ngIf="!isSingapore">
                            {{ total | currency: "&#8377; " }}</span
                        >
                        <span class="currency" *ngIf="isSingapore">
                            {{ total | currency: "S&#36; " }}</span
                        >
                    </div>
                </div>
                <hr />
                <div class="souce_text margin_bet">
                    <p>{{ sourceName }}</p>
                    <span
                        class="mb-8 text-lowercase"
                        *ngIf="serviceType || serviceSubType"
                    >
                        <span class="txt_boxed ml-0" *ngIf="serviceType">{{
                            serviceType
                        }}</span>
                        <span class="txt_boxed ml-0" *ngIf="serviceSubType">{{
                            serviceSubType
                        }}</span>
                    </span>
                    <span class="txt_boxed" selected>{{ orderType }}</span>
                </div>
            </div>
            <div class="bg_color_border">
                <div class="price_n_id margin_bet">
                    <div class="order_id">
                        <span class="service_icons"
                            ><i class="material-icons"> account_circle</i></span
                        >
                        <span class="caps_text txt_bold">customer details</span>
                    </div>
                </div>

                <hr />
                <div class="souce_text margin_bet">
                    <div class="line-height-38">
                        <div class="h3 text-bold caps_text">
                            {{ customerName }}
                        </div>
                        <div class="h4 c_detl">
                            <span><i class="material-icons"> email </i></span
                            >{{ customerEmail }}
                        </div>
                        <div class="h4 c_detl">
                            <span><i class="material-icons"> phone </i></span>
                            {{ customerMobile }}
                        </div>

                        <div
                            class="h3 text-bold c_detl"
                            style="word-break: break-word"
                        >
                            <span><i class="material-icons"> home </i></span>
                            {{ customerAddress }}
                        </div>
                    </div>
                </div>
                <div class="price_n_id margin_bet" *ngIf="puckupAddress">
                    <div>
                        <span class="text-bold">Pickup:&nbsp;</span>
                    </div>
                    <div>{{ puckupAddress }}</div>
                    <div>
                        <div class="order_id">
                            <span
                                class="service_icons"
                                *ngIf="addressEdit"
                                (click)="
                                    openEditAddress(pickupLine1, pickupLine2)
                                "
                                ><i class="material-icons"> edit</i></span
                            >
                        </div>
                    </div>
                </div>
                <div class="price_n_id margin_bet" *ngIf="dropAddress">
                    <div class="pr-1">
                        <span class="text-bold">Drop:&nbsp;</span>
                    </div>
                    <div>{{ dropAddress }}</div>
                    <div>
                        <div class="order_id">
                            <span
                                class="service_icons"
                                *ngIf="!addressEdit"
                                (click)="openEditAddress(dropLine1, dropLine2)"
                                ><i class="material-icons"> edit</i></span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <!-- -------------------------- edit address --------------- -->
            <div class="status_container" *ngIf="editAddress">
                <div class="change_status">
                    <h3 class="headi_ng">
                        Edit Address
                        <span class="cancel_icon" (click)="closeEditAddress()"
                            ><i class="material-icons"> cancel </i></span
                        >
                    </h3>
                    <div class="edit_customer">
                        <form [formGroup]="addressEditForm">
                            <mat-form-field class="full_fields">
                                <input
                                    matInput
                                    type="text"
                                    name="name"
                                    [(ngModel)]="editLine1"
                                    placeholder="Flat / House No./ Building"
                                    formControlName="customerHouse"
                                />
                            </mat-form-field>
                            <mat-form-field class="full_fields">
                                <mat-label>Colony/Street/Locality</mat-label>
                                <input
                                    matInput
                                    matGoogleMapsAutocomplete
                                    country="{{ country }}"
                                    #pickupLocation
                                    id="localityAddress"
                                    [(ngModel)]="editLine2"
                                    formControlName="customerLocation"
                                    (focusout)="
                                        getCustomerAddress(pickupLocation.value)
                                    "
                                    (onLocationSelected)="
                                        onLocationSelected($event)
                                    "
                                    (onAutocompleteSelected)="
                                        onAutocompleteSelected($event)
                                    "
                                    (keypress)="checkAddressSelection()"
                                />
                            </mat-form-field>
                            <div *ngIf="selectSuggession" class="text-center">
                                <mat-error
                                    >Please select location from the
                                    drop-down</mat-error
                                >
                            </div>
                        </form>
                    </div>
                    <mat-error *ngIf="failEditMsg">{{ failEditMsg }}</mat-error>
                    <div class="messageStatus" *ngIf="successEdit">
                        {{ successEdit }}
                    </div>
                    <mat-progress-bar
                        *ngIf="editAddressLoad"
                        mode="indeterminate"
                        class="prog_ress"
                    >
                    </mat-progress-bar>
                    <button
                        mat-button
                        class="status_update"
                        [disabled]="addressEditForm.invalid || validAddress"
                        (click)="submitAddressEdit()"
                    >
                        Save Details
                    </button>
                </div>
            </div>
            <!--  /------------- -->
            <div class="bg_color_border">
                <div class="price_n_id margin_bet">
                    <div class="order_id">
                        <span class="service_icons"
                            ><i class="material-icons"> directions_car</i></span
                        >
                        <span class="caps_text txt_bold">vehicle details</span>
                    </div>
                </div>
                <hr />
                <div class="souce_text margin_bet">
                    <div class="vehicl line-height-28">
                        <div class="h3 text-bold">{{ vehicleRegNum }}</div>
                        <div class="h3">{{ vehicleModel }}</div>
                        <div class="h3 text-bold text-capitalize">
                            {{ vehicleFuelType }}
                        </div>
                        <div class="h3">{{ vehicleChasisNum }}</div>
                        <div class="h3">
                            <div
                                class="green-600-fg font-size-18 font-weight-bold"
                            >
                                {{ vehicleOdometer }} KMS
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg_color_border">
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="svg_section report">
                            <svg
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 480 480"
                                style="enable-background: new 0 0 480 480"
                                xml:space="preserve"
                            >
                                <g>
                                    <g>
                                        <path
                                            d="M416.004,32c-0.001,0-0.003,0-0.004,0H312V8c0.001-4.417-3.579-7.999-7.996-8c-0.001,0-0.003,0-0.004,0H176
       c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v24H64c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v432
       c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h352c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V40
       C424.001,35.583,420.421,32.001,416.004,32z M184,16h112v48H184V16z M408,464H72V48h96v24c-0.001,4.417,3.579,7.999,7.996,8
       c0.001,0,0.003,0,0.004,0h128c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V48h96V464z"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <rect
                                            x="88"
                                            y="368"
                                            width="176"
                                            height="16"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <rect
                                            x="280"
                                            y="368"
                                            width="112"
                                            height="16"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <rect
                                            x="88"
                                            y="400"
                                            width="304"
                                            height="16"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <rect
                                            x="88"
                                            y="432"
                                            width="128"
                                            height="16"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <rect
                                            x="232"
                                            y="432"
                                            width="160"
                                            height="16"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M144.004,232c-0.001,0-0.003,0-0.004,0H96c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v104
       c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h48c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V240
       C152.001,235.583,148.421,232.001,144.004,232z M136,336h-32v-88h32V336z"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M224.004,200c-0.001,0-0.003,0-0.004,0h-48c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v136
       c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h48c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V208
       C232.001,203.583,228.421,200.001,224.004,200z M216,336h-32V216h32V336z"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M304.004,168c-0.001,0-0.003,0-0.004,0h-48c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v168
       c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h48c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V176
       C312.001,171.583,308.421,168.001,304.004,168z M296,336h-32V184h32V336z"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M384.004,136c-0.001,0-0.003,0-0.004,0h-48c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v200
       c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h48c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V144
       C392.001,139.583,388.421,136.001,384.004,136z M376,336h-32V152h32V336z"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M382.43,80.156l-80,16c-1.024,0.205-1.998,0.609-2.867,1.188L229.578,144H160c-1.499,0-2.968,0.422-4.238,1.219l-64,40
       l8.477,13.563L162.293,160H232c1.579-0.001,3.123-0.468,4.438-1.344l70.684-47.125l78.449-15.688L382.43,80.156z"
                                        />
                                    </g>
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                            </svg>
                        </span>
                        <span class="caps_text txt_bold">reports</span>
                    </div>
                </div>
                <hr />
                <div class="souce_text report_sec">
                    <mat-tab-group>
                        <mat-tab label="Engine Scanning">
                            <span *ngIf="noEngineScan" class="space_report"
                                >Reports not available</span
                            >
                            <div *ngIf="!noEngineScan" class="space_report">
                                <div (click)="downloadEngineScanSheet(id)">
                                    <i class="material-icons">
                                        cloud_download
                                    </i>
                                    <span class="file_name"
                                        >engineCal_{{ id }}.pdf</span
                                    >
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Inspection">
                            <span *ngIf="noInspection" class="space_report"
                                >Reports not available</span
                            >
                            <div *ngIf="!noInspection" class="space_report">
                                <div
                                    (click)="downloadInspection(id)"
                                    class="mrgin_icons"
                                    *ngIf="inspectionData"
                                >
                                    <i class="material-icons">
                                        cloud_download
                                    </i>
                                    <span class="file_name"
                                        >inspectionDiagnosis_{{ id }}.pdf</span
                                    >
                                </div>
                                <div
                                    (click)="downloadQcCheckSheet(id)"
                                    *ngIf="qcCheckData"
                                >
                                    <i class="material-icons">
                                        cloud_download
                                    </i>
                                    <span class="file_name"
                                        >qualityCheckSheet_{{ id }}.pdf</span
                                    >
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
        <!-------------------------- left section end------------------------------------------ -->
        <div class="action_section">
            <div class="bg_color_border" *ngIf="statusShowHide">
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="svg_section">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="-247 370.9 100 125"
                                style="
                                    enable-background: new -247 370.9 100 100;
                                "
                                xml:space="preserve"
                            >
                                <path
                                    d="M-193.3,465.1c-1.3,0-2.7-0.4-3.9-1.2c-1.6-1-2.6-2.6-3-4.4c-0.4-1.8,0-3.7,1-5.3l7.3-10.9l-6.4-10.2l-9.3-14.6l7.1-10.7  l-6.5-1.5l-6.5,9.8c-2.1,3.2-6.5,4.1-9.7,2c-1.6-1-2.6-2.6-3-4.4c-0.4-1.8,0-3.7,1-5.3l9.2-13.9c1.6-2.3,4.5-3.5,7.3-2.9l16,3.6  c0.9,0.2,7.1,3.5,18.5,9.8l1.9,1l-13.6,20.5l8.3,13.1c1.5,2.4,1.4,5.5-0.3,7.9c-0.2,0.3-0.3,0.6-0.5,0.9l-9.1,13.6  C-188.8,464-191,465.1-193.3,465.1z"
                                />
                                <path
                                    d="M-175.7,399.7c-2.3,0-4.6-0.6-6.6-2c-2.7-1.8-4.5-4.5-5.1-7.6s0-6.3,1.8-9c3.7-5.5,11.1-7,16.6-3.4c2.7,1.8,4.5,4.5,5.1,7.6  c0.6,3.1,0,6.3-1.8,9C-168,397.8-171.8,399.7-175.7,399.7z M-175.7,379.7c-0.5,0-1.1,0.1-1.6,0.2c-2.1,0.4-3.9,1.6-5.1,3.4  c-1.2,1.8-1.6,3.9-1.2,6c0.4,2.1,1.6,3.9,3.4,5.1c1.8,1.2,3.9,1.6,6,1.2s3.9-1.6,5.1-3.4c1.2-1.8,1.6-3.9,1.2-6  c-0.4-2.1-1.6-3.9-3.4-5.1C-172.6,380.1-174.1,379.7-175.7,379.7z"
                                />
                                <path
                                    d="M-163.6,422.5c-0.5,0-1-0.1-1.5-0.2l-13.9-3.1l8.3-12.3l4.4,1l6.4-9.6c1-1.5,2.6-2.6,4.4-2.9c1.8-0.4,3.6,0,5.2,1l0,0  c1.5,1,2.6,2.6,2.9,4.4c0.4,1.8,0,3.6-1,5.2l-8.9,13.4c-0.9,1.3-2.3,2.3-3.8,2.8c-0.1,0-0.1,0-0.2,0.1h-0.1h-0.1  C-162.3,422.4-163,422.5-163.6,422.5z M-172.4,416.6l8.2,1.9c0.5,0.1,1,0.1,1.5-0.1h0.1l0,0l0.3-0.1c0.5-0.1,1.2-0.4,1.6-1.2  l8.9-13.4c0.4-0.6,0.6-1.4,0.4-2.2c-0.2-0.8-0.6-1.4-1.2-1.8l0,0c-0.6-0.4-1.4-0.6-2.2-0.4c-0.8,0.2-1.4,0.6-1.8,1.2l-7.9,11.9  l-4.4-1L-172.4,416.6z"
                                />
                                <path
                                    d="M-213.9,446.9c-0.9,0-1.9-0.2-2.8-0.6l-14.3-3.2c-3.7-0.8-6-4.5-5.2-8.3c0.4-1.8,1.5-3.3,3.1-4.3c1.6-1,3.4-1.3,5.2-0.9  l11,2.5l6.2-9.3c0.1-0.2,0.2-0.3,0.4-0.5l1.8-2.2l1.5,2.3l6.4,10.1l-7.6,11.4C-209.5,445.8-211.7,446.9-213.9,446.9z M-229.5,433.5  c-0.5,0-1.1,0.2-1.5,0.4c-0.7,0.4-1.1,1.1-1.3,1.8c-0.4,1.6,0.6,3.1,2.2,3.5l14.8,3.4l0.2,0.1c1.3,0.6,2.9,0.2,3.7-1l6-9.1l-3.4-5.4  l-6.3,9.4l-13.7-3.1C-229.1,433.5-229.3,433.5-229.5,433.5z"
                                />
                                <rect
                                    x="-245.8"
                                    y="378.6"
                                    width="55.7"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="388.6"
                                    width="27.1"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="398.6"
                                    width="19.5"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="408.6"
                                    width="15.7"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="418.6"
                                    width="9.8"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="428.6"
                                    width="5.8"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="438.6"
                                    width="5.8"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="448.6"
                                    width="23.8"
                                    height="4"
                                />
                                <rect
                                    x="-245.8"
                                    y="458.6"
                                    width="38.8"
                                    height="4"
                                />
                            </svg>
                        </span>
                        <span class="caps_text txt_bold">Action zone</span>
                    </div>
                    <div class="order_price">
                        <button
                            mat-mini-fab
                            matTooltip="Change Status"
                            (click)="showChangeStatus()"
                        >
                            <mat-icon
                                ><svg
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 500 500"
                                >
                                    <defs>
                                        <style>
                                            .cls-1 {
                                                fill: #ffffff;
                                            }
                                        </style>
                                    </defs>
                                    <title>icon</title>
                                    <path
                                        class="cls-1"
                                        d="M140.87,450.33c1.11,2.88,2.08,5.2,2.9,7.56a11.24,11.24,0,0,1-3.51,12.57,10.79,10.79,0,0,1-12.77.91,13,13,0,0,1-4.67-5.24q-8.85-21.3-17.2-42.81c-3-7.74,2.4-15.46,10.7-15.49q22.76-.07,45.52.06a11.2,11.2,0,0,1,10.84,8.24,11.67,11.67,0,0,1-5.24,13,13.23,13.23,0,0,1-5.27,1.4c-2.89.23-5.81.06-8.71.06l-.18.58c5.14,2.4,10.21,5,15.43,7.18a201.84,201.84,0,0,0,91.7,16.29c54.5-3.15,101-24.2,139-63.6,28-29.1,45.73-63.74,52.43-103.58,9-53.3-2.34-102.34-32.85-146.93-2.23-3.25-3.92-6.55-3-10.57a11.33,11.33,0,0,1,20.37-4.28A212.35,212.35,0,0,1,455,156.94c16,32.65,23.79,67.17,22.4,103.47C475,322,451,374,406.26,416.21c-30.76,29-67.18,47.65-108.51,56.48a230.37,230.37,0,0,1-154.33-21.13C142.76,451.22,142.09,450.92,140.87,450.33Z"
                                    />
                                    <path
                                        class="cls-1"
                                        d="M355.25,47.54c-1.37-3.46-2.67-6.49-3.78-9.59a11.3,11.3,0,0,1,19.4-11A15.77,15.77,0,0,1,373,30.8q8.13,19.84,16.17,39.73c3.6,8.87-1.61,16.64-11.22,16.69q-22.29.12-44.59.12c-7,0-12.08-4.81-12.1-11.34s5-11.34,12-11.41a14.63,14.63,0,0,0,3.9-.38c-.68-.34-1.35-.71-2-1A200.38,200.38,0,0,0,270.23,46C215.49,41.14,166,55.06,122.65,88.89c-40.49,31.6-65.83,73-74.56,123.68C39,265.57,50.31,314.43,80.41,359A21.61,21.61,0,0,1,84,366.38a10.82,10.82,0,0,1-6.81,12.14,11.16,11.16,0,0,1-13.6-4c-3.91-5.66-7.73-11.4-11.2-17.34-16.18-27.63-26.18-57.41-28.94-89.29C17.65,201.1,37.84,143,83.41,93.94c27.11-29.18,60.1-49.35,98.09-61.25A230.46,230.46,0,0,1,353.59,46.83C354,47,354.39,47.17,355.25,47.54Z"
                                    />
                                    <path
                                        class="cls-1"
                                        d="M318.64,227.47v2.94q0,37.28,0,74.54c0,8.6-4.57,13.14-13.21,13.14q-55,0-110.07,0c-8.69,0-13.19-4.49-13.2-13.13,0-14.16,0-28.33,0-42.49,0-8.61,7.93-14.19,15.6-11.12a11.21,11.21,0,0,1,7.13,10.7c0,10.14,0,20.28,0,30.42v2.69h90.93V217.63c0-8.48,4.39-12.86,12.86-12.88l18.58,0c.13,0,.26-.12.75-.35L250,119.25l-78.35,85.57c1.11.07,1.82.14,2.53.14,6.89,0,13.78-.08,20.67,0,5,.09,8.77,2.47,10.66,7.19a10.78,10.78,0,0,1-2.34,12,10.89,10.89,0,0,1-8.31,3.5H146.08c-5,0-8.69-2.2-10.73-6.72s-1.14-8.81,2.17-12.47c6.91-7.64,13.88-15.21,20.83-22.8q41.15-45,82.29-89.95c5.76-6.3,12.87-6.37,18.64-.08q51.26,55.86,102.53,111.7c6.84,7.41,3.38,17.32-4.24,19.46a20.19,20.19,0,0,1-5.27.58c-10.3.05-20.59,0-30.89,0Z"
                                    />
                                    <path
                                        class="cls-1"
                                        d="M249.86,365.28q-27.87,0-55.72,0c-6.15,0-10.58-3.12-12-8.29a11.39,11.39,0,0,1,10.41-14.43c.47,0,.93,0,1.4,0h111.9c6.29,0,10.78,3.45,12,9.13a11.2,11.2,0,0,1-10.23,13.53c-4.94.22-9.9.09-14.85.1Z"
                                    />
                                    <path
                                        class="cls-1"
                                        d="M249.92,387c11.46,0,22.92-.06,34.38,0a10.63,10.63,0,0,1,9.95,6.45,11.36,11.36,0,0,1-10.07,16.23q-34.26.07-68.51,0a11.35,11.35,0,0,1,.11-22.7C227.16,386.92,238.54,387,249.92,387Z"
                                    />
                                </svg>
                            </mat-icon>
                        </button>
                    </div>
                </div>
                <div class="status_container" *ngIf="changeSatatus">
                    <div class="change_status">
                        <h3 class="headi_ng">
                            Change Status
                            <span
                                class="cancel_icon"
                                (click)="closeChangeStatus()"
                                ><i class="material-icons"> cancel </i></span
                            >
                        </h3>
                        <div mat-list-item *ngIf="error" class="error_sec">
                            <mat-error>
                                {{
                                    responseMessage ===
                                    "Cannot process request - you cannot change the status of this request"
                                        ? "You are not authorized to change the status"
                                        : responseMessage ==
                                          "Error in orderpayout or paymentlog mismatch.Please fix the same.Order payout grand total does
                                not match with pitstop and garage collection.Order payout grand total does not match with the amount collected.Order payout grand total does not match with the sum of amount collected from all payment modes."
                                        ? "Order Payout
                                or paymentlog Mismatch"
                                        : responseMessage
                                }}
                            </mat-error>
                        </div>
                        <div class="loader">
                            <mat-progress-bar
                                class="mt_progres"
                                *ngIf="changeStatusLoading"
                                mode="indeterminate"
                            ></mat-progress-bar>
                        </div>
                        <div class="curernt_status">
                            <span class="checkedstatus"
                                ><i class="material-icons">
                                    check_circle_outline
                                </i></span
                            ><span class="current_text">
                                Current Status:
                                <p>
                                    {{
                                        this.currentStatus === 3
                                            ? "Confirmed"
                                            : this.currentStatus === 4
                                            ? "Confirmed"
                                            : this.currentStatus === 5
                                            ? "Confirmed"
                                            : this.currentStatus === 6
                                            ? "Ongoing"
                                            : this.currentStatus === 7
                                            ? "Service Completed"
                                            : this.currentStatus === 8
                                            ? "Service Completed"
                                            : this.currentStatus === 9
                                            ? "Service Completed"
                                            : this.currentStatus === 10
                                            ? "Payment"
                                            : this.currentStatus === 11
                                            ? "Closed"
                                            : ""
                                    }}
                                </p>
                            </span>
                        </div>
                        <button
                            class="status_btn"
                            (click)="ChangeOrderStatus()"
                        >
                            <span class="next_status"
                                >Next Status :
                                <p>
                                    {{
                                        this.nextStatus === 3
                                            ? "Confirmed"
                                            : this.nextStatus === 4
                                            ? "Technician Assigned"
                                            : this.nextStatus == 5
                                            ? "Vehicle Picked"
                                            : this.nextStatus === 6
                                            ? "Ongoing"
                                            : this.nextStatus === 7
                                            ? "Service Completed"
                                            : this.nextStatus === 8
                                            ? "Service Completed"
                                            : this.nextStatus === 9
                                            ? "Service Completed"
                                            : this.nextStatus === 10
                                            ? "Payment"
                                            : this.nextStatus === 11
                                            ? "Closed"
                                            : ""
                                    }}
                                </p></span
                            >
                            <span
                                ><i class="material-icons">
                                    arrow_forward
                                </i></span
                            >
                        </button>
                    </div>
                </div>
            </div>

            <!-- --------------------/apply coupon ------------------- -->
            <div class="status_container" *ngIf="applyCouponSec">
                <div class="change_status">
                    <h3 class="headi_ng">
                        Apply Coupon
                        <span class="cancel_icon" (click)="closeCoupon()"
                            ><i class="material-icons"> cancel </i></span
                        >
                    </h3>
                    <div class="form_coupon">
                        <form [formGroup]="applyCouponForm">
                            <mat-form-field class="full_fields">
                                <input
                                    matInput
                                    placeholder="Coupon Code"
                                    formControlName="couponCode"
                                />
                            </mat-form-field>
                        </form>
                    </div>
                    <mat-error *ngIf="couponError">{{ errorCoupon }}</mat-error>
                    <div class="messageStatus" *ngIf="!couponError">
                        {{ applyCouponMsg }}
                    </div>

                    <mat-progress-bar
                        *ngIf="couponLoading"
                        mode="indeterminate"
                        class="prog_ress"
                    >
                    </mat-progress-bar>
                    <div class="copupon_btn_sect">
                        <button
                            mat-button
                            class="coupon_btn"
                            [disabled]="applyCouponForm.invalid"
                            (click)="applyCouponSubmit()"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
            <!-- --------------------confirm remove coupon ------------------- -->
            <div class="status_container" *ngIf="confirmRemove">
                <div class="change_status">
                    <h3 class="headi_ng">
                        Remove Discount<span
                            class="cancel_icon"
                            (click)="closeRemoveCoupon()"
                        >
                            <i class="material-icons"> cancel </i>
                        </span>
                    </h3>
                    <h4>Are you sure you want remove discount ?</h4>
                    <mat-error *ngIf="couponError">{{ errorCoupon }}</mat-error>
                    <div class="messageStatus" *ngIf="!couponError">
                        {{ removeCouponMsg }}
                    </div>

                    <mat-progress-bar
                        *ngIf="couponLoading"
                        mode="indeterminate"
                        class="prog_ress"
                    >
                    </mat-progress-bar>
                    <div class="copupon_btn_sect">
                        <button
                            mat-button
                            class="coupon_cancel"
                            (click)="closeRemoveCoupon()"
                        >
                            Cancel
                        </button>
                        <button
                            mat-button
                            class="coupon_btn"
                            (click)="removeCouponSubmit()"
                        >
                            YES
                        </button>
                    </div>
                </div>
            </div>
            <!--  ----------drop driver------------------ -->
            <div [class.driver_drop_pick]="showDropDriver">
                <div
                    class="bg_color_border driver_sectn"
                    *ngIf="driverDropSection"
                >
                    <div class="min_max_height">
                        <div
                            class="pad_0"
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                        >
                            <div
                                fxLayout="column"
                                fxLayoutAlign="center start"
                                class="mat-title p-0 mb-0 caps_text txt_bold"
                            >
                                <div class="price_n_id">
                                    <div class="order_id">
                                        <span class="svg_section">
                                            <svg
                                                xmlns:x="http://ns.adobe.com/Extensibility/1.0/"
                                                xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/"
                                                xmlns:graph="http://ns.adobe.com/Graphs/1.0/"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                version="1.1"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 125"
                                                style="
                                                    enable-background: new 0 0
                                                        100 100;
                                                "
                                                xml:space="preserve"
                                            >
                                                <switch>
                                                    <foreignObject
                                                        requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
                                                        x="0"
                                                        y="0"
                                                        width="1"
                                                        height="1"
                                                    />
                                                    <g i:extraneous="self">
                                                        <g>
                                                            <path
                                                                d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"
                                                            />
                                                            <path
                                                                d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z"
                                                            />
                                                            <path
                                                                d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"
                                                            />
                                                            <path
                                                                d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"
                                                            />
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M93.3,34.8h-6.8l-4.2-13.2c-2-6.2-7.7-10.4-14.3-10.4H32c-6.5,0-12.3,4.2-14.3,10.4l-4.2,13.2H6.7     c-2.3,0-4.2,1.9-4.2,4.2v2.8c0,2.3,1.9,4.2,4.2,4.2h2.6l-0.2,0.9c-1,4.6-1.5,9.2-1.5,13.8v23.9c0,2.3,1.9,4.2,4.2,4.2h8.3     c2.3,0,4.2-1.9,4.2-4.2v-8.3h51.2v8.3c0,2.3,1.9,4.2,4.2,4.2h8.3c2.3,0,4.2-1.9,4.2-4.2V60.7c0-4.5-0.5-9.2-1.5-13.8L90.6,46h2.7     c2.3,0,4.2-1.9,4.2-4.2V39C97.5,36.7,95.6,34.8,93.3,34.8z M25.8,24.2c0.9-2.7,3.4-4.5,6.2-4.5h36c2.8,0,5.3,1.8,6.2,4.5     l4.9,15.3c0.1,0.4,0.1,0.9-0.2,1.2c-0.3,0.4-0.7,0.6-1.1,0.6h-6c-0.7-2.7-2.3-4.9-4.5-6.4c-1.4,1.3-3.4,2.1-5.4,2.1     c-2.1,0-3.9-0.7-5.4-2.1c-2.1,1.4-3.8,3.7-4.4,6.4H22.2c-0.4,0-0.9-0.2-1.1-0.6c-0.3-0.4-0.3-0.8-0.2-1.2L25.8,24.2z M32.6,63.3     h-9.8c-1.6,0-3-1.3-3-3v-4.6c0-1.8,1.6-3.2,3.5-2.9l9.1,1.5c1.2,0.2,2.2,1.1,2.4,2.3l0.7,3.1C35.9,61.5,34.5,63.3,32.6,63.3z      M80.2,60.3c0,1.6-1.3,3-3,3h-9.8c-1.9,0-3.3-1.7-2.9-3.6l0.7-3.1c0.3-1.2,1.2-2.1,2.4-2.3l9.1-1.5c1.8-0.3,3.5,1.1,3.5,2.9V60.3     z"
                                                            />
                                                            <circle
                                                                cx="61.9"
                                                                cy="28.5"
                                                                r="6.1"
                                                            />
                                                        </g>
                                                    </g>
                                                </switch>
                                            </svg>
                                        </span>
                                        <span
                                            class="caps_text txt_bold blk_dspl"
                                            >Drop Driver Details</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <button
                                mat-mini-fab
                                (click)="
                                    openAssignTechnicianBottomSheet(
                                        $event,
                                        'driver'
                                    )
                                "
                                *ngIf="addDropDriver"
                            >
                                <mat-icon
                                    *ngIf="!dropDriver"
                                    aria-label="Add Technician"
                                    >add</mat-icon
                                >
                                <mat-icon
                                    *ngIf="dropDriver"
                                    aria-label="Edit Technician"
                                    >edit</mat-icon
                                >
                            </button>
                        </div>
                        <hr />
                        <div
                            class="p-16 mt-16"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            *ngIf="dropDriver"
                        >
                            <mat-icon class="h-36 w-36 font-size-36"
                                >supervisor_account</mat-icon
                            >
                            <div class="ml-16 line-height-28">
                                <div class="h3">{{ returnName }}</div>
                                <div class="h4">{{ returnMobile }}</div>
                            </div>
                        </div>
                        <div
                            class="p-16 mt-16 secondary-text text-center"
                            *ngIf="!dropDriver"
                        >
                            <div>
                                <mat-icon class="h-36 w-36 font-size-36"
                                    >layers_clear</mat-icon
                                >
                            </div>
                            <div class="h4 mt-4 text-bold">No data found</div>
                        </div>
                    </div>
                </div>
                <!-- ------------------pickup driver------------------- -->
                <div
                    class="bg_color_border driver_sectn"
                    *ngIf="pickUp && !noPickupDriverData"
                >
                    <div class="min_max_height">
                        <div
                            class="pad_0"
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                        >
                            <div
                                fxLayout="column"
                                fxLayoutAlign="center start"
                                class="mat-title p-0 mb-0 caps_text txt_bold"
                            >
                                <div class="price_n_id">
                                    <div class="order_id">
                                        <span class="svg_section">
                                            <svg
                                                xmlns:x="http://ns.adobe.com/Extensibility/1.0/"
                                                xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/"
                                                xmlns:graph="http://ns.adobe.com/Graphs/1.0/"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                version="1.1"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 125"
                                                style="
                                                    enable-background: new 0 0
                                                        100 100;
                                                "
                                                xml:space="preserve"
                                            >
                                                <switch>
                                                    <foreignObject
                                                        requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
                                                        x="0"
                                                        y="0"
                                                        width="1"
                                                        height="1"
                                                    />
                                                    <g i:extraneous="self">
                                                        <g>
                                                            <path
                                                                d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"
                                                            />
                                                            <path
                                                                d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z"
                                                            />
                                                            <path
                                                                d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"
                                                            />
                                                            <path
                                                                d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"
                                                            />
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M93.3,34.8h-6.8l-4.2-13.2c-2-6.2-7.7-10.4-14.3-10.4H32c-6.5,0-12.3,4.2-14.3,10.4l-4.2,13.2H6.7     c-2.3,0-4.2,1.9-4.2,4.2v2.8c0,2.3,1.9,4.2,4.2,4.2h2.6l-0.2,0.9c-1,4.6-1.5,9.2-1.5,13.8v23.9c0,2.3,1.9,4.2,4.2,4.2h8.3     c2.3,0,4.2-1.9,4.2-4.2v-8.3h51.2v8.3c0,2.3,1.9,4.2,4.2,4.2h8.3c2.3,0,4.2-1.9,4.2-4.2V60.7c0-4.5-0.5-9.2-1.5-13.8L90.6,46h2.7     c2.3,0,4.2-1.9,4.2-4.2V39C97.5,36.7,95.6,34.8,93.3,34.8z M25.8,24.2c0.9-2.7,3.4-4.5,6.2-4.5h36c2.8,0,5.3,1.8,6.2,4.5     l4.9,15.3c0.1,0.4,0.1,0.9-0.2,1.2c-0.3,0.4-0.7,0.6-1.1,0.6h-6c-0.7-2.7-2.3-4.9-4.5-6.4c-1.4,1.3-3.4,2.1-5.4,2.1     c-2.1,0-3.9-0.7-5.4-2.1c-2.1,1.4-3.8,3.7-4.4,6.4H22.2c-0.4,0-0.9-0.2-1.1-0.6c-0.3-0.4-0.3-0.8-0.2-1.2L25.8,24.2z M32.6,63.3     h-9.8c-1.6,0-3-1.3-3-3v-4.6c0-1.8,1.6-3.2,3.5-2.9l9.1,1.5c1.2,0.2,2.2,1.1,2.4,2.3l0.7,3.1C35.9,61.5,34.5,63.3,32.6,63.3z      M80.2,60.3c0,1.6-1.3,3-3,3h-9.8c-1.9,0-3.3-1.7-2.9-3.6l0.7-3.1c0.3-1.2,1.2-2.1,2.4-2.3l9.1-1.5c1.8-0.3,3.5,1.1,3.5,2.9V60.3     z"
                                                            />
                                                            <circle
                                                                cx="61.9"
                                                                cy="28.5"
                                                                r="6.1"
                                                            />
                                                        </g>
                                                    </g>
                                                </switch>
                                            </svg>
                                        </span>
                                        <span
                                            class="caps_text txt_bold blk_dspl"
                                            >Pickup Driver Details</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="doorStep || pickUp || self">
                                <div *ngIf="pickDriverGoldbell">
                                    <button
                                        mat-mini-fab
                                        (click)="
                                            openAssignTechnicianBottomSheet(
                                                $event,
                                                'driver'
                                            )
                                        "
                                    >
                                        <mat-icon
                                            *ngIf="!assignedTech"
                                            aria-label="Add Technician"
                                            >add</mat-icon
                                        >
                                        <mat-icon
                                            *ngIf="assignedTech"
                                            aria-label="Edit Technician"
                                            >edit</mat-icon
                                        >
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div
                            class="p-16 mt-16"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            *ngIf="
                                (doorStep && !noTechData) ||
                                (pickUp && !noPickupData) ||
                                (self && !noTechData)
                            "
                        >
                            <mat-icon class="h-36 w-36 font-size-36"
                                >supervisor_account</mat-icon
                            >
                            <div
                                class="ml-16 line-height-28"
                                *ngIf="techDstSelf"
                            >
                                <div class="h3">{{ pickName }}</div>
                                <div class="h4">{{ pickMobile }}</div>
                            </div>
                            <div class="ml-16 line-height-28">
                                <div class="h3">{{ pickedDriver }}</div>
                                <div class="h4">{{ pickMobile }}</div>
                            </div>
                        </div>
                        <div
                            class="p-16 mt-16 secondary-text text-center"
                            *ngIf="
                                (pickUp && noPickupData) ||
                                (doorStep && noTechData) ||
                                (self && noTechData)
                            "
                        >
                            <div>
                                <mat-icon class="h-36 w-36 font-size-36"
                                    >layers_clear</mat-icon
                                >
                            </div>
                            <div class="h4 mt-4 text-bold">No data found</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg_color_border" *ngIf="pickUp || doorStep || self">
                <div class="min_max_height">
                    <div
                        class="pad_0"
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                    >
                        <div
                            fxLayout="column"
                            fxLayoutAlign="center start"
                            class="mat-title p-0 mb-0 caps_text txt_bold"
                        >
                            <div class="price_n_id">
                                <div class="order_id">
                                    <span class="svg_section">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 100 125"
                                            enable-background="new 0 0 100 100"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <path
                                                    fill="#0000008a"
                                                    d="M57.021,81.796c-0.546,0-1.093,0.208-1.512,0.625c-0.831,0.839-0.827,2.195,0.001,3.03   c0.42,0.417,0.967,0.625,1.515,0.625s1.095-0.208,1.515-0.627c0.832-0.833,0.834-2.188-0.004-3.021   C58.118,82.006,57.57,81.796,57.021,81.796z"
                                                />
                                                <path
                                                    d="M61.589,88.496c-1.215,1.22-2.834,1.893-4.558,1.893s-3.345-0.671-4.566-1.89c-1.219-1.219-1.89-2.84-1.888-4.564   c0.001-1.723,0.672-3.341,1.889-4.557l18.74-18.739c-3.535-2.487-7.729-4.101-12.271-4.516c-0.101,4.635-6.97,8.374-15.428,8.374   c-8.46,0-15.327-3.739-15.428-8.374C15.138,57.307,5,68.185,5,81.433c0,14.04,11.381,14.004,25.418,14.004h26.178   c14.037,0,25.418,0.036,25.418-14.004c0-3.904-0.886-7.599-2.459-10.904L61.589,88.496z"
                                                />
                                                <path
                                                    d="M43.507,55.323c-8.586,0-15.682-9.307-16.822-21.39h-2.334c1.296,13.761,9.376,24.363,19.156,24.363   c9.777,0,17.859-10.602,19.156-24.363h-2.336C59.187,46.017,52.094,55.323,43.507,55.323z"
                                                />
                                                <path
                                                    d="M23.439,31.07h40.135c1.564,0,2.83-1.267,2.83-2.83c0-1.563-1.266-2.831-2.83-2.831h-0.771   c0-7.521-4.307-14.031-10.584-17.215l-1.6,9.065c-0.126,0.718-0.75,1.223-1.455,1.223c-0.085,0-0.172-0.007-0.259-0.022   c-0.804-0.142-1.341-0.909-1.199-1.714l1.826-10.358c-0.686-1.096-1.901-1.825-3.29-1.825h-5.477c-1.386,0-2.601,0.729-3.288,1.825   l1.826,10.358c0.142,0.805-0.394,1.572-1.199,1.714c-0.087,0.015-0.174,0.022-0.258,0.022c-0.706,0-1.332-0.504-1.456-1.223   l-1.599-9.064c-6.279,3.183-10.584,9.694-10.584,17.214h-0.771c-1.564,0-2.83,1.267-2.83,2.831   C20.609,29.803,21.875,31.07,23.439,31.07z"
                                                />
                                                <path
                                                    d="M92.997,55.067l-4.981,4.979h-4.128l-2.975-2.968l-0.002-4.138l4.979-4.979l-2.003-2.001   c-2.59,0.101-5.149,1.141-7.125,3.12c-3.061,3.056-3.872,7.505-2.448,11.304L53.894,80.806c-1.727,1.726-1.733,4.533-0.001,6.266   c0.867,0.864,2.003,1.298,3.139,1.298c1.132,0,2.265-0.433,3.127-1.298l20.421-20.424c1.207,0.451,2.479,0.677,3.752,0.677   c2.731,0,5.463-1.04,7.549-3.122c1.98-1.984,3.021-4.538,3.12-7.13L92.997,55.067z M58.54,85.449   c-0.42,0.419-0.967,0.627-1.515,0.627s-1.095-0.208-1.515-0.625c-0.828-0.835-0.832-2.191-0.001-3.03   c0.419-0.417,0.966-0.625,1.512-0.625c0.549,0,1.097,0.21,1.515,0.632C59.374,83.261,59.372,84.616,58.54,85.449z"
                                                />
                                            </g>
                                        </svg>
                                    </span>
                                    <span class="caps_text txt_bold blk_dspl"
                                        >Technician Details</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div *ngIf="ongoingOrder">
                            <div>
                                <button
                                    mat-mini-fab
                                    (click)="
                                        openAssignTechnicianBottomSheet(
                                            $event,
                                            'technician'
                                        )
                                    "
                                >
                                    <mat-icon
                                        *ngIf="!addTechnician"
                                        aria-label="Add Technician"
                                        >add</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="addTechnician"
                                        aria-label="Edit Technician"
                                        >edit</mat-icon
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div
                        class="p-16 mt-16"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        *ngIf="addTechnician"
                    >
                        <mat-icon class="h-36 w-36 font-size-36"
                            >supervisor_account</mat-icon
                        >
                        <div class="ml-16 line-height-28" *ngIf="doorStep">
                            <div class="h3">{{ techinicianAutoAssign }}</div>
                            <div class="h4">{{ techinicianAssignNum }}</div>
                        </div>
                        <div class="ml-16 line-height-28" *ngIf="!doorStep">
                            <div class="h3">{{ pickupOrderTechnician }}</div>
                            <div class="h4">{{ pickupTechnicianNumber }}</div>
                        </div>
                    </div>
                    <div
                        class="p-16 mt-16 secondary-text text-center"
                        *ngIf="!addTechnician"
                    >
                        <div>
                            <mat-icon class="h-36 w-36 font-size-36"
                                >layers_clear</mat-icon
                            >
                        </div>
                        <div class="h4 mt-4 text-bold">No data found</div>
                    </div>
                </div>
            </div>
            <div class="bg_color_border timeline_height">
                <div
                    class="pad_0"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                >
                    <div
                        fxLayout="column"
                        fxLayoutAlign="center start"
                        class="mat-title p-0 mb-0 caps_text txt_bold"
                    >
                        <div class="price_n_id">
                            <div class="order_id">
                                <span class="svg_section timlin">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 100 125"
                                        style="
                                            enable-background: new 0 0 100 100;
                                        "
                                        xml:space="preserve"
                                    >
                                        <polygon
                                            points="64.6,59.5 57.7,66.4 35.4,66.4 42.3,59.5 35.4,52.6 57.7,52.6 "
                                        />
                                        <polygon
                                            points="36,59.5 29,66.4 6.8,66.4 6.8,52.6 29,52.6 "
                                        />
                                        <polygon
                                            points="93.2,59.5 86.3,66.4 64,66.4 71,59.5 64,52.6 86.3,52.6 "
                                        />
                                        <path
                                            d="M21,33.6c-3.8,0-6.9,3.1-6.9,6.9c0,3.2,2.2,5.9,5.1,6.7l1.8,3.1l1.8-3.1c3-0.8,5.1-3.5,5.1-6.7C27.9,36.7,24.8,33.6,21,33.6  z M21,44.5c-2.2,0-3.9-1.8-3.9-3.9s1.8-3.9,3.9-3.9c2.2,0,3.9,1.8,3.9,3.9S23.1,44.5,21,44.5z"
                                        />
                                        <path
                                            d="M79,33.6c-3.8,0-6.9,3.1-6.9,6.9c0,3.2,2.2,5.9,5.1,6.7l1.8,3.1l1.8-3.1c3-0.8,5.1-3.5,5.1-6.7C86,36.7,82.9,33.6,79,33.6z   M79,44.5c-2.2,0-3.9-1.8-3.9-3.9s1.8-3.9,3.9-3.9s3.9,1.8,3.9,3.9S81.2,44.5,79,44.5z"
                                        />
                                    </svg>
                                </span>
                                <span class="caps_text txt_bold blk_dspl"
                                    >Order Timeline
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <mat-menu
                            #filterMenu="matMenu"
                            [overlapTrigger]="false"
                        >
                            <mat-radio-group class="filter-group">
                                <div mat-menu-item>
                                    <mat-radio-button
                                        [value]="all"
                                        [checked]="!notes && !updates"
                                        (click)="toggleFilter('all')"
                                        >All</mat-radio-button
                                    >
                                </div>
                                <div mat-menu-item>
                                    <mat-radio-button
                                        [value]="notes"
                                        [checked]="notes && !updates"
                                        (click)="toggleFilter('notes')"
                                        >Notes</mat-radio-button
                                    >
                                </div>
                                <div mat-menu-item>
                                    <mat-radio-button
                                        [value]="updates"
                                        [checked]="updates && !notes"
                                        (click)="toggleFilter('update')"
                                        >Updates</mat-radio-button
                                    >
                                </div>
                            </mat-radio-group>
                        </mat-menu>
                        <button
                            [matMenuTriggerFor]="filterMenu"
                            mat-mini-fab
                            class="mr-8"
                        >
                            <mat-icon aria-label="Filter">filter_list</mat-icon>
                        </button>
                        <button mat-mini-fab (click)="openBottomSheet()">
                            <mat-icon aria-label="Add">add</mat-icon>
                        </button>
                    </div>
                </div>
                <hr />
                <div class="souce_text min_height_sec">
                    <div
                        class="p-8 timeline-div"
                        [class.pickup_timline]="pickupTimline"
                    >
                        <ul class="timeline">
                            <li
                                *ngFor="
                                    let item of orderTimeline;
                                    let i = index
                                "
                                class="timeline-event"
                            >
                                <label class="timeline-event-icon"></label>
                                <div class="timeline-event-copy">
                                    <p
                                        class="timeline-event-thumbnail text-uppercase"
                                    >
                                        {{ item.status }}
                                    </p>
                                    <h4>
                                        Changed by
                                        <strong>{{ item.agentName }}</strong>
                                        {{
                                            item.agentName === "system"
                                                ? " "
                                                : item.agentRole ===
                                                  "admin_manager"
                                                ? "(Admin Manager)"
                                                : item.agentRole ===
                                                  "service_advisor"
                                                ? "(Service Advisor)"
                                                : item.agentRole === "owner"
                                                ? "(Owner)"
                                                : item.agentRole
                                        }}
                                        <!-- {{agentRole=='service_advisor,admin_manager'?'Service Advisor,Admin Manager':agentRole}} -->
                                    </h4>
                                    <h3>{{ item.notes }}</h3>
                                    <p>
                                        <strong>{{ item.created }}</strong>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="bg_color_border">
                <div class="price_n_id margin_bet">
                    <div class="order_id">
                        <span class="service_icons"
                            ><i class="material-icons"> payment</i></span
                        >
                        <span class="caps_text txt_bold">Payment</span>
                    </div>
                </div>
                <hr />
                <div class="souce_text margin_bet">
                    <div class="vehicl line-height-28">
                        <div class="pay_status">
                            <span class="txt_bold">Status - </span
                            ><span *ngIf="paymentDone">Done </span
                            ><span *ngIf="!paymentDone">Pending </span>
                        </div>
                        <div class="pay_mode" *ngIf="showMode">
                            <span class="txt_bold">Mode - </span>
                            <span
                                *ngFor="let item of paymentStatus"
                                class="pay_imgs"
                            >
                                {{ item.paymentMode }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- -------------------------right section end---------------------------------------------------- -->
    </div>
    <div class="bg_color_border">
        <div class="pad_0" fxLayout="row" fxLayoutAlign="space-between center">
            <div
                fxLayout="column"
                fxLayoutAlign="center start"
                class="mat-title p-0 mb-0 caps_text txt_bold"
            >
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="quote">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="0 0 100 125"
                                enable-background="new 0 0 100 100"
                                xml:space="preserve"
                            >
                                <path
                                    d="M63.252,18.342c-0.38-0.436-1.125-0.436-1.505,0l-10.5,12c-0.258,0.295-0.32,0.714-0.158,1.071  S51.607,32,52,32h4v5.37C54.179,36.501,52.149,36,50,36s-4.179,0.501-6,1.37V31c0-0.553-0.448-1-1-1H32c-0.552,0-1,0.447-1,1v37h-4  c-0.393,0-0.749,0.229-0.911,0.587s-0.1,0.776,0.158,1.071l10.5,12C36.938,81.876,37.211,82,37.5,82s0.563-0.124,0.752-0.342  l10.5-12c0.258-0.295,0.32-0.714,0.158-1.071S48.393,68,48,68h-4v-5.37c1.821,0.869,3.851,1.37,6,1.37s4.179-0.501,6-1.37V69  c0,0.553,0.448,1,1,1h11c0.552,0,1-0.447,1-1V32h4c0.393,0,0.749-0.229,0.911-0.587s0.1-0.776-0.158-1.071L63.252,18.342z M43,70  h2.796L37.5,79.481L29.204,70H32c0.552,0,1-0.447,1-1V32h9v6.527c-3.622,2.533-6,6.728-6,11.473s2.378,8.939,6,11.473V69  C42,69.553,42.448,70,43,70z M38,50c0-6.617,5.383-12,12-12s12,5.383,12,12s-5.383,12-12,12S38,56.617,38,50z M68,30  c-0.552,0-1,0.447-1,1v37h-9v-6.527c3.622-2.533,6-6.728,6-11.473s-2.378-8.939-6-11.473V31c0-0.553-0.448-1-1-1h-2.796l8.296-9.481  L70.796,30H68z"
                                />
                                <path
                                    d="M50,47c0.439,0,1,0.561,1,1c0,0.553,0.448,1,1,1s1-0.447,1-1c0-1.198-0.884-2.337-2-2.789V44  c0-0.553-0.448-1-1-1s-1,0.447-1,1v1.211c-1.116,0.452-2,1.591-2,2.789c0,1.504,1.276,2.46,2.402,3.304  C50.151,51.865,51,52.501,51,53c0,0.439-0.561,1-1,1c-0.449,0-1-0.552-1-1c0-0.553-0.448-1-1-1s-1,0.447-1,1  c0,1.198,0.884,2.337,2,2.789V57c0,0.553,0.448,1,1,1s1-0.447,1-1v-1.211c1.116-0.452,2-1.591,2-2.789  c0-1.5-1.274-2.454-2.398-3.297C49.851,49.141,49,48.503,49,48C49,47.552,49.551,47,50,47z"
                                />
                            </svg>
                        </span>
                        <span class="caps_text txt_bold blk_dspl">
                            Quotation</span
                        >
                    </div>
                </div>
            </div>
            <div>
                <button
                    *ngIf="showCustomQuotation"
                    mat-mini-fab
                    matTooltip="Upload Invoice"
                    (click)="openImageBottomSheet('invoice')"
                    class="mr-8"
                >
                    <mat-icon aria-label="Add">receipt</mat-icon>
                </button>
                <button
                    mat-mini-fab
                    matTooltip="Apply Coupon"
                    (click)="openApplyCoupon()"
                    *ngIf="hideCoupon"
                    class="mr-8"
                >
                    <mat-icon aria-label="Add">card_giftcard</mat-icon>
                </button>
                <button
                    mat-mini-fab
                    (click)="addUnit()"
                    *ngIf="!completed"
                    class="mr-8"
                >
                    <mat-icon aria-label="Add">add</mat-icon>
                </button>
                <button
                    mat-mini-fab
                    (click)="quotationSave.ngSubmit.emit()"
                    *ngIf="!completed"
                >
                    <mat-icon aria-label="Save">save</mat-icon>
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    (click)="openInvoice()"
                    *ngIf="completed"
                >
                    View Invoice
                </button>
            </div>
        </div>
        <hr />
        <div class="souce_text">
            <div class="p-8 pt-20 quotation-section" *ngIf="!noQuotationData">
                <mat-progress-bar
                    *ngIf="quotationLoading"
                    mode="indeterminate"
                ></mat-progress-bar>
                <form
                    *ngIf="dataReceived && !quotationLoading"
                    [formGroup]="quotationForm"
                    novalidate
                    (ngSubmit)="onQuotationSubmit()"
                    #quotationSave="ngForm"
                >
                    <div formArrayName="rows">
                        <table>
                            <thead>
                                <tr>
                                    <th class="w-20-p font-size-13">Name</th>
                                    <th
                                        class="w-20-p font-size-13"
                                        *ngIf="!showCustomQuotation"
                                    >
                                        Description
                                    </th>
                                    <th
                                        class="w-20-p font-size-13"
                                        *ngIf="showCustomQuotation"
                                    >
                                        Category
                                    </th>
                                    <th class="w-15-p font-size-13">
                                        Unit Price
                                    </th>
                                    <th class="w-10-p font-size-13">Qty</th>
                                    <th class="w-15-p font-size-13">
                                        Row Total
                                    </th>
                                    <th class="w-10-p font-size-13">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let row of quotationForm.controls.rows
                                            .controls;
                                        let i = index
                                    "
                                    [formGroupName]="i"
                                >
                                    <td class="w-20-p disable">
                                        <input
                                            class="form-control"
                                            formControlName="itemName"
                                            [matAutocomplete]="name"
                                            #itemName
                                        />
                                        <mat-autocomplete
                                            #name="matAutocomplete"
                                        >
                                            <mat-option
                                                *ngFor="
                                                    let item of filteredOptions[
                                                        i
                                                    ] | async
                                                "
                                                [value]="item.itemName"
                                            >
                                                {{ item.itemName }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </td>
                                    <td
                                        class="w-20-p disable"
                                        *ngIf="!showCustomQuotation"
                                    >
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="itemDescription"
                                            [matAutocomplete]="desc"
                                        />
                                        <mat-autocomplete
                                            #desc="matAutocomplete"
                                        >
                                            <mat-option
                                                *ngFor="
                                                    let item of filteredOptions[
                                                        i
                                                    ] | async
                                                "
                                                [value]="item.itemName"
                                            >
                                                {{ item.itemName }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </td>
                                    <td
                                        class="w-20-p disable"
                                        *ngIf="showCustomQuotation"
                                    >
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="itemDescription"
                                            [matAutocomplete]="desc"
                                        />
                                        <mat-autocomplete
                                            #desc="matAutocomplete"
                                        >
                                            <mat-option
                                                *ngFor="
                                                    let item of filteredCategOptions[
                                                        i
                                                    ] | async
                                                "
                                                [value]="item.name"
                                            >
                                                {{ item.name }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </td>
                                    <td class="w-15-p">
                                        <input
                                            type="number"
                                            class="form-control"
                                            formControlName="itemUnitPrice"
                                        />
                                    </td>
                                    <td class="w-10-p">
                                        <input
                                            type="number"
                                            min="1"
                                            class="form-control"
                                            formControlName="itemQuantity"
                                        />
                                    </td>
                                    <td class="w-15-p">
                                        <input
                                            type="number"
                                            class="form-control"
                                            formControlName="itemTotal"
                                        />
                                    </td>
                                    <td class="w-10-p">
                                        <label class="approve"
                                            >Approve
                                            <input [ngClass]="{'checked': row.get('status').value === 2}"
                                                type="radio"
                                                value="2"
                                                [checked]="
                                                    row.get('status').value ===
                                                    2
                                                "
                                                formControlName="status"
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="approve"
                                            >Reject
                                            <input [ngClass]="{'checked': row.get('status').value === 0}"
                                                type="radio"
                                                value="0"
                                                [checked]="
                                                    row.get('status').value ===
                                                    0
                                                "
                                                formControlName="status"
                                            />
                                            <span class="checkmark2"></span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="quotation-totals">
                            <tbody>
                                <tr>
                                    <td
                                        class="w-65-p text-right font-size-13 font-weight-700 pr-16"
                                    >
                                        Subtotal<span
                                            class="secondary-text font-size-12"
                                            >(Without Tax)</span
                                        >
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="!isSingapore"
                                    >
                                        {{
                                            quotationSubTotal
                                                | currency: "&#8377; "
                                        }}
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="isSingapore"
                                    >
                                        {{
                                            quotationSubTotal
                                                | currency: "S&#36; "
                                        }}
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td class="w-65-p text-right font-size-13 font-weight-700 pr-16">
                                        Discount<span class="secondary-text font-size-12"></span></td>
                                    <td class="w-25-p text-left font-size-13 font-weight-700 pl-16" *ngIf="!isSingapore">
                                        {{ discount | currency:"&#8377; "}}
                                    </td>
                                    <td class="w-25-p text-left font-size-13 font-weight-700 pl-16" *ngIf="isSingapore">
                                        {{ discount | currency:"S&#36; "}}
                                    </td>
                                </tr> -->
                                <tr>
                                    <td
                                        class="w-65-p text-right font-size-13 font-weight-700 pr-16"
                                    >
                                        Total Tax
                                        <span
                                            class="secondary-text font-size-12"
                                            >(GST)</span
                                        >
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="!isSingapore"
                                    >
                                        {{
                                            quotationTotalTax
                                                | currency: "&#8377; "
                                        }}
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="isSingapore"
                                    >
                                        {{
                                            quotationTotalTax
                                                | currency: "S&#36; "
                                        }}
                                    </td>
                                </tr>
                                <tr *ngIf="couponApplied">
                                    <td
                                        class="w-65-p text-right font-size-13 font-weight-700 pr-16"
                                    >
                                        Discount
                                        <span
                                            class="coupon_type"
                                            (click)="openRemoveCoupon()"
                                            >{{ discountType }}
                                            <i class="material-icons">
                                                cancel
                                            </i></span
                                        >
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="!isSingapore"
                                    >
                                        {{ discount | currency: "&#8377; " }}
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="isSingapore"
                                    >
                                        {{ discount | currency: "S&#36; " }}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        class="w-65-p text-right font-size-13 font-weight-700 pr-16"
                                    >
                                        Grand Total
                                        <span
                                            class="secondary-text font-size-12"
                                            >(Invoice Value - All
                                            Inclusive)</span
                                        >
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="!isSingapore"
                                    >
                                        {{
                                            quotationGrandTotal
                                                | currency: "&#8377; "
                                        }}
                                    </td>
                                    <td
                                        class="w-25-p text-left font-size-13 font-weight-700 pl-16"
                                        *ngIf="isSingapore"
                                    >
                                        {{
                                            quotationGrandTotal
                                                | currency: "S&#36; "
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
            <div
                class="p-16 secondary-text text-center"
                *ngIf="noQuotationData"
            >
                <div>
                    <mat-icon class="h-36 w-36 font-size-36"
                        >layers_clear</mat-icon
                    >
                </div>
                <div class="h4 mt-4 text-bold">No data found</div>
            </div>
        </div>
    </div>
    <div class="bg_color_border">
        <div class="pad_0" fxLayout="row" fxLayoutAlign="space-between center">
            <div
                fxLayout="column"
                fxLayoutAlign="center start"
                class="mat-title p-0 mb-0 caps_text txt_bold"
            >
                <div class="price_n_id">
                    <div class="order_id">
                        <span class="svg_section">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="0 0 100 125"
                                enable-background="new 0 0 100 100"
                                xml:space="preserve"
                            >
                                <path
                                    d="M87.895,14.474h-75.79C8.188,14.474,5,17.661,5,21.579v56.842c0,3.918,3.188,7.104,7.105,7.104h75.79  c3.918,0,7.104-3.188,7.104-7.104V21.579C95,17.661,91.813,14.474,87.895,14.474z M74.279,53.826  c-1.055-1.117-2.467-1.732-3.979-1.732c-1.512,0-2.925,0.615-3.979,1.732L55.584,65.215l-18.205-19.86  c-1.044-1.14-2.45-1.767-3.958-1.767c-1.508,0-2.914,0.627-3.958,1.767L9.737,66.875V21.579c0-1.307,1.062-2.369,2.368-2.369h75.79  c1.307,0,2.367,1.062,2.367,2.369v49.198L74.279,53.826z"
                                />
                                <circle cx="72.736" cy="36.737" r="8.526" />
                            </svg> </span
                        ><span class="caps_text txt_bold blk_dspl">Images</span>
                    </div>
                </div>
            </div>
            <div>
                <button mat-mini-fab (click)="openImageBottomSheet('image')">
                    <mat-icon aria-label="Add">add</mat-icon>
                </button>
            </div>
        </div>
        <hr />
        <div class="souce_text">
            <div class="p-16 image-list-container">
                <div class="souce_text">
                    <mat-tab-group>
                        <mat-tab label="Pre Service">
                            <p class="no_images" *ngIf="noPreImage">
                                No Images
                            </p>
                            <div *ngIf="!noPreImage">
                                <div
                                    class="image-container"
                                    *ngFor="
                                        let image of preServiceImages;
                                        let i = index
                                    "
                                >
                                    <img
                                        [src]="image.url"
                                        (click)="open(i)"
                                        class="h-100-p w-100-p"
                                        [alt]="image.description"
                                    />
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="During Service">
                            <p class="no_images" *ngIf="noDuringImage">
                                No Images
                            </p>
                            <div *ngIf="!noDuringImage">
                                <div
                                    class="image-container"
                                    *ngFor="
                                        let image of duringServiceImages;
                                        let i = index
                                    "
                                >
                                    <img
                                        [src]="image.url"
                                        (click)="open(i)"
                                        class="h-100-p w-100-p"
                                        [alt]="image.description"
                                    />
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Post Service">
                            <p class="no_images" *ngIf="noPostImage">
                                No Images
                            </p>
                            <div *ngIf="!noPostImage">
                                <div
                                    class="image-container"
                                    *ngFor="
                                        let image of postServiceImages;
                                        let i = index
                                    "
                                >
                                    <img
                                        [src]="image.url"
                                        (click)="open(i)"
                                        class="h-100-p w-100-p"
                                        [alt]="image.description"
                                    />
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Invoice">
                            <iframe *ngIf="invoiceImage"
                                [src]="invoiceImage"
                                frameborder="0"
                            ></iframe>

                            <p
                                class="veiw_pdf"
                                (click)="openPdf(invoiceImageUrl)"
                            ></p>
                        </mat-tab>
                    </mat-tab-group>
                </div>

                <mat-progress-bar
                    *ngIf="imageLoading"
                    mode="indeterminate"
                ></mat-progress-bar>
            </div>
        </div>
    </div>
</div>
