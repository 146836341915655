import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AppComponent } from 'app/app.component';
import { LoginService } from 'app/main/authentication/login/login.service';
import { ReceivablesService } from 'app/main/accounts/receivables/receivables.service';
import { ReceivableDetailsComponent } from './receivable-details/receivable-details.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface ReceivablesData {
    orderId: string;
    registrationNumber: string;
    fuelType: string;
    vehicle: string;
    grandTotal: string;
    commission: string;
    receivables: string;
    status: string;
}

@Component({
    selector: 'receivables',
    templateUrl: './receivables.component.html',
    styleUrls: ['./receivables.component.scss']
}

) export class ReceivablesComponent implements OnInit {
    token: any;
    netReceivables: number;
    netArr: any = [];
    pendingArr: any = [];
    approvedArr: any = [];
    pending: number;
    approved: number;
    allActive = false;
    pendingActive = false;
    approvedActive = false;
    receivables: any;
    receivablesList: any;
    receivablesData: any = [];
    receivablesSource: MatTableDataSource<ReceivablesData>;
    receivablesColumns: string[] = ['orderId', 'registrationNumber', 'grandTotal', 'commission', 'receivables', 'status'];
    renderedData: any;
    noData = false;
    dialogId: number;
    rDialogData: any;
    dataCSV: any;
    isSingapore = false;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    /**
     * Constructor
     * @param {Router} router
     * @param {LoginService} loginService
     * @param {ReceivablesService} rService
     * @param {Papa} papa
     */
    constructor(
        public router: Router,
        private loginService: LoginService,
        private rService: ReceivablesService,
        public dialog: MatDialog,
        private appComponent: AppComponent
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.getReceivableData();
        this.allActive = true;
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                // trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
            }
        });
    }

    ngOnInit() {
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        }
        else {
            this.isSingapore = false;
        }
    }

    getReceivableData() {
        this.receivablesList = this.rService.receivables;
        if (this.receivablesList.response.status === 'SUCCESS') {
            this.netReceivables = 0;
            this.receivables = this.receivablesList.response.body.orderpayouts;
            if (this.receivables.length === 0) {
                this.noData = true;
            } else {
                this.receivables.forEach(receivableList => {
                    if (receivableList.status.toString() === '2' || receivableList.status.toString() === '3') {
                        this.receivablesData.push(this.createReceivableData(receivableList));
                    }
                });
                this.receivablesSource = new MatTableDataSource(this.receivablesData);
                // this.receivablesSource.filterPredicate = (data: ReceivablesData, filter: string) => console.log(data.status.indexOf(filter) === -1);
                this.receivablesSource.connect().subscribe(d => this.renderedData = d);
                this.dataCSV = this.renderedData;
                this.netReceivables = this.netArr.reduce((a, b) => a + b, 0).toFixed(2);
                this.pending = this.pendingArr.reduce((a, b) => a + b, 0).toFixed(2);
                this.approved = this.approvedArr.reduce((a, b) => a + b, 0).toFixed(2);
                setTimeout(() => {
                    this.receivablesSource.paginator = this.paginator;
                    this.receivablesSource.sort = this.sort;
                }, 500);
            }
        }
        else {
            this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
            setTimeout(() => {
                this.loginService.logout();
            }, 1000);
        }
    }

    applyFilter(filterValue: string) {
        this.receivablesSource.filter = filterValue.trim().toLowerCase();
        if (this.receivablesSource.paginator) {
            this.receivablesSource.paginator.firstPage();
        }
    }

    applyFilters(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        if (filterValue === 'pending') {
            this.allActive = false;
            this.pendingActive = true;
            this.approvedActive = false;
        } else if (filterValue === 'approved') {
            this.allActive = false;
            this.pendingActive = false;
            this.approvedActive = true;
        } else {
            this.allActive = true;
            this.pendingActive = false;
            this.approvedActive = false;
        }
        this.receivablesSource.filter = filterValue;
    }

    createReceivableData(data: any): ReceivablesData {
        let status = '';
        if (data.status.toString() === '2') {
            status = 'Pending';
            this.pendingArr.push(data.payout);
        } else {
            status = 'Approved';
            this.approvedArr.push(data.payout);
        }
        if (data.status.toString() === '2' || data.status.toString() === '3') {
            this.netArr.push(data.payout);
        }
        return {
            orderId: data.orderId.toString(),
            registrationNumber: data.registrationNumber.toString(),
            fuelType: data.fuelType.toString(),
            vehicle: data.model.toString(),
            grandTotal: parseFloat(data.grandTotal).toFixed(2),
            commission: parseFloat(data.commission).toFixed(2),
            receivables: parseFloat(data.payout).toFixed(2),
            status: status
        };
    }
    exportEXCEL() {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataCSV);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'receivables_list');
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    openDialog(id: number) {
        this.router.navigate(['accounts/receivables/' + id]);
        setTimeout(() => {
            this.receivables.forEach(dialogData => {
                if (dialogData.orderId.toString() === id.toString()) {
                    this.rDialogData = dialogData;
                }
            });
            const dialogRef = this.dialog.open(ReceivableDetailsComponent, {
                height: 'auto',
                width: '80vw',
                data: {
                    id: id,
                    rdata: this.rDialogData
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                this.router.navigate(['accounts/receivables']);
                this.dialogId = result;
            });
        }, 500);
    }

    kFormatter(num) {
        return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
    }
}
