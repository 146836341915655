import { Component, OnInit, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { trigger,state,style,transition,animate,keyframes } from    '@angular/animations';
import {AspConfirmOrderPopupComponent} from '../asp-confirm-order-popup/asp-confirm-order-popup.component';
import {AspPaymentService} from './asp-payment.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../../main/globals';
import { AspSwitchGarageService } from '../asp-switch-garage/asp-switch-garage.service';
import { AspCartService } from "../asp-cart/asp-cart.service";
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import { AspShippingChargesBottomSheetComponent } from "../asp-shipping-charges-bottom-sheet/asp-shipping-charges-bottom-sheet.component";


declare var Razorpay: any;

@Component({
  selector: 'app-asp-payment',
  templateUrl: './asp-payment.component.html',
  styleUrls: ['./asp-payment.component.scss'],
  animations: [
    trigger('shakeit', [
        state('shakestart', style({
            transform: 'scale(1)',
        })),
        state('shakeend', style({
            transform: 'scale(1)',
        })),
        transition('shakestart => shakeend', animate('1000ms ease-in',     keyframes([
          style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
          style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
          style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
          style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
          style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
          style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
        ]))),
  ])]
})
export class AspPaymentComponent implements OnInit {
  loading: boolean = false;
  paymentMethod = '';
  paymentButtonText = '';
  states = {};
  shippingAddress = '';
  billingAddress = '';
  cartList = [];
  cartSubTotal = 0;
  sparesDiscount = 0;
  cartGrandTotal = 0;
  shippingAddressId: any;
  billingAddressId:any;
  orderKey: any = '';
  initiatedPayment:boolean = false;
  userDetails: any = [];
  spareSelectedGarageName: any;
  shippingChargesApplicable: any;
  shippingCharges: any;
  isShippingCharges: any;
  prepayUpiDiscount: any;
  discountPercentIcon:any = './assets/icons/discount-percent.png';

  constructor(    
    private router: Router,
    private zone: NgZone,
    public dialog: MatDialog,
    private aspPaymentService: AspPaymentService,
    private toastr: ToastrService,    
    private aspSwitchGarageService: AspSwitchGarageService,
    private aspCartService: AspCartService,
    private _bottomSheet: MatBottomSheet,
  ) { 
    this.states['shake'] = 'shakestart';
  }

  ngOnInit(): void {    
    this.loading = true;
    this.getUser();
    this.getCart();    
    this.getUserDetails();
    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName"); 
    this.isShippingCharges = localStorage.getItem("isShippingCharges");
    this.prepayUpiDiscount = localStorage.getItem("prepayUpiDiscount");
    // this.zone.run(() => {
    //   this.dialog.open(AspConfirmOrderPopupComponent);
    // });
  }

  ngAfterViewInit() {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
    this.shakeMe('shake');
  }

  shakeMe(stateVar: string) {
    this.states[stateVar] = (this.states[stateVar] === 'shakestart' ? 'shakeend' : 'shakestart');
  }

  shakeEnd(stateVar: string, event) {
  this.states[stateVar] = 'shakeend';
  }

  getUser(){      
    this.loading = true;  
    let garageKey = localStorage.getItem('garageKey');
    this.aspPaymentService.getUserDetails(garageKey).subscribe(
      res => {
        // console.log(result);
        // let res = JSON.parse(JSON.stringify(result)); 
        if(res['code']==200){
          this.loading = false;
          Object.values(res['data']).forEach(address => { 
            if(address['addressType']=='billing'){
              this.billingAddress = address['address']+", "+address['city']+", "+address['state']+" "+address['pincode'];
              this.billingAddressId = address['id'];
            }
            
            if(address['addressType']=='shipping'){
              this.shippingAddress = address['address']+", "+address['city']+", "+address['state']+" "+address['pincode'];
              this.shippingAddressId = address['id'];
            }
          });
        }
      }
    );
  }

  getUserDetails(){ 
    let spareUserKey = localStorage.getItem('spareUserKey');
    this.aspSwitchGarageService.getUserDetails(spareUserKey).subscribe(
      result => {
        // console.log(result);
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code && res.code==200){
          this.userDetails['username'] = res.data.name;
          this.userDetails['user_mobile'] = res.data.mobile;
          this.userDetails['user_email'] = res.data.email;
        }else{
          this.toastr.success(res.message, 'Error');
        }
      }
    );
    // console.log(this.userDetails);
  }

  getPaymentMode(paymentMode){
    this.paymentMethod = paymentMode;
    if(paymentMode=="2"){
      this.paymentButtonText = 'Proceed To Pay';
    }else{
      this.paymentButtonText = 'Confirm Order';
    }
  }

  getCart(){
    if(sessionStorage.getItem('addedCart')){    
      let cartData;  
      this.cartList = [];
      this.cartSubTotal = 0;
      this.sparesDiscount = 0;
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      Object.values(cartData[0]).forEach(cart => { 
        this.cartSubTotal += (cart['spareStocks']['mrp']*cart['cart_qty']);
        this.sparesDiscount += (cart['spareStocks']['discountAmount']*cart['cart_qty']);
        
        this.cartList.push(cart);
      });

      if(this.sparesDiscount>0){
        this.cartGrandTotal = (this.cartSubTotal - this.sparesDiscount);
      }else{
        this.cartGrandTotal = this.cartSubTotal;
      }

      if(this.isShippingCharges=='true'){
        this.getShippingCharges();
      }
      // console.log(this.cartList);
    }
  }


  confirmOrder(paymentMethod){
    if(paymentMethod==1){
      this.codPayment();
    }else if(paymentMethod==2){
      this.createRazorPayOrder(); 
      // this.razorPayPayment();   
    }
    
  }

  // COD Payment - Pitstop
  codPayment(){
    this.initiatedPayment = true;
    let razorPayPaymentId = '';
    let orderKey = '';
    this.createOrder(razorPayPaymentId, orderKey);
  }

  // Create RazorPay order
  createRazorPayOrder(){    
    this.loading = true;    
    this.initiatedPayment = true;
    // this.cartGrandTotal = 1;
    let grandTotal = parseInt((this.cartGrandTotal*100).toFixed(0),10);
    let orderKey = '';
    // let userKey = localStorage.getItem('spareUserKey');
    let agentKey = localStorage.getItem('spareUserId');
    let garageKey = localStorage.getItem('garageKey');
    let orderType = 'spare';    
    let userType = localStorage.getItem('spareUserType');

    this.aspPaymentService.createRazorPayOrder(orderKey, agentKey, garageKey, grandTotal, orderType, userType).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        // console.log(res);
        if(res.code==200){
          let orderKey = res.data.orderKey;
          let razorpayOrder = JSON.parse(res.data.order);
          let razorPayOrderId = razorpayOrder.id;
          this.razorPayPayment(orderKey, razorPayOrderId);
        }else{
          this.router.navigate(['auto-spare-parts/make']);
          this.toastr.error(res.message, 'Error');
        }
      }
    );
  }

  // Initiate Razorpay Payment
  razorPayPayment(orderKey, razorPayOrderId){
    this.orderKey = orderKey;
    let grandTotal = this.cartGrandTotal;
    const options = {
      key: GlobalVariable.RAZORPAY_ID,
      amount: parseInt((grandTotal*100).toFixed(0),10),
      name: 'Chatpay Commerce Pvt Ltd',
      image: 'https://cube.getpitstop.com/assets/img/logo-pitstop-2019.png',
      description: 'Payment for spares purchase',
      currency: 'INR',
      order_id: razorPayOrderId,
      prefill: {
          name: this.userDetails['username'],
          email: this.userDetails['user_email'],
          contact: this.userDetails['user_mobile']
      },
      notes: {
          // order_id: orderId,
          type: 'spare',
          order_key: orderKey,
          // order_number: orderNumber,
          notes: 'Payment for spares purchase',
      },
      theme: {
          color: '#cd2120'
      },
      handler: this.paymentResponseHandler.bind(this)
  };
  // console.log(options);

  const rzp = new Razorpay(options);
  rzp.open();
  }

  // Razorpay success/failure handler
  paymentResponseHandler(response) { 
    if(response.razorpay_payment_id){      
      // this.dialog.open(AspConfirmOrderPopupComponent);
      let razorPayPaymentId = response.razorpay_payment_id;
      let razorPayOrderId = response.razorpay_order_id;
      let razorPaySignature = response.razorpay_signature;
      this.verifyPayment(razorPayPaymentId, razorPayOrderId, razorPaySignature, this.orderKey);
    }else{ 
      this.orderKey = '';
      this.toastr.error('Payment Failed', 'Error');
      // window.location.href = '/payment/failure/membership/' + this.membershipKey;
    }
  }

  // Verify Razorpay Payment
  verifyPayment(razorPayPaymentId, razorPayOrderId, razorPaySignature, orderKey){
    this.aspPaymentService.verifyPayment(razorPayPaymentId, razorPayOrderId, razorPaySignature, orderKey).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        // console.log(res);
        if(res.code==200){
          // console.log('Payment verified successfully!!');
          this.createOrder(razorPayPaymentId, orderKey);
        }else{
          this.router.navigate(['auto-spare-parts/make']);
          this.toastr.error(res.message, 'Error');
        }
      }
    );
  }

  // Create Order - Pitstop (RazorPay Flow)
  createOrder(razorPayPaymentId, orderKey){
    this.loading = true;
    this.aspPaymentService.createOrder(this.shippingAddressId, this.billingAddressId, this.paymentMethod, razorPayPaymentId, orderKey).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        // console.log(res);
        if(res.code==200){
          this.loading = false;
          sessionStorage.setItem('order', JSON.stringify(res.data));
          this.zone.run(() => {
            this.dialog.open(AspConfirmOrderPopupComponent);
          });
        }else{
          // this.router.navigate(['auto-spare-parts/make']);
          this.toastr.error(res.message, 'Error');
        }
      }
    );
  }

  // Bottom sheet open
  shippingChargesBottomSheet(){
    this._bottomSheet.open(AspShippingChargesBottomSheetComponent);
  }

  // Shipping charges
  getShippingCharges(){    
    this.loading = true; 
    let garageKey = localStorage.getItem('garageKey');
    this.aspCartService.getShippingCharges(this.cartGrandTotal, garageKey).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code==200){
          this.shippingChargesApplicable = res.data.shippingChargesApplicable;
          this.shippingCharges = res.data.shippingCharges;

          this.loading = false;
          if(this.shippingChargesApplicable==true){
            this.cartGrandTotal = (this.cartGrandTotal+this.shippingCharges);
          }
        }
      }
    );
  }
}
