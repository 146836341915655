<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="content">
        <div class="left">
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
                <div class="widget detail_title" fxFlex="100" fxFlex.gt-sm="100">
                    <span fxLayout="row wrap" fxLayoutAlign="start center" class="mat-display-1 m-0 p-16 pl-0 font_size">Search
                        Bookings</span>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
                <div class="widget" fxFlex="100" fxFlex.gt-sm="100">
                    <div class="bg_color_border margn_top">
                        <div class="p-0 auto-width" fxLayout="row wrap" fxLayoutAlign="start center">
                            <form [class.loading]="loading" name="searchForm" [formGroup]="searchForm" (ngSubmit)="onSubmit()" novalidate>
                                <mat-form-field>
                                    <mat-select placeholder="Select" [(value)]="selectedValue" formControlName="condition">
                                        <mat-option value="id">Booking ID</mat-option>
                                        <mat-option value="mobile">Customer Mobile Number</mat-option>
                                        <mat-option value="status">Status</mat-option>
                                        <!-- <mat-option value="status">Status</mat-option> -->
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field *ngIf="selectedValue == 'id' || selectedValue == 'mobile'">
                                    <mat-label>Enter here</mat-label>
                                    <input matInput type="number" min="0" formControlName="query">
                                </mat-form-field>

                                <mat-form-field *ngIf="selectedValue == 'status'">
                                    <mat-label>Select</mat-label>
                                    <mat-select [(value)]="selected" formControlName="query">
                                        <mat-option value="6,4,2,1,7">All</mat-option>
                                        <mat-option value="6">Enquiry</mat-option>
                                        <mat-option value="7">Confirmed</mat-option>
                                        <mat-option value="2">Cancelled</mat-option>
                                        <mat-option value="1">Invalid</mat-option>

                                    </mat-select>
                                    <!-- <input matInput formControlName="query"> -->
                                </mat-form-field>
                                <!-- <mat-form-field *ngIf="selectedValue == 'status'">
                                        <mat-select placeholder="Select Status" formControlName="query">
                                            <mat-option value="id">Order ID</mat-option>
                                            <mat-option value="mobile">Customer Mobile Number</mat-option>
                                            <mat-option value="registrationNumber">Vehicle Reg. Number</mat-option>
                                            <mat-option value="status">Status</mat-option>
                                        </mat-select>
                                    </mat-form-field> -->
                                <button mat-button color="accent" class="submit-button common_btn" aria-label="LOGIN" [disabled]="searchForm.invalid">
                                    Search
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="bg_color_border" *ngIf="!loading && submitted">
        <div *ngIf="!noData" class="p-12 pb-0 pt-0 export-block" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="export-button" fxLayout="column" fxLayoutAlign="center start">
                <!-- <button [disabled]="noData" (click)="exportEXCEL()" mat-raised-button color="accent">
                    Export
                    <mat-icon class="ml-8 font-size-20">cloud_download</mat-icon>
                </button> -->
            </div>
            <mat-form-field>
                <input [disabled]="noData" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table *ngIf="!noData" class="clickable" mat-table [dataSource]="searchOrdersSource" matSort matSortActive="orderId" matSortDirection="desc" matSortDisableClear>
            <ng-container matColumnDef="leadId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>BOOKING ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.leadId}} </td>
            </ng-container>

            <ng-container matColumnDef="leadCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>CREATED ON</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> {{row.leadCreated|date: 'd MMMM yy'}}
                    <span id="time_show">{{row.leadCreated|date: 'h:mm a'}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="make">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>CAR DETAILS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row;">
                    <img [src]="makeUrl+''+(row.make | removewhitespaces)+'.png'" alt="car make" class="cars_img">
                    <span id="make_show">{{row.make +' '}}</span><span>{{' '+ row.model}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="accountName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>SOURCE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    {{row.accountName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="leadStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>STATUS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <span class="status{{row.leadStatus}}">
                        <strong>{{row.leadStatus == 1 ? 'Invalid' : row.leadStatus == 2 ? 'Cancelled' : row.leadStatus == 7 ? 'Confirmed' : row.leadStatus == 6 ? 'Enquiry' : ''}}
                        </strong>
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="searchOrdersColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: searchOrdersColumns;" (click)="openDetails(row.leadId)" id="created_on"></tr>
        </table>

        <mat-paginator class="order_paginator" [pageSizeOptions]="[10, 20]" showFirstLastButtons *ngIf="!noData"></mat-paginator>
        <div *ngIf="noData" class="txt_center">
            <mat-error> No Data to display</mat-error>
        </div>
    </div>
</div>