import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariable } from 'app/main/globals';
import { LoginService } from 'app/main/authentication/login/login.service';
@Injectable({ providedIn: 'root' })
export class SearchBookingsService {
    searchOrders: any[];
    paramData: any;
    merchantLogin: any;
    merchantId: any;
    userAppKey: any;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {
    }
    private searchedData(res: Response) {
        const body = res;
        return body || {};
    }
    getOrders(condition: string, query: string): Observable<any> {
        this.merchantLogin = JSON.parse(this.loginService.getLoginData());
        this.merchantId = this.merchantLogin.merchant.id;
        this.userAppKey = this.merchantLogin.account.appKey;
        let idQuery = '';
        let mobileQuery = '';
        let statusQuery = '';
        switch (condition) {
            case 'id':
                idQuery = query;
                break;
            case 'mobile':
                mobileQuery = query;
                break;
            case 'status':
                statusQuery = query;
                break;
            default:
                idQuery = '';
                mobileQuery = '';
                statusQuery = '';
        }
       
        const data = {
            'appKey': this.userAppKey,
            'sessionId': this.loginService.getToken(),
            'id': idQuery,
            'mobile': mobileQuery,
            'statuses': statusQuery,
            'page': 'searchAll',
            'merchants': this.merchantId,
            'userType': 'gms'
        }
        const formData = new FormData();
        formData.append('sessionPost', JSON.stringify(data));
        return this.http.post(`${GlobalVariable.BASE_API_URL}` + '/crmleadsearch', formData);
    }
}
