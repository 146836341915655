import { Component, OnDestroy, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { LoginService } from 'app/main/authentication/login/login.service';
import { OrderDetailsService } from 'app/main/orders/order-details/order-details.service';

@Component({
    selector: 'invoice-details',
    templateUrl: './invoice-details.component.html',
    styleUrls: ['./invoice-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class InvoiceDetailsComponent implements OnInit, OnDestroy {
    navigationSubscription;
    private sub: any;
    id: number;
    public invoiceData: any;
    loading = false;
    pdfLoading = false;
    noData = false;
    serviceDate: any;
    deliveryDate: any;
    odometer: number;
    billFrom: any;
    billFromName: string;
    billFromAddress: string;
    billFromGst: string;
    billFromPan: string;
    billTo: any;
    billToName: string;
    billToAddress: string;
    billToGst: string;
    billToPan: string;
    invoice: any;
    invoiceTitle: string;
    invoiceDate: string;
    subTotal: string;
    totalSgst: string;
    totalCgst: string;
    totalIgst: string;
    gTotal: string;
    discount: string;
    invoiceItems: any;
    vehicle: any;
    regNum: string;
    makeModelFuel: string;
    timezone: any;
    format: any;
    moment = moment();
    iData: any;

    constructor(
        private odService: OrderDetailsService,
        public router: Router,
        private route: ActivatedRoute,
        private appComponent: AppComponent,
        private loginService: LoginService,
    ) {
        this.timezone = this.loginService.getTimezone();
        this.format = this.loginService.getFormat();
        this.sub = this.route.params.subscribe(params => {
            this.id = +params['id'];
        });
        this.getInvoiceData();
    }

    /**
     * On init
     */
    ngOnInit(): void {
    }

    getInvoiceData() {
        this.loading = true;
        this.odService.getInvoiceData(this.id).subscribe((data: {}) => {
            this.invoiceData = data;
            this.iData = this.invoiceData.response.body;
            if (this.invoiceData.response.status === 'SUCCESS') {
                this.prepareBillFrom();
                this.prepareBillTo();
                this.prepareInvoice();
                this.prepareVehicle();
                this.invoiceItems = this.invoiceData.response.body.items;
                this.odometer = this.invoiceData.response.body.odometer;
                this.serviceDate = moment.unix(this.invoiceData.response.body.serviceDate / 1000).tz(this.timezone).format(this.format);
                this.deliveryDate = moment.unix(this.invoiceData.response.body.deliveryDate / 1000).tz(this.timezone).format(this.format);
                this.loading = false;
            } else {
                this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
                setTimeout(() => {
                    this.loginService.logout();
                }, 1000);
                this.loading = false;
            }
        });
    }

    prepareBillFrom() {
        this.billFrom = this.iData.billFrom;
        this.billFromName = this.billFrom.name;
        this.billFromAddress = this.billFrom.address;
        this.billFromGst = this.billFrom.gst;
        this.billFromPan = this.billFrom.pan;
    }

    prepareBillTo() {
        this.billTo = this.iData.billTo;
        this.billToName = this.billTo.name;
        this.billToAddress = this.billTo.address;
        this.billToGst = this.billTo.gst;
        this.billToPan = this.billTo.pan;
    }

    prepareInvoice() {
        this.invoice = this.iData.invoice;
        this.invoiceTitle = this.invoice.invoiceTitle;
        this.invoiceDate = moment.unix(this.invoice.invoiceDate / 1000).tz(this.timezone).format(this.format);
        this.subTotal = this.invoice.subTotal;
        this.totalSgst = this.invoice.sgst;
        this.totalCgst = this.invoice.cgst;
        this.totalIgst = this.invoice.igst;
        this.gTotal = this.invoice.grandTotal;
        this.discount = this.invoice.discount;
    }

    prepareVehicle() {
        this.vehicle = this.iData.vehicle;
        this.regNum = this.vehicle.registrationNumber;
        this.makeModelFuel = this.vehicle.manufacturer + ' / ' + this.vehicle.model + ' / ' + this.vehicle.fuelType;
    }

    public generatePDF() {
        this.pdfLoading = true;
        const data = document.getElementById('contentToConvert');
        html2canvas(data, {
            dpi: 300,
        }).then(canvas => {
            // Few necessary setting options 
            const imgWidth = 208;
            const pageHeight = 295;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            const heightLeft = imgHeight;

            const contentDataURL = canvas.toDataURL('image/png');
            const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF 
            const position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            pdf.save('invoice_' + this.id + '.pdf'); // Generated PDF 
            this.pdfLoading = false;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.sub.unsubscribe();
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }
}
