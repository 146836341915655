import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { LoginService } from 'app/main/authentication/login/login.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { first } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { GSTService } from './gst.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const backDate = (numOfDays) => {
    const today1 = new Date();
    return new Date(today1.setDate(today1.getDate() - numOfDays));
};
export interface GstData {
    cgst: string;
    cgst_rate: string;
    gst: string;
    gst_rate: string;
    igst: string;
    igst_rate: string;
    invoice_title: string;
    item_description: string;
    item_quantity: string;
    item_tax_code: string;
    item_tax_type: string;
    item_type: string;
    itemtotal: string;
    itemPrice: string;
    makeModel: string;
    orderid: string;
    price: string;
    registration_number: string;
    servicecompletedate: string;
    sgst: string;
    sgst_rate: string;
    // customerName:string;
    // placeOfSupply:string;

}
@Component({
    selector: 'gst',
    templateUrl: './gst.component.html',
    styleUrls: ['./gst.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class GSTComponent implements OnInit {
    token: any;
    loading = false;
    range: Range = { fromDate: new Date(), toDate: new Date() };
    options: NgxDrpOptions;
    presets: Array<PresetItem> = [];
    noData = false;
    GSTData: any;
    gDashData: any;
    gData: any;
    gstr2Result:any;
    gstr1Result: any;
    gstData: any = [];
    gstr1B2bCsvdata: any;
    gstr1B2bcsCsvdata:any;
    gstr1B2clCsvdata:any;
    gstDataSource: MatTableDataSource<GstData>;
    gstDataColumns: string[] = [
        'orderid', 'makeModel', 'registration_number', 'servicecompletedate', 'invoice_title',
        'item_quantity', 'itemPrice', 'gst', 'itemtotal', 'cgst', 'cgst_rate',
        'gst_rate', 'igst', 'igst_rate', 'item_description', 'item_tax_code', 'item_tax_type',
        'item_type', 'price', 'sgst', 'sgst_rate'
    ];
    renderedData: any;
    dataCSV: any;
    dataCSV2: any;
    timezone: any;
    format: any;
    gstr2Csvdata: any;
    moment = moment();
    loginUserData: any;
    isSingapore = false;
    gstNotConfig = false;
    @ViewChild('dateRangePicker', { static: false }) dateRangePicker;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    /**
     * Constructor
     *
     */
    constructor(
        private router: Router,
        private loginService: LoginService,
        private gService: GSTService
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        const today = new Date();
        const minus7 = backDate(7);
        this.setupPresets();
        this.options = {
            presets: this.presets,
            format: 'mediumDate',
            range: { fromDate: minus7, toDate: today },
            applyLabel: 'Get Reports',
            calendarOverlayConfig: {
                shouldCloseOnBackdropClick: true
            }
        };
    }
    updateRange(range: Range) {
        this.loading = true;
        this.range = range;
        const from = new Date(this.range.fromDate);
        const to = new Date(this.range.toDate);
        if (from.getTime() <= to.getTime()) {
            this.gService.getGstCustomData(this.range.fromDate, this.range.toDate)
                .pipe(first())
                .subscribe(
                    data => {
                        this.gDashData = data;
                        if (this.gDashData && this.gDashData.response.status === 'SUCCESS') {

                            this.gData = this.gDashData.response.body.orderDetail;
                            this.initGstData(this.gData);
                            if (this.gData.length !== 0) {
                                this.noData = false;
                                this.loading = false;
                            }
                            else {
                                this.noData = true;
                                this.loading = false;
                            }
                        }
                    },
                    error => {
                        this.noData = true;
                        this.loading = false;
                    }
                );
        } else {
            alert('"From Date" cannot be greater than "To Date"');
            this.loading = false;
        }
    }
    setupPresets() {
        const today = new Date();
        const threeMonthStart = new Date(today.getFullYear(), today.getMonth() - 3, 1);
        const threeMonthEnd = new Date(today.getFullYear(), today.getMonth() , 0);
        const sixMonthStart = new Date(today.getFullYear(), today.getMonth() - 6, 1);
        const sixMonthEnd = new Date(today.getFullYear(), today.getMonth() , 0);
        const yearlyStart = new Date(today.getFullYear(), today.getMonth() - 12, 1);
        const yearlyEnd = new Date(today.getFullYear(), today.getMonth() , 0);
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        this.presets = [
            { presetLabel: 'Last Month', range: { fromDate: lastMonthStart, toDate: lastMonthEnd } },
            { presetLabel: '3 Month', range: { fromDate: threeMonthStart, toDate: threeMonthEnd } },
            { presetLabel: '6 Month', range: { fromDate: sixMonthStart, toDate: sixMonthEnd } },
            { presetLabel: '1 Year', range: { fromDate: yearlyStart, toDate: yearlyEnd } },
        ];
    }
    ngOnInit() {
        this.loginUserData = JSON.parse(this.loginService.getLoginData());
        if (this.loginService.getTimezone().toString() === 'Asia/Singapore') {
            this.isSingapore = true;
        }
        else {
            this.isSingapore = false;
        }
        if (this.loginUserData.merchant.gstNo || this.loginUserData.merchant.gstNo !== '') {
            this.gstNotConfig = false;
        }
        else {
            this.gstNotConfig = true;
        }
    }
    initGstData(gData: any) {
        this.gstData = [];
        gData.forEach(iList => {
            this.gstData.push(this.createGstData(iList));
        });
        this.gstDataSource = new MatTableDataSource(this.gstData);
        this.gstDataSource.connect().subscribe(d => this.renderedData = d);
        for (let i = 0; i < this.renderedData.length; i++) {
            if (this.renderedData[i].makeModel.indexOf('<br>') > 0) {
                this.renderedData[i].makeModel = this.renderedData[i].makeModel.replace(/<br\s*\/?>/gi, ' ');
            }
            if (this.renderedData[i].servicecompletedate.indexOf('<br>') > 0) {
                this.renderedData[i].servicecompletedate = this.renderedData[i].servicecompletedate.replace(/<br\s*\/?>/gi, ' ');
            }
        }
        this.dataCSV2 = this.renderedData;
        this.dataCSV = this.gDashData.response.body.orderDetail;
        this.loading = false;
        setTimeout(() => {
            this.gstDataSource.paginator = this.paginator;
            this.gstDataSource.sort = this.sort;
        }, 500);
    }
    applyFilter(filterValue: string) {
        this.gstDataSource.filter = filterValue.trim().toLowerCase();
        if (this.gstDataSource.paginator) {
            this.gstDataSource.paginator.firstPage();
        }
    }
    createGstData(data: any): GstData {
        const serviceDate = new Date(data.servicecompletedate);
        let itemPrice = '';
        if (parseInt(data.labourprice, 10) === 0) {
            if (data.partsprice) {
                itemPrice = data.partsprice.toString();
            }
        } else {
            if (data.labourprice) {
                itemPrice = data.labourprice.toString();
            }
        }
        let itemType;
        (data.item_type) ? itemType = data.item_type : itemType = '';
        return {
            cgst: data.cgst.toString(),
            cgst_rate: data.cgst_rate.toString(),
            gst: data.gst.toString(),
            gst_rate: data.gst_rate.toString(),
            igst: data.igst.toString(),
            igst_rate: data.igst_rate.toString(),
            invoice_title: data.invoice_title.toString(),
            item_description: data.item_description.toString(),
            item_quantity: parseInt(data.item_quantity, 10).toString(),
            item_tax_code: data.item_tax_code.toString(),
            item_tax_type: data.item_tax_type.toString(),
            item_type: itemType.toString(),
            itemtotal: data.itemtotal.toString(),
            itemPrice: itemPrice,
            makeModel: data.make.toString() + '<br>' + data.model.toString(),
            orderid: data.orderid.toString(),
            price: data.price.toString(),
            registration_number: data.registration_number.toString(),
            servicecompletedate: moment(data.servicecompletedate, 'DD-MM-YYYY').format('ddd D') + '<br>' + moment(data.servicecompletedate, 'DD-MM-YYYY').format('MMM YYYY'),
            sgst: data.sgst.toString(),
            sgst_rate: data.sgst_rate.toString(),
        };
    }
    exportEXCEL() {
        this.gService.getGstr1Data(this.range.fromDate, this.range.toDate).subscribe(result => {
            this.gstr1Result = result;
            if(this.gstr1Result.response.status === 'SUCCESS'){

                 this.gstr1B2bCsvdata = this.gstr1Result.response.body.gst1B2bDataMap;
                 this.gstr1B2clCsvdata = this.gstr1Result.response.body. gst1B2cLDataMap;
                 this.gstr1B2bcsCsvdata = this.gstr1Result.response.body.gst1B2cSDataMap;
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.gstr1B2bCsvdata);
                    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.gstr1B2clCsvdata);
                    const worksheet3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.gstr1B2bcsCsvdata);
                    const workbook: XLSX.WorkBook = { Sheets: { 'B2B': worksheet, 'B2CL': worksheet2,'B2CS': worksheet3}, SheetNames: ['B2B', 'B2CL', 'B2CS'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.saveAsExcelFile(excelBuffer, 'GSTR1');

            }
            else{
                console.log('FAILS');
            }
        },
        error=>{
          console.log(error);

        });
    }
    exportEXCEL2() {
        this.gService.getGstr2Data(this.range.fromDate, this.range.toDate).subscribe(result=>{
            this.gstr2Result = result;
            if(this.gstr2Result.response.status==='SUCCESS'){
                 this.gstr2Csvdata = this.gstr2Result.response.body.gst2DataMap;
                 console.log(this.gstr2Csvdata);
                 const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.gstr2Csvdata);
                 const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                 const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                 this.saveAsExcelFile2(excelBuffer, 'GSTR2');
            }
            else{
                console.log('FAILS');
            }
        },
        error=>{
          console.log(error);

        });

    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    private saveAsExcelFile2(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}
