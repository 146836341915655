import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginService } from 'app/main/authentication/login/login.service';
import { GlobalVariable } from 'app/main/globals';
import { OrderDetailsService } from 'app/main/orders/order-details/order-details.service';
import { FileUploader } from 'ng2-file-upload';
import { Lightbox } from 'ngx-lightbox';
import { first } from 'rxjs/operators';

@Component({
    selector: 'add-images',
    templateUrl: 'add-images.component.html',
    styleUrls: ['./add-images.component.scss'],
})
export class AddImagesComponent {
    status: string;
    orderId: string;
    error = false;
    imagesLoading = false;
    imagesDataReceived = false;
    uploader: FileUploader;
    fileError = false;
    _albums = [];
    selectedFiles = [];
    urls = [];
    tag: string;
    imageType: string;
    @ViewChild('filesToUpload', { static: false }) filesToUpload: ElementRef;
    @ViewChild('image', { static: false }) image: ElementRef;

    constructor(
        private bottomSheetRef: MatBottomSheetRef<AddImagesComponent>,
        private router: Router,
        private loginService: LoginService,
        private odService: OrderDetailsService,
        private sanitizer: DomSanitizer,
        private _lightbox: Lightbox,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        this.status = data.status;
        this.orderId = data.orderId;
        this.imageType = data.imageType;
        this.uploader = new FileUploader({ url: '' });
        this.urls = [];
        this._albums = [];
        this.uploader.onAfterAddingFile = (file) => {
            this.selectedFiles.push(file);
            this.urls.push(
                this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(file._file)
                )
            );
            const sFiles = {
                src: this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(file._file)
                ),
            };
            this._albums.push(sFiles);
        };
    }

    open(index: number): void {
        this._lightbox.open(this._albums, index);
    }

    close(): void {
        this._lightbox.close();
    }

    removeImgEntry(id: number) {
        this.urls.splice(id, 1);
        this._albums.splice(id, 1);
        this.selectedFiles.splice(id, 1);
    }

    addImages() {
        this.imagesLoading = true;
        const formData: FormData = new FormData();
        if (!this.fileError) {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                formData.append(
                    'fileToUpload[]',
                    this.selectedFiles[i]._file,
                    this.selectedFiles[i]._file.name
                );
            }

            if (this.status < '6') {
                this.tag = 'pre_replacement_others';
            } else if (this.status === '6') {
                this.tag = 'during_replacement';
            }
            // else if (this.status === "7") {
            //     this.tag = "invoice";
            // }
            else {
                this.tag = 'post_replacement_others';
            }

            formData.append('appKey', `${GlobalVariable.APP_KEY}`);
            formData.append('sessionId', this.loginService.getToken());
            formData.append('orderId', this.orderId);
            formData.append('status', this.status);
            formData.append('id', this.orderId);
            formData.append('description', 'imageCaption');
            formData.append('tag', this.tag);
            if (this.imageType === 'invoice') {
                formData.append('entity', 'gb_invoice');
            } else {
                formData.append('entity', 'order');
            }

            formData.append('entity_type', 'gms_image');

            this.odService
                .addImages(formData)
                .pipe(first())
                .subscribe(
                    (result) => {
                        console.log(result);
                        const invoiceUrl = result.cdn_link[0];
                        if (this.imageType === 'invoice') {
                            if (invoiceUrl) {
                                this.getInvoice(invoiceUrl);
                            }
                        }
                        this.error = false;
                        this.imagesLoading = false;
                        this.imagesDataReceived = false;
                        this.bottomSheetRef.dismiss();
                        this.router.navigate([
                            'orders/details/' + this.orderId,
                        ]);
                    },
                    (error) => {
                        console.log(error);
                        this.imagesLoading = false;
                        this.error = true;
                    }
                );
        } else {
            this.imagesLoading = false;
            this.error = true;
        }
    }
    getInvoice(invoiceUrl) {
        const formData: FormData = new FormData();
        formData.append('appKey', `${GlobalVariable.APP_KEY}`);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('device', 'web');
        formData.append('language', 'en');
        formData.append('id', this.orderId);
        formData.append('supplierInvoiceNumber', '7821448');
        formData.append('supplierInvoiceUrl', invoiceUrl);
        this.odService.updateInvoice(formData).subscribe((res) => {
            console.log(res);
        });
    }
}
