import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { GlobalVariable } from "app/main/globals";
import { LoginService } from "app/main/authentication/login/login.service";

@Injectable({ providedIn: "root" })
export class LiveOrdersService implements Resolve<any> {
    liveOrders: any[];
    paramData: any;
    loginData: any;
    accountAppKey: any;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {LoginService} loginService
     */
    constructor(private http: HttpClient, private loginService: LoginService) {}

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([this.getLiveOrders()]).then(() => {
                resolve();
            }, reject);
        });
    }

    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
    getLiveOrders(): Promise<any> {
        this.loginData = JSON.parse(this.loginService.getLoginData());
        this.accountAppKey = this.loginData.account.appKey;
        const data = {
            appKey: this.accountAppKey,
            sessionId: this.loginService.getToken(),
            language: `${GlobalVariable.LANGUAGE}`,
            device: `${GlobalVariable.DEVICE}`,
            statuses: "3,4,5,6,7,8,9,10",
        };
        const formData = new FormData();
        formData.append("sessionPost", JSON.stringify(data));
        return new Promise((resolve, reject) => {
            this.http
                .post(
                    `${GlobalVariable.BASE_API_URL}` + "/gmssearchordersv2",
                    formData
                )
                .subscribe((response: any) => {
                    this.liveOrders = response;
                    resolve(response);
                }, reject);
        });
    }
}
