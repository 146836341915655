<h2 class="headi_ng">
    BOOKING DETAILS
    <button mat-raised-button matDialogClose>
        <span class="cancel_icon"
            ><i class="material-icons">
                cancel
            </i></span
        >
    </button>
</h2>

<div class="status_container">
    <div class="change_status">
        <div>
            <form [formGroup]="createBooking" name="createBooking">
                <h3 class="details_cate">Search and Select for Autofill</h3>
                <div class="search_section">
                    <div class="search_reg">
                        <mat-form-field class="full_fields">
                            <input
                                type="text"
                                placeholder="Registration Number"
                                aria-label="Number"
                                matInput
                                [formControl]="registrationControl"
                                [matAutocomplete]="autoRegistration"
                                (keyup)="getLeadByRegistration($event)"
                            />
                            <mat-autocomplete
                                #autoRegistration="matAutocomplete"
                            >
                                <mat-option
                                    *ngFor="
                                        let optionAccount of filteredRegistrationData
                                            | async
                                    "
                                    #vehicleRegVal
                                    [value]="optionAccount"
                                    (click)="
                                        selectedRegistrationValue(
                                            vehicleRegVal.value
                                        )
                                    "
                                >
                                    {{ optionAccount }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="search_classic">
                        <mat-form-field class="full_fields">
                            <input
                                matInput
                                type="text"
                                placeholder="Chassis Number"
                                (keyup)="getLeadByChassis($event)"
                                formControlName="chassiNumEntered"
                                [(ngModel)]="
                                    this.createBookingFillData.chassiNumEntered
                                "
                            />
                        </mat-form-field>
                        <div
                            class="suggestedResult_booking"
                            *ngIf="showResultChassis"
                        >
                            <ul>
                                <li
                                    *ngFor="let chassisNum of ChassisArray"
                                    (click)="
                                        selectedChassisValue(
                                            chassisNum.chassisNo
                                        )
                                    "
                                >
                                    <p class="chassis_p">
                                        {{ chassisNum.chassisNo }}
                                    </p>
                                    <p class="chassis_p">
                                        {{ chassisNum.registrationNumber }}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="search_mobile">
                        <mat-form-field class="full_fields">
                            <input
                                matInput
                                type="text"
                                placeholder="Mobile Number"
                                (keyup)="getLeadByMobile($event)"
                            />
                        </mat-form-field>
                        <div
                            class="suggestedResult_booking"
                            *ngIf="showResultMobile"
                        >
                            <ul>
                                <li
                                    *ngFor="let mobileLead of mobilesArray"
                                    (click)="
                                        getmobileNumbValue(
                                            mobileLead.registrationNumber
                                        )
                                    "
                                >
                                    {{ mobileLead.registrationNumber }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="booking_form_section">
                    <div class="form_left_section">
                        <h3 class="details_cate">Vehicle Details</h3>

                        <span
                            class="full_fields vehiclToggle border_inpt bkng_fild"
                            (click)="openVehicleList()"
                            ><span *ngIf="!selectedSearchText"
                                >Select Vehicle </span
                            ><span *ngIf="selectedSearchText">{{
                                selectedSearchText
                            }}</span></span
                        >
                        <div class="vehicle_ps vehiclToggle">
                            <div
                                class="suggestedResult_booking search_fild"
                                *ngIf="showVehicleResult"
                                (window:mouseup)="
                                    autoCloseForDropdownCars($event)
                                "
                            >
                                <mat-form-field
                                    class="full_fields vehiclToggle"
                                >
                                    <input
                                        type="text"
                                        class="ddd"
                                        placeholder="Search Vehicle"
                                        aria-label="Number"
                                        matInput
                                        #vehicleSec
                                        [formControl]="vehicleControl"
                                        [(ngModel)]="searchText"
                                        (click)="openVehicleList()"
                                    />
                                </mat-form-field>
                                <ul>
                                    <li
                                        *ngFor="
                                            let vehicle of carModel
                                                | filter: searchText;
                                            let i = index
                                        "
                                        (click)="vehicleSelected(vehicle)"
                                        #vehcl
                                    >
                                        <p class="chassis_p">{{ vehicle }}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <mat-form-field class="full_fields">
                            <mat-label>Select Fuel</mat-label>
                            <mat-select
                                formControlName="fuel"
                                required
                                (change)="checkFuel($event)"
                                [(ngModel)]="
                                    this.createBookingFillData.fueltype
                                "
                            >
                                <mat-option
                                    *ngFor="let fuel of fuel"
                                    [value]="fuel.value"
                                >
                                    {{ fuel.viewValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="full_fields">
                            <input
                                matInput
                                type="text"
                                class="cps_text"
                                placeholder="Registration Number"
                                [(ngModel)]="
                                    this.createBookingFillData
                                        .registrationNumber
                                "
                                formControlName="registrationNumber"
                                #numberReg
                                required
                                (keyup)="checkRegister($event)"
                            />
                        </mat-form-field>
                        <mat-error *ngIf="invalidReg"
                            >Invalid Registration Number
                        </mat-error>
                        <h3 class="details_cate">Booking Details</h3>
                        <mat-form-field class="full_fields sourceToggle">
                            <input
                                type="text"
                                placeholder="Source"
                                aria-label="Source"
                                matInput
                                [formControl]="sourceControl"
                                [(ngModel)]="searchText2"
                                required
                                (keyup)="checkVehicle($event)"
                                (click)="openSourceList()"
                            />
                        </mat-form-field>
                        <div class="vehicle_ps sourceToggle">
                            <div
                                class="suggestedResult_booking"
                                *ngIf="showSourceResult"
                                (window:mouseup)="
                                    autoCloseForDropdownSource($event)
                                "
                            >
                                <ul>
                                    <li
                                        *ngFor="
                                            let source of sourceData
                                                | filter: searchText2;
                                            let i = index
                                        "
                                        (click)="sourceSelected(source)"
                                        #vehcl
                                    >
                                        <p class="chassis_p">{{ source }}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <mat-form-field
                            class="full_fields"
                            required
                            *ngIf="renaultConvenience"
                        >
                            <mat-label>Convenience Type</mat-label>
                            <mat-select formControlName="convenience">
                                <mat-option
                                    *ngFor="
                                        let convenience of RenaultConvenienceType
                                    "
                                    [value]="convenience.value"
                                >
                                    {{ convenience.viewValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field
                            class="full_fields"
                            required
                            *ngIf="!renaultConvenience"
                        >
                            <mat-label>Convenience Type</mat-label>
                            <mat-select formControlName="convenience">
                                <mat-option
                                    *ngFor="let convenience of convenienceType"
                                    [value]="convenience.value"
                                >
                                    {{ convenience.viewValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <h4 class="serv_type">
                            Service Type <small class="s_require">*</small>
                        </h4>
                        <mat-radio-group
                            aria-label="Select an option"
                            class="radio_grp"
                            required
                            [(ngModel)]="serviceValue"
                            name="radio"
                            formControlName="serviceType"
                            (change)="getServiceType($event)"
                        >
                            <mat-radio-button value="general_service" required
                                >General</mat-radio-button
                            >
                            <mat-radio-button value="maintenance" required
                                >Maintenance</mat-radio-button
                            >
                            <mat-radio-button value="accident" required
                                >Accident</mat-radio-button
                            >
                            <mat-radio-button value="inspection" required
                                >Inspection</mat-radio-button
                            >
                            <mat-radio-button value="body_repair" required
                                >Denting & Painting
                            </mat-radio-button>
                        </mat-radio-group>
                        <p *ngIf="noServiceSelected">
                            <mat-error>Please fill required field</mat-error>
                        </p>
                        <div *ngIf="isMaintenace || isAccident">
                            <h4 class="serv_type">Service Sub Type</h4>
                            <mat-radio-group
                                aria-label="Select an option"
                                class="radio_grp"
                                formControlName="serviceSubtype"
                            >
                                <mat-radio-button
                                    value="repair"
                                    *ngIf="isMaintenace"
                                    >Repair and Replace
                                </mat-radio-button>
                                <mat-radio-button
                                    value="insurance"
                                    *ngIf="isAccident"
                                    >Insurance</mat-radio-button
                                >
                                <mat-radio-button
                                    value="noinsurance"
                                    *ngIf="isAccident"
                                    >Non-Insurance
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <p *ngIf="noServiceSubTypeSelected">
                            <mat-error
                                >Please Select Service Sub Type</mat-error
                            >
                        </p>
                    </div>
                    <div class="form_rgt_section">
                        <h3 class="details_cate mrg_btm">Customer Details</h3>
                        <mat-form-field class="full_fields">
                            <input
                                matInput
                                type="text"
                                placeholder="Name"
                                formControlName="name"
                                [(ngModel)]="this.createBookingFillData.name"
                                required
                            />
                        </mat-form-field>
                        <mat-form-field class="full_fields">
                            <input
                                matInput
                                type="number"
                                placeholder="Mobile"
                                formControlName="mobile"
                                [(ngModel)]="this.createBookingFillData.mobile"
                                required
                                (keyup)="mobileValidate($event)"
                            />
                        </mat-form-field>
                        <mat-error *ngIf="invalidMobi"
                            >Invalid Mobile Number</mat-error
                        >
                        <mat-form-field class="full_fields">
                            <input
                                matInput
                                type="text"
                                placeholder="Email"
                                formControlName="email"
                                [(ngModel)]="this.createBookingFillData.email"
                            />
                        </mat-form-field>

                        <h3 class="details_cate">Appointment Details</h3>
                        <mat-form-field class="full_fields">
                            <input
                                matInput
                                [owlDateTime]="dt1"
                                [owlDateTimeTrigger]="dt1"
                                placeholder=" Appointment Date & Time"
                                formControlName="appointmetTime"
                                #orderDateAndTime
                                required
                            />
                            <owl-date-time #dt1></owl-date-time>
                        </mat-form-field>
                        <mat-form-field class="full_fields">
                            <input
                                matInput
                                [owlDateTime]="dt2"
                                [owlDateTimeTrigger]="dt2"
                                placeholder=" Expected Delivery Date & Time"
                                formControlName="expectedDeliveryTime"
                                #orderStartTime
                            />
                            <owl-date-time #dt2></owl-date-time>
                        </mat-form-field>

                        <textarea
                            placeholder="Customer Notes"
                            rows="3"
                            class="full_fields text_width"
                            formControlName="notes"
                        ></textarea>
                    </div>
                </div>
                <mat-progress-bar
                    *ngIf="loading"
                    mode="indeterminate"
                ></mat-progress-bar>
                <p *ngIf="successMsg" class="succes_mesg">
                    Booking Created Successfully
                </p>
                <div class="button_section">
                    <button
                        mat-button
                        class="create_booking"
                        (click)="submitBookingForm()"
                    >
                        Create Booking
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
