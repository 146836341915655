import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AspHomeService } from '../asp-home/asp-home.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asp-all-categories-popup',
  templateUrl: './asp-all-categories-popup.component.html',
  styleUrls: ['./asp-all-categories-popup.component.scss']
})
export class AspAllCategoriesPopupComponent implements OnInit {
  allCategories = [];

  constructor(
    public dialog:MatDialogRef<AspAllCategoriesPopupComponent>,
    private homeService: AspHomeService,
    public toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories = () => {
    this.homeService.getAllCategories().subscribe(
      res => {
        // console.log(res);
        if(res && res['code']==200){

          if(res['data']){
            this.allCategories = res['data'].sort((a, b) => a.priority - b.priority);
          }
        }else{    
          this.toastr.error(res['message'], 'Error');   
        }
      }
    )
  }

  categorySparesList = (brand:any, brandImg:any) => {
    if(localStorage.getItem("selectedCategory")){
      localStorage.removeItem('selectedCategory');
    }

    if(localStorage.getItem("selectedCategoryImg")){
      localStorage.removeItem('selectedCategoryImg');
    }
      
    localStorage.setItem("selectedCategory",  brand);   
    localStorage.setItem("selectedCategoryImg",  brandImg);  
    
    this.closePopup(); 
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);
    });
  }

  closePopup(){
    this.dialog.close();
  }
}
