import {
    Appearance,
    Location
} from '@angular-material-extensions/google-maps-autocomplete';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClient } from '@angular/common/http';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { LoginService } from 'app/main/authentication/login/login.service';
import { GlobalVariable } from 'app/main/globals';
import { Observable } from 'rxjs';
import { CreateOrderService } from './create-order.service';
import PlaceResult = google.maps.places.PlaceResult;

export interface Cars {
    name: string;
    variants: any;
}

export interface VehicleFuelType {
    value: string;
    viewValue: string;
}

export interface CountryCodes {
    value: string;
    viewValue: string;
}

@Component({
    host: {
        '(document:click)': 'onClick($event)',
    },
    selector: 'create-order',
    templateUrl: './create-order.component.html',
    styleUrls: ['./create-order.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        },
    ],
})
export class CreateOrderComponent implements OnInit {
    /**
     * Constructor
     * @param {Router} router
     * @param {LoginService} loginService
     * @param {CreateOrderService} rService
     * @param {Papa} papa
     */
    constructor(
        public router: Router,
        private loginService: LoginService,
        private coService: CreateOrderService,
        private _formBuilder: FormBuilder,
        public zone: NgZone,
        private http: HttpClient
    ) {
        this.token = this.loginService.getToken();
        if (!this.token) {
            this.router.navigate(['/auth/login']);
        }
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        this.vehicleRegAutoData = {
            model: '',
            fuelType: '',
            odometerReading: '',
            name: '',
            countryCode: '',
            mobile: '',
            customerId: '',
            buildingName: '',
            houseNumber: '',
            landMark: '',
            order_type_tag: '',
            service_type_tag: '',
            appointmentDateTime: '',
            customerNote: '',
            email: '',
            make: '',
            rowKey: '',
            matchedModel: '',
            matchedRowKey: '',
            vehicleRegistration: '',
        };

        const accountData = JSON.parse(this.loginService.getLoginData());
        this.renaultMerchantId = accountData.merchant.id;
        const renaultAccount = accountData.account.name;
        if (renaultAccount.toLowerCase().indexOf('renault') > -1) {
            this.renaultConvenience = true;
        } else {
            this.renaultConvenience = false;
        }
    }
    vehiclefuelType: VehicleFuelType[] = [
        { value: 'petrol', viewValue: 'Petrol' },
        { value: 'diesel', viewValue: 'Diesel' },
        { value: 'cng/petrol', viewValue: 'CNG/Petrol' },
        { value: 'lpg/petrol', viewValue: 'LPG/Petrol' },
    ];

    countryCodes: CountryCodes[] = [
        { value: '91', viewValue: '91' },
        { value: '65', viewValue: '65' },
    ];
    renaultMerchantId: any;
    renaultConvenience = false;
    countryCode = '91';
    token: any;
    isLinear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    fourthFormGroup: FormGroup;
    extractData: any;
    carsData: any;
    manufacturers = [];
    cars: Cars[];
    registrationNumber: any;
    orderResult: any;
    vehicleModelResult = [];
    vehicleRegAutoData: any;
    values: '';
    showResultSearch = false;

    establishmentAddress: Object;
    resultModelSearch = [];
    formattedAddress: string;
    formattedEstablishmentAddress: string;
    phone: string;
    matchedRowKey: any;
    selectedMatchedData = [];
    pincode: any;
    locality: any;
    city: String;
    response: any;
    accountId: any;
    completeNumber: any;
    userId: any;
    vehicleId: any;
    fullAddress: any;
    createResponse: any;
    orderStartTimeMs: any;
    sublocality_level_3: any;
    sublocality_level_2: any;
    sublocality_level_1: any;
    administrative_area_level_2: any;
    administrative_area_level_1: any;
    country: any;
    selectedTitle: any;
    mobileNumber: any;
    orderMobileResult: any;
    proceedByMobile = false;
    proceedByRegistration = true;
    showLoader = false;
    registraitionError = false;
    orderId: number;
    successMessage: string;
    showSuccessMessage = false;
    orderError = false;
    ordersuccess = false;
    mobileValueError = false;
    disableBtn = true;
    public appearance = Appearance;
    public zoom: number;
    public latitude: any;
    public longitude: any;
    public selectedAddress: PlaceResult;
    line2: string;
    selectedMobileCode: number;
    marketPlaceData: any;
    accountData: any;
    permissionData: any;
    permissionType: any;
    marketPlaceAppKey: any;
    marketPlaceAccountId: any;
    showCreateOrder = false;
    NotAllowMessage = false;
    mobileRegistration = false;
    vanRegistration = false;
    showVehicleResult = false;
    checkVehicleVal: any;
    searchText: any;
    selectedSearchText: any;
    carModel = [];
    vehicleRowKey: any;
    selectedDropVal: any;
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
    registrationNumberPattern = '^[aA-zZ0-9]+[aA-zZ0-9]+[aA-zZ]+[0-9]{2,10}$';
    myControl = new FormControl();
    myControl2 = new FormControl();
    options: string[] = ['One', 'Two', 'Three'];
    filteredOptions: Observable<string[]>;
    vehicleFuelType = '';
    odometer = '';
    @ViewChild('addresstext', { static: false })
    public searchElementRef: ElementRef;

    @Input() adressType: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext', { static: false }) addresstext: ElementRef;
    vehicleControl = new FormControl();
    autocompleteInput: string;
    queryWait: boolean;

    public placeSearch;
    public autocomplete;

    public componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
    };

    @ViewChild('selectedValue', { static: false }) selectedValue: ElementRef;

    @ViewChild('mobileNum', { static: false }) mobileNum: ElementRef;
    @ViewChild('addresstext', { static: false }) addressFull: ElementRef;
    @ViewChild('orderStartTime', { static: false }) orderStartTime: ElementRef;
    ngOnInit() {
        this.getCarData();
        this.searchText = '';
        this.marketPlaceData = JSON.parse(this.loginService.getLoginData());
        if (this.marketPlaceData.marketplaceAccounts.length > 0) {
            this.marketPlaceAppKey = this.marketPlaceData.marketplaceAccounts[0].appKey;
            this.marketPlaceAccountId = this.marketPlaceData.marketplaceAccounts[0].accountId;
        } else {
            this.marketPlaceAppKey = this.marketPlaceData.account.appKey;
            this.marketPlaceAccountId = this.marketPlaceData.account.id;
        }
        this.accountData = JSON.parse(this.loginService.getLoginData());
        this.permissionData = JSON.parse(this.loginService.getLoginData());
        for (let i = 0; i < this.permissionData.permissions.length; i++) {
            if (
                this.permissionData.permissions[i].name === 'can_create_order'
            ) {
                this.permissionType = this.permissionData.permissions[i].name;
            }
        }

        // if (this.marketPlaceAppKey && this.marketPlaceAccountId !== undefined && this.permissionType === 'can_create_order'
        // ) {
        //     this.showCreateOrder = true;
        //     this.NotAllowMessage = false;
        // } else {
        //     this.NotAllowMessage = true;
        // }

        this.zoom = 10;
        this.latitude = 52.520008;
        this.longitude = 13.404954;

        this.setCurrentPosition();

        this.firstFormGroup = this._formBuilder.group({
            // registrationVehicle: new FormControl('', Validators.compose([
            //   Validators.minLength(3)
            // ])),
            registrationVehicle: [
                '',
                Validators.pattern(this.registrationNumberPattern),
            ],
            mobileNumber: new FormControl(
                '',
                Validators.compose([
                    Validators.minLength(10),
                    Validators.maxLength(10),
                ])
            ),
        });
        this.secondFormGroup = this._formBuilder.group({
            rowKey: [''],
            fuelType: ['', Validators.required],
            odometer: ['', Validators.required],
        });
        this.thirdFormGroup = this._formBuilder.group({
            name: ['', Validators.required],
            email: ['', Validators.pattern(this.emailPattern)],
            countryCode: [91],
            mobile: ['', Validators.required],
            registrationNumber: [
                '',
                Validators.pattern(this.registrationNumberPattern),
            ],
            customerId: [''],
            fullAddress: [''],
            line1: ['', Validators.required],
            line2: [''],
        });
        this.fourthFormGroup = this._formBuilder.group({
            serviceType: ['', Validators.required],
            orderType: ['', Validators.required],
            orderStartTime: ['', Validators.required],
            customerNotes: [''],
        });
        this.coService.getVehicleModel().subscribe((result) => {
            // this.vehicleModelResult = [];
            this.vehicleModelResult = result.response.body.data;
            for (let i = 0; i < this.vehicleModelResult.length; i++) {
                this.carModel.push(this.vehicleModelResult[i].title);
            }
        });
        this.setMobileNumberValidation();
    }
    autoCloseForDropdownCars(event) {
        const target = event.target;
        if (!target.closest('.vehiclToggle')) {
            this.showVehicleResult = false;
        }
    }

    vehicleSelected(option) {
        this.showVehicleResult = false;
        const selectedCar = option;
        this.selectedSearchText = option;
        for (let j = 0; j < this.vehicleModelResult.length; j++) {
            if (this.vehicleModelResult[j].title === selectedCar) {
                this.vehicleRowKey = this.vehicleModelResult[j].rowKey;
            }
        }
    }
    openVehicleList() {
        this.showVehicleResult = true;
    }
    //   checkVehicle(event) {
    //     this.checkVehicleVal = this.searchText;
    //     for (let j = 0; j < this.vehicleModelResult.length; j++) {
    //         if (this.vehicleModelResult[j].title === this.checkVehicleVal) {
    //             if(!this.selectedDropVal){
    //                 this.searchText= '';
    //             }
    //         }

    //       }
    //   }
    getCountryCode(value) {
        const enteredMobile = this.mobileNum.nativeElement.value;
        this.selectedMobileCode = value;
        const custMobile = this.vehicleRegAutoData.mobile;
        if (this.selectedMobileCode === 91) {
            if (enteredMobile.length !== 10) {
                this.mobileValueError = true;
            } else {
                this.mobileValueError = false;
            }
        }
        if (this.selectedMobileCode === 65) {
            if (enteredMobile.length !== 8) {
                this.mobileValueError = true;
            } else {
                this.mobileValueError = false;
            }
        }
    }

    setMobileNumberValidation() {
        const mobileNumber = this.thirdFormGroup.get('mobile');
        if (this.selectedMobileCode === 91) {
            mobileNumber.setValidators(
                Validators.compose([
                    Validators.minLength(10),
                    Validators.maxLength(10),
                ])
            );
        }
        if (this.selectedMobileCode === 65) {
            mobileNumber.setValidators(
                Validators.compose([
                    Validators.minLength(8),
                    Validators.maxLength(8),
                ])
            );
        }
    }

    private setCurrentPosition() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 12;
            });
        }
    }

    onAutocompleteSelected(result: PlaceResult) {
        this.line2 = result.formatted_address;
        for (let i = 0; i < result.address_components.length; i++) {
            if (i === result.address_components.length - 1) {
                this.pincode = result.address_components[i].long_name;
            }
            if (result.address_components[i].types[0] === 'locality') {
                this.locality = result.address_components[i].long_name;
            }
        }
    }

    onLocationSelected(location: Location) {
        this.latitude = location.latitude;
        this.longitude = location.longitude;
    }

    getCarData() {
        this.coService.getCarsData().subscribe((data: {}) => {
            this.cars = [];
            this.extractData = data;
            this.carsData = this.extractData.response.body.modelMap;
            for (const key in this.carsData) {
                if (this.carsData.hasOwnProperty(key)) {
                    for (let i = 0; i < this.carsData[key].length; i++) {
                        this.cars.push({
                            name: key,
                            variants: this.carsData[key][i],
                        });
                    }
                }
            }
            const output = [];
            this.cars.forEach(function (item) {
                const existing = output.filter(function (v, i) {
                    return v.name === item.name;
                });
                if (existing.length) {
                    const existingIndex = output.indexOf(existing[0]);
                    output[existingIndex].variants = output[
                        existingIndex
                    ].variants.concat(item.variants);
                } else {
                    if (typeof item.name === 'string') {
                        item.variants = [item.variants];
                    }
                    output.push(item);
                }
            });
            this.manufacturers = output;
        });
    }

    resetStepper(stepper: MatStepper) {
        stepper.selectedIndex = 0;
    }

    getCeateOrderData(event) {
        this.mobileValueError = false;
        this.mobileNumber = '';
        let searchTerm = '';
        searchTerm += event.target.value;
        const pattern = /^[aA-zZ0-9]+[aA-zZ0-9]+[aA-zZ]+[0-9]{1,10}$/;
        if (!pattern.test(searchTerm)) {
            this.registraitionError = true;
            this.disableBtn = true;
        } else {
            this.registraitionError = false;
            this.disableBtn = false;
        }
        if (searchTerm !== '') {
            this.proceedByRegistration = true;
            this.proceedByMobile = false;
        }

        this.showResultSearch = false;
        this.coService.getOrderData(searchTerm).subscribe((result) => {
            this.orderResult = result.response.body.users;
        });
    }
    checkAlphanumericFocus(event) {
        const pattern = /^[aA-zZ0-9]+[aA-zZ0-9]+[aA-zZ]+[0-9]{1,10}$/;
        if (!pattern.test(this.registrationNumber)) {
            this.registraitionError = true;
            this.disableBtn = true;
        } else {
            this.registraitionError = false;
            this.disableBtn = false;
        }
    }
    blankMobile() {
        this.mobileNumber = '';
    }

    getVehicleDataByMobile(mobileNumber, event) {
        this.registrationNumber = '';
        let mobileSearch = '';
        mobileSearch += event.target.value;
        if (mobileSearch !== '') {
            this.proceedByMobile = true;
            this.proceedByRegistration = false;
        }

        this.coService.getOrderMobileData(mobileSearch).subscribe((result) => {
            this.orderResult = result.response.body.users;
            this.showResultSearch = true;
            if (this.orderResult.length < 1) {
                this.showResultSearch = false;
            }
        });
    }

    onlyNumberAllow(event) {
        let mobileValue = '';
        mobileValue += event.target.value;
        this.registraitionError = false;
        const charCode = event.which ? event.which : event.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }

        if (this.selectedMobileCode === 91) {
            if (mobileValue.length !== 9) {
                this.mobileValueError = true;
                this.disableBtn = false;
            } else {
                this.mobileValueError = false;
            }
        }

        if (this.selectedMobileCode === 65) {
            if (mobileValue.length !== 7) {
                this.mobileValueError = true;
                this.disableBtn = false;
            } else {
                this.mobileValueError = false;
            }
        }
    }

    numberCheckFocus(event) {
        let mobileValue = '';
        mobileValue += event.target.value;
        if (this.selectedMobileCode === 91) {
            if (mobileValue.length !== 10) {
                this.mobileValueError = true;
                this.disableBtn = false;
            } else {
                this.mobileValueError = false;
            }
        } else if (this.selectedMobileCode === 65) {
            if (mobileValue.length !== 8) {
                this.mobileValueError = true;
                this.disableBtn = false;
            } else {
                this.mobileValueError = false;
            }
        }
    }

    onClick(event) {
        if (event.target.nativeElement !== '_eref') {
            this.showResultSearch = false;
        }
    }

    getVehicleMobileData(mobileNumber) {
        this.mobileRegistration = true;
        this.vanRegistration = false;
        this.coService.getOrderMobileData(mobileNumber).subscribe((result) => {
            this.orderResult = result.response.body.users;
            this.vehicleRegAutoData.make = result.response.body.users[0].make;
            this.vehicleRegAutoData.model = result.response.body.users[0].model;
            this.vehicleRegAutoData.name = result.response.body.users[0].name;
            this.vehicleRegAutoData.mobile =
                result.response.body.users[0].mobile;
            this.vehicleRegAutoData.email = result.response.body.users[0].email;
            this.vehicleRegAutoData.vehicleRegistration =
                result.response.body.users[0].registrationNumber;

            for (let i = 0; i < this.vehicleModelResult.length; i++) {
                if (
                    this.vehicleModelResult[i].model ===
                    this.vehicleRegAutoData.model
                ) {
                    this.selectedMatchedData = [];
                    this.selectedMatchedData.push(this.vehicleModelResult[i]);
                    this.vehicleRegAutoData.matchedModel = this.selectedMatchedData[0].model;
                    this.vehicleRegAutoData.matchedRowKey = this.selectedMatchedData[0].rowKey;
                    this.selectedTitle = this.selectedMatchedData[0].title;
                }
            }
        });
    }
    getVehicleRegData(registration) {
        this.mobileRegistration = false;
        this.vanRegistration = true;
        if (registration !== '') {
            this.coService.getOrderData(registration).subscribe((result) => {
                this.orderResult = result.response.body.users;
                this.vehicleRegAutoData.make =
                    result.response.body.users[0].make;
                this.vehicleRegAutoData.model =
                    result.response.body.users[0].model;
                this.vehicleRegAutoData.name =
                    result.response.body.users[0].name;
                this.vehicleRegAutoData.mobile =
                    result.response.body.users[0].mobile;
                this.vehicleRegAutoData.email =
                    result.response.body.users[0].email;
                this.vehicleRegAutoData.vehicleRegistration =
                    result.response.body.users[0].registrationNumber;
                for (let i = 0; i < this.vehicleModelResult.length; i++) {
                    this.resultModelSearch.push(this.vehicleModelResult[i]);
                    if (
                        this.resultModelSearch[i].model ===
                        this.vehicleRegAutoData.model
                    ) {
                        this.selectedMatchedData = [];
                        this.selectedMatchedData.push(
                            this.resultModelSearch[i]
                        );
                        this.vehicleRegAutoData.matchedModel = this.selectedMatchedData[0].model;
                        this.vehicleRegAutoData.matchedRowKey = this.selectedMatchedData[0].rowKey;
                        this.selectedTitle = this.selectedMatchedData[0].title;
                    }
                }
            });
        }
    }

    getmobileNumbValue(event: any) {
        this.proceedByMobile = true;
        this.proceedByRegistration = false;
        this.showResultSearch = false;
        this.mobileNumber = event.target.innerHTML;

        if (this.mobileNumber.length !== 10) {
            this.mobileValueError = true;
        } else {
            this.mobileValueError = false;
        }
    }

    invokeEvent(place: Object) {
        this.setAddress.emit(place);
    }

    addGmsUpdateUser(event) {
        const initNum = `${GlobalVariable.COMPLETE_NUMBER}`;
        const mobile = this.mobileNum.nativeElement.value;
        this.completeNumber = initNum + mobile;
        const formData = new FormData();
        formData.append('appKey', this.marketPlaceAppKey);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('type', `${GlobalVariable.TYPE}`);
        formData.append('name', this.thirdFormGroup.get('name').value);
        formData.append('mobile', this.thirdFormGroup.get('mobile').value);
        formData.append('email', this.thirdFormGroup.get('email').value);
        formData.append(
            'countryCode',
            this.thirdFormGroup.get('countryCode').value
        );
        formData.append('line1', this.thirdFormGroup.get('line1').value);
        formData.append('line2', this.line2);
        this.fullAddress =
            this.thirdFormGroup.get('line1').value + ',' + this.line2;

        formData.append('address', this.fullAddress);
        formData.append('completeNumber', this.completeNumber);
        formData.append('rowKey', this.vehicleRowKey);
        formData.append('fuelType', this.secondFormGroup.get('fuelType').value);
        formData.append(
            'registrationNumber',
            this.thirdFormGroup.get('registrationNumber').value
        );
        formData.append('latitude', this.latitude);
        formData.append('longitude', this.longitude);
        formData.append('city', this.locality);
        formData.append('locality', this.locality);
        formData.append('pincode', this.pincode);
        formData.append('language', `${GlobalVariable.LANGUAGE}`);
        formData.append('device', `${GlobalVariable.DEVICE}`);
        formData.append('orderOf', `${GlobalVariable.ORDER_OF}`);
        return this.http
            .post(
                `${GlobalVariable.BASE_API_URL}` + '/gmsaddupdateuser',
                formData
            )
            .subscribe((data) => {
                this.response = data;
                this.accountId = this.response.response.body.merchantuser.accountId;
                this.userId = this.response.response.body.merchantuser.userId;
                this.vehicleId = this.response.response.body.vehicle.id;
            });
    }

    createOrder(event) {
        this.showLoader = true;
        this.orderStartTimeMs = Date.parse(
            this.orderStartTime.nativeElement.value
        );

        const formData = new FormData();
        formData.append('appKey', this.marketPlaceAppKey);
        formData.append('accountId', this.marketPlaceAccountId);
        formData.append('sessionId', this.loginService.getToken());
        formData.append('type', `${GlobalVariable.TYPE}`);
        formData.append(
            'customerAddressId',
            `${GlobalVariable.CUSTOMER_ADDRESS_ID}`
        );
        formData.append('sendSms', `${GlobalVariable.SEND_SMS}`);
        formData.append('version', `${GlobalVariable.VERSION}`);
        formData.append('odometer', this.secondFormGroup.get('odometer').value);
        formData.append('address', this.fullAddress);
        formData.append('line1', this.thirdFormGroup.get('line1').value);
        formData.append('line2', this.line2);
        formData.append(
            'customerNotes',
            this.fourthFormGroup.get('customerNotes').value
        );
        formData.append('orderStartTime', this.orderStartTimeMs);
        formData.append(
            'serviceType',
            this.fourthFormGroup.get('serviceType').value
        );
        formData.append(
            'orderType',
            this.fourthFormGroup.get('orderType').value
        );
        formData.append('accountId', this.accountId);
        formData.append('userId', this.userId);
        formData.append('vehicleId', this.vehicleId);
        formData.append('latitude', this.latitude);
        formData.append('longitude', this.longitude);
        formData.append('city', this.locality);
        formData.append('locality', this.locality);
        formData.append('pincode', this.pincode);
        formData.append('language', `${GlobalVariable.LANGUAGE}`);
        formData.append('device', `${GlobalVariable.DEVICE}`);
        formData.append('orderOf', `${GlobalVariable.ORDER_OF}`);
        formData.append('orderSourceType', 'web');
        formData.append('orderSource', 'gms');
        if (`${GlobalVariable.MARKET_PLACE_APP_KEY}` && this.accountId !== '') {
            return this.http
                .post(
                    `${GlobalVariable.BASE_API_URL}` + '/gmsquickcreate',
                    formData
                )
                .subscribe((data) => {
                    this.createResponse = data;
                    this.orderId = this.createResponse.response.body.orderId;
                    this.successMessage = this.createResponse.response.message;

                    this.showLoader = false;
                    this.showSuccessMessage = true;
                    setTimeout(() => {
                        this.showSuccessMessage = false;
                    }, 5000);
                    if (this.orderId == null) {
                        this.orderError = true;
                        this.ordersuccess = false;
                    } else {
                        this.ordersuccess = true;
                        this.orderError = false;
                    }
                    if (this.orderId !== undefined) {
                        this.router.navigate(['orders/details/', this.orderId]);
                    }
                });
        }
    }
}
