import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
// import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { ApplicationPipesModule } from 'app/main/applicationPipesModule.module';
// import { MatRadioModule } from '@angular/material/radio';
// import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AspHomeComponent } from './asp-home.component'; 

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';

export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


const routes: Routes = [
  {
      path: 'auto-spare-parts/home',
      component: AspHomeComponent,
  },
];

@NgModule({
  declarations: [AspHomeComponent],
  imports: [    
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatCardModule,
    // MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    // MatStepperModule,
    CdkStepperModule,
    MatProgressBarModule,
    // MatProgressSpinnerModule,
    ApplicationPipesModule,
    // MatRadioModule,
    // MatAutocompleteModule,
    HttpClientModule,
    FlexLayoutModule,
    MatTabsModule,
    TranslateModule.forRoot({
    loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient]
    }
    })
  ],  
  exports: [AspHomeComponent],
})
export class AspHomeModule { }
