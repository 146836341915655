<div class="page-layout blank fullwidth p-12 all-cat" fusePerfectScrollbar>
    <h2 class="all-cat-title">All Categories        
        <mat-icon class="s-20" (click)="closePopup()">close</mat-icon>
    </h2>

    <div fxLayout="column" fxLayout.xs="column" style="padding: 10px;">
        <div fxLayout="row wrap" fxLayoutGap="10px">
            <div *ngFor="let cat of allCategories; let i = index" fxFlex="23%">
                <mat-card (click)="categorySparesList(cat.name, cat.imageUrl)">
                    <img src="{{cat.imageUrl}}">
                    <div class="cat_name">{{cat.name}}</div>
                </mat-card>
            </div>                      
        </div>
    </div>
</div>